import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import {
  setTableColumnSelection,
  clearCmdbCiServerMessages,
  CmdbCiServerSelector,
} from '../../../../store/serviceNow/cmdbCiServer/cmdbCiServer.reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { deleteCmdbCiServer, searchCmdbCiServer } from '../../../../store/serviceNow/cmdbCiServer/cmdbCiServer.action';
import _ from 'lodash';
import CmdbCiServerService from '../../../../services/serviceNow/cmdbCiServer/cmdbCiServer.service';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import { AlignType } from 'rc-table/lib/interface';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { showDateFromApi } from '../../../../common/helperFunction';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    setFilterKeys,
    tableButtons,
  } = props;
  const CmdbCiServer = useAppSelector(CmdbCiServerSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return CmdbCiServerService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, columnWidthOpt?: boolean, isRelativeDropDownFlag?: boolean) => {
   setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      CmdbCiServer.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      columnWidthOpt === undefined ? false : columnWidthOpt,
      isRelativeDropDownFlag
    );
  };

  const FilterByDateSwapTable = (dataIndex: string, tableName: string, form: any) => {
    return FilterByDateSwap(dataIndex, tableName, form, null, ObjectForColumnFilter);
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form, true),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              false,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              false,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              false,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwapTable('date_added', CmdbCiServer.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Virtual</span>,
        column: 'virtual',
        sorter: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'virtual',
              CmdbCiServer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'virtual',
            key: 'virtual',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">FQDN</span>,
        column: 'fqdn',
        sorter: true,
        children: [
          {
            title: FilterBySwap('fqdn', form),
            dataIndex: 'fqdn',
            key: 'fqdn',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('name', form),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Class Name</span>,
        column: 'sys_class_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_class_name', form),
            dataIndex: 'sys_class_name',
            key: 'sys_class_name',
            ellipsis: true,
          },
        ],
      },

      {
        title: <span className="dragHandler">Department</span>,
        column: 'department',
        sorter: true,
        children: [
          {
            title: FilterBySwap('department', form),
            dataIndex: 'department',
            key: 'department',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Install Status</span>,
        column: 'install_status',
        sorter: true,
        children: [
          {
            title: FilterBySwap('install_status', form),
            dataIndex: 'install_status',
            key: 'install_status',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operational Status</span>,
        column: 'operational_status',
        sorter: true,
        children: [
          {
            title: FilterBySwap('operational_status', form),
            dataIndex: 'operational_status',
            key: 'operational_status',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">IP Address</span>,
        column: 'ip_address',
        sorter: true,
        children: [
          {
            title: FilterBySwap('ip_address', form),
            dataIndex: 'ip_address',
            key: 'ip_address',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">OS</span>,
        column: 'os',
        sorter: true,
        children: [
          {
            title: FilterBySwap('os', form),
            dataIndex: 'os',
            key: 'os',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Classification</span>,
        column: 'classification',
        sorter: true,
        children: [
          {
            title: FilterBySwap('classification', form),
            dataIndex: 'classification',
            key: 'classification',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">CPU Core Count</span>,
        column: 'cpu_core_count',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cpu_core_count', form),
            dataIndex: 'cpu_core_count',
            key: 'cpu_core_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">CPU Count</span>,
        column: 'cpu_count',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cpu_count', form), 
            dataIndex: 'cpu_count',
            key: 'cpu_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">CPU Manufacturer</span>,
        column: 'cpu_manufacturer',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cpu_manufacturer', form),
            dataIndex: 'cpu_manufacturer',
            key: 'cpu_manufacturer',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">CPU Name</span>,
        column: 'cpu_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cpu_name', form),
            dataIndex: 'cpu_name',
            key: 'cpu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Crowdstrike Host ID</span>,
        column: 'u_crowd_strike_host_id',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_crowd_strike_host_id', form),
            dataIndex: 'u_crowd_strike_host_id',
            key: 'u_crowd_strike_host_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Model ID</span>,
        column: 'model_id',
        sorter: true,
        children: [
          {
            title: FilterBySwap('model_id', form),
            dataIndex: 'model_id',
            key: 'model_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Serial Number</span>,
        column: 'serial_number',
        sorter: true,
        children: [
          {
            title: FilterBySwap('serial_number', form),
            dataIndex: 'serial_number',
            key: 'serial_number',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Used For</span>,
        column: 'used_for',
        sorter: true,
        children: [
          {
            title: FilterBySwap('used_for', form),
            dataIndex: 'used_for',
            key: 'used_for',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Support Group</span>,
        column: 'support_group',
        sorter: true,
        children: [
          {
            title: FilterBySwap('support_group', form),
            dataIndex: 'support_group',
            key: 'support_group',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Raw OS</span>,
        column: 'u_raw_os',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_raw_os', form),
            dataIndex: 'u_raw_os',
            key: 'u_raw_os',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Raww Operation System Version</span>,
        column: 'u_raw_operating_system_version',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_raw_operating_system_version', form),
            dataIndex: 'u_raw_operating_system_version',
            key: 'u_raw_operating_system_version',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Location</span>,
        column: 'location',
        sorter: true,
        children: [
          {
            title: FilterBySwap('location', form),
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Discovered</span>,
        column: 'last_discovered',
        sorter: true,
        children: [
          {
            title: FilterBySwap('last_discovered', form),
            dataIndex: 'last_discovered',
            key: 'last_discovered',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Backup Reten</span>,
        column: 'u_backup_re_ten',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_backup_re_ten', form),
            dataIndex: 'u_backup_re_ten',
            key: 'u_backup_re_ten',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Backup Time</span>,
        column: 'u_back_up_time',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_back_up_time', form),
            dataIndex: 'u_back_up_time',
            key: 'u_back_up_time',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">DR Backup</span>,
        column: 'dr_backup',
        sorter: true,
        children: [
          {
            title: FilterBySwap('dr_backup', form),
            dataIndex: 'dr_backup',
            key: 'dr_backup',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Maintenance Schedule</span>,
        column: 'maintenance_schedule',
        sorter: true,
        children: [
          {
            title: FilterBySwap('maintenance_schedule', form),
            dataIndex: 'maintenance_schedule',
            key: 'maintenance_schedule',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U AMI</span>,
        column: 'u_ami',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_ami', form),
            dataIndex: 'u_ami',
            key: 'u_ami',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Build Image</span>,
        column: 'u_build_image',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_build_image', form),
            dataIndex: 'u_build_image',
            key: 'u_build_image',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Discovery Source</span>,
        column: 'discovery_source',
        sorter: true,
        children: [
          {
            title: FilterBySwap('discovery_source', form),
            dataIndex: 'discovery_source',
            key: 'discovery_source',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Tanium Discovered</span>,
        column: 'u_tanium_discovered',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_tanium_discovered', form),
            dataIndex: 'u_tanium_discovered',
            key: 'u_tanium_discovered',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Tanium Instance Type</span>,
        column: 'u_tanium_instance_type',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_tanium_instance_type', form),
            dataIndex: 'u_tanium_instance_type',
            key: 'u_tanium_instance_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Cluster</span>,
        column: 'u_cluster',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_cluster', form),
            dataIndex: 'u_cluster',
            key: 'u_cluster',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster ID</span>,
        column: 'cluster_id',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cluster_id', form),
            dataIndex: 'cluster_id',
            key: 'cluster_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster Name</span>,
        column: 'cluster_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cluster_name', form),
            dataIndex: 'cluster_name',
            key: 'cluster_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Environment</span>,
        column: 'environment',
        sorter: true,
        children: [
          {
            title: FilterBySwap('environment', form),
            dataIndex: 'environment',
            key: 'environment',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Managed By Group</span>,
        column: 'managed_by_group',
        sorter: true,
        children: [
          {
            title: FilterBySwap('managed_by_group', form),
            dataIndex: 'managed_by_group',
            key: 'managed_by_group',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Managed By</span>,
        column: 'managed_by',
        sorter: true,
        children: [
          {
            title: FilterBySwap('managed_by', form),
            dataIndex: 'managed_by',
            key: 'managed_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Owned By</span>,
        column: 'owned_by',
        sorter: true,
        children: [
          {
            title: FilterBySwap('owned_by', form),
            dataIndex: 'owned_by',
            key: 'owned_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor</span>,
        column: 'processor',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor', form),
            dataIndex: 'processor',
            key: 'processor',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor Name</span>,
        column: 'processor_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor_name', form),
            dataIndex: 'processor_name',
            key: 'processor_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">DNS Domain</span>,
        column: 'dns_domain',
        sorter: true,
        children: [
          {
            title: FilterBySwap('dns_domain', form),
            dataIndex: 'dns_domain',
            key: 'dns_domain',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U ILO FQDN</span>,
        column: 'u_ilo_fqdn',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_ilo_fqdn', form),
            dataIndex: 'u_ilo_fqdn',
            key: 'u_ilo_fqdn',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Domain</span>,
        column: 'sys_domain',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_domain', form),
            dataIndex: 'sys_domain',
            key: 'sys_domain',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Domain Path</span>,
        column: 'sys_domain_path',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_domain_path', form),
            dataIndex: 'sys_domain_path',
            key: 'sys_domain_path',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Install Date</span>,
        column: 'Install Date',
        sorter: true,
        children: [
          {
            title: FilterBySwap('install_date', form),
            dataIndex: 'install_date',
            key: 'install_date',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeCmdbCiServer = (id: number) => {
    dispatch(deleteCmdbCiServer(id));
  };

  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.CmdbCiServer}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/service-now/ciserver/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.CmdbCiServer}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeCmdbCiServer(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.CmdbCiServer)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={CmdbCiServerSelector}
        searchTableData={searchCmdbCiServer}
        clearTableDataMessages={clearCmdbCiServerMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.CmdbCiServer)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
