import { Button, Col, Form, InputNumber, Modal, Popconfirm, Row, Select, Spin, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { IPreviewExcel } from './PreviewExcel.model';
import { toast } from 'react-toastify';
import { bulkImportV2Selector, clearCSVExcelColumns } from '../../../../store/bulkImportV2/bulkImportV2.reducer';
import commonService from '../../../../services/common/common.service';
import { getCSVExcelColumns } from '../../../../store/bulkImportV2/bulkImportV2.action';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';
import { DEFAULT_PAGE_SIZE } from '../../../../common/constants/common';

const { Option } = Select;

const PreviewExcel: React.FC<IPreviewExcel> = (props) => {
  const {
    headerRowCount,
    dataRecords,
    setRecords,
    previewData,
    setDelimitFlag,
    records,
    showModal,
    handleModalClose,
    maxCount,
    maxColumn,
    seqNumber,
    firstFlag,
    setFirstFlag,
    setExcelPreviewData,
    setFlagForMappingHighlights,
    setHeaderDropDown,
  } = props;

  const [columns, setColumns] = useState([]);
  const dispatch = useAppDispatch();
  const [showDelimiter, setShowDelimiter] = useState(false);
  const [nullifyMapping, setNullifyMapping] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableWithDropDown, setTableWithDropDown] = useState(false);
  const [isMappingDropdown, setIsMappingDropDown] = useState(false);
  const [tableColumnState, setTableColumnState] = useState([]);
  const [mappingExcelWithDropDown, setMappingExcelWithDropDown] = useState([]);
  const bulkImportV2 = useAppSelector(bulkImportV2Selector);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    showSizeChanger: true,
  });
  const globalLookups = useAppSelector(globalSearchSelector);

  const checkDelimiter = () => {
    setDelimitFlag(false);
    setFirstFlag(true);
    const dummyRecord = dataRecords.filter((data) => data.index == seqNumber)[0];
    const arr = [
      {
        filename: dummyRecord.original_filename,
        original_filename: dummyRecord.filename,
        file_path: dummyRecord.file_path,
        delimiter: form.getFieldValue('deli_meter'),
      },
    ];
    const global_dd = {
      tenant_id: globalLookups.search.tenant_id ? globalLookups.search.tenant_id : null,
      company_id: globalLookups.search.company_id ? globalLookups.search.company_id : null,
      bu_id: globalLookups.search.bu_id ? globalLookups.search.bu_id : null,
    };
    const Obj = { csv_file_info: arr, global_dd };
    dispatch(getCSVExcelColumns(Obj));
  };

  useEffect(() => {
    if (firstFlag) {
      form.setFieldsValue({ no_header: false });
      changeHeader(false);
      if (
        bulkImportV2.getCSVExcelColumns.csvFiles !== null &&
        bulkImportV2.getCSVExcelColumns.csvFiles?.length > 0
      ) {
        toast.warn('Please re-check your Delimiter');
      }
      if (
        bulkImportV2.getCSVExcelColumns.csvFiles !== null &&
        bulkImportV2.getCSVExcelColumns.csvFiles?.length == 0
      ) {
        setNullifyMapping(true);
        toast.success('Your Delimiter is On Mark!');
        previewData(true, form.getFieldValue('header_row'));
      }
    }
  }, [bulkImportV2.getCSVExcelColumns.csvFiles]);

  useEffect(() => {
    const dummyRecord = dataRecords?.filter((data) => data.index === seqNumber);
    showModal && previewData(false, headerRowCount);
    if ((dummyRecord[0].is_dynamic_header)) {
      setTimeout(() => {
        addDummyHeader();
      },);
    }
  }, [showModal]);

  const [form] = Form.useForm();
  const initialValues = {
    file_path: dataRecords.filter((data) => data.index == seqNumber)[0]?.is_dynamic_header,
    header_row: headerRowCount,
    no_header: dataRecords.filter((data) => data.index == seqNumber).length > 0 &&
      dataRecords.filter((data) => data.index == seqNumber)[0].is_dynamic_header == true ? true : false,
    deli_meter:
      dataRecords.filter((data) => data.index == seqNumber).length > 0 &&
        dataRecords.filter((data) => data.index == seqNumber)[0].delimiter !== null
        ? dataRecords.filter((data) => data.index == seqNumber)[0].delimiter
        : ',',
  };

  useEffect(() => {
    let tableName;
    const dummy = _.cloneDeep(dataRecords);
    const mappingDummy = dummy.filter((data) => data.index === seqNumber)[0]?.excel_to_sql_mapping;
    setMappingExcelWithDropDown(mappingDummy);
    dataRecords.map((data) => {
      if (data.index == seqNumber) {
        data.original_filename.slice(((data?.original_filename.lastIndexOf('.') - 1) >>> 0) + 2) ==
          'csv' ||
          data.original_filename.slice(((data?.original_filename.lastIndexOf('.') - 1) >>> 0) + 2) ==
          'txt'
          ? setShowDelimiter(true)
          : setShowDelimiter(false);
        if (data.is_dynamic_header) {
          setTimeout(() => {
            addDummyHeader();
          },);
        }
      }
    });
    const initialValues = {
      header_row: headerRowCount,
    };
    form.setFieldsValue(initialValues);
    const dummyRecords = _.cloneDeep(dataRecords);
    dummyRecords.map((data) => {
      if (data.index === seqNumber) {
        tableName = data.table_name;
      }
    });
    if (tableName) {
      commonService.getTableColumns(tableName).then((res) => {
        if (res) {
          const response: any = res;
          const columnsArray = ['tenantid', 'companyid', 'bu_id', 'date added'];
          const filterTableColumns = response?.filter(
            (x) => !columnsArray.includes(x.name?.toLowerCase())
          );
          setTableColumnState(filterTableColumns);
        }
      });
    } else {
      setColumnForTable();
      setTableWithDropDown(true);
    }
    return () => {
      dispatch(clearCSVExcelColumns());
      setDelimitFlag(true);
      setFirstFlag(false);
    };
  }, []);

  useEffect(() => {
    if (tableColumnState.length)
      setColumnForTable();
  }, [tableColumnState, maxColumn]);

  // const onDropDownChange = (event, index) => {
  //   setNullifyMapping(false);
  //   const mappingName = records[0][index - 1];

  //   const dummyMapping = mappingExcelWithDropDown;
  //   dummyMapping?.map((x) => {
  //     if (x.key === event) {
  //       setIsMappingDropDown(true);
  //       x.value = mappingName;
  //     }
  //   });
  //   setMappingExcelWithDropDown(dummyMapping);
  // };

  //For Drop Down on Columns of table
  // const dropDown = (dataIndex) => {
  //   const selectedRecord = dataRecords.filter((data) => data.index === seqNumber)[0];
  //   const mapping = selectedRecord.excel_to_sql_mapping?.filter((data) => data.value === records[0][dataIndex - 1]);
  //   const arr = [];
  //   if (mapping?.length) {
  //     mapping.map((data) => arr.push(data.key));
  //   }

  //   setTableWithDropDown(true);
  //   return (
  //     <>
  //       <Form.Item
  //         name={'description' + dataIndex}
  //         className="m-0"
  //         initialValue={mapping && mapping?.length ? arr : undefined}
  //       >
  //         <Select
  //           showSearch
  //           //mode='multiple'
  //           dropdownClassName="value-box-select-medium"
  //           placeholder="Select"
  //           optionFilterProp="children"
  //           onChange={(e) => onDropDownChange(e, dataIndex)}
  //           filterOption={(input, option) =>
  //             option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //           }
  //         >
  //           {tableColumnState?.map(
  //             (option) => (
  //               <Option key={option.name} value={option.name}>
  //                 {option.name}
  //               </Option>
  //             )
  //           )}
  //         </Select>
  //       </Form.Item>
  //     </>
  //   );
  // };

  const setColumnForTable = () => {
    const mainColumns = [];

    for (let index = 0; index <= maxColumn; index++) {
      mainColumns.push({
        ellipsis: true,
        dataIndex: 'description' + index,
        key: 'description' + index,
        width: '80px',
        render: (_, data: any, i) => {
          return (
            <>
              {index === 0
                ? headerRowCount === 0
                  ? 1
                  : i + (form.getFieldValue('header_row') ?? 1)
                : data[index - 1]}
            </>
          );
        },
      });
    }
    setColumns(mainColumns);
  };

  useEffect(() => {
    if (records?.length > 0) {
      const dummyRecord = dataRecords?.filter((data) => data.index == seqNumber);
      if (dummyRecord && dummyRecord[0].is_dynamic_header && form.getFieldValue('no_header') && !(records[0][0]?.includes('Column')))
        addDummyHeader();
      setTableData(records);
    }
  }, [records]);

  const handleTableChange = (paginating) => {
    setPagination(paginating);
  };

  const addDummyHeader = () => {
    if (records && records?.length && !(records[0][0]?.toString()?.includes('Column'))) {
      //form.setFieldsValue({ header_row: 1 });
      const dummyRec = records[0];
      const columnHeader = [];
      for (let i = 0; i < dummyRec?.length; i++) {
        columnHeader.push(`Column-${('0' + (i + 1)).slice(-2)}`);
      }
      const rec = [columnHeader].concat(records);
      setExcelPreviewData(rec);
      setTableData(rec);
    }
  }

  const changeHeader = (value) => {
    setNullifyMapping(false);
    if (value) {
      addDummyHeader();
      setNullifyMapping(false);

      const dummyMapping = mappingExcelWithDropDown;
      dummyMapping?.map((x) => {
        if (x.value !== '') {
          const index = records[0].findIndex((data) => data == x.value);
          setIsMappingDropDown(true);
          x.value = `Column-${('0' + (index + 1)).slice(-2)}`;
        }
      });
      setMappingExcelWithDropDown(dummyMapping);
      // const dummyRecords = _.cloneDeep(dataRecords);
      // dummyRecords?.map((data) => {
      //   if (data.index == seqNumber) {
      //     data?.excel_to_sql_mapping?.map((data1) => {
      //       data1.value = null;
      //     });
      //   }
      // });
      // setRecords(dummyRecords);
    } else {
      records.shift();
      setExcelPreviewData(records);
      setTableData(records);
      handleTableChange(10);
    }
  };

  const submitHeaderRow = (values: any) => {
    setFlagForMappingHighlights(false);
    const dummyRecords = _.cloneDeep(dataRecords);
    dummyRecords.map((data) => {
      if (data.index == seqNumber) {
        data.header_row = values.header_row;
        data.delimiter = values.deli_meter;
        data.excel_to_sql_mapping = nullifyMapping ? null : (isMappingDropdown ? mappingExcelWithDropDown : data.excel_to_sql_mapping);
        data.is_dynamic_header = values.no_header;
        data.currentMapping = null;
      }
    });
    setRecords(dummyRecords);
    handleModalClose();
  };

  return (
    <Modal
      wrapClassName="custom-modal"
      title={'Manage Excel'}
      centered
      visible={showModal}
      onCancel={handleModalClose}
      footer={false}
    >
      <Form form={form} name="formUpload" initialValues={initialValues} onFinish={submitHeaderRow}>
        <Row gutter={[30, 15]} className="form-label-hide">
          <Col xs={24} sm={12} md={12}>
            <div className="form-group ">
              <label className="label">Header Row</label>
              <Form.Item
                name="header_row"
                className="m-0"
                rules={[
                  {
                    required: true,
                    type: 'integer',
                    message: 'Header Row is Required and Integral',
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  max={maxCount}
                  disabled={form.getFieldValue('no_header')}
                  className="form-control w-100"
                  onChange={(value) => {
                    previewData(false, value);
                    setHeaderDropDown([]);
                    setNullifyMapping(true);
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          {
            (<Col xs={24} sm={12} md={12}>
              <div className="form-group form-inline-pt m-0">
                <Form.Item
                  name="no_header"
                  className="m-0"
                  valuePropName="checked"
                >
                  <Switch className="form-control" onChange={(value) => { changeHeader(value); }} />
                </Form.Item>
                <label className="label">No Headers?</label>
              </div>
            </Col>)}
          {showDelimiter ? (
            <>
              <Col xs={24} sm={12} md={12}>
                <div className="form-group ">
                  <label className="label">Delimiter</label>
                  <Form.Item
                    name="deli_meter"
                    className="m-0"
                    rules={[{ required: true, message: 'Delimiter is Required' }]}
                  >
                    <Select
                      placeholder="Select a Delimit"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value=";">Semi Colon(;)</Option>
                      <Option value=",">Comma(,)</Option>
                      <Option value='$T'>TAB(  )</Option>
                      <Option value='#T'>TAB( UCS-2 Encoding )</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group ">
                  <label className="label">Click to check Delimiter</label>
                  <Button
                    type="primary"
                    loading={bulkImportV2.getCSVExcelColumns.loading}
                    onClick={() => {
                      checkDelimiter();
                    }}
                  >
                    Check Delimiter
                  </Button>
                </div>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        {(tableWithDropDown) || (columns?.length > 0) ?
          (<Table
            scroll={{ x: true }}
            rowKey={(record) => JSON.stringify(record)}
            loading={bulkImportV2.getCSVExcelColumns.loading}
            pagination={{
              ...pagination,
              pageSizeOptions: [
                '10','30'
              ],
              total: records?.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            dataSource={tableData?.length > 0 ? [...tableData] : []}
            columns={columns}
            className="custom-table first-row-header"
          />) : (<Spin />)}
        <div className="btns-block modal-footer">
          <Popconfirm
            title="Your mapping drop down will be over-written.Please re-check your mapping details after confirming"
            onConfirm={() => submitHeaderRow(form.getFieldsValue())}
          >
            <Button key="submit" type="primary" htmlType="submit">
              Ok
            </Button>
          </Popconfirm>
        </div>
      </Form>
    </Modal>
  );
};

export default PreviewExcel;
