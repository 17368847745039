import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigSoftwarePublisher,
  ISearchConfigSoftwarePublisher,
} from '../../../services/master/configSoftwarePublisher/configSoftwarePublisher.model';
import configSoftwarePublisher from '../../../services/master/configSoftwarePublisher/configSoftwarePublisher.service';
// Asynchronous thunk action

export const searchConfigSoftwarePublisher = createAsyncThunk(
  'searchConfigSoftwarePublisher',
  async (searchParam?: ISearchConfigSoftwarePublisher) => {
    const response = await configSoftwarePublisher.searchConfigSoftwarePublisher(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigSoftwarePublisherById = createAsyncThunk(
  'getConfigSoftwarePublisherById',
  async (id: number) => {
    const response = await configSoftwarePublisher.getConfigSoftwarePublisherById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigSoftwarePublisher = createAsyncThunk(
  'saveConfigSoftwarePublisher',
  async (data: IConfigSoftwarePublisher) => {
    const response = await configSoftwarePublisher.saveConfigSoftwarePublisher(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigSoftwarePublisher = createAsyncThunk(
  'deleteConfigSoftwarePublisher',
  async (id: number) => {
    const response = await configSoftwarePublisher.deleteConfigSoftwarePublisher(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
