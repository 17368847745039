import { createAsyncThunk } from '@reduxjs/toolkit';
import CmdbRelCiService from '../../../services/serviceNow/cmdbRelCi/cmdbRelCi.service';
import {
  ISearchCmdbRelCi,
  ICmdbRelCi,
  IProcessData,
} from '../../../services/serviceNow/cmdbRelCi/cmdbRelCi.model';

// Asynchronous thunk action

export const searchCmdbRelCi = createAsyncThunk(
  'searchCmdbRelCi',
  async (searchParam?: ISearchCmdbRelCi) => {
    const response = await CmdbRelCiService.searchCmdbRelCi(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getCmdbRelCiById = createAsyncThunk('getCmdbRelCiById', async (id: number) => {
  const response = await CmdbRelCiService.getCmdbRelCiById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveCmdbRelCi = createAsyncThunk('saveCmdbRelCi', async (data: ICmdbRelCi) => {
  const response = await CmdbRelCiService.saveCmdbRelCi(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteCmdbRelCi = createAsyncThunk('deleteCmdbRelCi', async (id: number) => {
  const response = await CmdbRelCiService.deleteCmdbRelCi(id).then((res) => {
    return res.body;
  });
  return response;
});

export const processDataCmdbRelCi = createAsyncThunk(
  'processDataCmdbRelCi',
  async (data: IProcessData) => {
    const response = await CmdbRelCiService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);
