import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowApplications,
  ISearchSnowApplications,
} from '../../../services/snow/snowApplications/snowApplications.model';
import snowApplicationsService from '../../../services/snow/snowApplications/snowApplications.service';

// Asynchronous thunk action

export const searchSnowApplications = createAsyncThunk(
  'searchSnowApplications',
  async (searchParam?: ISearchSnowApplications) => {
    const response = await snowApplicationsService
      .searchSnowApplications(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowApplicationsById = createAsyncThunk(
  'getSnowApplicationsById',
  async (id: number) => {
    const response = await snowApplicationsService.getSnowApplicationsById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowApplications = createAsyncThunk(
  'saveSnowApplications',
  async (data: ISnowApplications) => {
    const response = await snowApplicationsService.saveSnowApplications(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowApplications = createAsyncThunk(
  'deleteSnowApplications',
  async (id: number) => {
    const response = await snowApplicationsService.deleteSnowApplications(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
