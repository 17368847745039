import { IApiResponse, ISearchResponse } from '../../../common/models/common';
import request from '../../../utils/request';
import { IErrorLogFile } from './errorLogFile.model';

class errorLogFileService {
  ENDPOINT = '/app';

  public async searchErrorLogFile(
  ): Promise<IApiResponse<ISearchResponse<IErrorLogFile>>> {
    const url = `${this.ENDPOINT}/read-error-log-file`;
    return request({ url, method: 'GET' }).then((res) => {
      return res.data;
    });
  }

  public async getErrorLogFileById(id: number): Promise<any> {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: 'GET' }).then((res) => {
      return res.data;
    });
  }

  public async deleteErrorLogFile(id: number): Promise<any> {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: 'DELETE' }).then((res) => {
      return res.data;
    });
  }
}
export default new errorLogFileService();
