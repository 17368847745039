import { ISearchAzureAPIVmSizes, IAzureAPIVmSizes } from './azureAPIVmSizes.model';
import { IApiResponse, ISearchResponse } from '../../../common/models/common';
import request from '../../../utils/request';

class AzureAPIVmSizesService {
  ENDPOINT = '/azure-api-vm-sizes';

  public async searchAzureAPIVmSizes(
    searchParams?: ISearchAzureAPIVmSizes
  ): Promise<IApiResponse<ISearchResponse<IAzureAPIVmSizes>>> {
    delete searchParams.is_lookup;
    const url = `${this.ENDPOINT}/search`;
    return request({ url, method: 'POST', data: searchParams }).then((res) => {
      return res.data;
    });
  }

  public async getAzureAPIVmSizesById(id: number): Promise<any> {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: 'GET' }).then((res) => {
      return res.data;
    });
  }

  public async saveAzureAPIVmSizes(data: IAzureAPIVmSizes): Promise<any> {
    const { id, ...restData } = data;
    if (id > 0) {
      const url = `${this.ENDPOINT}/${id}`;
      return request({ url, method: 'PUT', data: restData }).then((res) => {
        return res.data;
      });
    } else {
      const url = `${this.ENDPOINT}`;
      return request({ url, method: 'POST', data: restData }).then((res) => {
        return res.data;
      });
    }
  }

  public async deleteAzureAPIVmSizes(id: number): Promise<any> {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: 'DELETE' }).then((res) => {
      return res.data;
    });
  }

  public async exportExcelFile(searchParams?: ISearchAzureAPIVmSizes): Promise<any> {
    const url = `back-ground-processes/export-excel`;
    return request({
      url,
      method: 'POST',
      data: searchParams,
    }).then((res) => {
      return res;
    });
  }
}
export default new AzureAPIVmSizesService();
