import { Button, DatePicker, Form, Popconfirm, Select, Table, TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { IRenderBIProps } from './renderBI.model';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { bulkImportV2Selector, clearBulkImportMessages, clearDeleteMessages, clearExcelColumns } from '../../../../store/bulkImportV2/bulkImportV2.reducer';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';
import commonService from '../../../../services/common/common.service';
import { IInlineSearch } from '../../../../common/models/common';
import { Common } from '../../../../common/constants/common';
import { getSimpleDate } from '../../../../common/helperFunction';
import { bulkInsert, deleteColumnMapping, deleteFileMapping, getExcelFileMappingLookup, getTables } from '../../../../store/bulkImportV2/bulkImportV2.action';
import bulkImportService from '../../../../services/bulkImport/bulkImport.service';
import { IDatabaseTable } from '../../../../services/common/common.model';
import MappingColumn from '../MappingColumnV2/MappingColumn';
import PreviewExcel from '../PreviewExcelFileV2/previewExcelFile';

const { Option } = Select;

let indexForTableLoading = 0;

const RenderBI: React.FC<IRenderBIProps> = (props) => {
  const {
    count,
    table,
    form,
    records,
    setRecords,
    date,
    loading,
    setLoading,
    setDelimitFlag,
    firstFlag,
    setFirstFlag,
    hideUnmapped,
    withoutUnmappedRecords,
    setWithoutUnmappedRecords,
    dateChangeFlag,
    setDateChangeFlag,
    setExpandedRecords,
    expandedRecords,
    blobStorageFile,
    onSwitchChangeRecords,
  } = props;
  const bulkImportsV2 = useAppSelector(bulkImportV2Selector);
  const dispatch = useAppDispatch();
  const globalFilters = useAppSelector(globalSearchSelector);

  const [innerFormUpload] = Form.useForm();

  const [maxHeaderRow, setMaxHeaderRow] = useState(1);
  const [maxColumn, setColumn] = useState(10);
  const [tableColumns, setTableColumns] = useState(null);
  const [headerRowCount, setHeaderRowCount] = useState(1);
  const [excelPreviewData, setExcelPreviewData] = useState<any>();
  const [showManageExcel, setShowManageExcel] = useState<boolean>(false);
  const [flagForMappingHighlights, setFlagForMappingHighlights] = useState<boolean>(true);
  const [tableColumnState, setTableColumnState] = useState<any>([]);
  const [savedExcelMapping, setSavedExcelMapping] = useState<any>([]);
  const [headerDropDown, setHeaderDropDown] = useState<any>([]);
  const [selectedRowId, setSelectedRowId] = useState<any>();
  const [curRecordMap, setCurRecordMap] = useState(null);

  const changedTableData = async (currRecord: any, tableName: string) => {
    const dummyRecords = _.cloneDeep(records);
    const data = dummyRecords.filter((data) => data.index == currRecord.index);
    if (data && data.length > 0) {
      data[0].table_name = tableName;
      await commonService.getTableColumns(tableName).then((res) => {
        if (res) {
          const response: any = res;
          const columnsArray = ['tenantid', 'companyid', 'bu_id', 'date added'];
          let filterExcelColumns: any = data[0].columns;
          const filterTableColumns = response?.filter(
            (x) => !columnsArray.includes(x.name?.toLowerCase())
          );
          if (filterExcelColumns?.length >= data[0].header_row) {
            filterExcelColumns = filterExcelColumns[data[0].header_row - 1];
          }
          const ExcelColsSorted = [...filterExcelColumns];
          ExcelColsSorted.sort();

          const initialValuesData: any = {};
          const sqlToExcelMapping = [];
          filterTableColumns.map(function (ele) {
            initialValuesData[ele.name] = ExcelColsSorted.filter(
              (x: any) =>
                x?.toString()?.toLowerCase()?.replace(/\s/g, '') ===
                ele.name?.toLowerCase()?.replace(/\s/g, '')
            )[0];
            data[0].validation =
              ele.is_nullable == 'NO' && initialValuesData[ele.name] == undefined
                ? true
                : data[0].validation;
            sqlToExcelMapping.push({
              key: `${ele.name}`,
              value:
                initialValuesData[ele.name] == undefined ? '' : `${initialValuesData[ele.name]}`,
            });
          });
          data[0].excel_to_sql_mapping = sqlToExcelMapping;
        }
      });
      data[0].currentMapping = null;
    }

    setRecords(dummyRecords);
    setLoading(false);
  };

  const handleTableChange = async (currRecord: any, tableName: string) => {
    setLoading(true);
    if (tableName) {
      changedTableData(currRecord, tableName);
    } else {
      setTableColumnState([]);
    }
  };

  useEffect(() => {
    if (count.save > 0) {
      const globalSearch: IInlineSearch = {};
      for (const key in globalFilters.search) {
        const element = globalFilters.search[key];
        globalSearch[key] = element ? [element] : null;
      }
      const arr: Array<{
        excel_to_sql_mapping: any;
        table_name: string;
        file_name: string;
        original_file_name: string;
        sheet_name: string;
        header_row: number;
        delimiter: string;
        foreign_key_values: any;
      }> = [];
      let isMapped = true;
      if (hideUnmapped === false && withoutUnmappedRecords.length) {
        withoutUnmappedRecords.map((data) => {
          if (data.excel_to_sql_mapping) {
            const excelData = data.excel_to_sql_mapping.filter((data) => data.value !== '');
            if (excelData.length > 0) {
              data.excel_to_sql_mapping = data.excel_to_sql_mapping?.filter(
                (data) => data.key !== 'Source'
              );
            } else {
              isMapped = false;
              toast.warn('Select some missing mappings for ' + data.original_filename);
            }
          } else {
            isMapped = false;
            toast.error('Select configurations for ' + data.original_filename);
          }
          const Obj = {
            excel_to_sql_mapping: data.excel_to_sql_mapping,
            table_name: data.table_name,
            file_name: data.filename,
            file_path: data.file_path,
            original_file_name: data.original_filename,
            sheet_name: data.sheet,
            header_row: data.header_row - 1,
            delimiter: data.delimiter ? data.delimiter : ',',
            is_dynamic_header: data.is_dynamic_header === true ? true : false,
            foreign_key_values: {
              date_added: data.date
                ? moment(data.date).format(Common.DATEFORMAT)
                : moment(date).format(Common.DATEFORMAT),
            },
          };
          arr.push(Obj);
        });
      } else {
        records.map((data) => {
          if (data.excel_to_sql_mapping) {
            const excelData = data.excel_to_sql_mapping.filter((data) => data.value !== '');
            if (excelData.length > 0) {
              data.excel_to_sql_mapping = data.excel_to_sql_mapping?.filter(
                (data) => data.key !== 'Source'
              );
            } else {
              isMapped = false;
              toast.warn('Select some missing mappings for ' + data.original_filename);
            }
          } else {
            isMapped = false;
            toast.error('Select configurations for ' + data.original_filename);
          }
          const Obj = {
            excel_to_sql_mapping: data.excel_to_sql_mapping,
            table_name: data.table_name,
            file_name: data.filename,
            file_path: data.file_path,
            original_file_name: data.original_filename,
            sheet_name: data.sheet,
            header_row: data.header_row - 1,
            delimiter: data.delimiter ? data.delimiter : ',',
            is_dynamic_header: data.is_dynamic_header === true ? true : false,
            foreign_key_values: {
              date_added: data.date
                ? moment(data.date).format(Common.DATEFORMAT)
                : moment(date).format(Common.DATEFORMAT),
            },
          };
          arr.push(Obj);
        });
      }
      const val = {
        excel_sheet_with_mapping_details: arr,
        foreign_key_values: {
          tenant_id: _.isNull(globalSearch.tenant_id)
            ? null
            : globalSearch.tenant_id === undefined
              ? null
              : globalSearch?.tenant_id[0],
          bu_id: _.isNull(globalSearch.bu_id)
            ? null
            : globalSearch.bu_id === undefined
              ? null
              : globalSearch?.bu_id[0],
          company_id: _.isNull(globalSearch.company_id)
            ? null
            : globalSearch.company_id === undefined
              ? null
              : globalSearch?.company_id[0],
          date_added: date
            ? moment(date).format(Common.DATEFORMAT)
            : getSimpleDate().format(Common.DATEFORMAT),
        },
      };
      if (val.excel_sheet_with_mapping_details.length === 0) {
        toast.info('Some Tab are lacking mapping concerns.');
      } else {
        if (isMapped) {
          dispatch(bulkInsert(val));
        }
      }
    }
  }, [count.save]);

  useEffect(() => {
    if (count.reset > 0) {
      resetPage();
      setRecords([]);
    }
  }, [count.reset]);

  const setFormFields = async () => {
    const skipRows =
      Number(innerFormUpload.getFieldValue('header_row')) > 0
        ? Number(innerFormUpload.getFieldValue('header_row')) - 1
        : 0;
    let currentSheetName = innerFormUpload.getFieldValue('sheet_name');
    if (
      !currentSheetName &&
      bulkImportsV2.getExcelColumnsV2.data?.length > 0 &&
      bulkImportsV2.getExcelColumnsV2.data[selectedRowId - 1]?.excel_sheet_columns
    ) {
      currentSheetName =
        bulkImportsV2.getExcelColumnsV2.data[selectedRowId - 1].excel_sheet_columns[0].sheet;
      innerFormUpload.setFieldsValue({ sheet_name: currentSheetName });
    }
    if (
      tableColumnState &&
      bulkImportsV2.getExcelColumnsV2.data?.length > 0 &&
      bulkImportsV2.getExcelColumnsV2.data[selectedRowId - 1]?.excel_sheet_columns
    ) {
      const columnsArray = ['tenantid', 'companyid', 'bu_id', 'date added'];
      let filterExcelColumns: any = bulkImportsV2.getExcelColumnsV2.data[
        selectedRowId - 1
      ]?.excel_sheet_columns?.find((e) => e.sheet === currentSheetName)?.columns;
      const filterTableColumns = tableColumnState.filter(
        (x) => !columnsArray.includes(x.name?.toLowerCase())
      );
      if (filterExcelColumns?.length >= skipRows) {
        filterExcelColumns = filterExcelColumns[skipRows];
      }
      if (filterExcelColumns && filterExcelColumns?.length) {
        const ExcelColsSorted = [...filterExcelColumns];
        ExcelColsSorted.sort();
      }
      setTableColumns(filterTableColumns);

      const globalSearch: IInlineSearch = {};
      for (const key in globalFilters.search) {
        const element = globalFilters.search[key];
        globalSearch[key] = element ? [element] : null;
      }

      const initialValuesData: any = {
        tenant_id: _.isNull(globalSearch.tenant_id)
          ? null
          : globalSearch.tenant_id === undefined
            ? null
            : globalSearch?.tenant_id[0],
        bu_id: _.isNull(globalSearch.bu_id)
          ? null
          : globalSearch.bu_id === undefined
            ? null
            : globalSearch?.bu_id[0],
        company_id: _.isNull(globalSearch.company_id)
          ? null
          : globalSearch.company_id === undefined
            ? null
            : globalSearch?.company_id[0],
        date_added: getSimpleDate(),
      };
      filterTableColumns.map(function (ele) {
        initialValuesData[ele.name] =
          filterExcelColumns?.filter(
            (x: any) =>
              x?.toString()?.toLowerCase()?.replace(/\s/g, '') ===
              ele.name?.toLowerCase()?.replace(/\s/g, '')
          ).length > 0
            ? filterExcelColumns.filter(
              (x: any) =>
                x?.toString()?.toLowerCase()?.replace(/\s/g, '') ===
                ele.name?.toLowerCase()?.replace(/\s/g, '')
            )[0]
            : '';
      });
      form.setFieldsValue(initialValuesData);
    } else {
      form.setFieldsValue({});
      setTableColumns(null);
    }
  };

  const resetPage = () => {
    setTableColumnState([]);
    dispatch(clearExcelColumns());
    innerFormUpload.resetFields(['upload_file', 'sheet_name', 'header_row']);
    setTableColumns(null);
  };

  useEffect(() => {
    if (bulkImportsV2.getExcelColumnsV2.data?.length > 0) {
      setFormFields();
    }
  }, [tableColumnState, bulkImportsV2.getExcelColumnsV2.data]);

  useEffect(() => {
    if (!(bulkImportsV2.getTables.data && bulkImportsV2.getTables.data.length > 0)) {
      dispatch(getTables());
    }
  }, [dispatch]);

  useEffect(() => {
    if (table) {
      const currentTable = bulkImportsV2.getTables.data.filter(
        (t) => t.name.toLowerCase() === (table || '').toLowerCase()
      );
      if (currentTable.length > 0) {
        innerFormUpload.setFieldsValue({ table_name: currentTable[0].name });
      }
    }
  }, [bulkImportsV2.getTables.data, table]);

  const getMaximumHeader = (data: any) => {
    let maximum = 1;
    data.columns?.map((data) => {
      if (data.length > maximum) {
        maximum = data.length
      }
    });
    return maximum;
  };

  const previewData = (csvFlag: boolean, headerValue = 0) => {
    const dummyRecords = csvFlag
      ? bulkImportsV2.getCSVExcelColumns.data[0].excel_sheet_columns[0].columns
      : records.filter((data) => data.index == selectedRowId);
    const currentExcelData = csvFlag ? [...dummyRecords] : [...dummyRecords[0]?.columns];
    currentExcelData?.splice(0, headerValue - 1 > 0 ? headerValue - 1 : 0);
    const recordsWithoutSpace = currentExcelData.filter(data => data[0] !== "\u0000");
    setExcelPreviewData(recordsWithoutSpace);
    if (csvFlag) {
      setColumn(dummyRecords[0]?.length);
      setMaxHeaderRow(dummyRecords?.length);
    } else {
      const maxHead = getMaximumHeader(dummyRecords[0]);
      setColumn(maxHead);
      setMaxHeaderRow(dummyRecords[0].columns?.length);
    }
    innerFormUpload.setFieldsValue({ header_row: headerValue });
    setFormFields();
  };
  // End: set tables for import

  useEffect(() => {
    const dummyRecords = _.cloneDeep(records);
    const unmapRec = dummyRecords.filter(
      (data) => data.currentMapping !== null && data.excel_to_sql_mapping !== null
    );
    setWithoutUnmappedRecords(unmapRec);
    return () => {
      setTableColumnState([]);
    };
  }, []);

  const removeColumnMapping = (id: number) => {
    dispatch(deleteColumnMapping(id));
  };

  const removeFileMapping = (id: number) => {
    dispatch(deleteFileMapping(id));
  };

  const geChildDropdown = (excelMappings: any, currentRecord: any) => {
    const chidDropdown = [];
    excelMappings?.map((m: any) => {
      chidDropdown.push({
        title: (
          <>
            {' '}
            {m.sheet_name}
            {currentRecord.isPublic === true ? (
              <></>
            ) : (
              <Popconfirm
                title={`Delete ${m.sheet_name} Mapping?`}
                onConfirm={() => {
                  removeColumnMapping(m.id);
                  setCurRecordMap(currentRecord);
                }}
              >
                <a href="#" title="" className="deleteMap-btn">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
                </a>
              </Popconfirm>
            )}
          </>
        ),
        value: `${m.sheet_name}!${m.id}`,
      });
    });

    return chidDropdown;
  };

  const getMenuDropdown = (recordsDefault: any, curRecord: any) => {
    const dropdown = [];
    recordsDefault?.map((m: any) => {
      dropdown.push({
        title: (
          <>
            {' '}
            {m.key_word}{' '}
            {curRecord?.isPublic === true ? (
              <></>
            ) : (
              <Popconfirm
                title={`Delete ${m.key_word} Mapping?`}
                onConfirm={() => {
                  removeFileMapping(m.id);
                  setCurRecordMap(curRecord);
                }}
              >
                <a href="#" title="" className="deleteMap-btn">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
                </a>
              </Popconfirm>
            )}
          </>
        ),
        disabled: true,
        value: `${m.id}-parent`,
        children: geChildDropdown(m.config_excel_column_mappings, curRecord),
      });
    });

    return dropdown;
  };

  useEffect(() => {
    if (bulkImportsV2.saveExcelFileMapping.messages.length > 0) {
      toast.success(bulkImportsV2.saveExcelFileMapping.messages.join(' '));
      const data = {
        tenant_id: globalFilters.search.tenant_id ? globalFilters.search.tenant_id : null,
        company_id: globalFilters.search.company_id ? globalFilters.search.company_id : null,
        bu_id: globalFilters.search.bu_id ? globalFilters.search.bu_id : null,
      };
      dispatch(getExcelFileMappingLookup(data));
      dispatch(clearBulkImportMessages());
      setSavedExcelMapping([]);
    }
  }, [bulkImportsV2.saveExcelFileMapping.messages]);

  const getExcelMappingColumns = async () => {
    const dummyRecord = _.cloneDeep(records);
    for (let index = 0; index < dummyRecord.length; index++) {
      const data = dummyRecord[index];
      if (data.index == curRecordMap.index) {
        let response = null;
        const global_dd = {
          tenant_id: globalFilters.search.tenant_id ? globalFilters.search.tenant_id : null,
          company_id: globalFilters.search.company_id ? globalFilters.search.company_id : null,
          bu_id: globalFilters.search.bu_id ? globalFilters.search.bu_id : null,
        };
        await bulkImportService
          .getExcelFileMapping({
            table_name: curRecordMap.table_name,
            key_word: curRecordMap.original_filename?.split('.')[0],
            file_type: curRecordMap?.original_filename.slice(
              ((curRecordMap?.original_filename.lastIndexOf('.') - 1) >>> 0) + 2
            ),
            global_dd,
            sheet_name_count: data?.show_mapping?.length,
          })
          .then((res) => {
            response = res?.body?.data;
          });
        data.currentMapping =
          response && response.length > 0
            ? response[0].config_excel_column_mappings[0]?.sheet_name
            : null;
        data.excel_to_sql_mapping =
          response && response.length > 0
            ? JSON.parse(response[0]?.config_excel_column_mappings[0]?.mapping)
            : null;
        data.show_mapping = response ? response : null;
      }
    }
    setRecords(dummyRecord);
  };

  useEffect(() => {
    if (bulkImportsV2.deleteColumnMapping.messages.length > 0) {
      if (bulkImportsV2.deleteColumnMapping.hasErrors) {
        toast.error(bulkImportsV2.deleteColumnMapping.messages.join(' '));
      } else {
        toast.success(bulkImportsV2.deleteColumnMapping.messages.join(' '));
        getExcelMappingColumns();
        onSwitchChangeRecords();
      }
      dispatch(clearDeleteMessages());
    }
  }, [bulkImportsV2.deleteColumnMapping.messages]);

  useEffect(() => {
    if (bulkImportsV2.deleteFileMapping.messages.length > 0) {
      if (bulkImportsV2.deleteFileMapping.hasErrors) {
        toast.error(bulkImportsV2.deleteFileMapping.messages.join(' '));
      } else {
        toast.success(bulkImportsV2.deleteFileMapping.messages.join(' '));
        getExcelMappingColumns();
        onSwitchChangeRecords();
      }
      dispatch(clearDeleteMessages());
    }
  }, [bulkImportsV2.deleteFileMapping.messages]);

  useEffect(() => {
    if (savedExcelMapping?.length > 0) {
      const defaultSelected = savedExcelMapping?.find((x) => x.is_select === true);
      if (defaultSelected && defaultSelected.config_excel_column_mappings?.length > 0) {
        const selectedMappingOrder = defaultSelected.config_excel_column_mappings[0]?.id;
        innerFormUpload.setFieldsValue({ mapping_order: selectedMappingOrder });
        onChange(null, selectedMappingOrder);
      }
    }
  }, [savedExcelMapping]);

  const onChange = (selectedRecord: any, value: any) => {
    const sheetNameV = value ? value.split('!')[0] : null;
    const idV = value ? value.split('!')[1] : null;
    const dummyRecord = _.cloneDeep(records);
    dummyRecord.map((data) => {
      if (data.index == selectedRecord.index) {
        let flagMapping = null;
        data.currentMapping = value;
        selectedRecord.show_mapping.map((data1) => {
          data1.config_excel_column_mappings.map((data2) => {
            if (data2.sheet_name == sheetNameV && data2.id == idV) {
              data.key_word = data1?.key_word;
              data.is_public = data1?.is_public;
              data.is_dynamic_header = data2?.is_dynamic_header;
              data.table_name = data2.table_name;
              flagMapping = JSON.parse(data2.mapping);
            }
          });
        });
        data.excel_to_sql_mapping = flagMapping ? flagMapping : data.excel_to_sql_mapping;
      }
    });
    const unmapRec = dummyRecord.filter(
      (data) => data.currentMapping !== null && data.excel_to_sql_mapping !== null
    );
    setWithoutUnmappedRecords(unmapRec);
    setRecords(dummyRecord);
    if (value) {
      const defaultMappingDetail = savedExcelMapping?.filter(
        (x) => x.table_name === innerFormUpload.getFieldValue('table_name')
      );
      let mappingDetail: any = {};
      let skipRows;
      defaultMappingDetail?.forEach((element) => {
        const mappingOrder = element?.config_excel_column_mappings?.find((y) => y.id === value);
        if (mappingOrder) {
          mappingDetail = JSON.parse(mappingOrder?.mapping);
          innerFormUpload.setFieldsValue({ header_row: mappingOrder.header_row });
          setFormFields();
          skipRows = Number(mappingOrder.header_row) - 1;
        }
      });

      if (bulkImportsV2.getExcelColumnsV2.data?.length > 0) {
        let filterExcelColumns: any = bulkImportsV2.getExcelColumnsV2.data[
          selectedRowId - 1
        ]?.excel_sheet_columns?.find(
          (e) => e.sheet === innerFormUpload?.getFieldValue('sheet_name')
        )?.columns;
        if (filterExcelColumns?.length >= skipRows) {
          filterExcelColumns = filterExcelColumns[skipRows];
        }
        tableColumns?.forEach((element) => {
          const mapObj = mappingDetail?.find((x) => x.key === element.name);
          if (mapObj && filterExcelColumns?.includes(mapObj?.value)) {
            form.setFieldsValue({ [element.name]: mapObj.value });
          }
        });
      }
    } else {
      innerFormUpload.setFieldsValue({ header_row: 1 });
      setFormFields();
    }
  };

  const deleteSelected = (index: number, fileName: string) => {
    if (index >= 0) {
      toast.success('Tab deleted successfully');
      const dummyRecords = _.cloneDeep(records);
      let flag = false;
      const filteredRecords = dummyRecords.filter((data) => data.index !== index);
      filteredRecords.map((data) => {
        if (data.filename === fileName) {
          flag = true;
        }
      });
      if (!flag) {
        commonService.deleteFileForBulkImport([fileName]);
      }

      const dummyWithoutRecords = _.cloneDeep(filteredRecords);
      const unmapRec = dummyWithoutRecords.filter(
        (data) => data.currentMapping !== null && data.excel_to_sql_mapping !== null
      );
      setWithoutUnmappedRecords(unmapRec);
      setRecords(filteredRecords);
    }
  };

  const onDateChange = (selectedReord: any, e) => {
    if (e) {
      if (expandedRecords !== null && expandedRecords?.length > 0) {
        setDateChangeFlag(false);
      }
      const dummyRecord = _.cloneDeep(records);
      dummyRecord.map((data) => {
        if (data.index == selectedReord.index) {
          data.date = e;
        }
      });
      setRecords(dummyRecord);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'File Name',
      dataIndex: 'original_filename',
      key: 'original_filename',
    },
    {
      title: 'Table Name',
      dataIndex: 'table_name',
      key: 'table_name',
      render: (records, recordCurr) => (
        <>
          <Select
            style={{ width: '190px' }}
            onChange={(tbName) => {
              handleTableChange(recordCurr, tbName);
              indexForTableLoading = recordCurr.index;
            }}
            loading={(bulkImportsV2.getTables.loading || loading) && indexForTableLoading == recordCurr.index}
            showSearch
            value={recordCurr.table_name}
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA: any, optionB: any) =>
              optionA.children?.toLowerCase()?.localeCompare(optionB.children?.toLowerCase())
            }
          >
            {bulkImportsV2.getTables.data?.map((option: IDatabaseTable, index: number) => (
              <Option key={index} value={option.name}>
                {option.name}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Tab',
      dataIndex: 'sheet',
      key: 'sheet',
    },
    {
      title: 'Header Row',
      dataIndex: 'header_row',
      key: 'header_row',
    },
    {
      title: 'Saved Mapping',
      dataIndex: 'show_mapping',
      key: 'show_mapping',
      render: (record, selectedRecord) => (
        <>
          <TreeSelect
            style={{ width: '320px' }}
            disabled={record.length == 0}
            dropdownClassName="value-box-select"
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={getMenuDropdown(selectedRecord.show_mapping, selectedRecord)}
            value={selectedRecord.currentMapping && ((!selectedRecord.currentMapping?.includes('>') ? (record[0]?.key_word + ' > ') : '') + selectedRecord.currentMapping?.split('!')[0])}
            onChange={(e) => onChange(selectedRecord, e)}
            treeDefaultExpandAll
            allowClear
            showSearch
          />
        </>
      ),
    },
    {
      title: 'Date Added',
      dataIndex: 'date',
      key: 'date',
      render: ((data, selectedData) => (
        <>
          <DatePicker
            allowClear={false}
            style={{ width: '117px' }}
            value={moment(selectedData.date)}
            className="form-control"
            onChange={(e) => onDateChange(selectedData, e)}
            placeholder="Select Date Added"
          />
        </>
      )),
    },
    {
      title: 'Action',
      dataIndex: 'key',
      key: 'key',
      render: (record, selectedRecord) => (
        <>
          <Button
            type="primary"
            style={{ margin: '0 5px 5px 0px' }}
            onClick={() => {
              setSelectedRowId(selectedRecord.index);
              setHeaderRowCount(selectedRecord.header_row);
              setShowManageExcel(true);
            }}
          >
            Manage Header
          </Button>

          <Button
            type="primary"
            onClick={() => {
              blobStorageFile();
              deleteSelected(selectedRecord.index, selectedRecord.filename);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        showHeader={true}
        pagination={false}
        scroll={{ x: true }}
        dataSource={hideUnmapped === false ? withoutUnmappedRecords : records}
        rowKey={(record) => record['index']}
        columns={columns}
        loading={records.length == 0}
        expandable={{
          onExpandedRowsChange: (record) => {
            setExpandedRecords(record);
          },
          expandedRowRender: (record) => (
            <MappingColumn
              setRecords={setRecords}
              record={record}
              count={count}
              records={records}
              flagForMappingHighlights={flagForMappingHighlights}
              setFlagForMappingHighlights={setFlagForMappingHighlights}
              skipRows={record?.header_row > 0 ? record?.header_row - 1 : 0}
              fileName={
                record?.key_word === null
                  ? record?.original_filename.split('.')[0]
                  : record?.key_word
              }
              fileType={record?.original_filename.slice(
                ((record?.original_filename.lastIndexOf('.') - 1) >>> 0) + 2
              )}
              is_public={record.is_public}
              tableName={record?.table_name}
              seqNumber={record?.index}
              dateChangeFlag={dateChangeFlag}
              setDateChangeFlag={setDateChangeFlag}
            ></MappingColumn>
          ),
        }}
      />
      {showManageExcel && (
        <PreviewExcel
          showModal={showManageExcel}
          maxCount={maxHeaderRow}
          maxColumn={maxColumn}
          handleModalClose={() => {
            setShowManageExcel(false);
          }}
          setFlagForMappingHighlights={setFlagForMappingHighlights}
          dataRecords={records}
          setRecords={setRecords}
          setDelimitFlag={setDelimitFlag}
          seqNumber={selectedRowId}
          previewData={previewData}
          records={excelPreviewData}
          setExcelPreviewData={setExcelPreviewData}
          headerRowCount={headerRowCount}
          firstFlag={firstFlag}
          setFirstFlag={setFirstFlag}
          headerDropDown={headerDropDown}
          setHeaderDropDown={setHeaderDropDown}
        />
      )}
    </>
  );
};

export default RenderBI;
