import { createAsyncThunk } from '@reduxjs/toolkit';
import CmdbCiComputerService from '../../../services/serviceNow/cmdbCiComputer/cmdbCiComputer.service';
import {
  ISearchCmdbCiComputer,
  ICmdbCiComputer,
  IProcessData,
} from '../../../services/serviceNow/cmdbCiComputer/cmdbCiComputer.model';

// Asynchronous thunk action

export const searchCmdbCiComputer = createAsyncThunk(
  'searchCmdbCiComputer',
  async (searchParam?: ISearchCmdbCiComputer) => {
    const response = await CmdbCiComputerService.searchCmdbCiComputer(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getCmdbCiComputerById = createAsyncThunk('getCmdbCiComputerById', async (id: number) => {
  const response = await CmdbCiComputerService.getCmdbCiComputerById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveCmdbCiComputer = createAsyncThunk('saveCmdbCiComputer', async (data: ICmdbCiComputer) => {
  const response = await CmdbCiComputerService.saveCmdbCiComputer(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteCmdbCiComputer = createAsyncThunk('deleteCmdbCiComputer', async (id: number) => {
  const response = await CmdbCiComputerService.deleteCmdbCiComputer(id).then((res) => {
    return res.body;
  });
  return response;
});

export const processDataCmdbCiComputer = createAsyncThunk(
  'processDataCmdbCiComputer',
  async (data: IProcessData) => {
    const response = await CmdbCiComputerService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);
