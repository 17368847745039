import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { IPegaDetails } from '../../../../services/pega/pegaDetailUserData/pegaDetailUserData.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getPegaDetailsById,
  savePegaDetails,
} from '../../../../store/pega/pegaDetailUserData/pegaDetailUserData.action';
import {
  clearPegaDetailsGetById,
  clearPegaDetailsMessages,
  pegaDetailsSelector,
} from '../../../../store/pega/pegaDetailUserData/pegaDetailUserData.reducer';
import { getBULookup, getCompanyLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { IAddPegaDetailsUserDataProps } from './addPegaDetailsUserData.model';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate, passDateToApi } from '../../../../common/helperFunction';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';

const { Option } = Select;

const AddPegaDetailsModal: React.FC<IAddPegaDetailsUserDataProps> = (props) => {
  const pegaDetails = useAppSelector(pegaDetailsSelector);
  const dispatch = useAppDispatch();
  const commonLookups = useAppSelector(commonSelector);
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.PegaDetails} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: IPegaDetails = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    date_added: getSimpleDate(),
    source: "",
    user_id: "",
    user_name: "",
    work_location: "",
    work_location_name: "",
    employee_is: "",
    job_code: "",
    title: "",
    user_type: "",
    may_check_out_rules_count: null,
    max_daily_usage_hours_for_month: null,
    total_monthly_invocation_count: null,
    is_compliance_central_user: false,
  };

  const onFinish = (values: any) => {
    const inputValues: IPegaDetails = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.date_added = passDateToApi(inputValues.date_added, true);
    if (!isMultiple) {
      dispatch(savePegaDetails(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        pegaDetails.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: IPegaDetails) => {
    if (data.tenant_id) {
      await dispatch(getCompanyLookup(data.tenant_id));
    }
    if (data.company_id) {
      await dispatch(getBULookup(data.company_id));
    }
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
        source: data.source,
        user_id: data.user_id,
        user_name: data.user_name,
        work_location: data.work_location,
        work_location_name: data.work_location_name,
        employee_is: data.employee_is,
        job_code: data.job_code,
        title: data.title,
        user_type: data.user_type,
        may_check_out_rules_count: _.isNull(data.may_check_out_rules_count) ? null : data.may_check_out_rules_count,
        max_daily_usage_hours_for_month: _.isNull(data.max_daily_usage_hours_for_month) ? null : data.max_daily_usage_hours_for_month,
        total_monthly_invocation_count: _.isNull(data.total_monthly_invocation_count) ? null : data.total_monthly_invocation_count,
        is_compliance_central_user: data.is_compliance_central_user,
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (pegaDetails.save.messages.length > 0) {
      if (pegaDetails.save.hasErrors) {
        toast.error(pegaDetails.save.messages.join(' '));
      } else {
        toast.success(pegaDetails.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearPegaDetailsMessages());
    }
  }, [pegaDetails.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && pegaDetails.getById.data) {
      const data = pegaDetails.getById.data;
      fillValuesOnEdit(data);
    }
  }, [pegaDetails.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getPegaDetailsById(+id));
    }
    return () => {
      dispatch(clearPegaDetailsGetById());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {pegaDetails.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={pegaDetails.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="pegaDetails"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.tenantLookup.loading}
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.buLookup.loading}
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'source']} valuePropName="checked" noStyle>
                      <Checkbox>Source</Checkbox>
                    </Form.Item>
                  ) : (
                    'Source'
                  )}
                  <Form.Item
                    name="source"
                    label="Source"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'work_location']} valuePropName="checked" noStyle>
                      <Checkbox>Work Location</Checkbox>
                    </Form.Item>
                  ) : (
                    'Work Location'
                  )}
                  <Form.Item
                    name="work_location"
                    label="Work Location"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'user_id']} valuePropName="checked" noStyle>
                      <Checkbox>User ID</Checkbox>
                    </Form.Item>
                  ) : (
                    'User ID'
                  )}
                  <Form.Item
                    name="user_id"
                    label="User ID"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'user_name']} valuePropName="checked" noStyle>
                      <Checkbox>User Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'User Name'
                  )}
                  <Form.Item
                    name="user_name"
                    label="User Name"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'work_location_name']} valuePropName="checked" noStyle>
                      <Checkbox>Work Location Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Work Location Name'
                  )}
                  <Form.Item
                    name="work_location_name"
                    label="Work Location Name"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'employee_is']} valuePropName="checked" noStyle>
                      <Checkbox>Employee Is</Checkbox>
                    </Form.Item>
                  ) : (
                    'Employee Is'
                  )}
                  <Form.Item
                    name="employee_is"
                    label="Employee Is"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'job_code']} valuePropName="checked" noStyle>
                      <Checkbox>Job Code</Checkbox>
                    </Form.Item>
                  ) : (
                    'Job Code'
                  )}
                  <Form.Item
                    name="job_code"
                    label="Job Code"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'title']} valuePropName="checked" noStyle>
                      <Checkbox>Title</Checkbox>
                    </Form.Item>
                  ) : (
                    'Title'
                  )}
                  <Form.Item
                    name="title"
                    label="Title"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'user_type']} valuePropName="checked" noStyle>
                      <Checkbox>User Type</Checkbox>
                    </Form.Item>
                  ) : (
                    'User Type'
                  )}
                  <Form.Item
                    name="user_type"
                    label="User Type"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'may_check_out_rules_count']} valuePropName="checked" noStyle>
                      <Checkbox>May CheckOut Rules Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'May CheckOut Rules Count'
                  )}
                  <Form.Item
                    name="may_check_out_rules_count"
                    label="May CheckOut Rules Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'max_daily_usage_hours_for_month']} valuePropName="checked" noStyle>
                      <Checkbox>Max Daily Usage Hours For Month</Checkbox>
                    </Form.Item>
                  ) : (
                    'Max Daily Usage Hours For Month'
                  )}
                  <Form.Item
                    name="max_daily_usage_hours_for_month"
                    label="Max Daily Usage Hours For Month"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'total_monthly_invocation_count']} valuePropName="checked" noStyle>
                      <Checkbox>Total Monthly Invocation Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Total Monthly Invocation Count'
                  )}
                  <Form.Item
                    name="total_monthly_invocation_count"
                    label="Total Monthly Invocation Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_compliance_central_user" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_compliance_central_user']} valuePropName="checked" noStyle>
                      <Checkbox>Is Compliance Central User</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Compliance Central User'
                  )}
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={pegaDetails.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddPegaDetailsModal;
