import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigSoftwareEdition,
  ISearchConfigSoftwareEdition,
} from '../../../services/master/configSoftwareEdition/configSoftwareEdition.model';
import configSoftwareEdition from '../../../services/master/configSoftwareEdition/configSoftwareEdition.service';
// Asynchronous thunk action

export const searchConfigSoftwareEdition = createAsyncThunk(
  'searchConfigSoftwareEdition',
  async (searchParam?: ISearchConfigSoftwareEdition) => {
    const response = await configSoftwareEdition.searchConfigSoftwareEdition(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigSoftwareEditionById = createAsyncThunk(
  'getConfigSoftwareEditionById',
  async (id: number) => {
    const response = await configSoftwareEdition.getConfigSoftwareEditionById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigSoftwareEdition = createAsyncThunk(
  'saveConfigSoftwareEdition',
  async (data: IConfigSoftwareEdition) => {
    const response = await configSoftwareEdition.saveConfigSoftwareEdition(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigSoftwareEdition = createAsyncThunk(
  'deleteConfigSoftwareEdition',
  async (id: number) => {
    const response = await configSoftwareEdition.deleteConfigSoftwareEdition(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
