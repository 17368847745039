import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearSnowComputerApplicationsMessages,
  snowComputerApplicationsSelector,
  setTableColumnSelection,
} from '../../../../store/snow/snowComputerApplications/snowComputerApplications.reducer';
import {
  deleteSnowComputerApplications,
  searchSnowComputerApplications,
} from '../../../../store/snow/snowComputerApplications/snowComputerApplications.action';
import snowComputerApplicationsService from '../../../../services/snow/snowComputerApplications/snowComputerApplications.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import { AlignType } from 'rc-table/lib/interface';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const snowComputerApplications = useAppSelector(snowComputerApplicationsSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return snowComputerApplicationsService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      snowComputerApplications.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', snowComputerApplications.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Computer Id</span>,
        column: 'ComputerId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'computer_id',
              form
            ),
            dataIndex: 'computer_id',
            key: 'computer_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Applications Id</span>,
        column: 'ApplicationsId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'application_id',
              form
            ),
            dataIndex: 'application_id',
            key: 'application_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'name',
              form
            ),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer Id</span>,
        column: 'ManufacturerId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer_id',
              form
            ),
            dataIndex: 'manufacturer_id',
            key: 'manufacturer_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer Name</span>,
        column: 'ManufacturerName',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer_name',
              form
            ),
            dataIndex: 'manufacturer_name',
            key: 'manufacturer_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Family Id</span>,
        column: 'FamilyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('family_id', form),
            dataIndex: 'family_id',
            key: 'family_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Family Name</span>,
        column: 'FamilyName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('family_name', form),
            dataIndex: 'family_name',
            key: 'family_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bundle Application Id</span>,
        column: 'BundleApplicationId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('bundle_application_id', form),
            dataIndex: 'bundle_application_id',
            key: 'bundle_application_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bundle Application Name</span>,
        column: 'BundleApplicationName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('bundle_application_name', form),
            dataIndex: 'bundle_application_name',
            key: 'bundle_application_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Used</span>,
        column: 'LastUsed',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('last_used', snowComputerApplications.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'last_used',
            key: 'last_used',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">First Used</span>,
        column: 'FirstUsed',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('first_used', snowComputerApplications.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'first_used',
            key: 'first_used',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Install Date</span>,
        column: 'InstallDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('install_date', snowComputerApplications.search.tableName, form),
            dataIndex: 'install_date',
            key: 'install_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Discovered Date</span>,
        column: 'DiscoveredDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('discovered_date', snowComputerApplications.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'discovered_date',
            key: 'discovered_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Run</span>,
        column: 'Run',
        sorter: true,
        children: [
          {
            title: FilterBySwap('run', form),
            dataIndex: 'run',
            key: 'run',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Avg Usage Time</span>,
        column: 'AvgUsageTime',
        sorter: true,
        children: [
          {
            title: FilterBySwap('avg_usage_time', form),
            dataIndex: 'avg_usage_time',
            key: 'avg_usage_time',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Users</span>,
        column: 'Users',
        sorter: true,
        children: [
          {
            title: FilterBySwap('users', form),
            dataIndex: 'users',
            key: 'users',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Application Item Cost</span>,
        column: 'ApplicationItemCost',
        sorter: true,
        children: [
          {
            title: FilterBySwap('application_item_cost', form),
            dataIndex: 'application_item_cost',
            key: 'application_item_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Required</span>,
        column: 'LicenseRequired',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'license_required',
              snowComputerApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'license_required',
            key: 'license_required',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Installed</span>,
        column: 'IsInstalled',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_installed',
              snowComputerApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_installed',
            key: 'is_installed',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Blacklisted</span>,
        column: 'IsBlacklisted',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_blacklisted',
              snowComputerApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_blacklisted',
            key: 'is_blacklisted',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      }, 
      {
        title: <span className="dragHandler">Is Whitelisted</span>,
        column: 'IsWhitelisted',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_whitelisted',
              snowComputerApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_whitelisted',
            key: 'is_whitelisted',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },  
      {
        title: <span className="dragHandler">Is Virtual</span>,
        column: 'IsVirtual',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_virtual',
              snowComputerApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_virtual',
            key: 'is_virtual',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is OEM</span>,
        column: 'IsOEM',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_oem',
              snowComputerApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_oem',
            key: 'is_oem',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is MSDN</span>,
        column: 'IsMSDN',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_msdn',
              snowComputerApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_msdn',
            key: 'is_msdn',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Web Application</span>,
        column: 'IsWebApplication',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_web_application',
              snowComputerApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_web_application',
            key: 'is_web_application',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },  
    ];
  };

  const removeSnowComputerApplications = (id: number) => {
    dispatch(deleteSnowComputerApplications(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.SnowComputerApplications}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/snow/snow-computer-applications/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.SnowComputerApplications}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeSnowComputerApplications(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.SnowComputerApplications)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={snowComputerApplicationsSelector}
        searchTableData={searchSnowComputerApplications}
        clearTableDataMessages={clearSnowComputerApplicationsMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.SnowComputerApplications)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
