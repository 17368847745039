import { Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import {
  setTableColumnSelection,
  clearCmdbCiDbMsSqlInstanceMessages,
  CmdbCiDbMsSqlInstanceSelector,
} from '../../../../store/serviceNow/cmdbCiDbMsSqlInstance/cmdbCiDbMsSqlInstance.reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { deleteCmdbCiDbMsSqlInstance, searchCmdbCiDbMsSqlInstance } from '../../../../store/serviceNow/cmdbCiDbMsSqlInstance/cmdbCiDbMsSqlInstance.action';
import _ from 'lodash';
import CmdbCiDbMsSqlInstanceService from '../../../../services/serviceNow/cmdbCiDbMsSqlInstance/cmdbCiDbMsSqlInstance.service';
import {
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { showDateFromApi } from '../../../../common/helperFunction';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    setFilterKeys,
    tableButtons,
  } = props;
  const CmdbCiDbMsSqlInstance = useAppSelector(CmdbCiDbMsSqlInstanceSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return CmdbCiDbMsSqlInstanceService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, columnWidthOpt?: boolean, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      CmdbCiDbMsSqlInstance.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      columnWidthOpt === undefined ? false : columnWidthOpt,
      isRelativeDropDownFlag
    );
  };

  const FilterByDateSwapTable = (dataIndex: string, tableName: string, form: any) => {
    return FilterByDateSwap(dataIndex, tableName, form, null, ObjectForColumnFilter);
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form, true),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              false,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              false,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              false,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwapTable('date_added', CmdbCiDbMsSqlInstance.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('name', form),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Instance Name</span>,
        column: 'instance_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('instance_name', form),
            dataIndex: 'instance_name',
            key: 'instance_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Version Name</span>,
        column: 'version_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('version_name', form),
            dataIndex: 'version_name',
            key: 'version_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Version</span>,
        column: 'version',
        sorter: true,
        children: [
          {
            title: FilterBySwap('version', form),
            dataIndex: 'version',
            key: 'version',
            ellipsis: true,
          },
        ],
      },

      {
        title: <span className="dragHandler">Service Pack</span>,
        column: 'service_pack',
        sorter: true,
        children: [
          {
            title: FilterBySwap('service_pack', form),
            dataIndex: 'service_pack',
            key: 'service_pack',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Edition</span>,
        column: 'edition',
        sorter: true,
        children: [
          {
            title: FilterBySwap('edition', form),
            dataIndex: 'edition',
            key: 'edition',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">TCP Port</span>,
        column: 'tcp_port',
        sorter: true,
        children: [
          {
            title: FilterBySwap('tcp_port', form),
            dataIndex: 'tcp_port',
            key: 'tcp_port',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Environment</span>,
        column: 'u_environment',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_environment', form),
            dataIndex: 'u_environment',
            key: 'u_environment',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Software Install</span>,
        column: 'software_install',
        sorter: true,
        children: [
          {
            title: FilterBySwap('software_install', form),
            dataIndex: 'software_install',
            key: 'software_install',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeCmdbCiDbMsSqlInstance = (id: number) => {
    dispatch(deleteCmdbCiDbMsSqlInstance(id));
  };

  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.CmdbCiDbMsSqlInstance}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/service-now/service-now-cmdb-ci-db-mssql-instance/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.CmdbCiDbMsSqlInstance}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeCmdbCiDbMsSqlInstance(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.CmdbCiDbMsSqlInstance)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={CmdbCiDbMsSqlInstanceSelector}
        searchTableData={searchCmdbCiDbMsSqlInstance}
        clearTableDataMessages={clearCmdbCiDbMsSqlInstanceMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.CmdbCiDbMsSqlInstance)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
