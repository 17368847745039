import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearSnowApplicationsMessages,
  snowApplicationsSelector,
  setTableColumnSelection,
} from '../../../../store/snow/snowApplications/snowApplications.reducer';
import {
  deleteSnowApplications,
  searchSnowApplications,
} from '../../../../store/snow/snowApplications/snowApplications.action';
import snowApplicationsService from '../../../../services/snow/snowApplications/snowApplications.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import { AlignType } from 'rc-table/lib/interface';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const snowApplications = useAppSelector(snowApplicationsSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return snowApplicationsService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      snowApplications.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', snowApplications.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Applications Id</span>,
        column: 'ApplicationsId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'application_id',
              form
            ),
            dataIndex: 'application_id',
            key: 'application_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'name',
              form
            ),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer Id</span>,
        column: 'ManufacturerId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer_id',
              form
            ),
            dataIndex: 'manufacturer_id',
            key: 'manufacturer_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer Name</span>,
        column: 'ManufacturerName',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer_name',
              form
            ),
            dataIndex: 'manufacturer_name',
            key: 'manufacturer_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Family Id</span>,
        column: 'FamilyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('family_id', form),
            dataIndex: 'family_id',
            key: 'family_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Family Name</span>,
        column: 'FamilyName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('family_name', form),
            dataIndex: 'family_name',
            key: 'family_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System Type</span>,
        column: 'OperatingSystemType',
        sorter: true,
        children: [
          {
            title: FilterBySwap('operating_system_type', form),
            dataIndex: 'operating_system_type',
            key: 'operating_system_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">UpdatedDate</span>,
        column: 'UpdatedDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('updated_date', snowApplications.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'updated_date',
            key: 'updated_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">UpdatedBy</span>,
        column: 'UpdatedBy',
        sorter: true,
        children: [
          {
            title: FilterBySwap('updated_by', form),
            dataIndex: 'updated_by',
            key: 'updated_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Installations</span>,
        column: 'Installations',
        sorter: true,
        children: [
          {
            title: FilterBySwap('installations', form),
            dataIndex: 'installations',
            key: 'installations',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Users</span>,
        column: 'Users',
        sorter: true,
        children: [
          {
            title: FilterBySwap('users', form),
            dataIndex: 'users',
            key: 'users',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Metric</span>,
        column: 'Metric',
        sorter: true,
        children: [
          {
            title: FilterBySwap('metric', form),
            dataIndex: 'metric',
            key: 'metric',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Required</span>,
        column: 'LicenseRequired',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'license_required',
              snowApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'license_required',
            key: 'license_required',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Bundle</span>,
        column: 'IsBundle',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_bundle',
              snowApplications.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_bundle',
            key: 'is_bundle',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },  
    ];
  };

  const removeSnowApplications = (id: number) => {
    dispatch(deleteSnowApplications(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.SnowApplications}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/snow/snow-applications/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.SnowApplications}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeSnowApplications(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.SnowApplications)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={snowApplicationsSelector}
        searchTableData={searchSnowApplications}
        clearTableDataMessages={clearSnowApplicationsMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.SnowApplications)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
