import { createAsyncThunk } from '@reduxjs/toolkit';
import CmdbCiVMInstanceService from '../../../services/serviceNow/cmdbCiVMInstance/cmdbCiVMInstance.service';
import {
  ISearchCmdbCiVMInstance,
  ICmdbCiVMInstance,
  IProcessData,
} from '../../../services/serviceNow/cmdbCiVMInstance/cmdbCiVMInstance.model';

// Asynchronous thunk action

export const searchCmdbCiVMInstance = createAsyncThunk(
  'searchCmdbCiVMInstance',
  async (searchParam?: ISearchCmdbCiVMInstance) => {
    const response = await CmdbCiVMInstanceService.searchCmdbCiVMInstance(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getCmdbCiVMInstanceById = createAsyncThunk('getCmdbCiVMInstanceById', async (id: number) => {
  const response = await CmdbCiVMInstanceService.getCmdbCiVMInstanceById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveCmdbCiVMInstance = createAsyncThunk('saveCmdbCiVMInstance', async (data: ICmdbCiVMInstance) => {
  const response = await CmdbCiVMInstanceService.saveCmdbCiVMInstance(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteCmdbCiVMInstance = createAsyncThunk('deleteCmdbCiVMInstance', async (id: number) => {
  const response = await CmdbCiVMInstanceService.deleteCmdbCiVMInstance(id).then((res) => {
    return res.body;
  });
  return response;
});

export const processDataCmdbCiVMInstance = createAsyncThunk(
  'processDataCmdbCiVMInstance',
  async (data: IProcessData) => {
    const response = await CmdbCiVMInstanceService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);
