import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowComputerApplications,
  ISearchSnowComputerApplications,
} from '../../../services/snow/snowComputerApplications/snowComputerApplications.model';
import snowComputerApplicationsService from '../../../services/snow/snowComputerApplications/snowComputerApplications.service';

// Asynchronous thunk action

export const searchSnowComputerApplications = createAsyncThunk(
  'searchSnowComputerApplications',
  async (searchParam?: ISearchSnowComputerApplications) => {
    const response = await snowComputerApplicationsService
      .searchSnowComputerApplications(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowComputerApplicationsById = createAsyncThunk(
  'getSnowComputerApplicationsById',
  async (id: number) => {
    const response = await snowComputerApplicationsService.getSnowComputerApplicationsById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowComputerApplications = createAsyncThunk(
  'saveSnowComputerApplications',
  async (data: ISnowComputerApplications) => {
    const response = await snowComputerApplicationsService.saveSnowComputerApplications(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowComputerApplications = createAsyncThunk(
  'deleteSnowComputerApplications',
  async (id: number) => {
    const response = await snowComputerApplicationsService.deleteSnowComputerApplications(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
