import { createAsyncThunk } from '@reduxjs/toolkit';
import CmdbCiServerService from '../../../services/serviceNow/cmdbCiServer/cmdbCiServer.service';
import {
  ISearchCmdbCiServer,
  ICmdbCiServer,
  IProcessData,
} from '../../../services/serviceNow/cmdbCiServer/cmdbCiServer.model';

// Asynchronous thunk action

export const searchCmdbCiServer = createAsyncThunk(
  'searchCmdbCiServer',
  async (searchParam?: ISearchCmdbCiServer) => {
    const response = await CmdbCiServerService.searchCmdbCiServer(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getCmdbCiServerById = createAsyncThunk('getCmdbCiServerById', async (id: number) => {
  const response = await CmdbCiServerService.getCmdbCiServerById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveCmdbCiServer = createAsyncThunk('saveCmdbCiServer', async (data: ICmdbCiServer) => {
  const response = await CmdbCiServerService.saveCmdbCiServer(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteCmdbCiServer = createAsyncThunk('deleteCmdbCiServer', async (id: number) => {
  const response = await CmdbCiServerService.deleteCmdbCiServer(id).then((res) => {
    return res.body;
  });
  return response;
});

export const processDataCmdbCiServer = createAsyncThunk(
  'processDataCmdbCiServer',
  async (data: IProcessData) => {
    const response = await CmdbCiServerService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);
