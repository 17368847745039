import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { ISnowComputers } from '../../../../services/snow/snowComputers/snowComputers.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getSnowComputersById,
  saveSnowComputers,
} from '../../../../store/snow/snowComputers/snowComputers.action';
import {
  clearSnowComputersGetById,
  clearSnowComputersMessages,
  snowComputersSelector,
} from '../../../../store/snow/snowComputers/snowComputers.reducer';
import { getBULookup, getCompanyLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { IAddSnowComputersProps } from './addSnowComputers.model';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate, passDateToApi } from '../../../../common/helperFunction';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';

const { Option } = Select;

const AddSnowComputersModal: React.FC<IAddSnowComputersProps> = (props) => {
  const snowComputers = useAppSelector(snowComputersSelector);
  const dispatch = useAppDispatch();
  const commonLookups = useAppSelector(commonSelector);
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.SnowComputers} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: ISnowComputers = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    date_added: getSimpleDate(),
    computer_id: null,
    name: '',
    organization: '',
    org_checksum: null,
    manufacturer: '',
    model: '',
    operating_system: '',
    operating_system_service_pack: '',
    is_virtual: false,
    status: '',
    ip_addresses: '',
    last_scan_date: getSimpleDate(),
    updated_by: '',
    updated_date: getSimpleDate(),
    domain: '',
    total_disk_space: null,
    physical_memory: null,
    processor_type: '',
    processor_count: null,
    core_count: null,
    bios_serial_number: '',
    hardware: '',
    hypervisor_name: '',
    is_portable: false,
    is_server: false,
    most_frequent_user_id: null,
    most_recent_user_id: null,
  };

  const onFinish = (values: any) => {
    const inputValues: ISnowComputers = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.date_added = passDateToApi(inputValues.date_added);
    inputValues.last_scan_date = passDateToApi(inputValues.last_scan_date);
    inputValues.updated_date = passDateToApi(inputValues.updated_date);
    if (!isMultiple) {
      dispatch(saveSnowComputers(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        snowComputers.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: ISnowComputers) => {
    if (data.tenant_id) {
      await dispatch(getCompanyLookup(data.tenant_id));
    }
    if (data.company_id) {
      await dispatch(getBULookup(data.company_id));
    }
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
        computer_id: _.isNull(data.computer_id) ? null : data.computer_id,
        name: data.name,
        organization: data.organization,
        org_checksum: _.isNull(data.org_checksum) ? null : data.org_checksum,
        manufacturer: data.manufacturer,
        model: data.model,
        operating_system: data.operating_system,
        operating_system_service_pack: data.operating_system_service_pack,
        is_virtual: data.is_virtual,
        status: data.status,
        ip_addresses: data.ip_addresses,
        last_scan_date: _.isNull(data.last_scan_date) ? null : forEditModal(data.last_scan_date),
        updated_by: data.updated_by,
        updated_date: _.isNull(data.updated_date) ? null : forEditModal(data.updated_date),
        domain: data.domain,
        total_disk_space: _.isNull(data.total_disk_space) ? null : +(data.total_disk_space),
        physical_memory: _.isNull(data.physical_memory) ? null : data.physical_memory,
        processor_type: data.processor_type,
        processor_count: _.isNull(data.processor_count) ? null : data.processor_count,
        core_count: _.isNull(data.core_count) ? null : data.core_count,
        bios_serial_number: data.bios_serial_number,
        hardware: data.hardware,
        hypervisor_name: data.hypervisor_name,
        is_portable: data.is_portable,
        is_server: data.is_server,
        most_frequent_user_id: _.isNull(data.most_frequent_user_id) ? null : data.most_frequent_user_id,
        most_recent_user_id: _.isNull(data.most_recent_user_id) ? null : data.most_recent_user_id,
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (snowComputers.save.messages.length > 0) {
      if (snowComputers.save.hasErrors) {
        toast.error(snowComputers.save.messages.join(' '));
      } else {
        toast.success(snowComputers.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearSnowComputersMessages());
    }
  }, [snowComputers.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && snowComputers.getById.data) {
      const data = snowComputers.getById.data;
      fillValuesOnEdit(data);
    }
  }, [snowComputers.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getSnowComputersById(+id));
    }
    return () => {
      dispatch(clearSnowComputersGetById());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {snowComputers.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={snowComputers.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="snowComputers"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.tenantLookup.loading}
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.buLookup.loading}
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'computer_id']} valuePropName="checked" noStyle>
                      <Checkbox>Computer Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Computer Id'
                  )}
                  <Form.Item
                    name="computer_id"
                    label="Computer Id"
                    className="m-0"
                    rules={[{ type: 'integer', required: !isMultiple }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'name']} valuePropName="checked" noStyle>
                      <Checkbox>Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Name'
                  )}
                  <Form.Item
                    name="name"
                    label="Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'organization']} valuePropName="checked" noStyle>
                      <Checkbox>Organization</Checkbox>
                    </Form.Item>
                  ) : (
                    'Organization'
                  )}
                  <Form.Item
                    name="organization"
                    label="Organization"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'org_checksum']} valuePropName="checked" noStyle>
                      <Checkbox>Org Checksum</Checkbox>
                    </Form.Item>
                  ) : (
                    'Org Checksum'
                  )}
                  <Form.Item
                    name="org_checksum"
                    label="Org Checksum"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'manufacturer']} valuePropName="checked" noStyle>
                      <Checkbox>Manufacturer</Checkbox>
                    </Form.Item>
                  ) : (
                    'Manufacturer'
                  )}
                  <Form.Item
                    name="manufacturer"
                    label="Manufacturer"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'model']} valuePropName="checked" noStyle>
                      <Checkbox>Model</Checkbox>
                    </Form.Item>
                  ) : (
                    'Model'
                  )}
                  <Form.Item
                    name="model"
                    label="Model"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'operating_system']} valuePropName="checked" noStyle>
                      <Checkbox>Operating System</Checkbox>
                    </Form.Item>
                  ) : (
                    'Operating System'
                  )}
                  <Form.Item
                    name="operating_system"
                    label="Operating System"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'operating_system_service_pack']} valuePropName="checked" noStyle>
                      <Checkbox>Operating System Service Pack</Checkbox>
                    </Form.Item>
                  ) : (
                    'Operating System Service Pack'
                  )}
                  <Form.Item
                    name="operating_system_service_pack"
                    label="Operating System Service Pack"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'status']} valuePropName="checked" noStyle>
                      <Checkbox>Status</Checkbox>
                    </Form.Item>
                  ) : (
                    'Status'
                  )}
                  <Form.Item
                    name="status"
                    label="Status"
                    className="m-0"
                    rules={[{ max: 50 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'ip_addresses']} valuePropName="checked" noStyle>
                      <Checkbox>Ip Addresses</Checkbox>
                    </Form.Item>
                  ) : (
                    'Ip Addresses'
                  )}
                  <Form.Item
                    name="ip_addresses"
                    label="Ip Addresses"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_scan_date']} valuePropName="checked" noStyle>
                      <Checkbox>Last Scan Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Scan Date'
                  )}
                  <Form.Item name="last_scan_date" label="Last Scan Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_date']} valuePropName="checked" noStyle>
                      <Checkbox>Updated Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated Date'
                  )}
                  <Form.Item name="updated_date" label="Updated Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_by']} valuePropName="checked" noStyle>
                      <Checkbox>Updated By</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated By'
                  )}
                  <Form.Item
                    name="updated_by"
                    label="Updated By"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'domain']} valuePropName="checked" noStyle>
                      <Checkbox>Domain</Checkbox>
                    </Form.Item>
                  ) : (
                    'Domain'
                  )}
                  <Form.Item
                    name="domain"
                    label="Domain"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'total_disk_space']} valuePropName="checked" noStyle>
                      <Checkbox>Total Disk Space</Checkbox>
                    </Form.Item>
                  ) : (
                    'Total Disk Space'
                  )}
                  <Form.Item
                    name="total_disk_space"
                    label="Total Disk Space"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'physical_memory']} valuePropName="checked" noStyle>
                      <Checkbox>Physical Memory</Checkbox>
                    </Form.Item>
                  ) : (
                    'Physical Memory'
                  )}
                  <Form.Item
                    name="physical_memory"
                    label="Physical Memory"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'processor_type']} valuePropName="checked" noStyle>
                      <Checkbox>Processor Type</Checkbox>
                    </Form.Item>
                  ) : (
                    'Processor Type'
                  )}
                  <Form.Item
                    name="processor_type"
                    label="Processor Type"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'processor_count']} valuePropName="checked" noStyle>
                      <Checkbox>Processor Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Processor Count'
                  )}
                  <Form.Item
                    name="processor_count"
                    label="Processor Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'core_count']} valuePropName="checked" noStyle>
                      <Checkbox>Core Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Core Count'
                  )}
                  <Form.Item
                    name="core_count"
                    label="Core Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bios_serial_number']} valuePropName="checked" noStyle>
                      <Checkbox>Bios Serial Number</Checkbox>
                    </Form.Item>
                  ) : (
                    'Bios Serial Number'
                  )}
                  <Form.Item
                    name="bios_serial_number"
                    label="Bios Serial Number"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'hardware']} valuePropName="checked" noStyle>
                      <Checkbox>Hardware</Checkbox>
                    </Form.Item>
                  ) : (
                    'Hardware'
                  )}
                  <Form.Item
                    name="hardware"
                    label="Hardware"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'hypervisor_name']} valuePropName="checked" noStyle>
                      <Checkbox>Hypervisor Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Hypervisor Name'
                  )}
                  <Form.Item
                    name="hypervisor_name"
                    label="Hypervisor Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'most_frequent_user_id']} valuePropName="checked" noStyle>
                      <Checkbox>Most Frequent User Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Most Frequent User Id'
                  )}
                  <Form.Item
                    name="most_frequent_user_id"
                    label="Most Frequent User Id"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'most_recent_user_id']} valuePropName="checked" noStyle>
                      <Checkbox>Most Recent User Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Most Recent User Id'
                  )}
                  <Form.Item
                    name="most_recent_user_id"
                    label="Most Recent User Id"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_virtual" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_virtual']} valuePropName="checked" noStyle>
                      <Checkbox>Is Virtual</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Virtual'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_portable" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_portable']} valuePropName="checked" noStyle>
                      <Checkbox>Is Portable</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Portable'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_server" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_server']} valuePropName="checked" noStyle>
                      <Checkbox>Is Server</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Server'
                  )}
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={snowComputers.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddSnowComputersModal;
