import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearSnowComputersMessages,
  snowComputersSelector,
  setTableColumnSelection,
} from '../../../../store/snow/snowComputers/snowComputers.reducer';
import {
  deleteSnowComputers,
  searchSnowComputers,
} from '../../../../store/snow/snowComputers/snowComputers.action';
import snowComputersService from '../../../../services/snow/snowComputers/snowComputers.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import { AlignType } from 'rc-table/lib/interface';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const snowComputers = useAppSelector(snowComputersSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return snowComputersService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      snowComputers.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', snowComputers.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Computer Id</span>,
        column: 'ComputerId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'computer_id',
              form
            ),
            dataIndex: 'computer_id',
            key: 'computer_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'name',
              form
            ),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Organization</span>,
        column: 'Organization',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'organization',
              form
            ),
            dataIndex: 'organization',
            key: 'organization',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Org Checksum</span>,
        column: 'OrgChecksum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'org_checksum',
              form
            ),
            dataIndex: 'org_checksum',
            key: 'org_checksum',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer</span>,
        column: 'Manufacturer',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer',
              form
            ),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Model</span>,
        column: 'Model',
        sorter: true,
        children: [
          {
            title: FilterBySwap('model', form),
            dataIndex: 'model',
            key: 'model',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System</span>,
        column: 'OperatingSystem',
        sorter: true,
        children: [
          {
            title: FilterBySwap('operating_system', form),
            dataIndex: 'operating_system',
            key: 'operating_system',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System Service Pack</span>,
        column: 'OperatingSystemServicePack',
        sorter: true,
        children: [
          {
            title: FilterBySwap('operating_system_service_pack', form),
            dataIndex: 'operating_system_service_pack',
            key: 'operating_system_service_pack',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Status</span>,
        column: 'Status',
        sorter: true,
        children: [
          {
            title: FilterBySwap('status', form),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Scan Date</span>,
        column: 'LastScanDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('last_scan_date', snowComputers.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'last_scan_date',
            key: 'last_scan_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Updated Date</span>,
        column: 'UpdatedDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('updated_date', snowComputers.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'updated_date',
            key: 'updated_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Ip Addresses</span>,
        column: 'IpAddresses',
        sorter: true,
        children: [
          {
            title: FilterBySwap('ip_addresses', form),
            dataIndex: 'ip_addresses',
            key: 'ip_addresses',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Updated By</span>,
        column: 'UpdatedBy',
        sorter: true,
        children: [
          {
            title: FilterBySwap('updated_by', form),
            dataIndex: 'updated_by',
            key: 'updated_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Domain</span>,
        column: 'Domain',
        sorter: true,
        children: [
          {
            title: FilterBySwap('domain', form),
            dataIndex: 'domain',
            key: 'domain',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Total Disk Space</span>,
        column: 'TotalDiskSpace',
        sorter: true,
        children: [
          {
            title: FilterBySwap('total_disk_space', form),
            dataIndex: 'total_disk_space',
            key: 'total_disk_space',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Physical Memory</span>,
        column: 'PhysicalMemory',
        sorter: true,
        children: [
          {
            title: FilterBySwap('physical_memory', form),
            dataIndex: 'physical_memory',
            key: 'physical_memory',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor Type</span>,
        column: 'ProcessorType',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor_type', form),
            dataIndex: 'processor_type',
            key: 'processor_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor Count</span>,
        column: 'ProcessorCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor_count', form),
            dataIndex: 'processor_count',
            key: 'processor_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Core Count</span>,
        column: 'CoreCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('core_count', form),
            dataIndex: 'core_count',
            key: 'core_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bios Serial Number</span>,
        column: 'BiosSerialNumber',
        sorter: true,
        children: [
          {
            title: FilterBySwap('bios_serial_number', form),
            dataIndex: 'bios_serial_number',
            key: 'bios_serial_number',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Hardware</span>,
        column: 'Hardware',
        sorter: true,
        children: [
          {
            title: FilterBySwap('hardware', form),
            dataIndex: 'hardware',
            key: 'hardware',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Hypervisor Name</span>,
        column: 'HypervisorName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('hypervisor_name', form),
            dataIndex: 'hypervisor_name',
            key: 'hypervisor_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Most Frequent User Id</span>,
        column: 'MostFrequentUserId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('most_frequent_user_id', form),
            dataIndex: 'most_frequent_user_id',
            key: 'most_frequent_user_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Most Recent User Id</span>,
        column: 'MostRecentUserId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('most_recent_user_id', form),
            dataIndex: 'most_recent_user_id',
            key: 'most_recent_user_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Virtual</span>,
        column: 'IsVirtual',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_virtual',
              snowComputers.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_virtual',
            key: 'is_virtual',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Portable</span>,
        column: 'IsPortable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_portable',
              snowComputers.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_portable',
            key: 'is_portable',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Server</span>,
        column: 'IsServer',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_server',
              snowComputers.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_server',
            key: 'is_server',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      }, 
    ];
  };

  const removeSnowComputers = (id: number) => {
    dispatch(deleteSnowComputers(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.SnowComputers}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/snow/snow-computers/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.SnowComputers}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeSnowComputers(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.SnowComputers)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={snowComputersSelector}
        searchTableData={searchSnowComputers}
        clearTableDataMessages={clearSnowComputersMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.SnowComputers)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
