import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import {
  getObjectForUpdateMultiple,
  getSimpleDate,
  passDateToApi,
  forEditModal,
} from '../../../../common/helperFunction';
import { IInlineSearch } from '../../../../common/models/common';
import { ICmdbCiServer } from '../../../../services/serviceNow/cmdbCiServer/cmdbCiServer.model';
import { ILookup } from '../../../../services/common/common.model';
import { getCmdbCiServerById, saveCmdbCiServer } from '../../../../store/serviceNow/cmdbCiServer/cmdbCiServer.action';
import {
  CmdbCiServerSelector,
  clearCmdbCiServerGetById,
  clearCmdbCiServerMessages,
} from '../../../../store/serviceNow/cmdbCiServer/cmdbCiServer.reducer';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getBULookup,
  getCompanyLookup,
  getTenantLookup,
  updateMultiple,
} from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';
import { IAddCmdbCiServerProps } from './addCmdbCiServer.model';

const { Option } = Select;

const AddCmdbCiServerModal: React.FC<IAddCmdbCiServerProps> = (props) => {
  const CmdbCiServer = useAppSelector(CmdbCiServerSelector);
  const commonLookups = useAppSelector(commonSelector);
  const dispatch = useAppDispatch();
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.CmdbCiServer} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: ICmdbCiServer = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    fqdn: '',
    name: '',
    sys_class_name: '',
    department: '',
    install_status: '',
    operational_status: '',
    ip_address: '',
    os: '',
    classification: '',
    virtual: false,
    cpu_core_count: null,
    cpu_count: null,
    cpu_manufacturer: '',
    cpu_name: '',
    u_crowd_strike_host_id: '',
    model_id: '',
    serial_number: '',
    used_for: '',
    support_group: '',
    u_raw_os: '',
    u_raw_operating_system_version: '',
    location: '',
    last_discovered: '',
    u_backup_re_ten: '',
    u_back_up_time: '',
    dr_backup: '',
    maintenance_schedule: '',
    u_ami: '',
    u_build_image: '',
    discovery_source: '',
    u_tanium_discovered: '',
    u_tanium_instance_type: '',
    u_cluster: '',
    cluster_id: '',
    cluster_name: '',
    environment: '',
    managed_by_group: '',
    managed_by: '',
    owned_by: '',
    processor: '',
    processor_name: '',
    dns_domain: '',
    u_ilo_fqdn: '',
    sys_domain: '',
    sys_domain_path: '',
    install_date: '',
    date_added: getSimpleDate(),
  };

  const onFinish = (values: any) => {
    const inputValues: ICmdbCiServer = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.date_added = passDateToApi(inputValues.date_added, true);
    inputValues.install_date = passDateToApi(inputValues.install_date, true);
    if (!isMultiple) {
      dispatch(saveCmdbCiServer(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        CmdbCiServer.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  const fillValuesOnEdit = async (data) => {
    if (data.tenant_id) {
      await dispatch(getCompanyLookup(data.tenant_id));
    }
    if (data.company_id) {
      await dispatch(getBULookup(data.company_id));
    }
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        fqdn: data.fqdn,
        name: data.name,
        sys_class_name: data.sys_class_name,
        department: data.department,
        install_status: data.install_status,
        operational_status: data.operational_status,
        ip_address: data.ip_address,
        os: data.os,
        classification: data.classification,
        virtual: data.virtual,
        cpu_core_count: _.isNull(data.cpu_core_count) ? null : data.cpu_core_count,
        cpu_count: _.isNull(data.cpu_count) ? null : data.cpu_count,
        cpu_manufacturer: data.cpu_manufacturer,
        cpu_name: data.cpu_name,
        u_crowd_strike_host_id: data.u_crowd_strike_host_id,
        model_id: data.model_id,
        serial_number: data.serial_number,
        used_for: data.used_for,
        support_group: data.support_group,
        u_raw_os: data.u_raw_os,
        u_raw_operating_system_version: data.u_raw_operating_system_version,
        location: data.location,
        last_discovered: data.last_discovered,
        u_backup_re_ten: data.u_backup_re_ten,
        u_back_up_time: data.u_back_up_time,
        dr_backup: data.dr_backup,
        maintenance_schedule: data.maintenance_schedule,
        u_ami: data.u_ami,
        u_build_image: data.u_build_image,
        discovery_source: data.discovery_source,
        u_tanium_discovered: data.u_tanium_discovered,
        u_tanium_instance_type: data.u_tanium_instance_type,
        u_cluster: data.u_cluster,
        cluster_id: data.cluster_id,
        cluster_name: data.cluster_name,
        environment: data.environment,
        managed_by_group: data.managed_by_group,
        managed_by: data.managed_by,
        owned_by: data.owned_by,
        processor: data.processor,
        processor_name: data.processor_name,
        dns_domain: data.dns_domain,
        u_ilo_fqdn: data.u_ilo_fqdn,
        sys_domain: data.sys_domain,
        sys_domain_path: data.sys_domain_path,
        install_date: data.install_date,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (CmdbCiServer.save.messages.length > 0) {
      if (CmdbCiServer.save.hasErrors) {
        toast.error(CmdbCiServer.save.messages.join(' '));
      } else {
        toast.success(CmdbCiServer.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearCmdbCiServerMessages());
    }
  }, [CmdbCiServer.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && CmdbCiServer.getById.data) {
      const data = CmdbCiServer.getById.data;
      fillValuesOnEdit(data);
    }
  }, [CmdbCiServer.getById.data]);

  useEffect(() => {
    if (Object.keys(globalFilters?.globalTenantLookup?.data).length == 0) {
      dispatch(getTenantLookup());
    }
    if (+id > 0) {
      dispatch(getCmdbCiServerById(+id));
    }
    return () => {
      dispatch(clearCmdbCiServerGetById());
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (+id === 0 && !isMultiple) {
      const globalSearch: IInlineSearch = {};
      for (const key in globalFilters.search) {
        const element = globalFilters.search[key];
        globalSearch[key] = element ? [element] : null;
      }
      if (globalFilters.search.tenant_id && globalFilters.search.tenant_id !== 0) {
        if (!globalFilters.search.company_id) {
          dispatch(getCompanyLookup(globalSearch.tenant_id[0]));
        }
        if (!globalFilters.search.bu_id && globalFilters.search.company_id !== 0) {
          dispatch(getBULookup(globalSearch.company_id[0]));
        }
        const initlValues = {
          company_id: _.isNull(globalSearch.company_id) ? null : globalSearch.company_id[0],
          bu_id: _.isNull(globalSearch.bu_id) ? null : globalSearch.bu_id[0],
          tenant_id: _.isNull(globalSearch.tenant_id) ? null : globalSearch.tenant_id[0],
        };
        form.setFieldsValue(initlValues);
      }
    }
  }, []);

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {CmdbCiServer.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={CmdbCiServer.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="addCmdbCiServer"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      loading={commonLookups.tenantLookup.loading}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      loading={commonLookups.buLookup.loading}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'fqdn']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>FDQN</Checkbox>
                    </Form.Item>
                  ) : (
                    'FDQN'
                  )}
                  <Form.Item
                    name="fqdn"
                    label="FDQN"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'name']} valuePropName="checked" noStyle>
                      <Checkbox>Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Name'
                  )}
                  <Form.Item
                    name="name"
                    className="m-0"
                    label="Name"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'sys_class_name']} valuePropName="checked" noStyle>
                      <Checkbox>System Class Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'System Class Name'
                  )}
                  <Form.Item
                    name="sys_class_name"
                    label="System Class Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'department']} valuePropName="checked" noStyle>
                      <Checkbox>Department</Checkbox>
                    </Form.Item>
                  ) : (
                    'Department'
                  )}
                  <Form.Item
                    name="department"
                    label="Department"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'os']} valuePropName="checked" noStyle>
                      <Checkbox>OS</Checkbox>
                    </Form.Item>
                  ) : (
                    'OS'
                  )}
                  <Form.Item
                    name="os"
                    label="OS"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'install_status']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>Install Status</Checkbox>
                    </Form.Item>
                  ) : (
                    'Install Status'
                  )}
                  <Form.Item name="install_status" label="Install Status" className="m-0">
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'operational_status']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>Operational Status</Checkbox>
                    </Form.Item>
                  ) : (
                    'Operational Status'
                  )}
                  <Form.Item
                    name="operational_status"
                    label="Operational Status"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'discovery_source']} valuePropName="checked" noStyle>
                      <Checkbox>Discovery Source</Checkbox>
                    </Form.Item>
                  ) : (
                    'Discovery Source'
                  )}
                  <Form.Item name="discovery_source" label="Discovery Source" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'ip_address']} valuePropName="checked" noStyle>
                      <Checkbox>IP Address</Checkbox>
                    </Form.Item>
                  ) : (
                    'IP Address'
                  )}
                  <Form.Item
                    name="ip_address"
                    label="IP Address"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'classification']} valuePropName="checked" noStyle>
                      <Checkbox>Classification</Checkbox>
                    </Form.Item>
                  ) : (
                    'Classification'
                  )}
                  <Form.Item
                    name="classification"
                    label="Classification"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'cpu_core_count']} valuePropName="checked" noStyle>
                      <Checkbox>CPU Core Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'CPU Core Count'
                  )}
                  <Form.Item name="cpu_core_count" label="CPU Core Count" className="m-0" rules={[{ type: 'integer' }]}>
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'cpu_count']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>CPU Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'CPU Count'
                  )}
                  <Form.Item
                    name="cpu_count"
                    label="CPU Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'cpu_manufacturer']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>CPU Manufacturer</Checkbox>
                    </Form.Item>
                  ) : (
                    'CPU Manufacturer'
                  )}
                  <Form.Item name="cpu_manufacturer" label="CPU Manufacturer" className="m-0">
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'cpu_name']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>CPU Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'CPU Name'
                  )}
                  <Form.Item
                    name="cpu_name"
                    label="CPU Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_crowdstrike_host_id']} valuePropName="checked" noStyle>
                      <Checkbox>U CrowdStrike Host ID</Checkbox>
                    </Form.Item>
                  ) : (
                    'U CrowdStrike Host ID'
                  )}
                  <Form.Item name="u_crowdstrike_host_id" label="U CrowdStrike Host ID" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'model_id']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>Model ID</Checkbox>
                    </Form.Item>
                  ) : (
                    'Model ID'
                  )}
                  <Form.Item
                    name="model_id"
                    label="Model ID"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'serial_number']} valuePropName="checked" noStyle>
                      <Checkbox>Serial Number</Checkbox>
                    </Form.Item>
                  ) : (
                    'Serial Number'
                  )}
                  <Form.Item
                    name="serial_number"
                    label="Serial Number"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'used_for']} valuePropName="checked" noStyle>
                      <Checkbox>Used For</Checkbox>
                    </Form.Item>
                  ) : (
                    'Used For'
                  )}
                  <Form.Item
                    name="used_for"
                    label="Used For"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input min={1} className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_raw_os']} valuePropName="checked" noStyle>
                      <Checkbox>U Raw OS</Checkbox>
                    </Form.Item>
                  ) : (
                    'U Raw OS'
                  )}
                  <Form.Item name="u_raw_os" label="U Raw OS" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_raw_operating_system_version']} valuePropName="checked" noStyle>
                      <Checkbox>U Raw Operating System Version</Checkbox>
                    </Form.Item>
                  ) : (
                    'U Raw Operating System Version'
                  )}
                  <Form.Item name="u_raw_operating_system_version" label="U Raw Operating System Version" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'location']} valuePropName="checked" noStyle>
                      <Checkbox>Location</Checkbox>
                    </Form.Item>
                  ) : (
                    'Location'
                  )}
                  <Form.Item name="location" label="Location" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_discovered']} valuePropName="checked" noStyle>
                      <Checkbox>Last Discovered</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Discovered'
                  )}
                  <Form.Item name="last_discovered" label="Last Discovered" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_backup_reten']} valuePropName="checked" noStyle>
                      <Checkbox>U Backup Reten</Checkbox>
                    </Form.Item>
                  ) : (
                    'U Backup Reten'
                  )}
                  <Form.Item name="u_backup_reten" label="U Backup Reten" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_back_up_time']} valuePropName="checked" noStyle>
                      <Checkbox>U Backup Time</Checkbox>
                    </Form.Item>
                  ) : (
                    'U Backup Time'
                  )}
                  <Form.Item name="u_back_up_time" label="U Backup Time" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'dr_backup']} valuePropName="checked" noStyle>
                      <Checkbox>Dr Backup</Checkbox>
                    </Form.Item>
                  ) : (
                    'Dr Backup'
                  )}
                  <Form.Item name="dr_backup" label="Dr Backup" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>


              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'maintenance_schedule']} valuePropName="checked" noStyle>
                      <Checkbox>Maintenance Schedule</Checkbox>
                    </Form.Item>
                  ) : (
                    'Maintenance Schedule'
                  )}
                  <Form.Item name="maintenance_schedule" label="Maintenance Schedule" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_ami']} valuePropName="checked" noStyle>
                      <Checkbox>U AMI</Checkbox>
                    </Form.Item>
                  ) : (
                    'U AMI'
                  )}
                  <Form.Item name="u_ami" label="U AMI" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_build_image']} valuePropName="checked" noStyle>
                      <Checkbox>U Build Image</Checkbox>
                    </Form.Item>
                  ) : (
                    'U Build Image'
                  )}
                  <Form.Item name="u_build_image" label="U Build Image" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_tanium_discovered']} valuePropName="checked" noStyle>
                      <Checkbox>U Tanium Discovered</Checkbox>
                    </Form.Item>
                  ) : (
                    'U Tanium Discovered'
                  )}
                  <Form.Item name="u_tanium_discovered" label="U Tanium Discovered" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_tanium_instance_type']} valuePropName="checked" noStyle>
                      <Checkbox>U Tanium Instance Type</Checkbox>
                    </Form.Item>
                  ) : (
                    'U Tanium Instance Type'
                  )}
                  <Form.Item name="u_tanium_instance_type" label="U Tanium Instance Type" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_cluster']} valuePropName="checked" noStyle>
                      <Checkbox>U Cluster</Checkbox>
                    </Form.Item>
                  ) : (
                    'U Cluster'
                  )}
                  <Form.Item name="u_cluster" label="U Cluster" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'cluster_id']} valuePropName="checked" noStyle>
                      <Checkbox>Cluster ID</Checkbox>
                    </Form.Item>
                  ) : (
                    'Cluster ID'
                  )}
                  <Form.Item name="cluster_id" label="Cluster ID" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'cluster_name']} valuePropName="checked" noStyle>
                      <Checkbox>Cluster Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Cluster Name'
                  )}
                  <Form.Item name="cluster_name" label="Cluster Name" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'environment']} valuePropName="checked" noStyle>
                      <Checkbox>Environment</Checkbox>
                    </Form.Item>
                  ) : (
                    'Environment'
                  )}
                  <Form.Item name="environment" label="Environment" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'managed_by_group']} valuePropName="checked" noStyle>
                      <Checkbox>Managed By Group</Checkbox>
                    </Form.Item>
                  ) : (
                    'Managed By Group'
                  )}
                  <Form.Item name="managed_by_group" label="Managed By Group" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'managed_by']} valuePropName="checked" noStyle>
                      <Checkbox>Managed By</Checkbox>
                    </Form.Item>
                  ) : (
                    'Managed By'
                  )}
                  <Form.Item name="managed_by" label="Managed By" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'owned_by']} valuePropName="checked" noStyle>
                      <Checkbox>Owned By</Checkbox>
                    </Form.Item>
                  ) : (
                    'Owned By'
                  )}
                  <Form.Item name="owned_by" label="Owned By" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'processor']} valuePropName="checked" noStyle>
                      <Checkbox>Processor</Checkbox>
                    </Form.Item>
                  ) : (
                    'Processor'
                  )}
                  <Form.Item name="processor" label="Processor" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'processor_name']} valuePropName="checked" noStyle>
                      <Checkbox>Processor Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Processor Name'
                  )}
                  <Form.Item name="processor_name" label="Processor Name" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'dns_domain']} valuePropName="checked" noStyle>
                      <Checkbox>DNS Domain</Checkbox>
                    </Form.Item>
                  ) : (
                    'DNS Domain'
                  )}
                  <Form.Item name="dns_domain" label="DNS Domain" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'u_ilo_fqdn']} valuePropName="checked" noStyle>
                      <Checkbox>U ILO FQDN</Checkbox>
                    </Form.Item>
                  ) : (
                    'U ILO FQDN'
                  )}
                  <Form.Item name="u_ilo_fqdn" label="U ILO FQDN" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'sys_domain']} valuePropName="checked" noStyle>
                      <Checkbox>System Domain</Checkbox>
                    </Form.Item>
                  ) : (
                    'System Domain'
                  )}
                  <Form.Item name="sys_domain" label="System Domain" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'sys_domain_path']} valuePropName="checked" noStyle>
                      <Checkbox>System Domain Path</Checkbox>
                    </Form.Item>
                  ) : (
                    'System Domain Path'
                  )}
                  <Form.Item name="sys_domain_path" label="System Domain Path" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="virtual" className="m-0 mr-1" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  {isMultiple ? (
                    <Form.Item name={['checked', 'virtual']} valuePropName="checked" noStyle>
                      <Checkbox>Virtual</Checkbox>
                    </Form.Item>
                  ) : (
                    'Virtual'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'install_date']} valuePropName="checked" noStyle>
                      <Checkbox>Install Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Install Date'
                  )}
                  <Form.Item name="install_date" label="Install Date" className="m-0" rules={[{ max: 255 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={CmdbCiServer.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddCmdbCiServerModal;
