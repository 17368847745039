import { Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearSnowHostMessages,
  snowHostSelector,
  setTableColumnSelection,
} from '../../../../store/snow/snowHost/snowHost.reducer';
import {
  deleteSnowHost,
  searchSnowHost,
} from '../../../../store/snow/snowHost/snowHost.action';
import snowHostService from '../../../../services/snow/snowHost/snowHost.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const snowHost = useAppSelector(snowHostSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return snowHostService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      snowHost.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', snowHost.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Dccs Id</span>,
        column: 'DccsId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'dccs_id',
              form
            ),
            dataIndex: 'dccs_id',
            key: 'dccs_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Computer Id</span>,
        column: 'ComputerId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'computer_id',
              form
            ),
            dataIndex: 'computer_id',
            key: 'computer_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Computer Name</span>,
        column: 'ComputerName',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'computer_name',
              form
            ),
            dataIndex: 'computer_name',
            key: 'computer_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Scan Date</span>,
        column: 'Last Scan Date',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('last_scan_date', snowHost.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'last_scan_date',
            key: 'last_scan_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Virtual Machines</span>,
        column: 'VirtualMachines',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'virtual_machines',
              form
            ),
            dataIndex: 'virtual_machines',
            key: 'virtual_machines',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System</span>,
        column: 'OperatingSystem',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'operating_system',
              form
            ),
            dataIndex: 'operating_system',
            key: 'operating_system',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor Type</span>,
        column: 'ProcessorType',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor_type', form),
            dataIndex: 'processor_type',
            key: 'processor_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor Count</span>,
        column: 'ProcessorCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor_count', form),
            dataIndex: 'processor_count',
            key: 'processor_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cores Per Processor</span>,
        column: 'CoresPerProcessor',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cores_per_processor', form),
            dataIndex: 'cores_per_processor',
            key: 'cores_per_processor',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Hypervisor Name</span>,
        column: 'HypervisorName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('hypervisor_name', form),
            dataIndex: 'hypervisor_name',
            key: 'hypervisor_name',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeSnowHost = (id: number) => {
    dispatch(deleteSnowHost(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.SnowHost}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/snow/snow-host/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.SnowHost}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeSnowHost(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.SnowHost)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={snowHostSelector}
        searchTableData={searchSnowHost}
        clearTableDataMessages={clearSnowHostMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.SnowHost)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
