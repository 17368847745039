import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPegaSecurityReports, ISearchPegaSecurityReports } from '../../../services/pega/pegaSecurityReport/pegaSecurityReport.model';
import PegaSecurityReportsService from '../../../services/pega/pegaSecurityReport/pegaSecurityReport.service';

// Asynchronous thunk action

export const searchPegaSecurityReports = createAsyncThunk(
  'searchPegaSecurityReports',
  async (searchParam?: ISearchPegaSecurityReports) => {
    const response = await PegaSecurityReportsService
      .searchPegaSecurityReports(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getPegaSecurityReportsById = createAsyncThunk(
  'getPegaSecurityReportsById',
  async (id: number) => {
    const response = await PegaSecurityReportsService.getPegaSecurityReportsById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const savePegaSecurityReports = createAsyncThunk(
  'savePegaSecurityReports',
  async (data: IPegaSecurityReports) => {
    const response = await PegaSecurityReportsService.savePegaSecurityReports(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deletePegaSecurityReports = createAsyncThunk(
  'deletePegaSecurityReports',
  async (id: number) => {
    const response = await PegaSecurityReportsService.deletePegaSecurityReports(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
