import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ability from '../../common/ability';
import { Action, Page } from '../../common/constants/pageAction';
import PegaDetails from './PegaDetailsUserData';
import PegaSecurityReports from './PegaSecurityReports';

const PegaRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div className="sqlServer">
      <Switch>
        {/* Pega Details User Data */}
        {ability.can(Action.View, Page.PegaDetails) && (
          <Route exact path={`${match.path}/pega-detail-user-data/:id`} component={PegaDetails} />
        )}
        {ability.can(Action.View, Page.PegaDetails) && (
          <Route exact path={`${match.path}/pega-detail-user-data`} component={PegaDetails} />
        )}

        {/* Pega Security Reports */}
        {ability.can(Action.View, Page.PegaSecurityReports) && (
          <Route exact path={`${match.path}/pega-security-report/:id`} component={PegaSecurityReports} />
        )}
        {ability.can(Action.View, Page.PegaSecurityReports) && (
          <Route exact path={`${match.path}/pega-security-report`} component={PegaSecurityReports} />
        )}

        {/* keep least always */}
        <Route path={`${match.path}/*`}>
          <Redirect to={`/404`} />
        </Route>
      </Switch>
    </div>
  );
};

export default PegaRoutes;
