import { IApiResponse } from '../../common/models/common';
import request from '../../utils/request';
import {
  IDataTable,
  IDataTableForImport,
  IImportDataTable,
  ISasTokenMapping,
  ISaveExcelMapping,
} from './bulkImport.model';

class BulkImportService {
  ENDPOINT = '/config-import-data-tables';

  public async getTablesForImport(): Promise<IApiResponse<Array<IDataTableForImport>>> {
    const url = `${this.ENDPOINT}/table-for-import`;
    return request({ url, method: 'GET' }).then((res) => {
      return res.data;
    });
  }

  public async getTables(): Promise<IApiResponse<Array<IDataTable>>> {
    const url = `${this.ENDPOINT}/tables`;
    return request({ url, method: 'GET' }).then((res) => {
      return res.data;
    });
  }

  public async saveTableForImport(data: IImportDataTable): Promise<any> {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: 'POST', data: data }).then((res) => {
      return res.data;
    });
  }

  public async saveTableForImportV2(data: IImportDataTable[]): Promise<any> {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: 'POST', data: data }).then((res) => {
      return res.data;
    });
  }

  public async getExcelFileMapping(data: any): Promise<any> {
    const url = `/config-excel-file-mapping/mapping`;
    return request({ url, method: 'POST', data: data }).then((res) => {
      return res.data;
    });
  }

  public async getExcelFileMappingLookup(data: any): Promise<any> {
    const url = `/config-excel-file-mapping/lookup`;
    return request({ url, method: 'POST', data: data }).then((res) => {
      return res.data;
    });
  }

  public async getExcelLargeFile(data: any,data1: any): Promise<any> {
    let url = `/app/read-large-excel-file`;
    if (data1.tenant_id !== null) {
      url = `/app/read-large-excel-file?tenant_id=${data1.tenant_id}`;
      if (data1.company_id !== null) {
        url = `/app/read-large-excel-file?tenant_id=${data1.tenant_id}&company_id=${data1.company_id}`;
        if (data1.bu_id !== null) {
          url = `/app/read-large-excel-file?tenant_id=${data1.tenant_id}&company_id=${data1.company_id}&bu_id=${data1.bu_id}`;
        }
      }
    }
    return request({ url, method: 'POST', data: data }).then((res) => {
      return res.data;
    });
  }

  public async deleteFileMapping(id: number): Promise<any> {
    const url = `/config-excel-file-mapping/${id}`;
    return request({ url, method: 'DELETE' }).then((res) => {
      return res.data;
    });
  }

  public async deleteColumnMapping(id: number): Promise<any> {
    const url = `/config-excel-column-mapping/${id}`;
    return request({ url, method: 'DELETE' }).then((res) => {
      return res.data;
    });
  }

  public async saveExcelFileMapping(data: ISaveExcelMapping): Promise<any> {
    const url = `/config-excel-file-mapping`;
    return request({ url, method: 'POST', data: data }).then((res) => {
      return res.data;
    });
  }
  public async getSasToken(): Promise<ISasTokenMapping> {
    const url = `/app/sas-token`;
    return request({ url, method: 'GET' }).then((res) => {
      return res.data.body.data;
    });
  }
}
export default new BulkImportService();
