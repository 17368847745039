import { createAsyncThunk } from '@reduxjs/toolkit';
import CmdbCiDbMsSqlInstanceService from '../../../services/serviceNow/cmdbCiDbMsSqlInstance/cmdbCiDbMsSqlInstance.service';
import {
  ISearchCmdbCiDbMsSqlInstance,
  ICmdbCiDbMsSqlInstance,
  IProcessData,
} from '../../../services/serviceNow/cmdbCiDbMsSqlInstance/cmdbCiDbMsSqlInstance.model';

// Asynchronous thunk action

export const searchCmdbCiDbMsSqlInstance = createAsyncThunk(
  'searchCmdbCiDbMsSqlInstance',
  async (searchParam?: ISearchCmdbCiDbMsSqlInstance) => {
    const response = await CmdbCiDbMsSqlInstanceService.searchCmdbCiDbMsSqlInstance(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getCmdbCiDbMsSqlInstanceById = createAsyncThunk('getCmdbCiDbMsSqlInstanceById', async (id: number) => {
  const response = await CmdbCiDbMsSqlInstanceService.getCmdbCiDbMsSqlInstanceById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveCmdbCiDbMsSqlInstance = createAsyncThunk('saveCmdbCiDbMsSqlInstance', async (data: ICmdbCiDbMsSqlInstance) => {
  const response = await CmdbCiDbMsSqlInstanceService.saveCmdbCiDbMsSqlInstance(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteCmdbCiDbMsSqlInstance = createAsyncThunk('deleteCmdbCiDbMsSqlInstance', async (id: number) => {
  const response = await CmdbCiDbMsSqlInstanceService.deleteCmdbCiDbMsSqlInstance(id).then((res) => {
    return res.body;
  });
  return response;
});

export const processDataCmdbCiDbMsSqlInstance = createAsyncThunk(
  'processDataCmdbCiDbMsSqlInstance',
  async (data: IProcessData) => {
    const response = await CmdbCiDbMsSqlInstanceService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);
