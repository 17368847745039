import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowVM,
  ISearchSnowVM,
} from '../../../services/snow/snowVM/snowVM.model';
import snowVMService from '../../../services/snow/snowVM/snowVM.service';

// Asynchronous thunk action

export const searchSnowVM = createAsyncThunk(
  'searchSnowVM',
  async (searchParam?: ISearchSnowVM) => {
    const response = await snowVMService
      .searchSnowVM(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowVMById = createAsyncThunk(
  'getSnowVMById',
  async (id: number) => {
    const response = await snowVMService.getSnowVMById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowVM = createAsyncThunk(
  'saveSnowVM',
  async (data: ISnowVM) => {
    const response = await snowVMService.saveSnowVM(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowVM = createAsyncThunk(
  'deleteSnowVM',
  async (id: number) => {
    const response = await snowVMService.deleteSnowVM(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
