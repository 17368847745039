import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CmdbCiComputer from './CmdbCiComputer';
import ability from '../../common/ability';
import { Action, Page } from '../../common/constants/pageAction';
import CmdbCiDbMsSqlInstance from './CmdbCiDbMsSqlInstance';
import CmdbCiVcenterCluster from './CmdbCiDbVcenterCluster';
import CmdbCiWinClusterNode from './CmdbCiWinClusterNode';
import CmdbCiVMInstance from './CmdbCiDbVMInstance';
import CmdbRelCi from './CmdbRelCi';
import CmdbSamSWInstall from './CmdbSamSWInstall';
import CmdbCiServer from './CmdbCiServer';
//import AdDevices from './AdDevices';

const ServiceNowRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div className="ad">
      <Switch>
        {/* Ci Computer */}
        {ability.can(Action.View, Page.CmdbCiComputer) && (
          <Route exact path={`${match.path}/service-now-cmdb-ci-computer/:id`} component={CmdbCiComputer} />
        )}
        {ability.can(Action.View, Page.CmdbCiComputer) && (
          <Route exact path={`${match.path}/service-now-cmdb-ci-computer`} component={CmdbCiComputer} />
        )}

        {/* Ci Db Ms Sql instance */}
        {ability.can(Action.View, Page.CmdbCiDbMsSqlInstance) && (
          <Route exact path={`${match.path}/service-now-cmdb-ci-db-mssql-instance/:id`} component={CmdbCiDbMsSqlInstance} />
        )}
        {ability.can(Action.View, Page.CmdbCiDbMsSqlInstance) && (
          <Route exact path={`${match.path}/service-now-cmdb-ci-db-mssql-instance`} component={CmdbCiDbMsSqlInstance} />
        )}

        {/* Ci Db Vcenter Cluster */}
        {ability.can(Action.View, Page.CmdbCiVcenterCluster) && (
          <Route exact path={`${match.path}/service-now-cmdb-ci-vcenter-cluster/:id`} component={CmdbCiVcenterCluster} />
        )}
        {ability.can(Action.View, Page.CmdbCiVcenterCluster) && (
          <Route exact path={`${match.path}/service-now-cmdb-ci-vcenter-cluster`} component={CmdbCiVcenterCluster} />
        )}

        {/* Ci Db VM Instance */}
        {ability.can(Action.View, Page.CmdbCiVMInstance) && (
          <Route exact path={`${match.path}/service-now-cmdb-ci-vm-instance/:id`} component={CmdbCiVMInstance} />
        )}
        {ability.can(Action.View, Page.CmdbCiVMInstance) && (
          <Route exact path={`${match.path}/service-now-cmdb-ci-vm-instance`} component={CmdbCiVMInstance} />
        )}
        
        {/* Ci Db Win Cluster Node */}
        {ability.can(Action.View, Page.CmdbCiWinClusterNode) && (
          <Route exact path={`${match.path}/winclusternode/:id`} component={CmdbCiWinClusterNode} />
        )}
        {ability.can(Action.View, Page.CmdbCiWinClusterNode) && (
          <Route exact path={`${match.path}/winclusternode`} component={CmdbCiWinClusterNode} />
        )}

        {/* Rel Ci */}
        {ability.can(Action.View, Page.CmdbRelCi) && (
          <Route exact path={`${match.path}/relci/:id`} component={CmdbRelCi} />
        )}
        {ability.can(Action.View, Page.CmdbRelCi) && (
          <Route exact path={`${match.path}/relci`} component={CmdbRelCi} />
        )}

        {/* Sam SW Install */}
        {ability.can(Action.View, Page.CmdbSamSWInstall) && (
          <Route exact path={`${match.path}/samswinstall/:id`} component={CmdbSamSWInstall} />
        )}
        {ability.can(Action.View, Page.CmdbSamSWInstall) && (
          <Route exact path={`${match.path}/samswinstall`} component={CmdbSamSWInstall} />
        )}

        {/* Sam SW Install */}
        {ability.can(Action.View, Page.CmdbCiServer) && (
          <Route exact path={`${match.path}/ciserver/:id`} component={CmdbCiServer} />
        )}
        {ability.can(Action.View, Page.CmdbCiServer) && (
          <Route exact path={`${match.path}/ciserver`} component={CmdbCiServer} />
        )}

        {/* keep least always */}
        <Route path={`${match.path}/*`}>
          <Redirect to={`/404`} />
        </Route>
      </Switch>
    </div>
  );
};

export default ServiceNowRoutes;
