import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISearchAzurePrices,
  IAzurePrices,
} from '../../../services/azure/azurePrices/azurePrices.model';
import azurePricesService from '../../../services/azure/azurePrices/azurePrices.service';

// Asynchronous thunk action

export const searchAzurePrices = createAsyncThunk(
  'searchAzurePrices',
  async (searchParam?: ISearchAzurePrices) => {
    const response = await azurePricesService.searchAzurePrices(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getAzurePricesById = createAsyncThunk('getAzurePricesById', async (id: number) => {
  const response = await azurePricesService.getAzurePricesById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveAzurePrices = createAsyncThunk(
  'saveAzurePrices',
  async (data: IAzurePrices) => {
    const response = await azurePricesService.saveAzurePrices(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteAzurePrices = createAsyncThunk('deleteAzurePrices', async (id: number) => {
  const response = await azurePricesService.deleteAzurePrices(id).then((res) => {
    return res.body;
  });
  return response;
});
