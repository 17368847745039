import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import {
  setTableColumnSelection,
  clearCmdbCiComputerMessages,
  CmdbCiComputerSelector,
} from '../../../../store/serviceNow/cmdbCiComputer/cmdbCiComputer.reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { deleteCmdbCiComputer, searchCmdbCiComputer } from '../../../../store/serviceNow/cmdbCiComputer/cmdbCiComputer.action';
import _ from 'lodash';
import CmdbCiComputerService from '../../../../services/serviceNow/cmdbCiComputer/cmdbCiComputer.service';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import { AlignType } from 'rc-table/lib/interface';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { showDateFromApi } from '../../../../common/helperFunction';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    setFilterKeys,
    tableButtons,
  } = props;
  const CmdbCiComputer = useAppSelector(CmdbCiComputerSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return CmdbCiComputerService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, columnWidthOpt?: boolean, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      CmdbCiComputer.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      columnWidthOpt === undefined ? false : columnWidthOpt,
      isRelativeDropDownFlag
    );
  };

  const FilterByDateSwapTable = (dataIndex: string, tableName: string, form: any) => {
    return FilterByDateSwap(dataIndex, tableName, form, null, ObjectForColumnFilter);
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form, true),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              false,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              false,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              false,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwapTable('date_added', CmdbCiComputer.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Virtual</span>,
        column: 'virtual',
        sorter: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'virtual',
              CmdbCiComputer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'virtual',
            key: 'virtual',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">FQDN</span>,
        column: 'fqdn',
        sorter: true,
        children: [
          {
            title: FilterBySwap('fqdn', form),
            dataIndex: 'fqdn',
            key: 'fqdn',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('name', form),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer</span>,
        column: 'manufacturer',
        sorter: true,
        children: [
          {
            title: FilterBySwap('manufacturer', form),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            ellipsis: true,
          },
        ],
      },

      {
        title: <span className="dragHandler">OS</span>,
        column: 'os',
        sorter: true,
        children: [
          {
            title: FilterBySwap('os', form),
            dataIndex: 'os',
            key: 'os',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Class Name</span>,
        column: 'sys_class_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_class_name', form),
            dataIndex: 'sys_class_name',
            key: 'sys_class_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Created On</span>,
        column: 'sys_created_on',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_created_on', form),
            dataIndex: 'sys_created_on',
            key: 'sys_created_on',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Updated On</span>,
        column: 'sys_updated_on',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_updated_on', form),
            dataIndex: 'sys_updated_on',
            key: 'sys_updated_on',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Discovery Source</span>,
        column: 'discovery_source',
        sorter: true,
        children: [
          {
            title: FilterBySwap('discovery_source', form),
            dataIndex: 'discovery_source',
            key: 'discovery_source',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Updated By</span>,
        column: 'sys_updated_by',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_updated_by', form),
            dataIndex: 'sys_updated_by',
            key: 'sys_updated_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Created By</span>,
        column: 'sys_created_by',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_created_by', form),
            dataIndex: 'sys_created_by',
            key: 'sys_created_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Serial Number</span>,
        column: 'serial_number',
        sorter: true,
        children: [
          {
            title: FilterBySwap('serial_number', form),
            dataIndex: 'serial_number',
            key: 'serial_number',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">CPU Count</span>,
        column: 'cpu_count',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cpu_count', form),
            dataIndex: 'cpu_count',
            key: 'cpu_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">CPU Core Count</span>,
        column: 'cpu_core_count',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cpu_core_count', form),
            dataIndex: 'cpu_core_count',
            key: 'cpu_core_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor</span>,
        column: 'processor',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor', form),
            dataIndex: 'processor',
            key: 'processor',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Metric Type</span>,
        column: 'metric_type',
        sorter: true,
        children: [
          {
            title: FilterBySwap('metric_type', form),
            dataIndex: 'metric_type',
            key: 'metric_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Used For</span>,
        column: 'used_for',
        sorter: true,
        children: [
          {
            title: FilterBySwap('used_for', form),
            dataIndex: 'used_for',
            key: 'used_for',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Hardware Status</span>,
        column: 'hardware_status',
        sorter: true,
        children: [
          {
            title: FilterBySwap('hardware_status', form),
            dataIndex: 'hardware_status',
            key: 'hardware_status',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">U Decommission Date</span>,
        column: 'u_decommission_date',
        sorter: true,
        children: [
          {
            title: FilterBySwap('u_decommission_date', form),
            dataIndex: 'u_decommission_date',
            key: 'u_decommission_date',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Domain</span>,
        column: 'sys_domain',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_domain', form),
            dataIndex: 'sys_domain',
            key: 'sys_domain',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">DNS Domain</span>,
        column: 'dns_domain',
        sorter: true,
        children: [
          {
            title: FilterBySwap('dns_domain', form),
            dataIndex: 'dns_domain',
            key: 'dns_domain',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">OS Domain</span>,
        column: 'os_domain',
        sorter: true,
        children: [
          {
            title: FilterBySwap('os_domain', form),
            dataIndex: 'os_domain',
            key: 'os_domain',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">CPU Name</span>,
        column: 'cpu_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cpu_name', form),
            dataIndex: 'cpu_name',
            key: 'cpu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">First Discovered</span>,
        column: 'first_discovered',
        sorter: true,
        children: [
          {
            title: FilterBySwap('first_discovered', form),
            dataIndex: 'first_discovered',
            key: 'first_discovered',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Model ID</span>,
        column: 'model_id',
        sorter: true,
        children: [
          {
            title: FilterBySwap('model_id', form),
            dataIndex: 'model_id',
            key: 'model_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assigned To</span>,
        column: 'assigned_to',
        sorter: true,
        children: [
          {
            title: FilterBySwap('assigned_to', form),
            dataIndex: 'assigned_to',
            key: 'assigned_to',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Location</span>,
        column: 'location',
        sorter: true,
        children: [
          {
            title: FilterBySwap('location', form),
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Install Status</span>,
        column: 'install_status',
        sorter: true,
        children: [
          {
            title: FilterBySwap('install_status', form),
            dataIndex: 'install_status',
            key: 'install_status',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeCmdbCiComputer = (id: number) => {
    dispatch(deleteCmdbCiComputer(id));
  };

  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.CmdbCiComputer}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/service-now/service-now-cmdb-ci-computer/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.CmdbCiComputer}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeCmdbCiComputer(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.CmdbCiComputer)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={CmdbCiComputerSelector}
        searchTableData={searchCmdbCiComputer}
        clearTableDataMessages={clearCmdbCiComputerMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.CmdbCiComputer)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
