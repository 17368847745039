import { Button, Checkbox, Col, Form, Switch, Modal, Row, Select, Spin, DatePicker, Input } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate } from '../../../../common/helperFunction';
import { IConfigSoftware } from '../../../../services/master/configSoftware/configSoftware.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import { getConfigSoftwareDeviceTypeLookup, getConfigSoftwareEditionLookup, getConfigSoftwareFamilyLookup, getConfigSoftwareMetricLookup, getConfigSoftwareProductsLookup, getConfigSoftwarePublisherLookup, getConfigSoftwareVersionLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import {
  getConfigSoftwareById,
  saveConfigSoftware,
} from '../../../../store/master/configSoftware/configSoftware.action';
import {
  clearConfigSoftwareGetById,
  clearConfigSoftwareMessages,
  configSoftwareSelector,
} from '../../../../store/master/configSoftware/configSoftware.reducer';
import { IAddConfigSoftwareProps } from './addConfigSoftware.model';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';

const { Option } = Select;

const AddConfigSoftwareModal: React.FC<IAddConfigSoftwareProps> = (props) => {
  const configSoftware = useAppSelector(configSoftwareSelector);
  const common = useAppSelector(commonSelector);
  const dispatch = useAppDispatch();
  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.ConfigSoftware} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: IConfigSoftware = {
    device_type_id: null,
    software_family_id: null,
    software_name: '',
    software_metric_id: null,
    software_product_id: null,
    software_publisher_id: null,
    software_edition_id: null,
    software_version_id: null,
    is_operating_system: false,
    end_of_life: getSimpleDate(),
  };

  const onFinish = (values: any) => {
    const inputValues: IConfigSoftware = {
      ...values,
      id: id ? +id : null,
    };
    if (!isMultiple) {
      dispatch(saveConfigSoftware(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        configSoftware.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: IConfigSoftware) => {
    if (data) {
      initialValues = {
        software_name: data.software_name,
        device_type_id: _.isNull(data.device_type_id) ? null : data.device_type_id,
        software_family_id: _.isNull(data.software_family_id) ? null : data.software_family_id,
        software_metric_id: _.isNull(data.software_metric_id) ? null : data.software_metric_id,
        software_product_id: _.isNull(data.software_product_id) ? null : data.software_product_id,
        software_publisher_id: _.isNull(data.software_publisher_id) ? null : data.software_publisher_id,
        software_edition_id: _.isNull(data.software_edition_id) ? null : data.software_edition_id,
        software_version_id: _.isNull(data.software_version_id) ? null : data.software_version_id,
        is_operating_system: data.is_operating_system,
        end_of_life: _.isNull(data.end_of_life) ? null : forEditModal(data.end_of_life),
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (configSoftware.save.messages.length > 0) {
      if (configSoftware.save.hasErrors) {
        toast.error(configSoftware.save.messages.join(' '));
      } else {
        toast.success(configSoftware.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearConfigSoftwareMessages());
    }
  }, [configSoftware.save.messages]);

  useEffect(() => {
    if (common.save.messages.length > 0) {
      if (common.save.hasErrors) {
        toast.error(common.save.messages.join(' '));
      } else {
        toast.warn(common.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [common.save.messages]);

  useEffect(() => {
    if (+id > 0 && configSoftware.getById.data) {
      const data = configSoftware.getById.data;
      fillValuesOnEdit(data);
    }
  }, [configSoftware.getById.data]);

  useEffect(() => {
    dispatch(getConfigSoftwarePublisherLookup());
    dispatch(getConfigSoftwareDeviceTypeLookup());
    dispatch(getConfigSoftwareVersionLookup());
    dispatch(getConfigSoftwareMetricLookup());
    dispatch(getConfigSoftwareEditionLookup());
    dispatch(getConfigSoftwareFamilyLookup());
    dispatch(getConfigSoftwareProductsLookup());
    if (+id > 0) {
      dispatch(getConfigSoftwareById(+id));
    }
    return () => {
      dispatch(clearConfigSoftwareGetById());
    };
  }, [dispatch]);

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {configSoftware.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={configSoftware.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="configSoftware"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'software_edition_id']} valuePropName="checked" noStyle>
                      <Checkbox>Software Edition</Checkbox>
                    </Form.Item>
                  ) : (
                    'Software Edition'
                  )}
                  <Form.Item
                    name="software_edition_id"
                    className="m-0"
                    label="Software Edition"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={common.configSoftwareEditionLookup.loading}
                    >
                      {common.configSoftwareEditionLookup.data.map((option: ILookup) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'software_version_id']} valuePropName="checked" noStyle>
                      <Checkbox>Software Version</Checkbox>
                    </Form.Item>
                  ) : (
                    'Software Version'
                  )}
                  <Form.Item
                    name="software_version_id"
                    className="m-0"
                    label="Software Version"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={common.configSoftwareVersionLookup.loading}
                    >
                      {common.configSoftwareVersionLookup.data.map((option: ILookup) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'device_type_id']} valuePropName="checked" noStyle>
                      <Checkbox>Software Device Type</Checkbox>
                    </Form.Item>
                  ) : (
                    'Software Device Type'
                  )}
                  <Form.Item
                    name="device_type_id"
                    className="m-0"
                    label="Software Device Type"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={common.configSoftwareDeviceTypeLookup.loading}
                    >
                      {common.configSoftwareDeviceTypeLookup.data.map((option: ILookup) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'software_metric_id']} valuePropName="checked" noStyle>
                      <Checkbox>Software Metric</Checkbox>
                    </Form.Item>
                  ) : (
                    'Software Metric'
                  )}
                  <Form.Item
                    name="software_metric_id"
                    className="m-0"
                    label="Software Metric"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={common.configSoftwareMetricLookup.loading}
                    >
                      {common.configSoftwareMetricLookup.data.map((option: ILookup) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'software_family_id']} valuePropName="checked" noStyle>
                      <Checkbox>Software Family</Checkbox>
                    </Form.Item>
                  ) : (
                    'Software Family'
                  )}
                  <Form.Item
                    name="software_family_id"
                    className="m-0"
                    label="Software Family"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={common.configSoftwareFamilyLookup.loading}
                    >
                      {common.configSoftwareFamilyLookup.data.map((option: ILookup) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'software_product_id']} valuePropName="checked" noStyle>
                      <Checkbox>Software Product</Checkbox>
                    </Form.Item>
                  ) : (
                    'Software Product'
                  )}
                  <Form.Item
                    name="software_product_id"
                    className="m-0"
                    label="Software Product"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={common.softwareProductsLookup.loading}
                    >
                      {common.softwareProductsLookup.data.map((option: ILookup) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'software_publisher_id']} valuePropName="checked" noStyle>
                      <Checkbox>Software Publisher</Checkbox>
                    </Form.Item>
                  ) : (
                    'Software Publisher'
                  )}
                  <Form.Item
                    name="software_publisher_id"
                    className="m-0"
                    label="Software Publisher"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={common.configSoftwarePublisherLookup.loading}
                    >
                      {common.configSoftwarePublisherLookup.data.map((option: ILookup) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'software_name']} valuePropName="checked" noStyle>
                      <Checkbox>Software Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Software Name'
                  )}
                  <Form.Item name="software_name" className="m-0" label="Software Name">
                    <Input className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'end_of_life']} valuePropName="checked" noStyle>
                      <Checkbox>End of Life</Checkbox>
                    </Form.Item>
                  ) : (
                    'End of Life'
                  )}
                  <Form.Item name="end_of_life" className="m-0" label="End of Life">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_operating_system" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_operating_system']} valuePropName="checked" noStyle>
                      <Checkbox>Is Operative System</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Operative System'
                  )}
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={configSoftware.save.loading || common.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddConfigSoftwareModal;
