import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { ISnowUser } from '../../../../services/snow/snowUser/snowUser.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getSnowUserById,
  saveSnowUser,
} from '../../../../store/snow/snowUser/snowUser.action';
import {
  clearSnowUserGetById,
  clearSnowUserMessages,
  snowUserSelector,
} from '../../../../store/snow/snowUser/snowUser.reducer';
import { getBULookup, getCompanyLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { IAddSnowUserProps } from './addSnowUser.model';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate, passDateToApi } from '../../../../common/helperFunction';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';

const { Option } = Select;

const AddSnowUserModal: React.FC<IAddSnowUserProps> = (props) => {
  const snowUser = useAppSelector(snowUserSelector);
  const dispatch = useAppDispatch();
  const commonLookups = useAppSelector(commonSelector);
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.SnowUser} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: ISnowUser = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    date_added: getSimpleDate(),
    user_id: null,
    customer_id: null,
    username: '',
    full_name: '',
    organization: '',
    org_checksum: null,
    last_logon: getSimpleDate(),
    last_used_computer_id: null,
    last_used_computer_name: '',
    auto_editing: false,
    email: '',
    location: '',
    mobile_phone_number: '',
    phone_number: '',
    room_number: '',
    status_code: null,
    quarantine_management: '',
    quarantine_date: getSimpleDate(),
    quarantine_delete_date: getSimpleDate(),
    updated_date: getSimpleDate(),
    updated_by: '',
    custom_fields: '',
  };

  const onFinish = (values: any) => {
    const inputValues: ISnowUser = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.date_added = passDateToApi(inputValues.date_added);
    inputValues.last_logon = passDateToApi(inputValues.last_logon);
    inputValues.quarantine_date = passDateToApi(inputValues.quarantine_date);
    inputValues.quarantine_delete_date = passDateToApi(inputValues.quarantine_delete_date);
    inputValues.updated_date = passDateToApi(inputValues.updated_date);
    if (!isMultiple) {
      dispatch(saveSnowUser(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        snowUser.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: ISnowUser) => {
    if (data.tenant_id) {
      await dispatch(getCompanyLookup(data.tenant_id));
    }
    if (data.company_id) {
      await dispatch(getBULookup(data.company_id));
    }
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
        user_id: _.isNull(data.user_id) ? null : data.user_id,
        customer_id: _.isNull(data.customer_id) ? null : data.customer_id,
        username: data.username,
        full_name: data.full_name,
        organization: data.organization,
        org_checksum: _.isNull(data.org_checksum) ? null : data.org_checksum,
        last_logon: _.isNull(data.last_logon) ? null : forEditModal(data.last_logon),
        last_used_computer_id: _.isNull(data.last_used_computer_id) ? null : data.last_used_computer_id,
        last_used_computer_name: data.last_used_computer_name,
        auto_editing: data.auto_editing,
        email: data.email,
        location: data.location,
        mobile_phone_number: data.mobile_phone_number,
        phone_number: data.phone_number,
        room_number: data.room_number,
        status_code: _.isNull(data.status_code) ? null : data.status_code,
        quarantine_management: data.quarantine_management,
        quarantine_date: _.isNull(data.quarantine_date) ? null : forEditModal(data.quarantine_date),
        quarantine_delete_date: _.isNull(data.quarantine_delete_date) ? null : forEditModal(data.quarantine_delete_date),
        updated_date: _.isNull(data.updated_date) ? null : forEditModal(data.updated_date),
        updated_by: data.updated_by,
        custom_fields: data.custom_fields,
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (snowUser.save.messages.length > 0) {
      if (snowUser.save.hasErrors) {
        toast.error(snowUser.save.messages.join(' '));
      } else {
        toast.success(snowUser.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearSnowUserMessages());
    }
  }, [snowUser.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && snowUser.getById.data) {
      const data = snowUser.getById.data;
      fillValuesOnEdit(data);
    }
  }, [snowUser.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getSnowUserById(+id));
    }
    return () => {
      dispatch(clearSnowUserGetById());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {snowUser.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={snowUser.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="snowUser"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.tenantLookup.loading}
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.buLookup.loading}
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'user_id']} valuePropName="checked" noStyle>
                      <Checkbox>User Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'User Id'
                  )}
                  <Form.Item
                    name="user_id"
                    label="User Id"
                    className="m-0"
                    rules={[{ type: 'integer', required: !isMultiple }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'customer_id']} valuePropName="checked" noStyle>
                      <Checkbox>Customer Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Customer Id'
                  )}
                  <Form.Item
                    name="customer_id"
                    label="Customer Id"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'username']} valuePropName="checked" noStyle>
                      <Checkbox>Username</Checkbox>
                    </Form.Item>
                  ) : (
                    'Username'
                  )}
                  <Form.Item
                    name="username"
                    label="Username"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'full_name']} valuePropName="checked" noStyle>
                      <Checkbox>Full Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Full Name'
                  )}
                  <Form.Item
                    name="full_name"
                    label="Full Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'organization']} valuePropName="checked" noStyle>
                      <Checkbox>Organization</Checkbox>
                    </Form.Item>
                  ) : (
                    'Organization'
                  )}
                  <Form.Item
                    name="organization"
                    label="Organization"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'org_checksum']} valuePropName="checked" noStyle>
                      <Checkbox>Org Checksum</Checkbox>
                    </Form.Item>
                  ) : (
                    'Org Checksum'
                  )}
                  <Form.Item
                    name="org_checksum"
                    label="Org Checksum"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_logon']} valuePropName="checked" noStyle>
                      <Checkbox>Last Logon</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Logon'
                  )}
                  <Form.Item name="last_logon" label="Last Logon" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'quarantine_date']} valuePropName="checked" noStyle>
                      <Checkbox>Quarantine Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Quarantine Date'
                  )}
                  <Form.Item name="quarantine_date" label="Quarantine Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'quarantine_delete_date']} valuePropName="checked" noStyle>
                      <Checkbox>Quarantine Delete Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Quarantine Delete Date'
                  )}
                  <Form.Item name="quarantine_delete_date" label="Quarantine Delete Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_date']} valuePropName="checked" noStyle>
                      <Checkbox>Updated Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated Date'
                  )}
                  <Form.Item name="updated_date" label="Updated Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_used_computer_id']} valuePropName="checked" noStyle>
                      <Checkbox>Last Used Computer Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Used Computer Id'
                  )}
                  <Form.Item
                    name="last_used_computer_id"
                    label="Last Used Computer Id"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_used_computer_name']} valuePropName="checked" noStyle>
                      <Checkbox>Last Used Computer Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Used Computer Name'
                  )}
                  <Form.Item
                    name="last_used_computer_name"
                    label="Last Used Computer Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'email']} valuePropName="checked" noStyle>
                      <Checkbox>Email</Checkbox>
                    </Form.Item>
                  ) : (
                    'Email'
                  )}
                  <Form.Item
                    name="email"
                    label="Email"
                    className="m-0"
                    rules={[{ type: "email" }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'location']} valuePropName="checked" noStyle>
                      <Checkbox>Location</Checkbox>
                    </Form.Item>
                  ) : (
                    'Location'
                  )}
                  <Form.Item
                    name="location"
                    label="Location"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'mobile_phone_number']} valuePropName="checked" noStyle>
                      <Checkbox>Mobile Phone Number</Checkbox>
                    </Form.Item>
                  ) : (
                    'Mobile Phone Number'
                  )}
                  <Form.Item
                    name="mobile_phone_number"
                    label="Mobile Phone Number"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'phone_number']} valuePropName="checked" noStyle>
                      <Checkbox>Phone Number</Checkbox>
                    </Form.Item>
                  ) : (
                    'Phone Number'
                  )}
                  <Form.Item
                    name="phone_number"
                    label="Phone Number"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'room_number']} valuePropName="checked" noStyle>
                      <Checkbox>Room Number</Checkbox>
                    </Form.Item>
                  ) : (
                    'Room Number'
                  )}
                  <Form.Item
                    name="room_number"
                    label="Room Number"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'status_code']} valuePropName="checked" noStyle>
                      <Checkbox>Status Code</Checkbox>
                    </Form.Item>
                  ) : (
                    'Status Code'
                  )}
                  <Form.Item
                    name="status_code"
                    label="Status Code"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'quarantine_management']} valuePropName="checked" noStyle>
                      <Checkbox>Quarantine Management</Checkbox>
                    </Form.Item>
                  ) : (
                    'Quarantine Management'
                  )}
                  <Form.Item
                    name="quarantine_management"
                    label="Quarantine Management"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_by']} valuePropName="checked" noStyle>
                      <Checkbox>Updated By</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated By'
                  )}
                  <Form.Item
                    name="updated_by"
                    label="Updated By"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'custom_fields']} valuePropName="checked" noStyle>
                      <Checkbox>Custom Fields</Checkbox>
                    </Form.Item>
                  ) : (
                    'Custom Fields'
                  )}
                  <Form.Item
                    name="custom_fields"
                    label="Custom Fields"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="auto_editing" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'auto_editing']} valuePropName="checked" noStyle>
                      <Checkbox>Auto Editing</Checkbox>
                    </Form.Item>
                  ) : (
                    'Auto Editing'
                  )}
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={snowUser.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddSnowUserModal;
