import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowUsers,
  ISearchSnowUsers,
} from '../../../services/snow/snowUsers/snowUsers.model';
import snowUsersService from '../../../services/snow/snowUsers/snowUsers.service';

// Asynchronous thunk action

export const searchSnowUsers = createAsyncThunk(
  'searchSnowUsers',
  async (searchParam?: ISearchSnowUsers) => {
    const response = await snowUsersService
      .searchSnowUsers(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowUsersById = createAsyncThunk(
  'getSnowUsersById',
  async (id: number) => {
    const response = await snowUsersService.getSnowUsersById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowUsers = createAsyncThunk(
  'saveSnowUsers',
  async (data: ISnowUsers) => {
    const response = await snowUsersService.saveSnowUsers(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowUsers = createAsyncThunk(
  'deleteSnowUsers',
  async (id: number) => {
    const response = await snowUsersService.deleteSnowUsers(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
