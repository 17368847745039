import { Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import {
  setTableColumnSelection,
  clearCmdbCiWinClusterNodeMessages,
  CmdbCiWinClusterNodeSelector,
} from '../../../../store/serviceNow/cmdbCiWinClusterNode/cmdbCiWinClusterNode.reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { deleteCmdbCiWinClusterNode, searchCmdbCiWinClusterNode } from '../../../../store/serviceNow/cmdbCiWinClusterNode/cmdbCiWinClusterNode.action';
import _ from 'lodash';
import CmdbCiWinClusterNodeService from '../../../../services/serviceNow/cmdbCiWinClusterNode/cmdbCiWinClusterNode.service';
import {
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { showDateFromApi } from '../../../../common/helperFunction';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    setFilterKeys,
    tableButtons,
  } = props;
  const CmdbCiWinClusterNode = useAppSelector(CmdbCiWinClusterNodeSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return CmdbCiWinClusterNodeService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, columnWidthOpt?: boolean, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      CmdbCiWinClusterNode.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      columnWidthOpt === undefined ? false : columnWidthOpt,
      isRelativeDropDownFlag
    );
  };

  const FilterByDateSwapTable = (dataIndex: string, tableName: string, form: any) => {
    return FilterByDateSwap(dataIndex, tableName, form, null, ObjectForColumnFilter);
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form, true),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('name', form),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              false,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              false,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              false,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwapTable('date_added', CmdbCiWinClusterNode.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer</span>,
        column: 'manufacturer',
        sorter: true,
        children: [
          {
            title: FilterBySwap('manufacturer', form),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Location</span>,
        column: 'location',
        sorter: true,
        children: [
          {
            title: FilterBySwap('location', form),
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Short Description</span>,
        column: 'short_description',
        sorter: true,
        children: [
          {
            title: FilterBySwap('short_description', form),
            dataIndex: 'short_description',
            key: 'short_description',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Class Name</span>,
        column: 'sys_class_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_class_name', form),
            dataIndex: 'sys_class_name',
            key: 'sys_class_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Discovery Source</span>,
        column: 'discovery_source',
        sorter: true,
        children: [
          {
            title: FilterBySwap('discovery_source', form),
            dataIndex: 'discovery_source',
            key: 'discovery_source',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Created On</span>,
        column: 'sys_created_on',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_created_on', form),
            dataIndex: 'sys_created_on',
            key: 'sys_created_on',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster</span>,
        column: 'cluster',
        sorter: true,
        children: [
          {
            title: FilterBySwap('cluster', form),
            dataIndex: 'cluster',
            key: 'cluster',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Node State</span>,
        column: 'node_state',
        sorter: true,
        children: [
          {
            title: FilterBySwap('node_state', form),
            dataIndex: 'node_state',
            key: 'node_state',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeCmdbCiWinClusterNode = (id: number) => {
    dispatch(deleteCmdbCiWinClusterNode(id));
  };

  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.CmdbCiWinClusterNode}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/service-now/winclusternode/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.CmdbCiWinClusterNode}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeCmdbCiWinClusterNode(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.CmdbCiWinClusterNode)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={CmdbCiWinClusterNodeSelector}
        searchTableData={searchCmdbCiWinClusterNode}
        clearTableDataMessages={clearCmdbCiWinClusterNodeMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.CmdbCiWinClusterNode)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
