import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigSoftwareFamily,
  ISearchConfigSoftwareFamily,
} from '../../../services/master/configSoftwareFamily/configSoftwareFamily.model';
import configSoftwareFamily from '../../../services/master/configSoftwareFamily/configSoftwareFamily.service';
// Asynchronous thunk action

export const searchConfigSoftwareFamily = createAsyncThunk(
  'searchConfigSoftwareFamily',
  async (searchParam?: ISearchConfigSoftwareFamily) => {
    const response = await configSoftwareFamily.searchConfigSoftwareFamily(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigSoftwareFamilyById = createAsyncThunk(
  'getConfigSoftwareFamilyById',
  async (id: number) => {
    const response = await configSoftwareFamily.getConfigSoftwareFamilyById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigSoftwareFamily = createAsyncThunk(
  'saveConfigSoftwareFamily',
  async (data: IConfigSoftwareFamily) => {
    const response = await configSoftwareFamily.saveConfigSoftwareFamily(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigSoftwareFamily = createAsyncThunk(
  'deleteConfigSoftwareFamily',
  async (id: number) => {
    const response = await configSoftwareFamily.deleteConfigSoftwareFamily(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
