import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowUser,
  ISearchSnowUser,
} from '../../../services/snow/snowUser/snowUser.model';
import snowUserService from '../../../services/snow/snowUser/snowUser.service';

// Asynchronous thunk action

export const searchSnowUser = createAsyncThunk(
  'searchSnowUser',
  async (searchParam?: ISearchSnowUser) => {
    const response = await snowUserService
      .searchSnowUser(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowUserById = createAsyncThunk(
  'getSnowUserById',
  async (id: number) => {
    const response = await snowUserService.getSnowUserById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowUser = createAsyncThunk(
  'saveSnowUser',
  async (data: ISnowUser) => {
    const response = await snowUserService.saveSnowUser(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowUser = createAsyncThunk(
  'deleteSnowUser',
  async (id: number) => {
    const response = await snowUserService.deleteSnowUser(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
