import { booleanLookup } from '../../../common/constants/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICmdbCiVcenterCluster } from '../../../services/serviceNow/cmdbCiVcenterCluster/cmdbCiVcenterCluster.model';
import { IApiResponseBody, ISearchResponse } from '../../../common/models/common';
import { RootState } from '../../app.model';
import {
  deleteCmdbCiVcenterCluster,
  getCmdbCiVcenterClusterById,
  searchCmdbCiVcenterCluster,
  saveCmdbCiVcenterCluster,
  processDataCmdbCiVcenterCluster,
} from './cmdbCiVcenterCluster.action';
import { ICmdbCiVcenterClusterState } from './cmdbCiVcenterCluster.model';

export const initialState: ICmdbCiVcenterClusterState = {
  search: {
    loading: false,
    hasErrors: false,
    data: [],
    count: 0,
    lookups: {},
    tableName: '',
  },
  tableColumnSelection: {
    id: null,
    table_name: null,
    columns: {},
  },
  getById: {
    loading: false,
    hasErrors: false,
    data: null,
  },
  save: {
    loading: false,
    hasErrors: false,
    messages: [],
  },
  delete: {
    loading: false,
    hasErrors: false,
    messages: [],
  },
  processData: {
    loading: false,
    hasErrors: false,
    messages: [],
  },
};

export const CmdbCiVcenterClusterSlice = createSlice({
  name: 'CmdbCiVcenterCluster',
  initialState,
  reducers: {
    clearCmdbCiVcenterCluster: () => {
      return initialState;
    },
    clearCmdbCiVcenterClusterMessages: (state) => {
      state.save.messages = [];
      state.delete.messages = [];
      state.processData.messages = [];
    },
    clearCmdbCiVcenterClusterGetById: (state) => {
      state.getById.data = null;
    },
    setTableColumnSelection: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.tableColumnSelection.columns = action.payload;
    },
  },
  extraReducers: {
    // Search
    [searchCmdbCiVcenterCluster.pending.type]: (state) => {
      state.search.loading = true;
    },
    [searchCmdbCiVcenterCluster.fulfilled.type]: (
      state,
      action: PayloadAction<ISearchResponse<ICmdbCiVcenterCluster>>
    ) => {
      const { search_result, ...rest } = action.payload;
      state.search.data = search_result.records;
      state.search.count = search_result.total_count;
      if (JSON.stringify(rest) !== '{}') {
        state.search.lookups = { ...rest, booleanLookup };
      }
      state.search.loading = false;
      state.search.hasErrors = false;
      state.search.tableName = search_result.table_name;
      if (search_result.column_selection) {
        state.tableColumnSelection.id = search_result.column_selection.id;
        const tableSelectionObj = JSON.parse(search_result.column_selection.columns as any);
        if (tableSelectionObj.columns) {
          state.tableColumnSelection.column_orders = tableSelectionObj.column_orders;
          state.tableColumnSelection.columns = tableSelectionObj.columns;
        } else {
          state.tableColumnSelection.columns = tableSelectionObj;
        }
      }
      state.tableColumnSelection.table_name = search_result.table_name;
    },
    [searchCmdbCiVcenterCluster.rejected.type]: (state) => {
      state.search.loading = false;
      state.search.hasErrors = true;
    },

    // Get by id
    [getCmdbCiVcenterClusterById.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [getCmdbCiVcenterClusterById.fulfilled.type]: (state, action: PayloadAction<ICmdbCiVcenterCluster>) => {
      state.getById.data = action.payload;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [getCmdbCiVcenterClusterById.rejected.type]: (state) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
    },

    // Save
    [saveCmdbCiVcenterCluster.pending.type]: (state) => {
      state.save.loading = true;
      state.save.messages = [];
    },
    [saveCmdbCiVcenterCluster.fulfilled.type]: (state, action: PayloadAction<IApiResponseBody<unknown>>) => {
      state.save.loading = false;
      state.save.hasErrors = false;
      state.save.messages = action.payload.messages;
    },
    [saveCmdbCiVcenterCluster.rejected.type]: (state) => {
      state.save.loading = false;
      state.save.hasErrors = true;
    },

    // Delete
    [deleteCmdbCiVcenterCluster.pending.type]: (state) => {
      state.delete.loading = true;
      state.delete.messages = [];
    },
    [deleteCmdbCiVcenterCluster.fulfilled.type]: (state, action: PayloadAction<IApiResponseBody<unknown>>) => {
      state.delete.loading = false;
      state.delete.hasErrors = false;
      state.delete.messages = action.payload.messages;
    },
    [deleteCmdbCiVcenterCluster.rejected.type]: (state) => {
      state.delete.loading = false;
      state.delete.hasErrors = true;
    },

    // Process Data
    [processDataCmdbCiVcenterCluster.pending.type]: (state) => {
      state.processData.loading = true;
      state.processData.messages = [];
    },
    [processDataCmdbCiVcenterCluster.fulfilled.type]: (
      state,
      action: PayloadAction<IApiResponseBody<unknown>>
    ) => {
      state.processData.loading = false;
      state.processData.hasErrors = false;
      state.processData.messages = action.payload.messages;
    },
    [processDataCmdbCiVcenterCluster.rejected.type]: (state) => {
      state.processData.loading = false;
      state.processData.hasErrors = true;
    },
  },
});

// A selector
export const CmdbCiVcenterClusterSelector = (state: RootState) => state.cmdbCiVcenterCluster;

// Actions
export const {
  clearCmdbCiVcenterCluster,
  clearCmdbCiVcenterClusterMessages,
  clearCmdbCiVcenterClusterGetById,
  setTableColumnSelection,
} = CmdbCiVcenterClusterSlice.actions;

// The reducer
export default CmdbCiVcenterClusterSlice.reducer;
