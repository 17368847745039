import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { ISnowApplication } from '../../../../services/snow/snowApplication/snowApplication.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getSnowApplicationById,
  saveSnowApplication,
} from '../../../../store/snow/snowApplication/snowApplication.action';
import {
  clearSnowApplicationGetById,
  clearSnowApplicationMessages,
  snowApplicationSelector,
} from '../../../../store/snow/snowApplication/snowApplication.reducer';
import { getBULookup, getCompanyLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { IAddSnowApplicationProps } from './addSnowApplication.model';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate, passDateToApi } from '../../../../common/helperFunction';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';

const { Option } = Select;

const AddSnowApplicationModal: React.FC<IAddSnowApplicationProps> = (props) => {
  const snowApplication = useAppSelector(snowApplicationSelector);
  const dispatch = useAppDispatch();
  const commonLookups = useAppSelector(commonSelector);
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.SnowApplication} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: ISnowApplication = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    date_added: getSimpleDate(),
    application_id: '',
    name: '',
    manufacturer_id: '',
    manufacturer_name: '',
    manufacturer_website: '',
    language_name: '',
    release_date: getSimpleDate(),
    created_date: getSimpleDate(),
    created_by: '',
    updated_date: getSimpleDate(),
    updated_by: '',
    description: '',
    system_owner_name: '',
    system_owner_phone: '',
    system_owner_email: '',
    media: '',
    is_operating_system: false,
    operating_system_type: '',
    application_types: '',
    upgrade_options: '',
    downgrade_options: '',
    total_coverage: null,
    license_required: false,
    license_requirement: null,
    license_count: null,
    installation_count: null,
    bundle_installation_count: null,
    unused_installation_count: null,
    usage_factor: null,
    user_count: null,
    risk: null,
    risk_unused: null,
    compliance: null,
    allocation: '',
    license_keys: '',
    metric: '',
    application_cost_total: null,
    application_cost_per_license: null,
    average_cost_per_license: null,
    license_cost_total: null,
    license_cost_currency: '',
    user_license_cost: null,
    alert_on_overlicensing: false,
    alert_on_underlicensing: false,
    alert_when_not_used: false,
    custom_values: '',
    secondary_use_allowed: false,
    multiple_versions_allowed: false,
    multiple_editions_allowed: false,
  };

  const onFinish = (values: any) => {
    const inputValues: ISnowApplication = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.date_added = passDateToApi(inputValues.date_added);
    inputValues.release_date = passDateToApi(inputValues.release_date);
    inputValues.created_date = passDateToApi(inputValues.created_date);
    inputValues.updated_date = passDateToApi(inputValues.updated_date);
    if (!isMultiple) {
      dispatch(saveSnowApplication(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        snowApplication.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: ISnowApplication) => {
    if (data.tenant_id) {
      await dispatch(getCompanyLookup(data.tenant_id));
    }
    if (data.company_id) {
      await dispatch(getBULookup(data.company_id));
    }
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
        application_id: data.application_id,
        name: data.name,
        manufacturer_id: data.manufacturer_id,
        manufacturer_name: data.manufacturer_name,
        manufacturer_website: data.manufacturer_website,
        language_name: data.language_name,
        release_date: _.isNull(data.release_date) ? null : forEditModal(data.release_date),
        created_date: _.isNull(data.created_date) ? null : forEditModal(data.created_date),
        created_by: data.created_by,
        updated_date: _.isNull(data.updated_date) ? null : forEditModal(data.updated_date),
        updated_by: data.updated_by,
        description: data.description,
        system_owner_name: data.system_owner_name,
        system_owner_phone: data.system_owner_phone,
        system_owner_email: data.system_owner_email,
        media: data.media,
        is_operating_system: data.is_operating_system,
        operating_system_type: data.operating_system_type,
        application_types: data.application_types,
        upgrade_options: data.upgrade_options,
        downgrade_options: data.downgrade_options,
        total_coverage: _.isNull(data.total_coverage) ? null : data.total_coverage,
        license_required: data.license_required,
        license_requirement: _.isNull(data.license_requirement) ? null : data.license_requirement,
        license_count: _.isNull(data.license_count) ? null : data.license_count,
        installation_count: _.isNull(data.installation_count) ? null : data.installation_count,
        bundle_installation_count: _.isNull(data.bundle_installation_count) ? null : data.bundle_installation_count,
        unused_installation_count: _.isNull(data.unused_installation_count) ? null : data.unused_installation_count,
        usage_factor: _.isNull(data.usage_factor) ? null : data.usage_factor,
        user_count: _.isNull(data.user_count) ? null : data.user_count,
        risk: _.isNull(data.risk) ? null : data.risk,
        risk_unused: _.isNull(data.risk_unused) ? null : data.risk_unused,
        compliance: _.isNull(data.compliance) ? null : data.compliance,
        allocation: data.allocation,
        license_keys: data.license_keys,
        metric: data.metric,
        application_cost_total: _.isNull(data.application_cost_total) ? null : data.application_cost_total,
        application_cost_per_license: _.isNull(data.application_cost_per_license) ? null : data.application_cost_per_license,
        average_cost_per_license: _.isNull(data.average_cost_per_license) ? null : data.average_cost_per_license,
        license_cost_total: _.isNull(data.license_cost_total) ? null : data.license_cost_total,
        license_cost_currency: data.license_cost_currency,
        user_license_cost: _.isNull(data.user_license_cost) ? null : data.user_license_cost,
        alert_on_overlicensing: data.alert_on_overlicensing,
        alert_on_underlicensing: data.alert_on_underlicensing,
        alert_when_not_used: data.alert_when_not_used,
        custom_values: data.custom_values,
        secondary_use_allowed: data.secondary_use_allowed,
        multiple_versions_allowed: data.multiple_versions_allowed,
        multiple_editions_allowed: data.multiple_editions_allowed,
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (snowApplication.save.messages.length > 0) {
      if (snowApplication.save.hasErrors) {
        toast.error(snowApplication.save.messages.join(' '));
      } else {
        toast.success(snowApplication.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearSnowApplicationMessages());
    }
  }, [snowApplication.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && snowApplication.getById.data) {
      const data = snowApplication.getById.data;
      fillValuesOnEdit(data);
    }
  }, [snowApplication.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getSnowApplicationById(+id));
    }
    return () => {
      dispatch(clearSnowApplicationGetById());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {snowApplication.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={snowApplication.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="snowApplication"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.tenantLookup.loading}
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.buLookup.loading}
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'application_id']} valuePropName="checked" noStyle>
                      <Checkbox>Application Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Application Id'
                  )}
                  <Form.Item
                    name="application_id"
                    label="Application Id"
                    className="m-0"
                    rules={[{ max: 255, required: !isMultiple }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'name']} valuePropName="checked" noStyle>
                      <Checkbox>Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Name'
                  )}
                  <Form.Item
                    name="name"
                    label="Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'manufacturer_id']} valuePropName="checked" noStyle>
                      <Checkbox>Manufacturer Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Manufacturer Id'
                  )}
                  <Form.Item
                    name="manufacturer_id"
                    label="Manufacturer Id"
                    className="m-0"
                    rules={[{ max: 36 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'manufacturer_name']} valuePropName="checked" noStyle>
                      <Checkbox>Manufacturer Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Manufacturer Name'
                  )}
                  <Form.Item
                    name="manufacturer_name"
                    label="Manufacturer Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'manufacturer_website']} valuePropName="checked" noStyle>
                      <Checkbox>Manufacturer Website</Checkbox>
                    </Form.Item>
                  ) : (
                    'Manufacturer Website'
                  )}
                  <Form.Item
                    name="manufacturer_website"
                    label="Manufacturer Website"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'language_name']} valuePropName="checked" noStyle>
                      <Checkbox>Language Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Language Name'
                  )}
                  <Form.Item
                    name="language_name"
                    label="Language Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'release_date']} valuePropName="checked" noStyle>
                      <Checkbox>Release Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Release Date'
                  )}
                  <Form.Item name="release_date" label="Release Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'created_date']} valuePropName="checked" noStyle>
                      <Checkbox>Created Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Created Date'
                  )}
                  <Form.Item name="created_date" label="Created Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'created_by']} valuePropName="checked" noStyle>
                      <Checkbox>Created By</Checkbox>
                    </Form.Item>
                  ) : (
                    'Created By'
                  )}
                  <Form.Item
                    name="created_by"
                    label="Created By"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_date']} valuePropName="checked" noStyle>
                      <Checkbox>Updated Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated Date'
                  )}
                  <Form.Item name="updated_date" label="Updated Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_by']} valuePropName="checked" noStyle>
                      <Checkbox>Updated By</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated By'
                  )}
                  <Form.Item
                    name="updated_by"
                    label="Updated By"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'description']} valuePropName="checked" noStyle>
                      <Checkbox>Description</Checkbox>
                    </Form.Item>
                  ) : (
                    'Description'
                  )}
                  <Form.Item
                    name="description"
                    label="Description"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'system_owner_name']} valuePropName="checked" noStyle>
                      <Checkbox>System Owner Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'System Owner Name'
                  )}
                  <Form.Item
                    name="system_owner_name"
                    label="System Owner Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'system_owner_phone']} valuePropName="checked" noStyle>
                      <Checkbox>System Owner Phone</Checkbox>
                    </Form.Item>
                  ) : (
                    'System Owner Phone'
                  )}
                  <Form.Item
                    name="system_owner_phone"
                    label="System Owner Phone"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'system_owner_email']} valuePropName="checked" noStyle>
                      <Checkbox>System Owner Email</Checkbox>
                    </Form.Item>
                  ) : (
                    'System Owner Email'
                  )}
                  <Form.Item
                    name="system_owner_email"
                    label="System Owner Email"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'media']} valuePropName="checked" noStyle>
                      <Checkbox>Media</Checkbox>
                    </Form.Item>
                  ) : (
                    'Media'
                  )}
                  <Form.Item
                    name="media"
                    label="Media"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'operating_system_type']} valuePropName="checked" noStyle>
                      <Checkbox>Operating System Type</Checkbox>
                    </Form.Item>
                  ) : (
                    'Operating System Type'
                  )}
                  <Form.Item
                    name="operating_system_type"
                    label="Operating System Type"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'application_types']} valuePropName="checked" noStyle>
                      <Checkbox>Application Types</Checkbox>
                    </Form.Item>
                  ) : (
                    'Application Types'
                  )}
                  <Form.Item
                    name="application_types"
                    label="Application Types"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'upgrade_options']} valuePropName="checked" noStyle>
                      <Checkbox>Upgrade Options</Checkbox>
                    </Form.Item>
                  ) : (
                    'Upgrade Options'
                  )}
                  <Form.Item
                    name="upgrade_options"
                    label="Upgrade Options"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'downgrade_options']} valuePropName="checked" noStyle>
                      <Checkbox>Downgrade Options</Checkbox>
                    </Form.Item>
                  ) : (
                    'Downgrade Options'
                  )}
                  <Form.Item
                    name="downgrade_options"
                    label="Downgrade Options"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'total_coverage']} valuePropName="checked" noStyle>
                      <Checkbox>Total Coverage</Checkbox>
                    </Form.Item>
                  ) : (
                    'Total Coverage'
                  )}
                  <Form.Item
                    name="total_coverage"
                    label="Total Coverage"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'license_requirement']} valuePropName="checked" noStyle>
                      <Checkbox>License Requirement</Checkbox>
                    </Form.Item>
                  ) : (
                    'License Requirement'
                  )}
                  <Form.Item
                    name="license_requirement"
                    label="License Requirement"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'license_count']} valuePropName="checked" noStyle>
                      <Checkbox>License Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'License Count'
                  )}
                  <Form.Item
                    name="license_count"
                    label="License Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'installation_count']} valuePropName="checked" noStyle>
                      <Checkbox>Installation Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Installation Count'
                  )}
                  <Form.Item
                    name="installation_count"
                    label="Installation Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bundle_installation_count']} valuePropName="checked" noStyle>
                      <Checkbox>Bundle Installation Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Bundle Installation Count'
                  )}
                  <Form.Item
                    name="bundle_installation_count"
                    label="Bundle Installation Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'unused_installation_count']} valuePropName="checked" noStyle>
                      <Checkbox>Unused Installation Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Unused Installation Count'
                  )}
                  <Form.Item
                    name="unused_installation_count"
                    label="Unused Installation Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'usage_factor']} valuePropName="checked" noStyle>
                      <Checkbox>Usage Factor</Checkbox>
                    </Form.Item>
                  ) : (
                    'Usage Factor'
                  )}
                  <Form.Item
                    name="usage_factor"
                    label="Usage Factor"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'user_count']} valuePropName="checked" noStyle>
                      <Checkbox>User Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'User Count'
                  )}
                  <Form.Item
                    name="user_count"
                    label="User Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'risk']} valuePropName="checked" noStyle>
                      <Checkbox>Risk</Checkbox>
                    </Form.Item>
                  ) : (
                    'Risk'
                  )}
                  <Form.Item
                    name="risk"
                    label="Risk"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'risk_unused']} valuePropName="checked" noStyle>
                      <Checkbox>Risk Unused</Checkbox>
                    </Form.Item>
                  ) : (
                    'Risk Unused'
                  )}
                  <Form.Item
                    name="risk_unused"
                    label="Risk Unused"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'compliance']} valuePropName="checked" noStyle>
                      <Checkbox>Compliance</Checkbox>
                    </Form.Item>
                  ) : (
                    'Compliance'
                  )}
                  <Form.Item
                    name="compliance"
                    label="Compliance"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'allocation']} valuePropName="checked" noStyle>
                      <Checkbox>Allocation</Checkbox>
                    </Form.Item>
                  ) : (
                    'Allocation'
                  )}
                  <Form.Item
                    name="allocation"
                    label="Allocation"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'license_keys']} valuePropName="checked" noStyle>
                      <Checkbox>License Keys</Checkbox>
                    </Form.Item>
                  ) : (
                    'License Keys'
                  )}
                  <Form.Item
                    name="license_keys"
                    label="License Keys"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'metric']} valuePropName="checked" noStyle>
                      <Checkbox>Metric</Checkbox>
                    </Form.Item>
                  ) : (
                    'Metric'
                  )}
                  <Form.Item
                    name="metric"
                    label="Metric"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'application_cost_total']} valuePropName="checked" noStyle>
                      <Checkbox>Application Cost Total</Checkbox>
                    </Form.Item>
                  ) : (
                    'Application Cost Total'
                  )}
                  <Form.Item
                    name="application_cost_total"
                    label="Application Cost Total"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'application_cost_per_license']} valuePropName="checked" noStyle>
                      <Checkbox>Application Cost Per License</Checkbox>
                    </Form.Item>
                  ) : (
                    'Application Cost Per License'
                  )}
                  <Form.Item
                    name="application_cost_per_license"
                    label="Application Cost Per License"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'average_cost_per_license']} valuePropName="checked" noStyle>
                      <Checkbox>Average Cost Per License</Checkbox>
                    </Form.Item>
                  ) : (
                    'Average Cost Per License'
                  )}
                  <Form.Item
                    name="average_cost_per_license"
                    label="Average Cost Per License"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'license_cost_total']} valuePropName="checked" noStyle>
                      <Checkbox>License Cost Total</Checkbox>
                    </Form.Item>
                  ) : (
                    'License Cost Total'
                  )}
                  <Form.Item
                    name="license_cost_total"
                    label="License Cost Total"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'license_cost_currency']} valuePropName="checked" noStyle>
                      <Checkbox>License Cost Currency</Checkbox>
                    </Form.Item>
                  ) : (
                    'License Cost Currency'
                  )}
                  <Form.Item
                    name="license_cost_currency"
                    label="License Cost Currency"
                    className="m-0"
                    rules={[{ max: 10 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'user_license_cost']} valuePropName="checked" noStyle>
                      <Checkbox>User License Cost</Checkbox>
                    </Form.Item>
                  ) : (
                    'User License Cost'
                  )}
                  <Form.Item
                    name="user_license_cost"
                    label="User License Cost"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'custom_values']} valuePropName="checked" noStyle>
                      <Checkbox>Custom Values</Checkbox>
                    </Form.Item>
                  ) : (
                    'Custom Values'
                  )}
                  <Form.Item
                    name="custom_values"
                    label="Custom Values"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_operating_system" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_operating_system']} valuePropName="checked" noStyle>
                      <Checkbox>Is Operating System</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Operating System'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="license_required" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'license_required']} valuePropName="checked" noStyle>
                      <Checkbox>License Required</Checkbox>
                    </Form.Item>
                  ) : (
                    'License Required'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="alert_on_overlicensing" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'alert_on_overlicensing']} valuePropName="checked" noStyle>
                      <Checkbox>Alert On Over Licensing</Checkbox>
                    </Form.Item>
                  ) : (
                    'Alert On Over Licensing'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="alert_on_underlicensing" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'alert_on_underlicensing']} valuePropName="checked" noStyle>
                      <Checkbox>Alert On Under Licensing</Checkbox>
                    </Form.Item>
                  ) : (
                    'Alert On Under Licensing'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="alert_when_not_used" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'alert_when_not_used']} valuePropName="checked" noStyle>
                      <Checkbox>Alert When Not Used</Checkbox>
                    </Form.Item>
                  ) : (
                    'Alert When Not Used'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="secondary_use_allowed" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'secondary_use_allowed']} valuePropName="checked" noStyle>
                      <Checkbox>Secondary Use Allowed</Checkbox>
                    </Form.Item>
                  ) : (
                    'Secondary Use Allowed'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="multiple_versions_allowed" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'multiple_versions_allowed']} valuePropName="checked" noStyle>
                      <Checkbox>Multiple Versions Allowed</Checkbox>
                    </Form.Item>
                  ) : (
                    'Multiple Versions Allowed'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="multiple_editions_allowed" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'multiple_editions_allowed']} valuePropName="checked" noStyle>
                      <Checkbox>Multiple Editions Allowed</Checkbox>
                    </Form.Item>
                  ) : (
                    'Multiple Editions Allowed'
                  )}
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={snowApplication.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddSnowApplicationModal;
