import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowComputer,
  ISearchSnowComputer,
} from '../../../services/snow/snowComputer/snowComputer.model';
import snowComputerService from '../../../services/snow/snowComputer/snowComputer.service';

// Asynchronous thunk action

export const searchSnowComputer = createAsyncThunk(
  'searchSnowComputer',
  async (searchParam?: ISearchSnowComputer) => {
    const response = await snowComputerService
      .searchSnowComputer(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowComputerById = createAsyncThunk(
  'getSnowComputerById',
  async (id: number) => {
    const response = await snowComputerService.getSnowComputerById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowComputer = createAsyncThunk(
  'saveSnowComputer',
  async (data: ISnowComputer) => {
    const response = await snowComputerService.saveSnowComputer(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowComputer = createAsyncThunk(
  'deleteSnowComputer',
  async (id: number) => {
    const response = await snowComputerService.deleteSnowComputer(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
