import { Button, Checkbox, Col, Form, Input, message, Popconfirm, Popover, Progress, Row, Select, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import BreadCrumbs from '../../../common/components/Breadcrumbs';
import { Page } from '../../../common/constants/pageAction';
import _ from 'lodash';
import { SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../store/app.hooks';
import {
    bulkImportSelector,
    clearBulkImport,
    clearBulkImportMessages,
    clearExcelColumns,
    clearGetTableColumns,
    setTableForImport,
} from '../../../store/bulkImport/bulkImport.reducer';
import { useEffect, useState } from 'react';
import {
    getExcelColumnsLarge,
    getTables,
    getTablesForImport,
    saveTableForImport,
} from '../../../store/bulkImport/bulkImport.action';
import { toast } from 'react-toastify';
import { IDatabaseTable } from '../../../services/common/common.model';
import RenderBI from '../RenderBI';
import { globalSearchSelector } from '../../../store/globalSearch/globalSearch.reducer';
import CkeckDelimiterModal from '../CheckDelimiter';
import commonService from '../../../services/common/common.service';
import { getSimpleDate } from '../../../common/helperFunction';
import GlobalSearch from '../../../common/components/globalSearch/GlobalSearch';
import { BlobServiceClient } from '@azure/storage-blob';
import bulkImportService from '../../../services/bulkImport/bulkImport.service';

const { Option } = Select;
let currentIndex = 1;
let blob_file_info = [];
let countAzureFiles = 0;

const BulkImportExcel: React.FC = () => {
    const globalLookups = useAppSelector(globalSearchSelector);
    const bulkImports = useAppSelector(bulkImportSelector);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [formUpload] = Form.useForm();
    const [form] = Form.useForm();
    const [finalTableForm] = Form.useForm();


    let { table } = useParams<{ table: string }>();
    table && (table = decodeURIComponent(table));
    const [count, setCount] = useState({ save: 0, reset: 0 });
    const [recordLength, setRecordLength] = useState(0);
    const [fileProgress, setFileProgress] = useState('');
    const [fileProgressBar, setFileProgressBar] = useState(0);
    const [firstFlag, setFirstFlag] = useState(false);
    const [repeatSheetFlag, setRepeatSheetFlag] = useState(false);
    const [tableName, setTableName] = useState<string>(table);
    const [withoutUnmappedRecords, setWithoutUnmappedRecords] = useState([]);
    const [defaultDelimeter, setDefaultDelimeter] = useState([]);
    const [records, setRecords] = useState<
        Array<{
            index: number;
            filename: string;
            excel_to_sql_mapping: any;
            show_mapping: any;
            original_filename: string;
            table_name: string;
            header_row: number;
            sheet: string;
            is_dynamic_header: boolean;
            file_path: string;
        }>
    >([]);
    const [loading, setLoading] = useState(false);
    const [delimitModalShow, setDelimitModalShow] = useState(false);
    const [delimitFlag, setDelimitFlag] = useState(true);
    const [dateChangeFlag, setDateChangeFlag] = useState(true);
    const [expandedRecords, setExpandedRecords] = useState(null);
    //const [columnTableArray, setColumnTableArray] = useState<any>([]);

    const formUploadInitialValues = {
        header_row: 1,
        table_name: table,
        hide_unmapped: true,
        date_added: getSimpleDate(),
    };

    useEffect(() => {
        dispatch(clearGetTableColumns());
        dispatch(clearExcelColumns());
        if (tableName && records.length) {
            updateRecords();
        }
        return () => {
            dispatch(clearGetTableColumns());
            dispatch(clearBulkImport());
            setRecords([]);
            currentIndex = 1;
        };
    }, []);

    useEffect(() => {
        if (bulkImports.getExcelColumnsLarge.notExistingFiles?.length > 0) {
            let files = '';
            const filesForCallAgain = [];
            const tenantID = globalLookups.search.tenant_id ? globalLookups.search.tenant_id : null;
            const companyID = globalLookups.search.company_id ? globalLookups.search.company_id : null;
            const buID = globalLookups.search.bu_id ? globalLookups.search.bu_id : null;
            bulkImports.getExcelColumnsLarge.notExistingFiles.map((data) => {
                files = files + data.file_name + ',';
                const fileObj = {
                    original_name: data.original_name,
                    file_name: data.file_name
                };
                filesForCallAgain.push(fileObj);
            });
            toast.warn('Files: ' + files + ' are still uploading on blob storage. Kindly wait for the response!');
            setTimeout(() => {
                dispatch(getExcelColumnsLarge({ file: filesForCallAgain, tenantID, companyID, buID }));
            }, 10000);
        }
    }, [bulkImports.getExcelColumnsLarge.notExistingFiles]);

    useEffect(() => {
        if (
            bulkImports?.getExcelColumnsLarge?.csvFiles?.length > 0 &&
            bulkImports.getExcelColumnsLarge.csvFiles !== null &&
            delimitFlag
        ) {
            setDelimitModalShow(true);
        }
        if (bulkImports.getExcelColumnsLarge.data?.length || bulkImports.getExcelColumnsLarge.csvFiles?.length > 0) {
            if (!repeatSheetFlag) {
                bulkImports.getExcelColumnsLarge.data?.map(async (x: any) => {
                    const mappingData = [];
                    setRecords((records) => {
                        const dummyRecords = _.cloneDeep(records);
                        let filteredRecords = dummyRecords.filter(
                            (data) =>
                                data.filename !== x.filename && data.original_filename !== x.original_filename
                        );
                        (x?.excel_sheet_columns || []).map((sheet) => {
                            const mappingSheet =
                                mappingData.length > 0
                                    ? mappingData[0]?.config_excel_column_mappings?.filter(
                                        (data) => data.sheet_name == sheet.sheet
                                    )
                                    : [];
                            if (mappingData.length) {
                                formUpload.setFieldsValue({ hide_unmapped: false });
                            }

                            filteredRecords = [
                                ...filteredRecords,
                                {
                                    index: currentIndex++,
                                    filename: x.filename,
                                    file_path: x.file_path,
                                    original_filename: x.original_filename,
                                    is_public: mappingData.length > 0 ? mappingData[0].is_public : false,
                                    key_word: mappingData.length > 0 ? mappingData[0].key_word : null,
                                    delimiter: mappingData.length > 0 ? mappingData[0].delimiter : null,
                                    table_name:
                                        mappingData.length > 0
                                            ? mappingSheet.length > 0
                                                ? mappingSheet[0]?.table_name
                                                : tableName
                                            : tableName,
                                    header_row:
                                        mappingData.length > 0
                                            ? mappingSheet.length > 0
                                                ? mappingSheet[0]?.header_row + 1
                                                : 1
                                            : 1,
                                    is_dynamic_header:
                                        mappingData.length > 0
                                            ? mappingSheet.length > 0
                                                ? mappingSheet[0]?.is_dynamic_header
                                                : false
                                            : false,
                                    sheet: sheet.sheet,
                                    columns: sheet.columns,
                                    currentMapping:
                                        x.file_mapping && x.file_mapping.length > 0
                                            ? mappingData.length > 0
                                                ? mappingSheet.length > 0
                                                    ? mappingSheet[0]?.sheet_name + '!' + mappingSheet[0]?.id
                                                    : ''
                                                : ''
                                            : null,
                                    excel_to_sql_mapping:
                                        x.file_mapping && x.file_mapping.length > 0
                                            ? mappingData.length > 0
                                                ? mappingSheet.length > 0
                                                    ? JSON.parse(mappingSheet[0]?.mapping)
                                                    : null
                                                : null
                                            : null,
                                    show_mapping: x.file_mapping ? x.file_mapping : null,
                                },
                            ];
                        });

                        return filteredRecords;
                    });
                });
            } else {
                const realData = _.cloneDeep(records);
                bulkImports.getExcelColumnsLarge.data?.map(async (x: any) => {
                    const mappingData = [];
                    setRecords((records) => {
                        const dummyRecords = _.cloneDeep(records);
                        let filteredRecords = dummyRecords;
                        (x?.excel_sheet_columns || []).map((sheet) => {
                            let nonRepeated = true;
                            realData.map((data) => {
                                if (data.sheet == sheet.sheet && data.original_filename == x.original_filename) {
                                    nonRepeated = false;
                                }
                            });
                            const mappingSheet =
                                mappingData.length > 0
                                    ? mappingData[0]?.config_excel_column_mappings?.filter(
                                        (data) => data.sheet_name == sheet.sheet
                                    )
                                    : [];
                            if (mappingData.length) {
                                formUpload.setFieldsValue({ hide_unmapped: false });
                            }

                            if (nonRepeated) {
                                filteredRecords = [
                                    ...filteredRecords,
                                    {
                                        index: currentIndex++,
                                        filename: x.filename,
                                        file_path: x.file_path,
                                        original_filename: x.original_filename,
                                        is_public: mappingData.length > 0 ? mappingData[0].is_public : false,
                                        key_word: mappingData.length > 0 ? mappingData[0].key_word : null,
                                        delimiter: mappingData.length > 0 ? mappingData[0].delimiter : null,
                                        table_name:
                                            mappingData.length > 0
                                                ? mappingSheet.length > 0
                                                    ? mappingSheet[0]?.table_name
                                                    : tableName
                                                : tableName,
                                        header_row:
                                            mappingData.length > 0
                                                ? mappingSheet.length > 0
                                                    ? mappingSheet[0]?.header_row + 1
                                                    : 1
                                                : 1,
                                        is_dynamic_header:
                                            mappingData.length > 0
                                                ? mappingSheet.length > 0
                                                    ? mappingSheet[0]?.is_dynamic_header
                                                    : false
                                                : false,
                                        sheet: sheet.sheet,
                                        columns: sheet.columns,
                                        currentMapping:
                                            x.file_mapping && x.file_mapping.length > 0
                                                ? mappingData.length > 0
                                                    ? mappingSheet.length > 0
                                                        ? mappingSheet[0]?.sheet_name + '!' + mappingSheet[0]?.id
                                                        : ''
                                                    : ''
                                                : null,
                                        excel_to_sql_mapping:
                                            x.file_mapping && x.file_mapping.length > 0
                                                ? mappingData.length > 0
                                                    ? mappingSheet.length > 0
                                                        ? JSON.parse(mappingSheet[0]?.mapping)
                                                        : null
                                                    : null
                                                : null,
                                        show_mapping: x.file_mapping ? x.file_mapping : null,
                                    },
                                ];
                            }
                        });

                        return filteredRecords;
                    });
                });
            }
            setRepeatSheetFlag(false);
        }
    }, [bulkImports.getExcelColumnsLarge.data, bulkImports.getExcelColumnsLarge.csvFiles]);

    useEffect(() => {
        if (
            bulkImports.getCSVExcelColumnsforUpdate.data !== null &&
            bulkImports.getCSVExcelColumnsforUpdate.data?.length > 0
        ) {
            bulkImports.getCSVExcelColumnsforUpdate.data?.map(async (x: any) => {
                const mappingData = [];
                setRecords((records) => {
                    const dummyRecords = _.cloneDeep(records);
                    const defDel = defaultDelimeter.filter(
                        (data) => data.original_filename == x.original_filename
                    )[0]?.delimiter;
                    let filteredRecords = dummyRecords.filter(
                        (data) => data.filename !== x.filename && data.original_filename !== x.original_filename
                    );
                    const orgFile = dummyRecords.filter(
                        (data) => data.filename == x.filename && data.original_filename == x.original_filename
                    );
                    (x?.excel_sheet_columns || []).map((sheet) => {
                        const mappingSheet =
                            mappingData.length > 0
                                ? mappingData[0]?.config_excel_column_mappings?.filter(
                                    (data) => data.sheet_name == sheet.sheet
                                )
                                : [];
                        if (mappingData.length) {
                            formUpload.setFieldsValue({ hide_unmapped: false });
                        }

                        filteredRecords = [
                            ...filteredRecords,
                            {
                                index: firstFlag
                                    ? orgFile.length > 0
                                        ? orgFile[0].index
                                        : currentIndex++
                                    : currentIndex++,
                                file_path: x.file_path,
                                filename: x.filename,
                                is_public: mappingData.length > 0 ? mappingData[0].is_public : false,
                                key_word: mappingData.length > 0 ? mappingData[0].key_word : null,
                                original_filename: x.original_filename,
                                delimiter: defDel && defDel.length ? defDel : ';',
                                table_name: !firstFlag
                                    ? mappingData.length > 0
                                        ? mappingSheet.length > 0
                                            ? mappingSheet[0]?.table_name
                                            : tableName
                                        : tableName
                                    : orgFile.length > 0
                                        ? orgFile[0].table_name
                                        : tableName,
                                header_row:
                                    mappingData.length > 0
                                        ? mappingSheet.length > 0
                                            ? mappingSheet[0]?.header_row + 1
                                            : 1
                                        : 1,
                                is_dynamic_header:
                                    mappingData.length > 0
                                        ? mappingSheet.length > 0
                                            ? mappingSheet[0]?.is_dynamic_header
                                            : false
                                        : false,
                                sheet: sheet.sheet,
                                columns: sheet.columns,
                                currentMapping:
                                    x.file_mapping && x.file_mapping.length > 0
                                        ? mappingData.length > 0
                                            ? mappingSheet.length > 0
                                                ? mappingSheet[0]?.sheet_name + '!' + mappingSheet[0]?.id
                                                : ''
                                            : ''
                                        : null,
                                excel_to_sql_mapping: !firstFlag
                                    ? x.file_mapping && x.file_mapping.length > 0
                                        ? mappingData.length > 0
                                            ? mappingSheet.length > 0
                                                ? JSON.parse(mappingSheet[0]?.mapping)
                                                : null
                                            : null
                                        : null
                                    : orgFile.length > 0
                                        ? orgFile[0].excel_to_sql_mapping
                                        : null,
                                show_mapping: x.file_mapping ? x.file_mapping : null,
                            },
                        ];
                    });

                    return filteredRecords;
                });
            });
        }
        if (
            bulkImports.getCSVExcelColumnsforUpdate.csvFiles !== null &&
            bulkImports.getCSVExcelColumnsforUpdate.csvFiles?.length > 0
        ) {
            if (
                bulkImports.getCSVExcelColumnsforUpdate.csvFiles !== null &&
                delimitFlag &&
                bulkImports.getCSVExcelColumnsforUpdate.csvFiles?.length > 0
            ) {
                toast.info('Please Select Proper Delimiters');
                setDelimitModalShow(true);
            }
        }
    }, [bulkImports.getCSVExcelColumnsforUpdate.data, bulkImports.getCSVExcelColumnsforUpdate.csvFiles]);

    const updateRecords = async () => {
        if (formUpload?.getFieldValue('table_name')) {
            const dummyRecords = _.cloneDeep(records);
            setLoading(true);
            for (let index = 0; index < dummyRecords.length; index++) {
                const data = dummyRecords[index];
                data.table_name = formUpload?.getFieldValue('table_name');
                data.excel_to_sql_mapping = null;
            }
            await commonService.getTableColumns(formUpload?.getFieldValue('table_name'), true).then((res) => {
                if (res) {
                    //setColumnTableArray(res);
                    setMapping(dummyRecords, res);
                }
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        if (bulkImports.bulkUpdate.messages.length > 0 && (count.save > 0 || count.reset > 0)) {
            if (bulkImports.bulkUpdate.hasErrors) {
                toast.error(bulkImports.bulkUpdate.messages.join(' '));
            } else {
                toast.warning(bulkImports.bulkUpdate.messages.join(' '));
                dispatch(clearExcelColumns());
                dispatch(clearBulkImportMessages());
                setRecords([]);
                currentIndex = 1;
                //setExcelColumnState([]);
                onCancel();
                if (table) {
                    countAzureFiles = 0;
                    blob_file_info = [];
                    history.goBack();
                }
            }
        }
    }, [bulkImports.bulkUpdate.messages]);

    useEffect(() => {
        if (bulkImports.saveTableForImport.messages.length > 0) {
            if (bulkImports.saveTableForImport.hasErrors) {
                toast.error(bulkImports.saveTableForImport.messages.join(' '));
            } else {
                toast.success(bulkImports.saveTableForImport.messages.join(' '));
                dispatch(getTables());
            }
            dispatch(clearBulkImportMessages());
        }
    }, [bulkImports.saveTableForImport.messages]);

    useEffect(() => {
        handleIndeterminate();
    }, [bulkImports.getTablesForImport.data]);

    useEffect(() => {
        if (!(bulkImports.getTables.data && bulkImports.getTables.data.length > 0)) {
            dispatch(getTables());
        }
        if (!table) {
            dispatch(getTablesForImport());
            handleIndeterminate();
        }
        return () => {
            dispatch(clearExcelColumns());
        };
    }, [dispatch]);

    const getFileMappingCall = async (formData: any, token: string, totalLength?: number, fileKey?: string) => {
        setRepeatSheetFlag(true);

        const tenantID = globalLookups.search.tenant_id ? globalLookups.search.tenant_id : null;
        const companyID = globalLookups.search.company_id ? globalLookups.search.company_id : null;
        const buID = globalLookups.search.bu_id ? globalLookups.search.bu_id : null;
        //This is for blob storage.
        const blobService = new BlobServiceClient(token);

        // Create a unique name for the container
        const containerName = "bulk-insert-files";

        // Get a reference to a container
        const containerClient = blobService.getContainerClient(containerName);

        // Create a unique name for the blob
        const filenameForBlob = fileKey;
        const blobClient = containerClient.getBlockBlobClient(filenameForBlob);

        //upload File
        await blobClient.uploadData(formData, {
            blobHTTPHeaders: {
                blobContentType: formData.type
            },
            onProgress(prg) {
                setFileProgress(formData.name);
                setFileProgressBar(Math.floor((prg.loadedBytes / formData.size) * 100));
            },
        }).then(() => {
            message.success(formData.name + ' uploaded to Azure Blob');
            setFileProgress('');
            setFileProgressBar(0);
            countAzureFiles++;
            //filesintofiveblocks.push(blob_file_info.find((x) => x.original_name == fileKey));
            if (countAzureFiles == totalLength) {
                dispatch(getExcelColumnsLarge({ file: blob_file_info, tenantID, companyID, buID }));
                countAzureFiles = 0;
                //filesintofiveblocks = [];
                blob_file_info = [];
            }
            // else if (countAzureFiles % 5 == 0) {
            //   dispatch(getExcelColumns({ file: filesintofiveblocks, tenantID, companyID, buID }));
            //   filesintofiveblocks = [];
            // }
        });
    };

    const handleOnChange = async (info) => {
        const files = info.target.files;
        let sasTokenData = await bulkImportService.getSasToken();
        Array.from(files).map(async (data: any) => {
            let fileObj: any = {};
            if ((data.type == "application/x-tar" || data.type == "application/x-gzip" || data.type == "") && data.size > 2000000000) {
                message.warning(data.name + ' (tar) extension file above 2GB can not be uploaded to blob storage.Please try to compress the file!');
            } else {
                fileObj = {
                    file_name: data.name,
                    original_name: Math.random().toString(36).slice(2) + '.' + data.name.slice(((data.name.lastIndexOf('.') - 1) >>> 0) + 2),
                };

                blob_file_info.push(fileObj);
            }
            if (data.type !== "application/x-tar" && data.size < 2000000000) {
                if (new Date(sasTokenData.expiryDate) < new Date()) {
                    sasTokenData = await bulkImportService.getSasToken();
                }
                await getFileMappingCall(data, sasTokenData.token, files.length, fileObj.original_name);
            }
        });

        // fileList?.forEach((element) => {
        //   if (records?.filter((data) => data.original_filename === element.originFileObj).length === 0)
        //     updatedFileList?.push(element.originFileObj ? element.originFileObj : element);
        // });
        //setDefaultFileList(files);
        // if (file.status === 'removed') {
        //   if (fileList?.length === 0) {
        //     dispatch(clearExcelColumns());
        //   }
        // } else if (file.status === 'done') {
        //   const formData = new FormData();
        //   fileList?.forEach((ele) => {
        //     formData.append('file', ele.originFileObj ? ele.originFileObj : ele);
        //     //formData.append('file', ele );
        //   });
        //   try {
        //     if (getFileMappingTimeOut) {
        //       clearTimeout(getFileMappingTimeOut);
        //     }
        //     getFileMappingTimeOut = setTimeout(() => {
        //       getFileMappingCall(FILE);
        //     }, 1000);
        //   } catch (err) {
        //     toast.error(err?.toString());
        //   }
        //   //setDefaultFile(fileList);
        // }
        formUpload.setFieldsValue({ sheet_name: '' });
    };

    const saveTables = () => {
        const selectedTables = bulkImports.getTablesForImport.data
            .filter((table) => table.is_available)
            .map((table) => table.name);
        if (selectedTables.length > 0) {
            const inputValues = {
                table_names: selectedTables,
            };
            dispatch(saveTableForImport(inputValues));
        } else {
            toast.info('Please select some tables.');
            return false;
        }
    };

    const handleIndeterminate = () => {
        const selectedTables = bulkImports.getTablesForImport.data.filter(
            (table) => table.is_available
        );
        setIndeterminate(
            !!selectedTables.length && selectedTables.length < bulkImports.getTablesForImport.data.length
        );
        setCheckAll(selectedTables.length === bulkImports.getTablesForImport.data.length);
    };

    const handleCheckChange = (e, tableName) => {
        dispatch(
            setTableForImport(
                bulkImports.getTablesForImport.data.map((table) =>
                    table.name === tableName ? { ...table, is_available: e.target.checked } : table
                )
            )
        );
        handleIndeterminate();
    };

    const handleSelectAllChange = (e) => {
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        dispatch(
            setTableForImport(
                bulkImports.getTablesForImport.data.map((item) => ({
                    name: item.name,
                    is_available: e.target.checked,
                }))
            )
        );
    };

    const onCancel = () => {
        dispatch(clearExcelColumns());
        //setExcelColumnState([]);
        if (bulkImports.getExcelColumnsLarge.progress !== null && commonService.cancelTokenSource !== null) {
            commonService.cancelTokenSource.cancel();
        }
        setCount({ save: 0, reset: 0 });
        const tbName = formUpload?.getFieldValue('table_name');
        const date1 = formUpload?.getFieldValue('date_added');
        formUpload.resetFields();
        formUpload.setFieldsValue({ table_name: tbName });
        formUpload.setFieldsValue({ date_added: date1 });
        setRecords([]);
        currentIndex = 1;
        setTableName(tbName);
    };

    const dropdownMenu = (
        <div className="checkbox-list-wrapper">
            <ul className="checkbox-list">
                <li className="line-bottom">
                    <Checkbox
                        className="strong"
                        checked={checkAll}
                        onClick={handleSelectAllChange}
                        indeterminate={indeterminate}
                    >
                        Select All
                    </Checkbox>
                </li>
                {bulkImports.getTablesForImport.data?.map((table) => (
                    <li key={table.name}>
                        <Checkbox
                            checked={table.is_available}
                            onClick={(e) => handleCheckChange(e, table.name)}
                        >
                            {table.name}
                        </Checkbox>
                    </li>
                ))}
            </ul>
            <div className="bottom-fix">
                <Button
                    type="primary"
                    className="w-100"
                    loading={bulkImports.saveTableForImport.loading}
                    onClick={saveTables}
                >
                    Save
                </Button>
            </div>
        </div>
    );
    // End: set tables for import

    const setMapping = async (dummyRecords = null, columnsArrays?: any) => {
        if (dummyRecords === null) {
            dummyRecords = _.cloneDeep(records);
        }
        await dummyRecords.map((data) => {
            if (data && data.table_name !== undefined) {
                if (data.excel_to_sql_mapping == null) {
                    if (columnsArrays) {
                        const response: any = columnsArrays;
                        //const columnsArray = ['tenantid', 'companyid', 'bu_id'];
                        let filterExcelColumns: any = data.columns;
                        const filterTableColumns = response;
                        if (filterExcelColumns?.length >= data.header_row) {
                            filterExcelColumns = filterExcelColumns[data.header_row - 1];
                        }
                        const ExcelColsSorted = [...filterExcelColumns];
                        ExcelColsSorted.sort();

                        const initialValuesData: any = {};
                        const sqlToExcelMapping = [];
                        filterTableColumns.map(function (ele) {
                            initialValuesData[ele.name] = ExcelColsSorted.filter(
                                (x: any) =>
                                    x?.toString()?.toLowerCase()?.replace(/\s/g, '') ===
                                    ele.name?.toLowerCase()?.replace(/\s/g, '')
                            )[0];
                            sqlToExcelMapping.push({
                                key: `${ele.name}`,
                                value:
                                    initialValuesData[ele.name] == undefined ? '' : `${initialValuesData[ele.name]}`,
                            });
                        });
                        if (sqlToExcelMapping.length) {
                            sqlToExcelMapping.map((data1) => {
                                switch (data1.key) {
                                    case 'TenantId':
                                        data1.value = 'Tenant Name';
                                        return;
                                    case 'CompanyId':
                                        data1.value = 'Company Name';
                                        return;
                                    case 'BU_Id':
                                        data1.value = 'Bu Name';
                                        return;
                                    case 'LicenseId':
                                        data1.value = 'Product Name';
                                        return;
                                    case 'CurrencyId':
                                        data1.value = 'Currency Name';
                                        return;
                                    // case 'AgreementTypeId':
                                    //     data1.value = 'Agreement Type';
                                    //     return;
                                    default:
                                        return;
                                }
                            });
                        }
                        data.excel_to_sql_mapping = sqlToExcelMapping;
                    }
                }
            }
        });
        setRecords(dummyRecords);
    };

    useEffect(() => {
        const dummyRecords = _.cloneDeep(records);
        const unmapRec = dummyRecords.filter(
            (data) => data.currentMapping !== null && data.excel_to_sql_mapping !== null
        );
        setWithoutUnmappedRecords(unmapRec);
        setRecordLength(records.length);
        if (records.length > recordLength && formUpload?.getFieldValue('table_name')) {
            commonService.getTableColumns(formUpload?.getFieldValue('table_name'), true).then((res) => {
                if (res) {
                    //setColumnTableArray(res);
                    setMapping(dummyRecords, res);
                }
            });
        }
    }, [records]);


    const finalTableName = () => {
        if (tableName == finalTableForm.getFieldValue('final_table')) {
            setCount({ ...count, save: count.save + 1 });
        } else {
            message.error('Please re-check your Table!');
        }
        finalTableForm.resetFields();
    };

    return (
        <>
            <div className="update-excel-page">
                <div className="title-block">
                    <h4 className="p-0">
                        <BreadCrumbs pageName={Page.BulkUpdateFromExcel} />
                    </h4>
                    <div className="right-title">
                        <GlobalSearch />
                    </div>
                    <div className="btns-block">
                        {table ? (
                            <Button
                                className="btn-icon"
                                type="primary"
                                onClick={() => history.goBack()}
                                icon={
                                    <em className="anticon">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/ic-left-arrow.svg`} alt="" />
                                    </em>
                                }
                            >
                                Back
                            </Button>
                        ) : (
                            <Popover content={dropdownMenu} trigger="click" overlayClassName="custom-popover">
                                <Button
                                    icon={<SettingOutlined />}
                                    loading={bulkImports.getTablesForImport.loading}
                                ></Button>
                            </Popover>
                        )}
                    </div>
                </div>
                <div>
                    <div className="main-card">
                        <div>
                            <Form form={formUpload} name="formUpload" initialValues={formUploadInitialValues}>
                                <Row gutter={[30, 30]} className="align-item-start">
                                    <Col xs={24} md={12}>
                                        <label className="label w-100"></label>
                                        <Form.Item name={'upload_file'} className="m-0">
                                            <div className="custom-upload-file-wrapper">
                                                <div className="custom-upload-file">
                                                    <input
                                                        id="files"
                                                        accept=".xls,.xlsx,.csv,.txt"
                                                        className="py-sm"
                                                        type="file"
                                                        onChange={(e) => handleOnChange(e)}
                                                        multiple={true}/>
                                                    <div className="layout-upload">
                                                        <UploadOutlined />
                                                        <span className="ant-upload-text">
                                                            {' Click or drag file'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <span style={{ color: 'red', textAlign: 'center' }}>
                                                    {'File Type Supported: [csv, txt, xlsx, json, zip, 7z, tar.gz, tar, tar.bz2]'}
                                                </span>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <div className="form-group m-0">
                                            <label className="label">Table Name</label>
                                            <Form.Item name={'table_name'} className="m-0">
                                                <Select
                                                    loading={bulkImports.getTables.loading || loading}
                                                    onChange={(name: string) => {
                                                        setTableName(name);
                                                        updateRecords();
                                                    }}
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp="children"
                                                    filterOption={(input, option: any) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA: any, optionB: any) =>
                                                        optionA.children
                                                            ?.toLowerCase()
                                                            ?.localeCompare(optionB.children?.toLowerCase())
                                                    }
                                                >
                                                    {bulkImports.getTables.data?.map(
                                                        (option: IDatabaseTable, index: number) => (
                                                            <Option key={index} value={option.name}>
                                                                {option.name}
                                                            </Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                            {fileProgress == '' ? (<></>) : (<><p>{fileProgress}</p><Progress size='small' percent={fileProgressBar} width={40} /></>)}
                        </div>
                    </div>
                    <br />
                    <br />
                    {bulkImports.getExcelColumnsLarge.loading && records?.length == 0 ? (
                        <div className="spin-loader">
                            <Spin spinning={true} />
                        </div>
                    ) : records?.length > 0 ? (
                        <>
                            {/* {bulkImports.getExcelColumnsLarge.data?.map(
              (data: any, index) =>
                excelColumnState?.find((x) => x.original_filename === data.original_filename) && ( */}
                            <>
                                <RenderBI
                                    //handleSave={(data: any) => handleSave(data)}
                                    count={count}
                                    form={form}
                                    fileData={null}
                                    records={records}
                                    loading={loading}
                                    setLoading={setLoading}
                                    setDelimitFlag={setDelimitFlag}
                                    setRecords={setRecords}
                                    //seqNumber={index + 1}
                                    table={tableName}
                                    firstFlag={firstFlag}
                                    setFirstFlag={setFirstFlag}
                                    withoutUnmappedRecords={withoutUnmappedRecords}
                                    setWithoutUnmappedRecords={setWithoutUnmappedRecords}
                                    dateChangeFlag={dateChangeFlag}
                                    setDateChangeFlag={setDateChangeFlag}
                                    setExpandedRecords={setExpandedRecords}
                                    expandedRecords={expandedRecords}
                                    isUpdateComponent={true}
                                    blobStorageFile={() => { blob_file_info = []; }}
                                ></RenderBI>
                                <br />
                                <hr />
                            </>
                        </>
                    ) : (
                        <></>
                    )}
                    {delimitModalShow && (
                        <>
                            <CkeckDelimiterModal
                                setRecords={setRecords}
                                records={records}
                                isUpdateComponent={true}
                                setDefaultDelimeter={setDefaultDelimeter}
                                tableName={tableName}
                                showModal={delimitModalShow}
                                handleModalClose={() => {
                                    setDelimitModalShow(false);
                                }}
                            />
                        </>
                    )}
                    <div className="btns-block">
                        {Object.values(globalLookups.search)?.filter((x) => x > 0)?.length < 2 || tableName == undefined ? (
                            <Popover
                                content={<>Please select atleast Tenant, Company and Table Name!</>}
                                trigger="click"
                            >
                                <Button
                                    type="primary"
                                    disabled={records?.length == 0}
                                    loading={bulkImports.bulkUpdate.loading}
                                >
                                    Update
                                </Button>
                            </Popover>
                        ) : (
                            <Popconfirm placement="topLeft"
                                title=
                                {<div><span style={{ fontWeight: 'bold' }}>{tableName}</span> is your table! Please re-enter to confirm
                                    <Form
                                        form={finalTableForm}
                                    >
                                        <Form.Item
                                            name="final_table"
                                            className="m-0"
                                        >
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </Form>
                                </div>}
                                onConfirm={() => { finalTableName(); }}>
                                <Button
                                    type="primary"
                                    disabled={records?.length == 0}
                                    loading={bulkImports.bulkUpdate.loading}
                                >
                                    Update
                                </Button>
                            </Popconfirm>
                        )}
                        <Button
                            type="primary"
                            onClick={() => {
                                countAzureFiles = 0;
                                blob_file_info = [];
                                setCount({ save: 0, reset: count.reset + 1 });
                                onCancel();
                                if (records.length) {
                                    const fileName = [];
                                    const dummyRecord = _.cloneDeep(records);
                                    dummyRecord.map((data) => {
                                        if (fileName.indexOf(data.filename) === -1) {
                                            fileName.push(data.filename);
                                        }
                                    });
                                    commonService.deleteFileForBulkImport(fileName);
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BulkImportExcel;
