import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { ISnowComputerApplications } from '../../../../services/snow/snowComputerApplications/snowComputerApplications.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getSnowComputerApplicationsById,
  saveSnowComputerApplications,
} from '../../../../store/snow/snowComputerApplications/snowComputerApplications.action';
import {
  clearSnowComputerApplicationsGetById,
  clearSnowComputerApplicationsMessages,
  snowComputerApplicationsSelector,
} from '../../../../store/snow/snowComputerApplications/snowComputerApplications.reducer';
import { getBULookup, getCompanyLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { IAddSnowComputerApplicationsProps } from './addSnowComputerApplications.model';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate, passDateToApi } from '../../../../common/helperFunction';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';

const { Option } = Select;

const AddSnowComputerApplicationsModal: React.FC<IAddSnowComputerApplicationsProps> = (props) => {
  const snowComputerApplications = useAppSelector(snowComputerApplicationsSelector);
  const dispatch = useAppDispatch();
  const commonLookups = useAppSelector(commonSelector);
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.SnowComputerApplications} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: ISnowComputerApplications = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    date_added: getSimpleDate(),
    computer_id: null,
    application_id: '',
    name: '',
    manufacturer_id: '',
    manufacturer_name: '',
    family_id: '',
    family_name: '',
    bundle_application_id: '',
    bundle_application_name: '',
    last_used: getSimpleDate(),
    first_used: getSimpleDate(),
    install_date: getSimpleDate(),
    discovered_date: getSimpleDate(),
    run: null,
    avg_usage_time: null,
    users: null,
    license_required: false,
    is_installed: false,
    is_blacklisted: false,
    is_whitelisted: false,
    is_virtual: false,
    is_oem: false,
    is_msdn: false,
    is_web_application: false,
    application_item_cost: null,
  };

  const onFinish = (values: any) => {
    const inputValues: ISnowComputerApplications = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.date_added = passDateToApi(inputValues.date_added);
    inputValues.last_used = passDateToApi(inputValues.last_used);
    inputValues.first_used = passDateToApi(inputValues.first_used);
    inputValues.install_date = passDateToApi(inputValues.install_date);
    inputValues.discovered_date = passDateToApi(inputValues.discovered_date);
    if (!isMultiple) {
      dispatch(saveSnowComputerApplications(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        snowComputerApplications.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: ISnowComputerApplications) => {
    if (data.tenant_id) {
      await dispatch(getCompanyLookup(data.tenant_id));
    }
    if (data.company_id) {
      await dispatch(getBULookup(data.company_id));
    }
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
        computer_id: _.isNull(data.computer_id) ? null : data.computer_id,
        application_id: data.application_id,
        name: data.name,
        manufacturer_id: data.manufacturer_id,
        manufacturer_name: data.manufacturer_name,
        family_id: data.family_id,
        family_name: data.family_name,
        bundle_application_id: data.bundle_application_id,
        bundle_application_name: data.bundle_application_name,
        last_used: _.isNull(data.last_used) ? null : forEditModal(data.last_used),
        first_used: _.isNull(data.first_used) ? null : forEditModal(data.first_used),
        install_date: _.isNull(data.install_date) ? null : forEditModal(data.install_date),
        discovered_date: _.isNull(data.discovered_date) ? null : forEditModal(data.discovered_date),
        run: _.isNull(data.run) ? null : data.run,
        avg_usage_time: _.isNull(data.avg_usage_time) ? null : data.avg_usage_time,
        users: _.isNull(data.users) ? null : data.users,
        license_required: data.license_required,
        is_installed: data.is_installed,
        is_blacklisted: data.is_blacklisted,
        is_whitelisted: data.is_whitelisted,
        is_virtual: data.is_virtual,
        is_oem: data.is_oem,
        is_msdn: data.is_msdn,
        is_web_application: data.is_web_application,
        application_item_cost: _.isNull(data.application_item_cost) ? null : data.application_item_cost,
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (snowComputerApplications.save.messages.length > 0) {
      if (snowComputerApplications.save.hasErrors) {
        toast.error(snowComputerApplications.save.messages.join(' '));
      } else {
        toast.success(snowComputerApplications.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearSnowComputerApplicationsMessages());
    }
  }, [snowComputerApplications.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && snowComputerApplications.getById.data) {
      const data = snowComputerApplications.getById.data;
      fillValuesOnEdit(data);
    }
  }, [snowComputerApplications.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getSnowComputerApplicationsById(+id));
    }
    return () => {
      dispatch(clearSnowComputerApplicationsGetById());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {snowComputerApplications.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={snowComputerApplications.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="snowComputerApplications"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.tenantLookup.loading}
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.buLookup.loading}
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'computer_id']} valuePropName="checked" noStyle>
                      <Checkbox>Computer Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Computer Id'
                  )}
                  <Form.Item
                    name="computer_id"
                    label="Computer Id"
                    className="m-0"
                    rules={[{ type: 'integer', required: !isMultiple }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'application_id']} valuePropName="checked" noStyle>
                      <Checkbox>Applications Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Applications Id'
                  )}
                  <Form.Item
                    name="application_id"
                    label="Applications Id"
                    className="m-0"
                    rules={[{ max: 255, required: !isMultiple }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'name']} valuePropName="checked" noStyle>
                      <Checkbox>Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Name'
                  )}
                  <Form.Item
                    name="name"
                    label="Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'manufacturer_id']} valuePropName="checked" noStyle>
                      <Checkbox>Manufacturer Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Manufacturer Id'
                  )}
                  <Form.Item
                    name="manufacturer_id"
                    label="Manufacturer Id"
                    className="m-0"
                    rules={[{ max: 36 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'manufacturer_name']} valuePropName="checked" noStyle>
                      <Checkbox>Manufacturer Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Manufacturer Name'
                  )}
                  <Form.Item
                    name="manufacturer_name"
                    label="Manufacturer Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'family_id']} valuePropName="checked" noStyle>
                      <Checkbox>Family Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Family Id'
                  )}
                  <Form.Item
                    name="family_id"
                    label="Family Id"
                    className="m-0"
                    rules={[{ max: 36 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'family_name']} valuePropName="checked" noStyle>
                      <Checkbox>Family Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Family Name'
                  )}
                  <Form.Item
                    name="family_name"
                    label="Family Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bundle_application_id']} valuePropName="checked" noStyle>
                      <Checkbox>Bundle Application Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Bundle Application Id'
                  )}
                  <Form.Item
                    name="bundle_application_id"
                    label="Bundle Application Id"
                    className="m-0"
                    rules={[{ max: 36 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bundle_application_name']} valuePropName="checked" noStyle>
                      <Checkbox>Bundle Application Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Bundle Application Name'
                  )}
                  <Form.Item
                    name="bundle_application_name"
                    label="Bundle Application Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_used']} valuePropName="checked" noStyle>
                      <Checkbox>Last Used</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Used'
                  )}
                  <Form.Item name="last_used" label="Last Used" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'first_used']} valuePropName="checked" noStyle>
                      <Checkbox>First Used</Checkbox>
                    </Form.Item>
                  ) : (
                    'First Used'
                  )}
                  <Form.Item name="first_used" label="First Used" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'install_date']} valuePropName="checked" noStyle>
                      <Checkbox>Install Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Install Date'
                  )}
                  <Form.Item name="install_date" label="Install Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'discovered_date']} valuePropName="checked" noStyle>
                      <Checkbox>Discovered Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Discovered Date'
                  )}
                  <Form.Item name="discovered_date" label="Discovered Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'run']} valuePropName="checked" noStyle>
                      <Checkbox>Run</Checkbox>
                    </Form.Item>
                  ) : (
                    'Run'
                  )}
                  <Form.Item
                    name="run"
                    label="Run"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'avg_usage_time']} valuePropName="checked" noStyle>
                      <Checkbox>Avg Usage Time</Checkbox>
                    </Form.Item>
                  ) : (
                    'Avg Usage Time'
                  )}
                  <Form.Item
                    name="avg_usage_time"
                    label="Avg Usage Time"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'users']} valuePropName="checked" noStyle>
                      <Checkbox>Users</Checkbox>
                    </Form.Item>
                  ) : (
                    'Users'
                  )}
                  <Form.Item
                    name="users"
                    label="Users"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'application_item_cost']} valuePropName="checked" noStyle>
                      <Checkbox>Application Item Cost</Checkbox>
                    </Form.Item>
                  ) : (
                    'Application Item Cost'
                  )}
                  <Form.Item
                    name="application_item_cost"
                    label="Application Item Cost"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="license_required" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'license_required']} valuePropName="checked" noStyle>
                      <Checkbox>License Required</Checkbox>
                    </Form.Item>
                  ) : (
                    'License Required'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_installed" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_installed']} valuePropName="checked" noStyle>
                      <Checkbox>Is Installed</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Installed'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_blacklisted" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_blacklisted']} valuePropName="checked" noStyle>
                      <Checkbox>Is Blacklisted</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Blacklisted'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_whitelisted" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_whitelisted']} valuePropName="checked" noStyle>
                      <Checkbox>Is Whitelisted</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Whitelisted'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_virtual" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_virtual']} valuePropName="checked" noStyle>
                      <Checkbox>Is Virtual</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Virtual'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_oem" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_oem']} valuePropName="checked" noStyle>
                      <Checkbox>Is OEM</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is OEM'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_msdn" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_msdn']} valuePropName="checked" noStyle>
                      <Checkbox>Is MSDN</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is MSDN'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_web_application" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_web_application']} valuePropName="checked" noStyle>
                      <Checkbox>Is Web Application</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Web Application'
                  )}
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={snowComputerApplications.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddSnowComputerApplicationsModal;
