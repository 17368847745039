import { createAsyncThunk } from '@reduxjs/toolkit';
import CmdbCiWinClusterNodeService from '../../../services/serviceNow/cmdbCiWinClusterNode/cmdbCiWinClusterNode.service';
import {
  ISearchCmdbCiWinClusterNode,
  ICmdbCiWinClusterNode,
  IProcessData,
} from '../../../services/serviceNow/cmdbCiWinClusterNode/cmdbCiWinClusterNode.model';

// Asynchronous thunk action

export const searchCmdbCiWinClusterNode = createAsyncThunk(
  'searchCmdbCiWinClusterNode',
  async (searchParam?: ISearchCmdbCiWinClusterNode) => {
    const response = await CmdbCiWinClusterNodeService.searchCmdbCiWinClusterNode(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getCmdbCiWinClusterNodeById = createAsyncThunk('getCmdbCiWinClusterNodeById', async (id: number) => {
  const response = await CmdbCiWinClusterNodeService.getCmdbCiWinClusterNodeById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveCmdbCiWinClusterNode = createAsyncThunk('saveCmdbCiWinClusterNode', async (data: ICmdbCiWinClusterNode) => {
  const response = await CmdbCiWinClusterNodeService.saveCmdbCiWinClusterNode(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteCmdbCiWinClusterNode = createAsyncThunk('deleteCmdbCiWinClusterNode', async (id: number) => {
  const response = await CmdbCiWinClusterNodeService.deleteCmdbCiWinClusterNode(id).then((res) => {
    return res.body;
  });
  return response;
});

export const processDataCmdbCiWinClusterNode = createAsyncThunk(
  'processDataCmdbCiWinClusterNode',
  async (data: IProcessData) => {
    const response = await CmdbCiWinClusterNodeService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);
