import ReactEcharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/app.hooks';
import { commonSelector } from '../../store/common/common.reducer';
import { IPieGraph } from './pieGraph.model';

export const PieChart: React.FC<IPieGraph> = (props) => {
    const { isBackgroundProcess } = props;

    const commonLookups = useAppSelector(commonSelector);
    const [options, setOptions] = useState({});

    useEffect(() => {
        const data = [];
        if (commonLookups.getBackgroundProcessGraphDetails.data?.length > 0 || commonLookups.getSPSApiGraphDetails?.data?.length > 0) {
            if (isBackgroundProcess) {
                commonLookups.getBackgroundProcessGraphDetails.data.map((a) => {
                    data.push(a);
                });
            } else {
                commonLookups.getSPSApiGraphDetails.data.map((a) => {
                    data.push(a);
                });
            }

            const option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'horizontal',
                    top: 'bottom',
                    type: 'scroll',
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '70%',
                        data: data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            setOptions(option);
        }
    }, [commonLookups.getBackgroundProcessGraphDetails.data]);


    return (
        <>
            <div className="sqlServer">
                <ReactEcharts option={options} />
            </div>
        </>
    )
};
