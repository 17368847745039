import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigSoftwareDeviceType,
  ISearchConfigSoftwareDeviceType,
} from '../../../services/master/configSoftwareDeviceType/configSoftwareDeviceType.model';
import configSoftwareDeviceType from '../../../services/master/configSoftwareDeviceType/configSoftwareDeviceType.service';
// Asynchronous thunk action

export const searchConfigSoftwareDeviceType = createAsyncThunk(
  'searchConfigSoftwareDeviceType',
  async (searchParam?: ISearchConfigSoftwareDeviceType) => {
    const response = await configSoftwareDeviceType.searchConfigSoftwareDeviceType(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigSoftwareDeviceTypeById = createAsyncThunk(
  'getConfigSoftwareDeviceTypeById',
  async (id: number) => {
    const response = await configSoftwareDeviceType.getConfigSoftwareDeviceTypeById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigSoftwareDeviceType = createAsyncThunk(
  'saveConfigSoftwareDeviceType',
  async (data: IConfigSoftwareDeviceType) => {
    const response = await configSoftwareDeviceType.saveConfigSoftwareDeviceType(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigSoftwareDeviceType = createAsyncThunk(
  'deleteConfigSoftwareDeviceType',
  async (id: number) => {
    const response = await configSoftwareDeviceType.deleteConfigSoftwareDeviceType(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
