import { Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearPegaSecurityReportsMessages,
  pegaSecurityReportsSelector,
  setTableColumnSelection,
} from '../../../../store/pega/pegaSecurityReports/pegaSecurityReports.reducer';
import {
  deletePegaSecurityReports,
  searchPegaSecurityReports,
} from '../../../../store/pega/pegaSecurityReports/pegaSecurityReports.action';
import pegaSecurityReportsService from '../../../../services/pega/pegaSecurityReport/pegaSecurityReport.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const pegaSecurityReportss = useAppSelector(pegaSecurityReportsSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return pegaSecurityReportsService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      pegaSecurityReportss.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', pegaSecurityReportss.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Source</span>,
        column: 'source',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'source',
              form
            ),
            dataIndex: 'source',
            key: 'source',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">User ID</span>,
        column: 'User_ID',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'user_id',
              form
            ),
            dataIndex: 'user_id',
            key: 'user_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Unique ID</span>,
        column: 'Unique ID',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'unique_id',
              form
            ),
            dataIndex: 'unique_id',
            key: 'unique_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">First Name</span>,
        column: 'First Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('first_name', form),
            dataIndex: 'first_name',
            key: 'first_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Name</span>,
        column: 'Last Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('last_name', form),
            dataIndex: 'last_name',
            key: 'last_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">User Type</span>,
        column: 'User Type',
        sorter: true,
        children: [
          {
            title: FilterBySwap('user_type', form),
            dataIndex: 'user_type',
            key: 'user_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Login Name</span>,
        column: 'Login Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('login_name', form),
            dataIndex: 'login_name',
            key: 'login_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Work Location</span>,
        column: 'Work Location',
        sorter: true,
        children: [
          {
            title: FilterBySwap('work_location', form),
            dataIndex: 'work_location',
            key: 'work_location',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Payroll Location</span>,
        column: 'Payroll Location',
        sorter: true,
        children: [
          {
            title: FilterBySwap('payroll_location', form),
            dataIndex: 'payroll_location',
            key: 'payroll_location',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Pega Company</span>,
        column: 'Pega Company',
        sorter: true,
        children: [
          {
            title: FilterBySwap('pega_company', form),
            dataIndex: 'pega_company',
            key: 'pega_company',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Application Role</span>,
        column: 'Application Role',
        sorter: true,
        children: [
          {
            title: FilterBySwap('application_role', form),
            dataIndex: 'application_role',
            key: 'application_role',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Application Status</span>,
        column: 'Application Status',
        sorter: true,
        children: [
          {
            title: FilterBySwap('application_status', form),
            dataIndex: 'application_status',
            key: 'application_status',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">HSP Code</span>,
        column: 'HSP Code',
        sorter: true,
        children: [
          {
            title: FilterBySwap('hsp_code', form),
            dataIndex: 'hsp_code',
            key: 'hsp_code',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Level CD</span>,
        column: 'Level CD',
        sorter: true,
        children: [
          {
            title: FilterBySwap('level_cd', form),
            dataIndex: 'level_cd',
            key: 'level_cd',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Org CD</span>,
        column: 'Org CD',
        sorter: true,
        children: [
          {
            title: FilterBySwap('org_cd', form),
            dataIndex: 'org_cd',
            key: 'org_cd',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Phone</span>,
        column: 'Phone',
        sorter: true,
        children: [
          {
            title: FilterBySwap('phone', form),
            dataIndex: 'phone',
            key: 'phone',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Email</span>,
        column: 'Email',
        sorter: true,
        children: [
          {
            title: FilterBySwap('email', form),
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Login Date</span>,
        column: 'Last Login Date',
        sorter: true,
        children: [
          {
            title: FilterBySwap('last_login_date', form),
            dataIndex: 'last_login_date',
            key: 'last_login_date',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Department Code</span>,
        column: 'Department Code',
        sorter: true,
        children: [
          {
            title: FilterBySwap('department_code', form),
            dataIndex: 'department_code',
            key: 'department_code',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Department Name</span>,
        column: 'Department Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('department_name', form),
            dataIndex: 'department_name',
            key: 'department_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Job Code</span>,
        column: 'Job Code',
        sorter: true,
        children: [
          {
            title: FilterBySwap('job_code', form),
            dataIndex: 'job_code',
            key: 'job_code',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Job Title</span>,
        column: 'Job Title',
        sorter: true,
        children: [
          {
            title: FilterBySwap('job_title', form),
            dataIndex: 'job_title',
            key: 'job_title',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeCmsCategoryExtended = (id: number) => {
    dispatch(deletePegaSecurityReports(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.CmsCategoryExtended}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/pega/pega-security-report/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.CmsCategoryExtended}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeCmsCategoryExtended(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.CmsCategoryExtended)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={pegaSecurityReportsSelector}
        searchTableData={searchPegaSecurityReports}
        clearTableDataMessages={clearPegaSecurityReportsMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.CmsCategoryExtended)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
