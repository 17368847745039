import { createAsyncThunk } from '@reduxjs/toolkit';
import CmdbCiVcenterClusterService from '../../../services/serviceNow/cmdbCiVcenterCluster/cmdbCiVcenterCluster.service';
import {
  ISearchCmdbCiVcenterCluster,
  ICmdbCiVcenterCluster,
  IProcessData,
} from '../../../services/serviceNow/cmdbCiVcenterCluster/cmdbCiVcenterCluster.model';

// Asynchronous thunk action

export const searchCmdbCiVcenterCluster = createAsyncThunk(
  'searchCmdbCiVcenterCluster',
  async (searchParam?: ISearchCmdbCiVcenterCluster) => {
    const response = await CmdbCiVcenterClusterService.searchCmdbCiVcenterCluster(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getCmdbCiVcenterClusterById = createAsyncThunk('getCmdbCiVcenterClusterById', async (id: number) => {
  const response = await CmdbCiVcenterClusterService.getCmdbCiVcenterClusterById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveCmdbCiVcenterCluster = createAsyncThunk('saveCmdbCiVcenterCluster', async (data: ICmdbCiVcenterCluster) => {
  const response = await CmdbCiVcenterClusterService.saveCmdbCiVcenterCluster(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteCmdbCiVcenterCluster = createAsyncThunk('deleteCmdbCiVcenterCluster', async (id: number) => {
  const response = await CmdbCiVcenterClusterService.deleteCmdbCiVcenterCluster(id).then((res) => {
    return res.body;
  });
  return response;
});

export const processDataCmdbCiVcenterCluster = createAsyncThunk(
  'processDataCmdbCiVcenterCluster',
  async (data: IProcessData) => {
    const response = await CmdbCiVcenterClusterService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);
