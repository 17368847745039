import { Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearSnowUsersMessages,
  snowUsersSelector,
  setTableColumnSelection,
} from '../../../../store/snow/snowUsers/snowUsers.reducer';
import {
  deleteSnowUsers,
  searchSnowUsers,
} from '../../../../store/snow/snowUsers/snowUsers.action';
import snowUsersService from '../../../../services/snow/snowUsers/snowUsers.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const snowUsers = useAppSelector(snowUsersSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return snowUsersService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      snowUsers.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', snowUsers.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">User Id</span>,
        column: 'UserId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'user_id',
              form
            ),
            dataIndex: 'user_id',
            key: 'user_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Customer Id</span>,
        column: 'CustomerId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'customer_id',
              form
            ),
            dataIndex: 'customer_id',
            key: 'customer_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Usersname</span>,
        column: 'Usersname',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'username',
              form
            ),
            dataIndex: 'username',
            key: 'username',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Full Name</span>,
        column: 'FullName',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'full_name',
              form
            ),
            dataIndex: 'full_name',
            key: 'full_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Organization</span>,
        column: 'Organization',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'organization',
              form
            ),
            dataIndex: 'organization',
            key: 'organization',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Org Checksum</span>,
        column: 'OrgChecksum',
        sorter: true,
        children: [
          {
            title: FilterBySwap('org_checksum', form),
            dataIndex: 'org_checksum',
            key: 'org_checksum',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Logon</span>,
        column: 'Last Logon',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('last_logon', snowUsers.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'last_logon',
            key: 'last_logon',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Updated Date</span>,
        column: 'UpdatedDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('updated_date', snowUsers.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'updated_date',
            key: 'updated_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Email</span>,
        column: 'Email',
        sorter: true,
        children: [
          {
            title: FilterBySwap('email', form),
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Phone Number</span>,
        column: 'PhoneNumber',
        sorter: true,
        children: [
          {
            title: FilterBySwap('phone_number', form),
            dataIndex: 'phone_number',
            key: 'phone_number',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Updated By</span>,
        column: 'UpdatedBy',
        sorter: true,
        children: [
          {
            title: FilterBySwap('updated_by', form),
            dataIndex: 'updated_by',
            key: 'updated_by',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeSnowUsers = (id: number) => {
    dispatch(deleteSnowUsers(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.SnowUsers}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/snow/snow-users/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.SnowUsers}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeSnowUsers(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.SnowUsers)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={snowUsersSelector}
        searchTableData={searchSnowUsers}
        clearTableDataMessages={clearSnowUsersMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.SnowUsers)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
