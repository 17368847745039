import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowDCCS,
  ISearchSnowDCCS,
} from '../../../services/snow/snowDCCS/snowDCCS.model';
import snowDCCSService from '../../../services/snow/snowDCCS/snowDCCS.service';

// Asynchronous thunk action

export const searchSnowDCCS = createAsyncThunk(
  'searchSnowDCCS',
  async (searchParam?: ISearchSnowDCCS) => {
    const response = await snowDCCSService
      .searchSnowDCCS(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowDCCSById = createAsyncThunk(
  'getSnowDCCSById',
  async (id: number) => {
    const response = await snowDCCSService.getSnowDCCSById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowDCCS = createAsyncThunk(
  'saveSnowDCCS',
  async (data: ISnowDCCS) => {
    const response = await snowDCCSService.saveSnowDCCS(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowDCCS = createAsyncThunk(
  'deleteSnowDCCS',
  async (id: number) => {
    const response = await snowDCCSService.deleteSnowDCCS(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
