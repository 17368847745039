import { Checkbox, Popconfirm, Popover } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  setTableColumnSelection,
  clearExcelColumnMappingMessages,
  excelColumnMappingSelector,
} from '../../../../store/master/excelColumnMapping/excelColumnMapping.reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  deleteExcelColumnMapping,
  searchExcelColumnMapping,
} from '../../../../store/master/excelColumnMapping/excelColumnMapping.action';
import excelColumnMappingService from '../../../../services/master/excelColumnMapping/excelColumnMapping.service';
import { FilterByBooleanDropDown, FilterWithSwapOption } from '../../../../common/components/DataTable/DataTableFilters';
import { ISearch } from '../../../../common/models/common';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { IMainTable } from './mainTable.model';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AlignType } from 'rc-table/lib/interface';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const { id } = props;
  const excelColumnMapping = useAppSelector(excelColumnMappingSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  // useEffect(() => {
  //   setObjectForColumnFilter({ excel_file_mapping_id: id });
  // }, []);

  const extraSearchData = {
    excel_file_mapping_id: id,
  };

  const exportExcelFile = (searchData: ISearch) => {
    return excelColumnMappingService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form) => {
    return FilterWithSwapOption(
      dataIndex,
      excelColumnMapping.search.tableName,
      form,
      null,
      ObjectForColumnFilter
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">Excel File Mapping Id</span>,
        column: 'ExcelFileMappingId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('excel_file_mapping_id', form),
            dataIndex: 'excel_file_mapping_id',
            key: 'excel_file_mapping_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Sheet Name</span>,
        column: 'SheetName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sheet_name', form),
            dataIndex: 'sheet_name',
            key: 'sheet_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Header Row</span>,
        column: 'HeaderRow',
        sorter: true,
        children: [
          {
            title: FilterBySwap('header_row', form),
            dataIndex: 'header_row',
            key: 'header_row',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Table Name</span>,
        column: 'TableName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('table_name', form),
            dataIndex: 'table_name',
            key: 'table_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Dynamic Header</span>,
        column: 'Is Dynamic Header',
        sorter: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_dynamic_header',
              excelColumnMapping.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_dynamic_header',
            key: 'is_dynamic_header',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
    ];
  };

  const removeExcelColumnMapping = (id: number) => {
    dispatch(deleteExcelColumnMapping(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Popover content={data.mapping} trigger="hover">
        <div style={{ marginTop: '5px' }}>
          <InfoCircleOutlined />
        </div>
      </Popover>
      <Can I={Action.Delete} a={Page.ExcelColumnMapping}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeExcelColumnMapping(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        tableAction={tableAction}
        globalSearchExist={false}
        hideExportButton={true}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={excelColumnMappingSelector}
        searchTableData={searchExcelColumnMapping}
        clearTableDataMessages={clearExcelColumnMappingMessages}
        setTableColumnSelection={setTableColumnSelection}
        disableRowSelection={true}
        showAddButton={false}
        extraSearchData={extraSearchData}
        showBulkUpdate={false}
        showDelete={ability.can(Action.Delete, Page.ExcelColumnMapping)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        isExcelColumnMapping={true}
      />
    </>
  );
};

export default forwardRef(MainTable);
