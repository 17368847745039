import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ability from '../../common/ability';
import { Action, Page } from '../../common/constants/pageAction';
import SnowApplication from './SnowApplication';
import SnowApplications from './SnowApplications';
import SnowComputer from './SnowComputer';
import SnowComputerApplications from './SnowComputerApplications';
import SnowComputers from './SnowComputers';
import SnowDCCS from './SnowDCCS';
import SnowHost from './SnowHost';
import SnowUser from './SnowUser';
import SnowUsers from './SnowUsers';
import SnowVM from './SnowVM';

const SnowRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div className="snow">
      <Switch>
        {/* Snow Application */}
        {ability.can(Action.View, Page.SnowApplication) && (
          <Route exact path={`${match.path}/snow-application/:id`} component={SnowApplication} />
        )}
        {ability.can(Action.View, Page.SnowApplication) && (
          <Route exact path={`${match.path}/snow-application`} component={SnowApplication} />
        )}

        {/* Snow Applications */}
        {ability.can(Action.View, Page.SnowApplications) && (
          <Route exact path={`${match.path}/snow-applications/:id`} component={SnowApplications} />
        )}
        {ability.can(Action.View, Page.SnowApplications) && (
          <Route exact path={`${match.path}/snow-applications`} component={SnowApplications} />
        )}

        {/* Snow Computer Applications */}
        {ability.can(Action.View, Page.SnowComputerApplications) && (
          <Route exact path={`${match.path}/snow-computer-applications/:id`} component={SnowComputerApplications} />
        )}
        {ability.can(Action.View, Page.SnowComputerApplications) && (
          <Route exact path={`${match.path}/snow-computer-applications`} component={SnowComputerApplications} />
        )}

        {/* Snow Computers */}
        {ability.can(Action.View, Page.SnowComputers) && (
          <Route exact path={`${match.path}/snow-computers/:id`} component={SnowComputers} />
        )}
        {ability.can(Action.View, Page.SnowComputers) && (
          <Route exact path={`${match.path}/snow-computers`} component={SnowComputers} />
        )}

        {/* Snow Computer */}
        {ability.can(Action.View, Page.SnowComputer) && (
          <Route exact path={`${match.path}/snow-computer/:id`} component={SnowComputer} />
        )}
        {ability.can(Action.View, Page.SnowComputer) && (
          <Route exact path={`${match.path}/snow-computer`} component={SnowComputer} />
        )}

        {/* Snow DCCS */}
        {ability.can(Action.View, Page.SnowDCCS) && (
          <Route exact path={`${match.path}/snow-dccs/:id`} component={SnowDCCS} />
        )}
        {ability.can(Action.View, Page.SnowDCCS) && (
          <Route exact path={`${match.path}/snow-dccs`} component={SnowDCCS} />
        )}

        {/* Snow Host */}
        {ability.can(Action.View, Page.SnowHost) && (
          <Route exact path={`${match.path}/snow-host/:id`} component={SnowHost} />
        )}
        {ability.can(Action.View, Page.SnowHost) && (
          <Route exact path={`${match.path}/snow-host`} component={SnowHost} />
        )}

        {/* Snow VM */}
        {ability.can(Action.View, Page.SnowVM) && (
          <Route exact path={`${match.path}/snow-vm/:id`} component={SnowVM} />
        )}
        {ability.can(Action.View, Page.SnowVM) && (
          <Route exact path={`${match.path}/snow-vm`} component={SnowVM} />
        )}
        
        {/* Snow User */}
        {ability.can(Action.View, Page.SnowUser) && (
          <Route exact path={`${match.path}/snow-user/:id`} component={SnowUser} />
        )}
        {ability.can(Action.View, Page.SnowUser) && (
          <Route exact path={`${match.path}/snow-user`} component={SnowUser} />
        )}

        {/* Snow Users */}
        {ability.can(Action.View, Page.SnowUsers) && (
          <Route exact path={`${match.path}/snow-users/:id`} component={SnowUsers} />
        )}
        {ability.can(Action.View, Page.SnowUsers) && (
          <Route exact path={`${match.path}/snow-users`} component={SnowUsers} />
        )}

        {/* keep least always */}
        <Route path={`${match.path}/*`}>
          <Redirect to={`/404`} />
        </Route>
      </Switch>
    </div>
  );
};

export default SnowRoutes;
