import { useEffect, useRef } from 'react';
import { useAppDispatch } from '../../../store/app.hooks';
import React from 'react';
import { IErrorLogFileProps } from './errorLogFile.model';
import MainTable from './MainTable';
import { Page } from '../../../common/constants/pageAction';
import BreadCrumbs from '../../../common/components/Breadcrumbs';
import { clearErrorLogFile } from '../../../store/master/errorLogFile/errorLogFile.reducer';

const ErrorLogFiles: React.FC<IErrorLogFileProps> = () => {
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);

  const [showSelectedListModal, setShowSelectedListModal] = React.useState(false);

  useEffect(() => {
    setShowSelectedListModal(false);
    return () => {
      dispatch(clearErrorLogFile());
    };
  }, []);

  const tableButtons = () => <></>;

  return (
    <div className="ad">
      <div className="title-block">
        <h4 className="p-0">
          <BreadCrumbs pageName={Page.ErrorLogFile} />
        </h4>
      </div>
      <div className="main-card">
        <MainTable
          ref={dataTableRef}
          isMultiple={showSelectedListModal}
          setShowSelectedListModal={(state) => {
            setShowSelectedListModal(state);
          }}
          tableButtons={tableButtons}
        />
      </div>
    </div>
  );
};

export default ErrorLogFiles;
