import { Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import DataTable from '../../../../common/components/DataTable';
import {
  clearTelemetryMessages,
  setTableColumnSelection,
  telemetrySelector,
} from '../../../../store/master/telemetry/telemetry.reducer';
import telemetryService from '../../../../services/master/telemetry/telemetry.service';
import { deleteTelemetry, searchTelemetry } from '../../../../store/master/telemetry/telemetry.action';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { showDateFromApi } from '../../../../common/helperFunction';
import _ from 'lodash';
import { Common } from '../../../../common/constants/common';
import moment from 'moment';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    anyId
  } = props;
  const telemetry = useAppSelector(telemetrySelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  const extraSearchData = {
    bg_process_id: anyId,
  };

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return telemetryService.exportExcelFile(searchData);
  };

  const FilterByDateSwapTable = (dataIndex: string, tableName: string, form: any) => {
    return FilterByDateSwap(dataIndex, tableName, form, null, ObjectForColumnFilter);
  };

  const FilterBySwap = (dataIndex: string, form,isRelativeDropDownFlag?: boolean) => {
    return FilterWithSwapOption(
      dataIndex,
      telemetry.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByDateSwapTable('date_added', telemetry.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">User</span>,
        column: 'UserId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'user_id',
              form,
              true
            ),
            dataIndex: 'user_name',
            key: 'user_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Background Process ID</span>,
        column: 'BgProcessId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'bg_process_id',
              form
            ),
            dataIndex: 'bg_process_id',
            key: 'bg_process_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Table Name</span>,
        column: 'TableName',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'table_name',
              form
            ),
            dataIndex: 'table_name',
            key: 'table_name',
            ellipsis: true,
          },
        ],
      },
      // {
      //   title: <span className="dragHandler">Status</span>,
      //   column: 'Status',
      //   sorter: true,
      //   children: [
      //     {
      //       title: FilterBySwap(
      //         'status',
      //         form
      //       ),
      //       dataIndex: 'status',
      //       key: 'status',
      //       ellipsis: true,
      //     },
      //   ],
      // },
      {
        title: <span className="dragHandler">Action</span>,
        column: 'Action',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'action',
              form
            ),
            dataIndex: 'action',
            key: 'action',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Start</span>,
        column: 'Start',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByDateSwap(
              'start',
              telemetry.search.tableName,
              form,
              null,
              ObjectForColumnFilter,
              true,
              true
            ),
            dataIndex: 'start',
            key: 'start',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? moment(date).format(Common.DATETIMEFORMAT) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">End</span>,
        column: 'End',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByDateSwap(
              'end',
              telemetry.search.tableName,
              form,
              null,
              ObjectForColumnFilter,
              true,
              true
            ),
            dataIndex: 'end',
            key: 'end',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? moment(date).format(Common.DATETIMEFORMAT) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Duration</span>,
        column: 'Duration',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'duration',
              form
            ),
            dataIndex: 'duration',
            key: 'duration',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeTelemetry = (id: number) => {
    dispatch(deleteTelemetry(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      {/* <Can I={Action.Update} a={Page.Telemetry}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/administration/telemetry/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can> */}
      <Can I={Action.Delete} a={Page.Telemetry}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeTelemetry(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={false}
        disableRowSelection={true}
        globalSearchExist={true}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={telemetrySelector}
        searchTableData={searchTelemetry}
        clearTableDataMessages={clearTelemetryMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        showBulkUpdate={ability.can(Action.Update, Page.Telemetry)}
        setValuesForSelection={setValuesForSelection}
        setObjectForColumnFilter={setObjectForColumnFilter}
        showDelete={ability.can(Action.Delete, Page.Telemetry)}
        tableButtons={tableButtons}
        extraSearchData={extraSearchData}
        isExcelColumnMapping={true}
      />
    </>
  );
};

export default forwardRef(MainTable);
