import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorLogFileState } from './errorLogFile.model';
import {
  deleteErrorLogFile,
  getErrorLogFileById,
  searchErrorLogFile,
} from './errorLogFile.action';
import { IErrorLogFile } from '../../../services/master/errorLogFile/errorLogFile.model';
import { IApiResponseBody } from '../../../common/models/common';
import { RootState } from '../../app.model';

export const initialState: IErrorLogFileState = {
  search: {
    loading: false,
    hasErrors: false,
    data: [],
    count: 0,
    lookups: {},
    tableName: '',
  },
  tableColumnSelection: {
    id: null,
    table_name: null,
    columns: {},
  },
  getById: {
    loading: false,
    hasErrors: false,
    data: null,
  },
  delete: {
    loading: false,
    hasErrors: false,
    messages: [],
  },
};

export const errorLogFileSlice = createSlice({
  name: 'errorLogFile',
  initialState,
  reducers: {
    clearErrorLogFile: () => {
      return initialState;
    },
    clearErrorLogFileMessages: (state) => {
      state.delete.messages = [];
    },
    clearErrorLogFileGetById: (state) => {
      state.getById.data = null;
    },
    setTableColumnSelection: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.tableColumnSelection.columns = action.payload;
    },
  },
  extraReducers: {
    // Search
    [searchErrorLogFile.pending.type]: (state) => {
      state.search.loading = true;
    },
    [searchErrorLogFile.fulfilled.type]: (state, action: PayloadAction<any>) => {
      const search_result = action.payload.errorLogResult;
      state.search.data = search_result.records;
      state.search.count = search_result.total_count;
      state.search.loading = false;
      state.search.hasErrors = false;
    },
    [searchErrorLogFile.rejected.type]: (state) => {
      state.search.loading = false;
      state.search.hasErrors = true;
    },

    // Get by id
    [getErrorLogFileById.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [getErrorLogFileById.fulfilled.type]: (
      state,
      action: PayloadAction<IErrorLogFile>
    ) => {
      state.getById.data = action.payload;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [getErrorLogFileById.rejected.type]: (state) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
    },

    // Delete
    [deleteErrorLogFile.pending.type]: (state) => {
      state.delete.loading = true;
      state.delete.messages = [];
    },
    [deleteErrorLogFile.fulfilled.type]: (
      state,
      action: PayloadAction<IApiResponseBody<unknown>>
    ) => {
      state.delete.loading = false;
      state.delete.hasErrors = false;
      state.delete.messages = action.payload.messages;
    },
    [deleteErrorLogFile.rejected.type]: (state) => {
      state.delete.loading = false;
      state.delete.hasErrors = true;
    },
  },
});

// A selector
export const errorLogFileSelector = (state: RootState) => state.errorLogFile;

// Actions
export const {
  clearErrorLogFile,
  clearErrorLogFileMessages,
  clearErrorLogFileGetById,
  setTableColumnSelection,
} = errorLogFileSlice.actions;

// The reducer
export default errorLogFileSlice.reducer;
