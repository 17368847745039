import { Modal } from "antd";
import { IModalInfo } from './modalI';

const ModalForInfo: React.FC<IModalInfo> = (props) => {
    const { showModal, setShowModal, data } = props;

    return (
        <>
            <Modal
                wrapClassName="custom-modal"
                title="Information"
                centered
                visible={showModal}
                onCancel={() => {setShowModal(false)}}
                footer={false}
            >
                <p>{data}</p>
            </Modal>
        </>
    )
}
export default ModalForInfo;
