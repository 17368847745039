import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiResponseBody } from '../../../common/models/common';
import { RootState } from '../../app.model';
import { IPegaDetailsState } from './pegaDetailUserData.model';
import {
  deletePegaDetails,
  getPegaDetailsById,
  savePegaDetails,
  searchPegaDetails,
} from './pegaDetailUserData.action';
import { IPegaDetails } from '../../../services/pega/pegaDetailUserData/pegaDetailUserData.model';

export const initialState: IPegaDetailsState = {
  search: {
    loading: false,
    hasErrors: false,
    data: [],
    count: 0,
    lookups: {},
    tableName: '',
  },
  tableColumnSelection: {
    id: null,
    table_name: null,
    columns: {},
  },
  getById: {
    loading: false,
    hasErrors: false,
    data: null,
  },
  save: {
    loading: false,
    hasErrors: false,
    messages: [],
  },
  delete: {
    loading: false,
    hasErrors: false,
    messages: [],
  },
};

export const pegaDetailsSlice = createSlice({
  name: 'pegaDetails',
  initialState,
  reducers: {
    clearPegaDetails: () => {
      return initialState;
    },
    clearPegaDetailsMessages: (state) => {
      state.save.messages = [];
      state.delete.messages = [];
    },
    clearPegaDetailsGetById: (state) => {
      state.getById.data = null;
    },
    setTableColumnSelection: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.tableColumnSelection.columns = action.payload;
    },
  },
  extraReducers: {
    // Search
    [searchPegaDetails.pending.type]: (state) => {
      state.search.loading = true;
    },
    [searchPegaDetails.fulfilled.type]: (state, action: PayloadAction<any>) => {
      const { search_result, ...rest } = action.payload;
      state.search.data = search_result.records;
      state.search.count = search_result.total_count;
      if (JSON.stringify(rest) !== '{}') {
        state.search.lookups = { ...rest };
      }
      state.search.loading = false;
      state.search.hasErrors = false;
      state.search.tableName = search_result.table_name;
      if (search_result.column_selection) {
        state.tableColumnSelection.id = search_result.column_selection.id;
        const tableSelectionObj = JSON.parse(search_result.column_selection.columns as any);
        if (tableSelectionObj.columns) {
          state.tableColumnSelection.column_orders = tableSelectionObj.column_orders;
          state.tableColumnSelection.columns = tableSelectionObj.columns;
        } else {
          state.tableColumnSelection.columns = tableSelectionObj;
        }
      }
      state.tableColumnSelection.table_name = search_result.table_name;
    },
    [searchPegaDetails.rejected.type]: (state) => {
      state.search.loading = false;
      state.search.hasErrors = true;
    },

    // Get by id
    [getPegaDetailsById.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [getPegaDetailsById.fulfilled.type]: (
      state,
      action: PayloadAction<IPegaDetails>
    ) => {
      state.getById.data = action.payload;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [getPegaDetailsById.rejected.type]: (state) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
    },

    // Save
    [savePegaDetails.pending.type]: (state) => {
      state.save.loading = true;
      state.save.messages = [];
    },
    [savePegaDetails.fulfilled.type]: (
      state,
      action: PayloadAction<IApiResponseBody<unknown>>
    ) => {
      state.save.loading = false;
      state.save.hasErrors = false;
      state.save.messages = action.payload.messages;
    },
    [savePegaDetails.rejected.type]: (state) => {
      state.save.loading = false;
      state.save.hasErrors = true;
    },

    // Delete
    [deletePegaDetails.pending.type]: (state) => {
      state.delete.loading = true;
      state.delete.messages = [];
    },
    [deletePegaDetails.fulfilled.type]: (
      state,
      action: PayloadAction<IApiResponseBody<unknown>>
    ) => {
      state.delete.loading = false;
      state.delete.hasErrors = false;
      state.delete.messages = action.payload.messages;
    },
    [deletePegaDetails.rejected.type]: (state) => {
      state.delete.loading = false;
      state.delete.hasErrors = true;
    },
  },
});

// A selector
export const pegaDetailsSelector = (state: RootState) => state.pegaDetails;

// Actions
export const {
  clearPegaDetails,
  clearPegaDetailsMessages,
  clearPegaDetailsGetById,
  setTableColumnSelection,
} = pegaDetailsSlice.actions;

// The reducer
export default pegaDetailsSlice.reducer;
