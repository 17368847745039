import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { IMainTable } from './mainTable.model';
import {
  FilterByBooleanDropDown,
  FilterByDropdown,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { ISearch } from '../../../../common/models/common';
import DataTable from '../../../../common/components/DataTable';
import { Action, Page } from '../../../../common/constants/pageAction';
import { Can } from '../../../../common/ability';
import {
  apiColumnMappingSelector,
  clearApiColMappingMessages,
  setTableColumnSelection,
} from '../../../../store/sps/apiColumnMapping/apiColMapping.reducer';
import {
  deleteApiColMapping,
  searchApiColMapping,
} from '../../../../store/sps/apiColumnMapping/apiColMapping.action';
import apiColMappingService from '../../../../services/sps/apiColumnMapping/apiColMapping.service';
import { ControlFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { AlignType } from 'rc-table/lib/interface';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const { setSelectedId } = props;
  const apiColMapping = useAppSelector(apiColumnMappingSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});
  //For Api Id get from URLs param
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const apiIdForConfig = queryParams.get('api_id');

  const obj = { api_id: apiIdForConfig?.toString() }

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  const exportExcelFile = (searchData: ISearch) => {
    return apiColMappingService.exportExcelFile(searchData);
  };

  // const FilterBySwap = (dataIndex: string, form,isRelativeDropDownFlag?: boolean) => {
  //   return FilterWithSwapOption(
  //     dataIndex,
  //     apiColMapping.search.tableName,
  //     form,
  //     null,
  //     ObjectForColumnFilter,
  //     null,
  //     null,
  //     isRelativeDropDownFlag
  //   );
  // };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean, isInventory?: boolean, additional_column?: string, switchFilter?: boolean) => {
    return FilterWithSwapOption(
      dataIndex,
      apiColMapping.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag,
      false,
      isInventory,
      additional_column,
      switchFilter,
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        children: [
          {
            title: FilterByDropdown(
              'tenant_id',
              apiColMapping.search?.lookups?.tenants
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        children: [
          {
            title: FilterByDropdown(
              'company_id',
              apiColMapping.search?.lookups?.companies
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        children: [
          {
            title: FilterByDropdown(
              'bu_id',
              apiColMapping.search?.lookups?.bus
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">API</span>,
        column: 'ApiId',
        sorter: true,
        children: [
          {
            title: FilterByDropdown(
              'api_id',
              apiColMapping.search?.lookups?.apis
            ),
            dataIndex: 'api_name',
            key: 'api_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SPS API Types</span>,
        column: 'SpsApiTypes',
        sorter: true,
        children: [
          {
            title: FilterByDropdown(
              'sps_api_type_id',
              apiColMapping.search?.lookups?.sps_api_types
            ),
            dataIndex: 'sps_api_type_name',
            key: 'sps_api_type_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Table Name</span>,
        column: 'TableName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('table_name', form),
            dataIndex: 'table_name',
            key: 'table_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Mapping</span>,
        column: 'Mapping',
        sorter: true,
        children: [
          {
            title: FilterBySwap('mapping', form),
            dataIndex: 'mapping',
            key: 'mapping',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Individual Mapping</span>,
        column: 'IsIndividualMapping',
        sorter: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_individual_mapping',
              apiColMapping.search.tableName,
              ObjectForColumnFilter),
            dataIndex: 'is_individual_mapping',
            key: 'is_individual_mapping',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
    ];
  };

  const deleteApiColumnMapping = (id: number) => {
    dispatch(deleteApiColMapping(id));
  };

  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.ConfigSPSColMapping}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
          }}
        >
          <ControlFilled title="Mapped Api" style={{ color: '#00274d' }} />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.ConfigSPSColMapping}>
        <Popconfirm title="Delete Record?" onConfirm={() => deleteApiColumnMapping(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={false}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={apiColumnMappingSelector}
        searchTableData={searchApiColMapping}
        clearTableDataMessages={clearApiColMappingMessages}
        setTableColumnSelection={setTableColumnSelection}
        globalSearchExist={false}
        hideExportButton={true}
        disableRowSelection={true}
        setObjectForColumnFilter={setObjectForColumnFilter}
        showDelete={false}
        isForeignLookup={true}
        isIdFiltered={true}
        extraSearchData={parseInt(apiIdForConfig) > 0 ? obj : undefined}
      />
    </>
  );
};

export default forwardRef(MainTable);
