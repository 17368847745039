import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getPegaSecurityReportsById,
  savePegaSecurityReports,
} from '../../../../store/pega/pegaSecurityReports/pegaSecurityReports.action';
import {
  clearPegaSecurityReportsGetById,
  clearPegaSecurityReportsMessages,
  pegaSecurityReportsSelector,
} from '../../../../store/pega/pegaSecurityReports/pegaSecurityReports.reducer';
import { getBULookup, getCompanyLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { IAddPegaSecurityReportsProps } from './addPegaSecurityReports.model';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate } from '../../../../common/helperFunction';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';
import { IPegaSecurityReports } from '../../../../services/pega/pegaSecurityReport/pegaSecurityReport.model';

const { Option } = Select;

const AddPegaSecurityReportsModal: React.FC<IAddPegaSecurityReportsProps> = (props) => {
  const pegaDetails = useAppSelector(pegaSecurityReportsSelector);
  const dispatch = useAppDispatch();
  const commonLookups = useAppSelector(commonSelector);
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.PegaSecurityReports} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: IPegaSecurityReports = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    date_added: getSimpleDate(),
    source: "",
    user_id: "",
    unique_id: "",
    first_name: "",
    last_name: "",
    user_type: "",
    login_name: "",
    work_location: "",
    payroll_location: "",
    pega_company: "",
    company_name: "",
    application_role: "",
    application_status: "",
    hsp_code: "",
    level_cd: "",
    org_cd: "",
    phone: "",
    email: "",
    last_login_date: "",
    department_code: "",
    department_name: "",
    job_code: "",
    job_title: "",
  };

  const onFinish = (values: any) => {
    const inputValues: IPegaSecurityReports = {
      ...values,
      id: id ? +id : null,
    };
    if (!isMultiple) {
      dispatch(savePegaSecurityReports(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        pegaDetails.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: IPegaSecurityReports) => {
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
        source: data.source,
        user_id: data.user_id,
        unique_id: data.unique_id,
        first_name: data.first_name,
        last_name: data.last_name,
        user_type: data.user_type,
        login_name: data.login_name,
        work_location: data.work_location,
        payroll_location: data.payroll_location,
        pega_company: data.pega_company,
        company_name: data.company_name,
        application_role: data.application_role,
        application_status: data.application_status,
        hsp_code: data.hsp_code,
        level_cd: data.level_cd,
        org_cd: data.org_cd,
        phone: data.phone,
        email: data.email,
        last_login_date: data.last_login_date,
        department_code: data.department_code,
        department_name: data.department_name,
        job_code: data.job_code,
        job_title: data.job_title,
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (pegaDetails.save.messages.length > 0) {
      if (pegaDetails.save.hasErrors) {
        toast.error(pegaDetails.save.messages.join(' '));
      } else {
        toast.success(pegaDetails.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearPegaSecurityReportsMessages());
    }
  }, [pegaDetails.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && pegaDetails.getById.data) {
      const data = pegaDetails.getById.data;
      fillValuesOnEdit(data);
    }
  }, [pegaDetails.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getPegaSecurityReportsById(+id));
    }
    return () => {
      dispatch(clearPegaSecurityReportsGetById());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {pegaDetails.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={pegaDetails.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="pegaDetails"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.tenantLookup.loading}
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.buLookup.loading}
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'user_id']} valuePropName="checked" noStyle>
                      <Checkbox>User ID</Checkbox>
                    </Form.Item>
                  ) : (
                    'User ID'
                  )}
                  <Form.Item
                    name="user_id"
                    label="User ID"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'unique_id']} valuePropName="checked" noStyle>
                      <Checkbox>Unique ID</Checkbox>
                    </Form.Item>
                  ) : (
                    'Unique ID'
                  )}
                  <Form.Item
                    name="unique_id"
                    label="Unique ID"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'first_name']} valuePropName="checked" noStyle>
                      <Checkbox>First Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'First Name'
                  )}
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'source']} valuePropName="checked" noStyle>
                      <Checkbox>Source</Checkbox>
                    </Form.Item>
                  ) : (
                    'Source'
                  )}
                  <Form.Item
                    name="source"
                    label="Source"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_name']} valuePropName="checked" noStyle>
                      <Checkbox>Last Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Name'
                  )}
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'user_type']} valuePropName="checked" noStyle>
                      <Checkbox>User Type</Checkbox>
                    </Form.Item>
                  ) : (
                    'User Type'
                  )}
                  <Form.Item
                    name="user_type"
                    label="User Type"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'login_name']} valuePropName="checked" noStyle>
                      <Checkbox>Login Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Login Name'
                  )}
                  <Form.Item
                    name="login_name"
                    label="Login Name"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'work_location']} valuePropName="checked" noStyle>
                      <Checkbox>Work Location</Checkbox>
                    </Form.Item>
                  ) : (
                    'Work Location'
                  )}
                  <Form.Item
                    name="work_location"
                    label="Work Location"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'payroll_location']} valuePropName="checked" noStyle>
                      <Checkbox>Payroll Location</Checkbox>
                    </Form.Item>
                  ) : (
                    'Payroll Location'
                  )}
                  <Form.Item
                    name="payroll_location"
                    label="Payroll Location"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'pega_company']} valuePropName="checked" noStyle>
                      <Checkbox>Pega Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Pega Company'
                  )}
                  <Form.Item
                    name="pega_company"
                    label="Pega Company"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'application_role']} valuePropName="checked" noStyle>
                      <Checkbox>Application Role</Checkbox>
                    </Form.Item>
                  ) : (
                    'Application Role'
                  )}
                  <Form.Item
                    name="application_role"
                    label="Application Role"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'application_status']} valuePropName="checked" noStyle>
                      <Checkbox>Application status</Checkbox>
                    </Form.Item>
                  ) : (
                    'Application status'
                  )}
                  <Form.Item
                    name="application_status"
                    label="Application status"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'hsp_code']} valuePropName="checked" noStyle>
                      <Checkbox>HSP Code</Checkbox>
                    </Form.Item>
                  ) : (
                    'HSP Code'
                  )}
                  <Form.Item
                    name="hsp_code"
                    label="HSP Code"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'level_cd']} valuePropName="checked" noStyle>
                      <Checkbox>Level CD</Checkbox>
                    </Form.Item>
                  ) : (
                    'Level CD'
                  )}
                  <Form.Item
                    name="level_cd"
                    label="Level CD"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'org_cd']} valuePropName="checked" noStyle>
                      <Checkbox>Org Cd</Checkbox>
                    </Form.Item>
                  ) : (
                    'Org Cd'
                  )}
                  <Form.Item
                    name="org_cd"
                    label="Org Cd"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'phone']} valuePropName="checked" noStyle>
                      <Checkbox>Phone</Checkbox>
                    </Form.Item>
                  ) : (
                    'Phone'
                  )}
                  <Form.Item
                    name="phone"
                    label="Phone"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'email']} valuePropName="checked" noStyle>
                      <Checkbox>Email</Checkbox>
                    </Form.Item>
                  ) : (
                    'Email'
                  )}
                  <Form.Item
                    name="email"
                    label="Email"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_login_date']} valuePropName="checked" noStyle>
                      <Checkbox>Last Login Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Login Date'
                  )}
                  <Form.Item
                    name="last_login_date"
                    label="Last Login Date"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'department_code']} valuePropName="checked" noStyle>
                      <Checkbox>Department Code</Checkbox>
                    </Form.Item>
                  ) : (
                    'Department Code'
                  )}
                  <Form.Item
                    name="department_code"
                    label="Department Code"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'department_name']} valuePropName="checked" noStyle>
                      <Checkbox>Department Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Department Name'
                  )}
                  <Form.Item
                    name="department_name"
                    label="Department Name"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'job_code']} valuePropName="checked" noStyle>
                      <Checkbox>Job Code</Checkbox>
                    </Form.Item>
                  ) : (
                    'Job Code'
                  )}
                  <Form.Item
                    name="job_code"
                    label="Job Code"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'job_title']} valuePropName="checked" noStyle>
                      <Checkbox>Job Title</Checkbox>
                    </Form.Item>
                  ) : (
                    'Job Title'
                  )}
                  <Form.Item
                    name="job_title"
                    label="Job Title"
                    className="m-0"
                    rules={[{  max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={pegaDetails.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddPegaSecurityReportsModal;
