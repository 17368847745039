import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { ISnowDCCS } from '../../../../services/snow/snowDCCS/snowDCCS.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getSnowDCCSById,
  saveSnowDCCS,
} from '../../../../store/snow/snowDCCS/snowDCCS.action';
import {
  clearSnowDCCSGetById,
  clearSnowDCCSMessages,
  snowDCCSSelector,
} from '../../../../store/snow/snowDCCS/snowDCCS.reducer';
import { getBULookup, getCompanyLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { IAddSnowDCCSProps } from './addSnowDCCS.model';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate, passDateToApi } from '../../../../common/helperFunction';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';

const { Option } = Select;

const AddSnowDCCSModal: React.FC<IAddSnowDCCSProps> = (props) => {
  const snowDCCS = useAppSelector(snowDCCSSelector);
  const dispatch = useAppDispatch();
  const commonLookups = useAppSelector(commonSelector);
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.SnowDCCS} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: ISnowDCCS = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    date_added: getSimpleDate(),
    dccs_id: null,
    name: '',
    description: '',
    organization: '',
    server_count: null,
    licensed_application_count: null,
    virtual_machine_count: null,
    inventoried_virtual_machine_count: null,
    updated_by: '',
    updated_date: getSimpleDate(),
  };

  const onFinish = (values: any) => {
    const inputValues: ISnowDCCS = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.date_added = passDateToApi(inputValues.date_added);
    inputValues.updated_date = passDateToApi(inputValues.updated_date);
    if (!isMultiple) {
      dispatch(saveSnowDCCS(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        snowDCCS.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: ISnowDCCS) => {
    if (data.tenant_id) {
      await dispatch(getCompanyLookup(data.tenant_id));
    }
    if (data.company_id) {
      await dispatch(getBULookup(data.company_id));
    }
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
        dccs_id: _.isNull(data.dccs_id) ? null : data.dccs_id,
        name: data.name,
        description: data.description,
        organization: data.organization,
        server_count: _.isNull(data.server_count) ? null : data.server_count,
        licensed_application_count: _.isNull(data.licensed_application_count) ? null : data.licensed_application_count,
        virtual_machine_count: _.isNull(data.virtual_machine_count) ? null : data.virtual_machine_count,
        inventoried_virtual_machine_count: _.isNull(data.inventoried_virtual_machine_count) ? null : data.inventoried_virtual_machine_count,
        updated_by: data.updated_by,
        updated_date: _.isNull(data.updated_date) ? null : forEditModal(data.updated_date),
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (snowDCCS.save.messages.length > 0) {
      if (snowDCCS.save.hasErrors) {
        toast.error(snowDCCS.save.messages.join(' '));
      } else {
        toast.success(snowDCCS.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearSnowDCCSMessages());
    }
  }, [snowDCCS.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && snowDCCS.getById.data) {
      const data = snowDCCS.getById.data;
      fillValuesOnEdit(data);
    }
  }, [snowDCCS.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getSnowDCCSById(+id));
    }
    return () => {
      dispatch(clearSnowDCCSGetById());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {snowDCCS.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={snowDCCS.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="snowDCCS"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.tenantLookup.loading}
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.buLookup.loading}
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'dccs_id']} valuePropName="checked" noStyle>
                      <Checkbox>DCCS Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'DCCS Id'
                  )}
                  <Form.Item
                    name="dccs_id"
                    label="DCCS Id"
                    className="m-0"
                    rules={[{ type: 'integer', required: !isMultiple }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'name']} valuePropName="checked" noStyle>
                      <Checkbox>Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Name'
                  )}
                  <Form.Item
                    name="name"
                    label="Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_date']} valuePropName="checked" noStyle>
                      <Checkbox>Updated Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated Date'
                  )}
                  <Form.Item name="updated_date" label="Updated Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'description']} valuePropName="checked" noStyle>
                      <Checkbox>Description</Checkbox>
                    </Form.Item>
                  ) : (
                    'Description'
                  )}
                  <Form.Item
                    name="description"
                    label="Description"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'organization']} valuePropName="checked" noStyle>
                      <Checkbox>Organization</Checkbox>
                    </Form.Item>
                  ) : (
                    'Organization'
                  )}
                  <Form.Item
                    name="organization"
                    label="Organization"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'server_count']} valuePropName="checked" noStyle>
                      <Checkbox>Server Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Server Count'
                  )}
                  <Form.Item
                    name="server_count"
                    label="Server Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'licensed_application_count']} valuePropName="checked" noStyle>
                      <Checkbox>Licensed Application Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Licensed Application Count'
                  )}
                  <Form.Item
                    name="licensed_application_count"
                    label="Licensed Application Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_by']} valuePropName="checked" noStyle>
                      <Checkbox>Updated By</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated By'
                  )}
                  <Form.Item
                    name="updated_by"
                    label="Updated By"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'virtual_machine_count']} valuePropName="checked" noStyle>
                      <Checkbox>Virtual Machine Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Virtual Machine Count'
                  )}
                  <Form.Item
                    name="virtual_machine_count"
                    label="Virtual Machine Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'inventoried_virtual_machine_count']} valuePropName="checked" noStyle>
                      <Checkbox>Inventoried Virtual Machine Count</Checkbox>
                    </Form.Item>
                  ) : (
                    'Inventoried Virtual Machine Count'
                  )}
                  <Form.Item
                    name="inventoried_virtual_machine_count"
                    label="Inventoried Virtual Machine Count"
                    className="m-0"
                    rules={[{ type: 'integer' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={snowDCCS.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddSnowDCCSModal;
