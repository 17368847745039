import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch } from 'antd';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { validateMessages } from '../../../../common/constants/common';
import { Page } from '../../../../common/constants/pageAction';
import { ISnowComputer } from '../../../../services/snow/snowComputer/snowComputer.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getSnowComputerById,
  saveSnowComputer,
} from '../../../../store/snow/snowComputer/snowComputer.action';
import {
  clearSnowComputerGetById,
  clearSnowComputerMessages,
  snowComputerSelector,
} from '../../../../store/snow/snowComputer/snowComputer.reducer';
import { getBULookup, getCompanyLookup, updateMultiple } from '../../../../store/common/common.action';
import {
  clearBULookUp,
  clearCompanyLookUp,
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';
import { IAddSnowComputerProps } from './addSnowComputer.model';
import { forEditModal, getObjectForUpdateMultiple, getSimpleDate, passDateToApi } from '../../../../common/helperFunction';
import _ from 'lodash';
import { ILookup } from '../../../../services/common/common.model';
import { globalSearchSelector } from '../../../../store/globalSearch/globalSearch.reducer';

const { Option } = Select;

const AddSnowComputerModal: React.FC<IAddSnowComputerProps> = (props) => {
  const snowComputer = useAppSelector(snowComputerSelector);
  const dispatch = useAppDispatch();
  const commonLookups = useAppSelector(commonSelector);
  const globalFilters = useAppSelector(globalSearchSelector);

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.SnowComputer} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: ISnowComputer = {
    tenant_id: null,
    company_id: null,
    bu_id: null,
    date_added: getSimpleDate(),
    computer_id: null,
    name: '',
    manufacturer: '',
    manufacturer_website: '',
    model: '',
    operating_system: '',
    operating_system_service_pack: '',
    organization: '',
    org_checksum: '',
    ip_addresses: '',
    last_scan_date: getSimpleDate(),
    auto_editing: false,
    datacenter_name: '',
    quarantine_management: false,
    status: '',
    quarantine_date: getSimpleDate(),
    quarantine_delete_date: getSimpleDate(),
    is_portable: false,
    is_server: false,
    is_virtual: false,
    is_64_bit: false,
    is_part_of_vdi: false,
    client_configuration_name: '',
    client_install_date: getSimpleDate(),
    client_site_name: '',
    client_version: '',
    scanner_version: '',
    domain: '',
    hypervisor_name: '',
    scan_identifier: '',
    transfer_date: getSimpleDate(),
    updated_by: '',
    updated_date: getSimpleDate(),
    notes: '',
    security_code: '',
    purchase_date: getSimpleDate(),
    purchase_vendor: '',
    purchase_price: null,
    purchase_currency: '',
    purchase_invoice_reference: '',
    custom_fields: '',
    hardware: '',
  };

  const onFinish = (values: any) => {
    const inputValues: ISnowComputer = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.date_added = passDateToApi(inputValues.date_added);
    inputValues.last_scan_date = passDateToApi(inputValues.last_scan_date);
    inputValues.quarantine_date = passDateToApi(inputValues.quarantine_date);
    inputValues.quarantine_delete_date = passDateToApi(inputValues.quarantine_delete_date);
    inputValues.client_install_date = passDateToApi(inputValues.client_install_date);
    inputValues.transfer_date = passDateToApi(inputValues.transfer_date);
    inputValues.updated_date = passDateToApi(inputValues.updated_date);
    inputValues.purchase_date = passDateToApi(inputValues.purchase_date);
    if (!isMultiple) {
      dispatch(saveSnowComputer(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        snowComputer.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: ISnowComputer) => {
    if (data.tenant_id) {
      await dispatch(getCompanyLookup(data.tenant_id));
    }
    if (data.company_id) {
      await dispatch(getBULookup(data.company_id));
    }
    if (data) {
      initialValues = {
        tenant_id: _.isNull(data.tenant_id) ? null : data.tenant_id,
        company_id: _.isNull(data.company_id) ? null : data.company_id,
        bu_id: _.isNull(data.bu_id) ? null : data.bu_id,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
        computer_id: _.isNull(data.computer_id) ? null : data.computer_id,
        name: data.name,
        manufacturer: data.manufacturer,
        manufacturer_website: data.manufacturer_website,
        model: data.model,
        operating_system: data.operating_system,
        operating_system_service_pack: data.operating_system_service_pack,
        organization: data.organization,
        org_checksum: data.org_checksum,
        ip_addresses: data.ip_addresses,
        last_scan_date: _.isNull(data.last_scan_date) ? null : forEditModal(data.last_scan_date),
        auto_editing: data.auto_editing,
        datacenter_name: data.datacenter_name,
        quarantine_management: data.quarantine_management,
        status: data.status,
        quarantine_date: _.isNull(data.quarantine_date) ? null : forEditModal(data.quarantine_date),
        quarantine_delete_date: _.isNull(data.quarantine_delete_date) ? null : forEditModal(data.quarantine_delete_date),
        is_portable: data.is_portable,
        is_server: data.is_server,
        is_virtual: data.is_virtual,
        is_64_bit: data.is_64_bit,
        is_part_of_vdi: data.is_part_of_vdi,
        client_configuration_name: data.client_configuration_name,
        client_install_date: _.isNull(data.client_install_date) ? null : forEditModal(data.client_install_date),
        client_site_name: data.client_site_name,
        client_version: data.client_version,
        scanner_version: data.scanner_version,
        domain: data.domain,
        hypervisor_name: data.hypervisor_name,
        scan_identifier: data.scan_identifier,
        transfer_date: _.isNull(data.transfer_date) ? null : forEditModal(data.transfer_date),
        updated_by: data.updated_by,
        updated_date: _.isNull(data.updated_date) ? null : forEditModal(data.updated_date),
        notes: data.notes,
        security_code: data.security_code,
        purchase_date: _.isNull(data.purchase_date) ? null : forEditModal(data.purchase_date),
        purchase_vendor: data.purchase_vendor,
        purchase_price: _.isNull(data.purchase_price) ? null : data.purchase_price,
        purchase_currency: data.purchase_currency,
        purchase_invoice_reference: data.purchase_invoice_reference,
        custom_fields: data.custom_fields,
        hardware: data.hardware,
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (snowComputer.save.messages.length > 0) {
      if (snowComputer.save.hasErrors) {
        toast.error(snowComputer.save.messages.join(' '));
      } else {
        toast.success(snowComputer.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearSnowComputerMessages());
    }
  }, [snowComputer.save.messages]);

  useEffect(() => {
    if (commonLookups.save.messages.length > 0) {
      if (commonLookups.save.hasErrors) {
        toast.error(commonLookups.save.messages.join(' '));
      } else {
        toast.warn(commonLookups.save.messages.join(' '));
        handleModalClose();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [commonLookups.save.messages]);

  useEffect(() => {
    if (+id > 0 && snowComputer.getById.data) {
      const data = snowComputer.getById.data;
      fillValuesOnEdit(data);
    }
  }, [snowComputer.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getSnowComputerById(+id));
    }
    return () => {
      dispatch(clearSnowComputerGetById());
      dispatch(clearBULookUp());
    };
  }, [dispatch]);

  const handleTenantChange = (tenantId: number) => {
    form.setFieldsValue({ tenant_id: tenantId, company_id: null, bu_id: null });
    if (tenantId) {
      dispatch(getCompanyLookup(tenantId));
      dispatch(clearBULookUp());
    } else {
      dispatch(clearCompanyLookUp());
      dispatch(clearBULookUp());
    }
  };

  const handleCompanyChange = (companyId: number) => {
    form.setFieldsValue({ company_id: companyId, bu_id: null });
    if (companyId) {
      dispatch(getBULookup(companyId));
    } else {
      dispatch(clearBULookUp());
    }
  };

  const handleBUChange = (buId: number) => {
    form.setFieldsValue({ bu_id: buId });
  };

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {snowComputer.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={snowComputer.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="snowComputer"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tenant_id']} valuePropName="checked" noStyle>
                      <Checkbox>Tenant</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tenant'
                  )}
                  <Form.Item
                    name="tenant_id"
                    className="m-0"
                    label="Tenant"
                    rules={[{ required: !isMultiple }]}
                  >
                    <Select
                      onChange={handleTenantChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.tenantLookup.loading}
                    >
                      {Object.keys(globalFilters?.globalTenantLookup?.data).length > 0
                        ? globalFilters?.globalTenantLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : commonLookups.tenantLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'company_id']} valuePropName="checked" noStyle>
                      <Checkbox>Company</Checkbox>
                    </Form.Item>
                  ) : (
                    'Company'
                  )}
                  <Form.Item name="company_id" className="m-0" label="Company">
                    <Select
                      onChange={handleCompanyChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.companyLookup.loading}
                    >
                      {Object.keys(commonLookups.companyLookup.data).length > 0
                        ? commonLookups.companyLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalCompanyLookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'bu_id']} valuePropName="checked" noStyle>
                      <Checkbox>BU</Checkbox>
                    </Form.Item>
                  ) : (
                    'BU'
                  )}
                  <Form.Item name="bu_id" className="m-0" label="BU">
                    <Select
                      onChange={handleBUChange}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.children
                          ?.toLowerCase()
                          ?.localeCompare(optionB.children?.toLowerCase())
                      }
                      loading={commonLookups.buLookup.loading}
                    >
                      {Object.keys(commonLookups.buLookup.data).length > 0
                        ? commonLookups.buLookup.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))
                        : globalFilters?.globalBULookup?.data.map((option: ILookup) => (
                          <Option key={option.id} value={option.id}>
                            {option.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'computer_id']} valuePropName="checked" noStyle>
                      <Checkbox>Computer Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Computer Id'
                  )}
                  <Form.Item
                    name="computer_id"
                    label="Computer Id"
                    className="m-0"
                    rules={[{ type: 'integer', required: !isMultiple }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'name']} valuePropName="checked" noStyle>
                      <Checkbox>Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Name'
                  )}
                  <Form.Item
                    name="name"
                    label="Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'manufacturer']} valuePropName="checked" noStyle>
                      <Checkbox>Manufacturer</Checkbox>
                    </Form.Item>
                  ) : (
                    'Manufacturer'
                  )}
                  <Form.Item
                    name="manufacturer"
                    label="Manufacturer"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'manufacturer_website']} valuePropName="checked" noStyle>
                      <Checkbox>Manufacturer Website</Checkbox>
                    </Form.Item>
                  ) : (
                    'Manufacturer Website'
                  )}
                  <Form.Item
                    name="manufacturer_website"
                    label="Manufacturer Website"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'model']} valuePropName="checked" noStyle>
                      <Checkbox>Model</Checkbox>
                    </Form.Item>
                  ) : (
                    'Model'
                  )}
                  <Form.Item
                    name="model"
                    label="Model"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'operating_system']} valuePropName="checked" noStyle>
                      <Checkbox>Operating System</Checkbox>
                    </Form.Item>
                  ) : (
                    'Operating System'
                  )}
                  <Form.Item
                    name="operating_system"
                    label="Operating System"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'operating_system_service_pack']} valuePropName="checked" noStyle>
                      <Checkbox>Operating System Service Pack</Checkbox>
                    </Form.Item>
                  ) : (
                    'Operating System Service Pack'
                  )}
                  <Form.Item
                    name="operating_system_service_pack"
                    label="Operating System Service Pack"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'organization']} valuePropName="checked" noStyle>
                      <Checkbox>Organization</Checkbox>
                    </Form.Item>
                  ) : (
                    'Organization'
                  )}
                  <Form.Item
                    name="organization"
                    label="Organization"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'org_checksum']} valuePropName="checked" noStyle>
                      <Checkbox>Org Checksum</Checkbox>
                    </Form.Item>
                  ) : (
                    'Org Checksum'
                  )}
                  <Form.Item
                    name="org_checksum"
                    label="Org Checksum"
                    className="m-0"
                    rules={[{ max: 50 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'ip_addresses']} valuePropName="checked" noStyle>
                      <Checkbox>Ip Addresses</Checkbox>
                    </Form.Item>
                  ) : (
                    'Ip Addresses'
                  )}
                  <Form.Item
                    name="ip_addresses"
                    label="Ip Addresses"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'last_scan_date']} valuePropName="checked" noStyle>
                      <Checkbox>Last Scan Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Last Scan Date'
                  )}
                  <Form.Item name="last_scan_date" label="Last Scan Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'datacenter_name']} valuePropName="checked" noStyle>
                      <Checkbox>Data Center Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Data Center Name'
                  )}
                  <Form.Item
                    name="datacenter_name"
                    label="Data Center Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'status']} valuePropName="checked" noStyle>
                      <Checkbox>Status</Checkbox>
                    </Form.Item>
                  ) : (
                    'Status'
                  )}
                  <Form.Item
                    name="status"
                    label="Status"
                    className="m-0"
                    rules={[{ max: 50 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'quarantine_date']} valuePropName="checked" noStyle>
                      <Checkbox>Quarantine Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Quarantine Date'
                  )}
                  <Form.Item name="quarantine_date" label="Quarantine Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'quarantine_delete_date']} valuePropName="checked" noStyle>
                      <Checkbox>Quarantine Delete Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Quarantine Delete Date'
                  )}
                  <Form.Item name="quarantine_delete_date" label="Quarantine Delete Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'client_configuration_name']} valuePropName="checked" noStyle>
                      <Checkbox>Client Configuration Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Client Configuration Name'
                  )}
                  <Form.Item name="client_configuration_name" label="Client Configuration Name" className="m-0" rules={[{ max: 255 }]} >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'client_install_date']} valuePropName="checked" noStyle>
                      <Checkbox>Client Install Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Client Install Date'
                  )}
                  <Form.Item name="client_install_date" label="Client Install Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'client_site_name']} valuePropName="checked" noStyle>
                      <Checkbox>Client Site Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Client Site Name'
                  )}
                  <Form.Item name="client_site_name" label="Client Site Name" className="m-0" rules={[{ max: 255 }]} >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'client_version']} valuePropName="checked" noStyle>
                      <Checkbox>Client Version</Checkbox>
                    </Form.Item>
                  ) : (
                    'Client Version'
                  )}
                  <Form.Item name="client_version" label="Client Version" className="m-0" rules={[{ max: 50 }]} >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'scanner_version']} valuePropName="checked" noStyle>
                      <Checkbox>Scanner Version</Checkbox>
                    </Form.Item>
                  ) : (
                    'Scanner Version'
                  )}
                  <Form.Item name="scanner_version" label="Scanner Version" className="m-0" rules={[{ max: 50 }]} >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'domain']} valuePropName="checked" noStyle>
                      <Checkbox>Domain</Checkbox>
                    </Form.Item>
                  ) : (
                    'Domain'
                  )}
                  <Form.Item name="domain" label="Domain" className="m-0" rules={[{ max: 255 }]} >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'hypervisor_name']} valuePropName="checked" noStyle>
                      <Checkbox>Hypervisor Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Hypervisor Name'
                  )}
                  <Form.Item name="hypervisor_name" label="Hypervisor Name" className="m-0" rules={[{ max: 255 }]} >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'scan_identifier']} valuePropName="checked" noStyle>
                      <Checkbox>Scan Identifier</Checkbox>
                    </Form.Item>
                  ) : (
                    'Scan Identifier'
                  )}
                  <Form.Item name="scan_identifier" label="Scan Identifier" className="m-0" rules={[{ max: 255 }]} >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'transfer_date']} valuePropName="checked" noStyle>
                      <Checkbox>Transfer Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Transfer Date'
                  )}
                  <Form.Item name="transfer_date" label="Transfer Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_date']} valuePropName="checked" noStyle>
                      <Checkbox>Updated Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated Date'
                  )}
                  <Form.Item name="updated_date" label="Updated Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'updated_by']} valuePropName="checked" noStyle>
                      <Checkbox>Updated By</Checkbox>
                    </Form.Item>
                  ) : (
                    'Updated By'
                  )}
                  <Form.Item
                    name="updated_by"
                    label="Updated By"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'notes']} valuePropName="checked" noStyle>
                      <Checkbox>Notes</Checkbox>
                    </Form.Item>
                  ) : (
                    'Notes'
                  )}
                  <Form.Item
                    name="notes"
                    label="Notes"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'security_code']} valuePropName="checked" noStyle>
                      <Checkbox>Security Code</Checkbox>
                    </Form.Item>
                  ) : (
                    'Security Code'
                  )}
                  <Form.Item
                    name="security_code"
                    label="Security Code"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'purchase_date']} valuePropName="checked" noStyle>
                      <Checkbox>Purchase Date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Purchase Date'
                  )}
                  <Form.Item name="purchase_date" label="Purchase Date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'purchase_vendor']} valuePropName="checked" noStyle>
                      <Checkbox>Purchase Vendor</Checkbox>
                    </Form.Item>
                  ) : (
                    'Purchase Vendor'
                  )}
                  <Form.Item
                    name="purchase_vendor"
                    label="Purchase Vendor"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'purchase_price']} valuePropName="checked" noStyle>
                      <Checkbox>Purchase Price</Checkbox>
                    </Form.Item>
                  ) : (
                    'Purchase Price'
                  )}
                  <Form.Item
                    name="purchase_price"
                    label="Purchase Price"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'purchase_currency']} valuePropName="checked" noStyle>
                      <Checkbox>Purchase Currency</Checkbox>
                    </Form.Item>
                  ) : (
                    'Purchase Currency'
                  )}
                  <Form.Item
                    name="purchase_currency"
                    label="Purchase Currency"
                    className="m-0"
                    rules={[{ max: 10 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'purchase_invoice_reference']} valuePropName="checked" noStyle>
                      <Checkbox>Purchase Invoice Reference</Checkbox>
                    </Form.Item>
                  ) : (
                    'Purchase Invoice Reference'
                  )}
                  <Form.Item
                    name="purchase_invoice_reference"
                    label="Purchase Invoice Reference"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'custom_fields']} valuePropName="checked" noStyle>
                      <Checkbox>Custom Fields</Checkbox>
                    </Form.Item>
                  ) : (
                    'Custom Fields'
                  )}
                  <Form.Item
                    name="custom_fields"
                    label="Custom Fields"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'hardware']} valuePropName="checked" noStyle>
                      <Checkbox>Hardware</Checkbox>
                    </Form.Item>
                  ) : (
                    'Hardware'
                  )}
                  <Form.Item
                    name="hardware"
                    label="Hardware"
                    className="m-0"
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="auto_editing" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'auto_editing']} valuePropName="checked" noStyle>
                      <Checkbox>Auto Editing</Checkbox>
                    </Form.Item>
                  ) : (
                    'Auto Editing'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="quarantine_management" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'quarantine_management']} valuePropName="checked" noStyle>
                      <Checkbox>Quarantine Management</Checkbox>
                    </Form.Item>
                  ) : (
                    'Quarantine Management'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_virtual" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_virtual']} valuePropName="checked" noStyle>
                      <Checkbox>Is Virtual</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Virtual'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_portable" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_portable']} valuePropName="checked" noStyle>
                      <Checkbox>Is Portable</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Portable'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_server" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_server']} valuePropName="checked" noStyle>
                      <Checkbox>Is Server</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Server'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_64_bit" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_64_bit']} valuePropName="checked" noStyle>
                      <Checkbox>Is 64 Bit</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is 64 Bit'
                  )}
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_part_of_vdi" className="m-0" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  &nbsp;
                  {isMultiple ? (
                    <Form.Item name={['checked', 'is_part_of_vdi']} valuePropName="checked" noStyle>
                      <Checkbox>Is Part Of Vdi</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Part Of Vdi'
                  )}
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={snowComputer.save.loading || commonLookups.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddSnowComputerModal;
