import React, { useRef, useState } from 'react';
import { IDetailDataTableProps } from './detailDataTable.model';
import _ from 'lodash';
import { AlignType } from 'rc-table/lib/interface';
import DataTable from '../../../../../common/components/DataTable';
import {
  FilterWithSwapOption,
  FilterByDropdown,
  FilterByBooleanDropDown,
} from '../../../../../common/components/DataTable/DataTableFilters';
import sqlServerLicenseDetailService from '../../../../../services/sqlServer/sqlServerLicenseDetail/sqlServerLicenseDetail.service';
import { useAppSelector } from '../../../../../store/app.hooks';
import { searchSqlServerLicenseDetail } from '../../../../../store/sqlServer/sqlServerLicenseDetail/sqlServerLicenseDetail.action';
import {
  setTableColumnSelection,
  sqlServerLicenseDetailSelector,
} from '../../../../../store/sqlServer/sqlServerLicenseDetail/sqlServerLicenseDetail.reducer';
import { ISearchSqlServerLicenseDetail } from '../../../../../services/sqlServer/sqlServerLicenseDetail/sqlServerLicenseDetail.model';
import { Checkbox } from 'antd';
import { showDateFromApi } from '../../../../../common/helperFunction';

const DetailDataTable: React.FC<IDetailDataTableProps> = (props) => {
  const { licenseId } = props;
  const sqlServerLicenseDetail = useAppSelector(sqlServerLicenseDetailSelector);
  const dataTableRef = useRef(null);
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  const extraSearchData = {
    sql_server_license_id: licenseId,
  };

  const exportExcelFile = (searchData: ISearchSqlServerLicenseDetail) => {
    return sqlServerLicenseDetailService.exportExcelFile(searchData);
  };

  const getColumnLookup = (data: {}) => {
    return sqlServerLicenseDetailService
      .getLicenseDetailColumnLookup(licenseId, data)
      .then((res) => {
        return res.body.data;
      });
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean, isInventory?: boolean, additional_column?: string, switchFilter?: boolean) => {
    return FilterWithSwapOption(
      dataIndex,
      sqlServerLicenseDetail.search.tableName,
      form,
      getColumnLookup,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag,
      false,
      isInventory,
      additional_column,
      switchFilter,
    );
  };

  const FilterByBoolean = (dataIndex: string) => {
    return FilterByBooleanDropDown(
      dataIndex,
      sqlServerLicenseDetail.search.tableName,
      ObjectForColumnFilter
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Sql Server Id</span>,
        column: 'SQLServerId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sql_server_id', form),
            dataIndex: 'sql_server_id',
            key: 'sql_server_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'SqlServerTenantId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'TenantId'),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'SqlServerCompanyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'CompanyId'),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">BU Name</span>,
        column: 'SqlServerBUId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'BU_Id'),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'SqlServerDateAdded',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'Date Added'),
            dataIndex: 'sql_server_license_date_added',
            key: 'sql_server_license_date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Source</span>,
        column: 'SqlServerSource',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Source', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_source',
            key: 'sql_server_source',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Agreement Type</span>,
        column: 'SqlServerLicenseAgreementType',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'Opt_AgreementType'),
            dataIndex: 'sql_server_license_agreement_type',
            key: 'sql_server_license_agreement_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Exclude Non Prod</span>,
        column: 'SqlServerLicenseExcludeNonProd',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'Opt_ExcludeNonProd'),
            dataIndex: 'sql_server_license_exclude_non_prod',
            key: 'sql_server_license_exclude_non_prod',
            ellipsis: true, render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster Logic</span>,
        column: 'SqlServerLicenseClusterLogic',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'Opt_ClusterLogic'),
            dataIndex: 'sql_server_license_cluster_logic',
            key: 'sql_server_license_cluster_logic',
            ellipsis: true, render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Default to Enterprise on Hosts</span>,
        column: 'SqlServerLicenseDefaulttoEnterprise',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'Opt_DefaultToEnterpriseOnHosts'),
            dataIndex: 'sql_server_license_default_to_enterprise',
            key: 'sql_server_license_default_to_enterprise',
            ellipsis: true, render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assign Entitlements</span>,
        column: 'SqlServerLicenseAssignEntitlements',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'Opt_Entitlements'),
            dataIndex: 'sql_server_license_assign_entitlements',
            key: 'sql_server_license_assign_entitlements',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Notes( Name )</span>,
        column: 'SqlServerLicenseNotes',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sql_server_license_id', form, true, true, 'Notes'),
            dataIndex: 'sql_server_license_notes',
            key: 'sql_server_license_notes',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Datacenter</span>,
        column: 'SqlServerDataCenter',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Datacenter', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_data_center',
            key: 'sql_server_data_center',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster</span>,
        column: 'SqlServerCluster',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Cluster', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_cluster',
            key: 'sql_server_cluster',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">HA Enabled</span>,
        column: 'SqlServerHAEnabled',
        sorter: true,
        children: [
          {
            title: FilterBySwap('HA enabled', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_ha_enabled',
            key: 'sql_server_ha_enabled',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host</span>,
        column: 'SqlServerHost',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Host', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_host',
            key: 'sql_server_host',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Procs</span>,
        column: 'SqlServerProcs',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Procs', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_procs',
            key: 'sql_server_procs',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cores</span>,
        column: 'SqlServerCores',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Cores', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_cores',
            key: 'sql_server_cores',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Device Name</span>,
        column: 'SqlServerDeviceName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Device Name', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_device_name',
            key: 'sql_server_device_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Device Type</span>,
        column: 'SqlServerDevice Type',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Device Type', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_device_type',
            key: 'sql_server_device_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Edition</span>,
        column: 'SqlServerEdition',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Edition', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_edition',
            key: 'sql_server_edition',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Version</span>,
        column: 'SqlServerVersion',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Version', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_version',
            key: 'sql_server_version',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Product Name</span>,
        column: 'SqlServerProductName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Product Name', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_product_name',
            key: 'sql_server_product_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Service</span>,
        column: 'SqlServerService',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Service', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_service',
            key: 'sql_server_service',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">V Cpu</span>,
        column: 'SqlServerVCPU',
        sorter: true,
        children: [
          {
            title: FilterBySwap('vCPU', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_vcpu',
            key: 'sql_server_vcpu',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System</span>,
        column: 'SqlServerOS',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Operating System', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_os',
            key: 'sql_server_os',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Os Type</span>,
        column: 'SqlServerOSType',
        sorter: true,
        children: [
          {
            title: FilterBySwap('OS Type', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_os_type',
            key: 'sql_server_os_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Raw Software Title</span>,
        column: 'SqlServerRawSoftwareTitle',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Raw Software Title', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_raw_software_title',
            key: 'sql_server_raw_software_title',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">FQDN</span>,
        column: 'SqlServerFQDN',
        sorter: true,
        children: [
          {
            title: FilterBySwap('FQDN', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_fqdn',
            key: 'sql_server_fqdn',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Serial Number</span>,
        column: 'SqlServerSerialNumber',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Serial Number', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_serial_num',
            key: 'sql_server_serial_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SQL Cluster</span>,
        column: 'SqlServerSQLCluster',
        sorter: true,
        children: [
          {
            title: FilterBySwap('SQL Cluster', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_sql_cluster',
            key: 'sql_server_sql_cluster',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SQL Cluster Node Type</span>,
        column: 'SqlServerSQLClusterNodeType',
        sorter: true,
        children: [
          {
            title: FilterBySwap('SQL Cluster Node Type', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_sql_cluster_node_Type',
            key: 'sql_server_sql_cluster_node_Type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Device State</span>,
        column: 'SqlServerDeviceState',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Device State', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_device_state',
            key: 'sql_server_device_state',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Software State</span>,
        column: 'SqlServerSoftwareState',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Software State', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_software_state',
            key: 'sql_server_software_state',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cost Code</span>,
        column: 'SqlServerCostCode',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Cost Code', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_cost_code',
            key: 'sql_server_cost_code',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Line Of Business</span>,
        column: 'SqlServerLineOfBusiness',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Line of Business', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_line_of_business',
            key: 'sql_server_line_of_business',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Market</span>,
        column: 'SqlServerMarket',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Market', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_market',
            key: 'sql_server_market',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Application</span>,
        column: 'SqlServerApplication',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Application', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_application',
            key: 'sql_server_application',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Azure Hosted</span>,
        column: 'SqlServerAzure Hosted',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Azure Hosted', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_azure_hosted',
            key: 'sql_server_azure_hosted',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">AWS Hosted</span>,
        column: 'SqlServerAWSHosted',
        sorter: true,
        children: [
          {
            title: FilterBySwap('AWS Hosted', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_aws_hosted',
            key: 'sql_server_aws_hosted',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cloud BYOL</span>,
        column: 'SqlServerCloudBYOL',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Cloud BYOL', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_cloud_byol',
            key: 'sql_server_cloud_byol',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Orphaned Vm</span>,
        column: 'OrphanedVM',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByDropdown(
              'orphaned_vm',
              sqlServerLicenseDetail.search.lookups?.booleanLookup
            ),
            dataIndex: 'orphaned_vm',
            key: 'orphaned_vm',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Service Id</span>,
        column: 'ServiceId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('service_id', form),
            dataIndex: 'service_id',
            key: 'service_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Version Id</span>,
        column: 'VersionId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('version_id', form),
            dataIndex: 'version_id',
            key: 'version_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Edition Id</span>,
        column: 'SqlServerEditionId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('edition_id', form),
            dataIndex: 'edition_id',
            key: 'edition_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster Max Version Id</span>,
        column: 'ClusterMaxVersionId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('cluster_max_version_id', form),
            dataIndex: 'cluster_max_version_id',
            key: 'cluster_max_version_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster Max Edition Id</span>,
        column: 'ClusterMaxEditionid',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('cluster_max_edition_id', form),
            dataIndex: 'cluster_max_edition_id',
            key: 'cluster_max_edition_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host Max Version Id</span>,
        column: 'HostMaxVersionId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('host_max_version_id', form),
            dataIndex: 'host_max_version_id',
            key: 'host_max_version_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host Max Edition Id</span>,
        column: 'HostMaxEditionId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('host_max_edition_id', form),
            dataIndex: 'host_max_edition_id',
            key: 'host_max_edition_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster Seq Num</span>,
        column: 'ClusterSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('cluster_seq_num', form),
            dataIndex: 'cluster_seq_num',
            key: 'cluster_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host Seq Num</span>,
        column: 'HostSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('host_seq_num', form),
            dataIndex: 'host_seq_num',
            key: 'host_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Device Seq Num</span>,
        column: 'DeviceSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('device_seq_num', form),
            dataIndex: 'device_seq_num',
            key: 'device_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Core Density</span>,
        column: 'Core Density',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('core_density', form),
            dataIndex: 'core_density',
            key: 'core_density',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Effective Processors</span>,
        column: 'Effective Processors',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('effective_processors', form),
            dataIndex: 'effective_processors',
            key: 'effective_processors',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Effective Cores</span>,
        column: 'Effective Cores',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('effective_cores', form),
            dataIndex: 'effective_cores',
            key: 'effective_cores',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Effective vCPU</span>,
        column: 'Effective vCPU',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('effective_vcpu', form),
            dataIndex: 'effective_vcpu',
            key: 'effective_vcpu',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Effective vCPU for Proc Licenses</span>,
        column: 'Effective vCPU for Proc Licenses',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('effective_vcpu_for_proc_licenses', form),
            dataIndex: 'effective_vcpu_for_proc_licenses',
            key: 'effective_vcpu_for_proc_licenses',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Excluded</span>,
        column: 'Excluded',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('excluded', form),
            dataIndex: 'excluded',
            key: 'excluded',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Notes</span>,
        column: 'Notes',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('notes', form),
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Licensable</span>,
        column: 'Licensable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByDropdown(
              'licensable',
              sqlServerLicenseDetail.search.lookups?.booleanLookup
            ),
            dataIndex: 'licensable',
            key: 'licensable',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Licensable Cluster Seq Num</span>,
        column: 'Licensable - ClusterSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('licensable_cluster_seq_num', form),
            dataIndex: 'licensable_cluster_seq_num',
            key: 'licensable_cluster_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Licensable Host Seq Num</span>,
        column: 'Licensable - HostSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('licensable_host_seq_num', form),
            dataIndex: 'licensable_host_seq_num',
            key: 'licensable_host_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Licensable Device Seq Num</span>,
        column: 'Licensable - DeviceSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('licensable_device_seq_num', form),
            dataIndex: 'licensable_device_seq_num',
            key: 'licensable_device_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Requires Server Mobility</span>,
        column: 'Requires Server Mobility',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBoolean('requires_server_mobility'),
            dataIndex: 'requires_server_mobility',
            key: 'requires_server_mobility',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">License At</span>,
        column: 'License At',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('licensed_at', form),
            dataIndex: 'licensed_at',
            key: 'licensed_at',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Type</span>,
        column: 'License Type',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('license_type', form),
            dataIndex: 'license_type',
            key: 'license_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Count</span>,
        column: 'License Count',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('license_count', form),
            dataIndex: 'license_count',
            key: 'license_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Cost</span>,
        column: 'License Cost',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('license_cost', form),
            dataIndex: 'license_cost',
            key: 'license_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - License Type Device</span>,
        column: 'S - License Type - Device',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_license_type_device', form),
            dataIndex: 's_license_type_device',
            key: 's_license_type_device',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - License Count Device</span>,
        column: 'S - License Count - Device',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_license_count_device', form),
            dataIndex: 's_license_count_device',
            key: 's_license_count_device',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - License Cost Device</span>,
        column: 'S - License Cost - Device',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_license_cost_device', form),
            dataIndex: 's_license_cost_device',
            key: 's_license_cost_device',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - License Cost Host Device Licensed</span>,
        column: 'S - License Cost - Host - Device Licensed',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_license_cost_host_device_licensed', form),
            dataIndex: 's_license_cost_host_device_licensed',
            key: 's_license_cost_host_device_licensed',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - License Type Host</span>,
        column: 'S - License Type - Host',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_license_type_host', form),
            dataIndex: 's_license_type_host',
            key: 's_license_type_host',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - License Count Host</span>,
        column: 'S - License Count - Host',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_license_count_host', form),
            dataIndex: 's_license_count_host',
            key: 's_license_count_host',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - License Cost Host</span>,
        column: 'S - License Cost - Host',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_license_cost_host', form),
            dataIndex: 's_license_cost_host',
            key: 's_license_cost_host',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Comment</span>,
        column: 'Comment',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('comment', form),
            dataIndex: 'comment',
            key: 'comment',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Product Name</span>,
        column: 'License Product Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('license_id', form, true),
            dataIndex: 'license_product_name',
            key: 'license_product_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Qty</span>,
        column: 'License Qty',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('license_qty', form),
            dataIndex: 'license_qty',
            key: 'license_qty',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Requires SA</span>,
        column: 'License Requires SA',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBoolean('license_requires_sa'),
            dataIndex: 'license_requires_sa',
            key: 'license_requires_sa',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assigned License Product Name</span>,
        column: 'Assigned License Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('assigned_license_id', form, true),
            dataIndex: 'assigned_license_product_name',
            key: 'assigned_license_product_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assigned License Qty</span>,
        column: 'Assigned License Qty',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('assigned_license_qty', form),
            dataIndex: 'assigned_license_qty',
            key: 'assigned_license_qty',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Install Date</span>,
        column: 'SqlServerInstallDate',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Install Date', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_install_date',
            key: 'sql_server_install_date',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Department</span>,
        column: 'SqlServerDepartment',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Department', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_department',
            key: 'sql_server_department',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Other Cloud Hosted</span>,
        column: 'SqlServerOtherCloudHosted',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Other Cloud Hosted', form, true, true, 'sql_server_id', true),
            dataIndex: 'sql_server_other_cloud_hosted',
            key: 'sql_server_other_cloud_hosted',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
    ];
  };

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={false}
        globalSearchExist={false}
        showDelete={false}
        disableRowSelection={true}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={sqlServerLicenseDetailSelector}
        searchTableData={searchSqlServerLicenseDetail}
        setTableColumnSelection={setTableColumnSelection}
        defaultOrderBy="id"
        extraSearchData={extraSearchData}
        isSpsApiJobsId={true}
        setObjectForColumnFilter={setObjectForColumnFilter}
      />
    </>
  );
};

export default DetailDataTable;
