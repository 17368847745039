import { ITelemetry, ISearchTelemetry } from '../../../services/master/telemetry/telemetry.model';
import { createAsyncThunk } from '@reduxjs/toolkit';
import telemetryService from '../../../services/master/telemetry/telemetry.service';

// Asynchronous thunk action

export const searchTelemetry = createAsyncThunk(
  'searchTelemetry',
  async (searchParam?: ISearchTelemetry) => {
    const response = await telemetryService.searchTelemetry(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getTelemetryById = createAsyncThunk('getTelemetryById', async (id: number) => {
  const response = await telemetryService.getTelemetryById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveTelemetry = createAsyncThunk('saveTelemetry', async (data: ITelemetry) => {
  const response = await telemetryService.saveTelemetry(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteTelemetry = createAsyncThunk('deleteTelemetry', async (id: number) => {
  const response = await telemetryService.deleteTelemetry(id).then((res) => {
    return res.body;
  });
  return response;
});
