import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowApplication,
  ISearchSnowApplication,
} from '../../../services/snow/snowApplication/snowApplication.model';
import snowApplicationService from '../../../services/snow/snowApplication/snowApplication.service';

// Asynchronous thunk action

export const searchSnowApplication = createAsyncThunk(
  'searchSnowApplication',
  async (searchParam?: ISearchSnowApplication) => {
    const response = await snowApplicationService
      .searchSnowApplication(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowApplicationById = createAsyncThunk(
  'getSnowApplicationById',
  async (id: number) => {
    const response = await snowApplicationService.getSnowApplicationById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowApplication = createAsyncThunk(
  'saveSnowApplication',
  async (data: ISnowApplication) => {
    const response = await snowApplicationService.saveSnowApplication(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const processDataSnow = createAsyncThunk(
  'processDataSnow',
  async (data: ISnowApplication) => {
    const response = await snowApplicationService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowApplication = createAsyncThunk(
  'deleteSnowApplication',
  async (id: number) => {
    const response = await snowApplicationService.deleteSnowApplication(id).then((res) => {
      return res.body;
    });
    return response;
  }
);


