import { Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import {
  setTableColumnSelection,
  clearCmdbSamSWInstallMessages,
  CmdbSamSWInstallSelector,
} from '../../../../store/serviceNow/cmdbSamSWInstall/cmdbSamSWInstall.reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { deleteCmdbSamSWInstall, searchCmdbSamSWInstall } from '../../../../store/serviceNow/cmdbSamSWInstall/cmdbSamSWInstall.action';
import _ from 'lodash';
import CmdbSamSWInstallService from '../../../../services/serviceNow/cmdbSamSWInstall/cmdbSamSWInstall.service';
import {
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { showDateFromApi } from '../../../../common/helperFunction';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    setFilterKeys,
    tableButtons,
  } = props;
  const CmdbSamSWInstall = useAppSelector(CmdbSamSWInstallSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return CmdbSamSWInstallService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, columnWidthOpt?: boolean, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      CmdbSamSWInstall.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      columnWidthOpt === undefined ? false : columnWidthOpt,
      isRelativeDropDownFlag
    );
  };

  const FilterByDateSwapTable = (dataIndex: string, tableName: string, form: any) => {
    return FilterByDateSwap(dataIndex, tableName, form, null, ObjectForColumnFilter);
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form, true),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              false,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              false,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              false,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwapTable('date_added', CmdbSamSWInstall.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Display Name</span>,
        column: 'display_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('display_name', form),
            dataIndex: 'display_name',
            key: 'display_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Publisher</span>,
        column: 'publisher',
        sorter: true,
        children: [
          {
            title: FilterBySwap('publisher', form),
            dataIndex: 'publisher',
            key: 'publisher',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Version</span>,
        column: 'version',
        sorter: true,
        children: [
          {
            title: FilterBySwap('version', form),
            dataIndex: 'version',
            key: 'version',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Discovery Model</span>,
        column: 'discovery_model',
        sorter: true,
        children: [
          {
            title: FilterBySwap('discovery_model', form),
            dataIndex: 'discovery_model',
            key: 'discovery_model',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Installed On</span>,
        column: 'installed_on',
        sorter: true,
        children: [
          {
            title: FilterBySwap('installed_on', form),
            dataIndex: 'installed_on',
            key: 'installed_on',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Updated On</span>,
        column: 'sys_updated_on',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_updated_on', form),
            dataIndex: 'sys_updated_on',
            key: 'sys_updated_on',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Norm Product</span>,
        column: 'norm_product',
        sorter: true,
        children: [
          {
            title: FilterBySwap('norm_product', form),
            dataIndex: 'norm_product',
            key: 'norm_product',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Norm Publisher</span>,
        column: 'norm_publisher',
        sorter: true,
        children: [
          {
            title: FilterBySwap('norm_publisher', form),
            dataIndex: 'norm_publisher',
            key: 'norm_publisher',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Normalized Display Name</span>,
        column: 'normalized_display_name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('normalized_display_name', form),
            dataIndex: 'normalized_display_name',
            key: 'normalized_display_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Entitlement</span>,
        column: 'entitlement',
        sorter: true,
        children: [
          {
            title: FilterBySwap('entitlement', form),
            dataIndex: 'entitlement',
            key: 'entitlement',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Metric result</span>,
        column: 'license_metric_result',
        sorter: true,
        children: [
          {
            title: FilterBySwap('license_metric_result', form),
            dataIndex: 'license_metric_result',
            key: 'license_metric_result',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor Mapping</span>,
        column: 'processor_mapping',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor_mapping', form),
            dataIndex: 'processor_mapping',
            key: 'processor_mapping',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Software Model Result</span>,
        column: 'software_model_result',
        sorter: true,
        children: [
          {
            title: FilterBySwap('software_model_result', form),
            dataIndex: 'software_model_result',
            key: 'software_model_result',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Discovery Source</span>,
        column: 'discovery_source',
        sorter: true,
        children: [
          {
            title: FilterBySwap('discovery_source', form),
            dataIndex: 'discovery_source',
            key: 'discovery_source',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assigned To</span>,
        column: 'assigned_to',
        sorter: true,
        children: [
          {
            title: FilterBySwap('assigned_to', form),
            dataIndex: 'assigned_to',
            key: 'assigned_to',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Domain</span>,
        column: 'sys_domain',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sys_domain', form),
            dataIndex: 'sys_domain',
            key: 'sys_domain',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Edition</span>,
        column: 'edition',
        sorter: true,
        children: [
          {
            title: FilterBySwap('edition', form),
            dataIndex: 'edition',
            key: 'edition',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Scanned</span>,
        column: 'last_scanned',
        sorter: true,
        children: [
          {
            title: FilterBySwap('last_scanned', form),
            dataIndex: 'last_scanned',
            key: 'last_scanned',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Normalized Version</span>,
        column: 'normalized_version',
        sorter: true,
        children: [
          {
            title: FilterBySwap('normalized_version', form),
            dataIndex: 'normalized_version',
            key: 'normalized_version',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Install Date</span>,
        column: 'Install Date',
        sorter: true,
        children: [
          {
            title: FilterBySwap('install_date', form),
            dataIndex: 'install_date',
            key: 'install_date',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeCmdbSamSWInstall = (id: number) => {
    dispatch(deleteCmdbSamSWInstall(id));
  };

  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.CmdbSamSWInstall}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/service-now/samswinstall/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.CmdbSamSWInstall}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeCmdbSamSWInstall(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.CmdbSamSWInstall)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={CmdbSamSWInstallSelector}
        searchTableData={searchCmdbSamSWInstall}
        clearTableDataMessages={clearCmdbSamSWInstallMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.CmdbSamSWInstall)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
