import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISearchHyperV,
  IHyperV,
} from '../../../services/rvTools/hyperV/hyperV.model';
import hyperVService from '../../../services/rvTools/hyperV/hyperV.service';

// Asynchronous thunk action

export const searchHyperV = createAsyncThunk(
  'searchHyperV',
  async (searchParam?: ISearchHyperV) => {
    const response = await hyperVService.searchHyperV(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getHyperVById = createAsyncThunk('getHyperVById', async (id: number) => {
  const response = await hyperVService.getHyperVById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveHyperV = createAsyncThunk('saveHyperV', async (data: IHyperV) => {
  const response = await hyperVService.saveHyperV(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteHyperV = createAsyncThunk('deleteHyperV', async (id: number) => {
  const response = await hyperVService.deleteHyperV(id).then((res) => {
    return res.body;
  });
  return response;
});
