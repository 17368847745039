import ReactEcharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/app.hooks';
import { commonSelector } from '../../store/common/common.reducer';
import { IBarGraph } from './barGraph.model';

export const BarGraph: React.FC<IBarGraph> = (props) => {
    const { isSpsJobApi } = props;
    const commonLookups = useAppSelector(commonSelector);
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (commonLookups.getGraphDetails.data.length > 0 || commonLookups.getSPSSchedulerGraphDetails.data.length > 0) {
            const xaxis = [];
            const yaxis = [];
            if (isSpsJobApi) {
                commonLookups.getGraphDetails.data.map((x) => {
                    xaxis.push(x.Data);
                    yaxis.push(x.TotalCount);
                });
            } else {
                commonLookups.getSPSSchedulerGraphDetails.data.map((x) => {
                    xaxis.push(x.Data);
                    yaxis.push(x.TotalCount);
                });
            }

            const option = {
                xAxis: {
                    type: 'category',
                    data: xaxis,
                    axisLabel: { interval: 0, rotate: 30 },
                },
                yAxis: {
                    type: 'value'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                // legend: {
                //     data: xaxis,
                // },
                series: [
                    {
                        data: yaxis,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                ]
            };
            setOptions(option)
        }
    }, [commonLookups.getGraphDetails.data]);

    return (
        <>
            <div className="sqlServer">
                <ReactEcharts option={options} />
            </div>
        </>
    )
};
