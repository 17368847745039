import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearPegaDetailsMessages,
  pegaDetailsSelector,
  setTableColumnSelection,
} from '../../../../store/pega/pegaDetailUserData/pegaDetailUserData.reducer';
import {
  deletePegaDetails,
  searchPegaDetails,
} from '../../../../store/pega/pegaDetailUserData/pegaDetailUserData.action';
import pegaDetailsService from '../../../../services/pega/pegaDetailUserData/pegaDetailUserData.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import { AlignType } from 'rc-table/lib/interface';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const pegaDetails = useAppSelector(pegaDetailsSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return pegaDetailsService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      pegaDetails.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', pegaDetails.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Source</span>,
        column: 'source',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'source',
              form
            ),
            dataIndex: 'source',
            key: 'source',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">User ID</span>,
        column: 'User_ID',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'user_id',
              form
            ),
            dataIndex: 'user_id',
            key: 'user_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">User Name</span>,
        column: 'User_Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'user_name',
              form
            ),
            dataIndex: 'user_name',
            key: 'user_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Work Location</span>,
        column: 'Work Location',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'work_location',
              form
            ),
            dataIndex: 'work_location',
            key: 'work_location',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Work Location Name</span>,
        column: 'Work Location Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('work_location_name', form),
            dataIndex: 'work_location_name',
            key: 'work_location_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Employee Is</span>,
        column: 'Employee Is',
        sorter: true,
        children: [
          {
            title: FilterBySwap('employee_is', form),
            dataIndex: 'employee_is',
            key: 'employee_is',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Job Code</span>,
        column: 'Job Code',
        sorter: true,
        children: [
          {
            title: FilterBySwap('job_code', form),
            dataIndex: 'job_code',
            key: 'job_code',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Title</span>,
        column: 'Title',
        sorter: true,
        children: [
          {
            title: FilterBySwap('title', form),
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">User Type</span>,
        column: 'User Type',
        sorter: true,
        children: [
          {
            title: FilterBySwap('user_type', form),
            dataIndex: 'user_type',
            key: 'user_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">May Check Out Rules Count</span>,
        column: 'May Check Out Rules Count',
        sorter: true,
        children: [
          {
            title: FilterBySwap('may_check_out_rules_count', form),
            dataIndex: 'may_check_out_rules_count',
            key: 'may_check_out_rules_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Max Daily Usage Hours for Month</span>,
        column: 'Max Daily Usage Hours for Month',
        sorter: true,
        children: [
          {
            title: FilterBySwap('max_daily_usage_hours_for_month', form),
            dataIndex: 'max_daily_usage_hours_for_month',
            key: 'max_daily_usage_hours_for_month',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Total Monthly Invocation Count</span>,
        column: 'Total Monthly Invocation Count',
        sorter: true,
        children: [
          {
            title: FilterBySwap('total_monthly_invocation_count', form),
            dataIndex: 'total_monthly_invocation_count',
            key: 'total_monthly_invocation_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Total Monthly Invocation Count</span>,
        column: 'Total Monthly Invocation Count',
        sorter: true,
        children: [
          {
            title: FilterBySwap('total_monthly_invocation_count', form),
            dataIndex: 'total_monthly_invocation_count',
            key: 'total_monthly_invocation_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Charges Billed Separately</span>,
        column: 'Charges Billed Separately',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_compliance_central_user',
              pegaDetails.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_compliance_central_user',
            key: 'is_compliance_central_user',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
    ];
  };

  const removeCmsCategoryExtended = (id: number) => {
    dispatch(deletePegaDetails(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.CmsCategoryExtended}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/pega/pega-detail-user-data/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.CmsCategoryExtended}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeCmsCategoryExtended(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.CmsCategoryExtended)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={pegaDetailsSelector}
        searchTableData={searchPegaDetails}
        clearTableDataMessages={clearPegaDetailsMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.CmsCategoryExtended)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
