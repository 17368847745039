import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowHost,
  ISearchSnowHost,
} from '../../../services/snow/snowHost/snowHost.model';
import snowHostService from '../../../services/snow/snowHost/snowHost.service';

// Asynchronous thunk action

export const searchSnowHost = createAsyncThunk(
  'searchSnowHost',
  async (searchParam?: ISearchSnowHost) => {
    const response = await snowHostService
      .searchSnowHost(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowHostById = createAsyncThunk(
  'getSnowHostById',
  async (id: number) => {
    const response = await snowHostService.getSnowHostById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowHost = createAsyncThunk(
  'saveSnowHost',
  async (data: ISnowHost) => {
    const response = await snowHostService.saveSnowHost(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowHost = createAsyncThunk(
  'deleteSnowHost',
  async (id: number) => {
    const response = await snowHostService.deleteSnowHost(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
