import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigSoftwareVersion,
  ISearchConfigSoftwareVersion,
} from '../../../services/master/configSoftwareVersion/configSoftwareVersion.model';
import configSoftwareVersion from '../../../services/master/configSoftwareVersion/configSoftwareVersion.service';
// Asynchronous thunk action

export const searchConfigSoftwareVersion = createAsyncThunk(
  'searchConfigSoftwareVersion',
  async (searchParam?: ISearchConfigSoftwareVersion) => {
    const response = await configSoftwareVersion.searchConfigSoftwareVersion(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigSoftwareVersionById = createAsyncThunk(
  'getConfigSoftwareVersionById',
  async (id: number) => {
    const response = await configSoftwareVersion.getConfigSoftwareVersionById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigSoftwareVersion = createAsyncThunk(
  'saveConfigSoftwareVersion',
  async (data: IConfigSoftwareVersion) => {
    const response = await configSoftwareVersion.saveConfigSoftwareVersion(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigSoftwareVersion = createAsyncThunk(
  'deleteConfigSoftwareVersion',
  async (id: number) => {
    const response = await configSoftwareVersion.deleteConfigSoftwareVersion(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
