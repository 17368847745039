import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearSnowApplicationMessages,
  snowApplicationSelector,
  setTableColumnSelection,
} from '../../../../store/snow/snowApplication/snowApplication.reducer';
import {
  deleteSnowApplication,
  searchSnowApplication,
} from '../../../../store/snow/snowApplication/snowApplication.action';
import snowApplicationService from '../../../../services/snow/snowApplication/snowApplication.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import { AlignType } from 'rc-table/lib/interface';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const snowApplication = useAppSelector(snowApplicationSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return snowApplicationService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      snowApplication.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', snowApplication.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Application Id</span>,
        column: 'ApplicationId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'application_id',
              form
            ),
            dataIndex: 'application_id',
            key: 'application_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'name',
              form
            ),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer Id</span>,
        column: 'ManufacturerId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer_id',
              form
            ),
            dataIndex: 'manufacturer_id',
            key: 'manufacturer_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">ManufacturerName</span>,
        column: 'ManufacturerName',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer_name',
              form
            ),
            dataIndex: 'manufacturer_name',
            key: 'manufacturer_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">ManufacturerWebsite</span>,
        column: 'ManufacturerWebsite',
        sorter: true,
        children: [
          {
            title: FilterBySwap('manufacturer_website', form),
            dataIndex: 'manufacturer_website',
            key: 'manufacturer_website',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Language Name</span>,
        column: 'LanguageName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('language_name', form),
            dataIndex: 'language_name',
            key: 'language_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Release Date</span>,
        column: 'ReleaseDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('release_date', snowApplication.search.tableName,
              form,
              null,
              ObjectForColumnFilter,
              ),
            dataIndex: 'release_date',
            key: 'release_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Created Date</span>,
        column: 'CreatedDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('created_date', snowApplication.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'created_date',
            key: 'created_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Created By</span>,
        column: 'CreatedBy',
        sorter: true,
        children: [
          {
            title: FilterBySwap('created_by', form),
            dataIndex: 'created_by',
            key: 'created_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Updated Date</span>,
        column: 'UpdatedDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('updated_date', snowApplication.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'updated_date',
            key: 'updated_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Updated By</span>,
        column: 'UpdatedBy',
        sorter: true,
        children: [
          {
            title: FilterBySwap('updated_by', form),
            dataIndex: 'updated_by',
            key: 'updated_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Description</span>,
        column: 'Description',
        sorter: true,
        children: [
          {
            title: FilterBySwap('description', form),
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Owner Name</span>,
        column: 'System Owner Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('system_owner_name', form),
            dataIndex: 'system_owner_name',
            key: 'system_owner_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Owner Phone</span>,
        column: 'System Owner Phone',
        sorter: true,
        children: [
          {
            title: FilterBySwap('system_owner_phone', form),
            dataIndex: 'system_owner_phone',
            key: 'system_owner_phone',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">System Owner Email</span>,
        column: 'System Owner Email',
        sorter: true,
        children: [
          {
            title: FilterBySwap('system_owner_email', form),
            dataIndex: 'system_owner_email',
            key: 'system_owner_email',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Media</span>,
        column: 'Media',
        sorter: true,
        children: [
          {
            title: FilterBySwap('media', form),
            dataIndex: 'media',
            key: 'media',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Operating System</span>,
        column: 'IsOperatingSystem',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_operating_system',
              snowApplication.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_operating_system',
            key: 'is_operating_system',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System Type</span>,
        column: 'OperatingSystemType',
        sorter: true,
        children: [
          {
            title: FilterBySwap('operating_system_type', form),
            dataIndex: 'operating_system_type',
            key: 'operating_system_type',
            ellipsis: true,
          },
        ],
      },
      
      {
        title: <span className="dragHandler">Application Types</span>,
        column: 'ApplicationTypes',
        sorter: true,
        children: [
          {
            title: FilterBySwap('application_types', form),
            dataIndex: 'application_types',
            key: 'application_types',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">UpgradeOptions</span>,
        column: 'UpgradeOptions',
        sorter: true,
        children: [
          {
            title: FilterBySwap('upgrade_options', form),
            dataIndex: 'upgrade_options',
            key: 'upgrade_options',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">DowngradeOptions</span>,
        column: 'DowngradeOptions',
        sorter: true,
        children: [
          {
            title: FilterBySwap('downgrade_options', form),
            dataIndex: 'downgrade_options',
            key: 'downgrade_options',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">TotalCoverage</span>,
        column: 'TotalCoverage',
        sorter: true,
        children: [
          {
            title: FilterBySwap('total_coverage', form),
            dataIndex: 'total_coverage',
            key: 'total_coverage',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">LicenseRequirement</span>,
        column: 'LicenseRequirement',
        sorter: true,
        children: [
          {
            title: FilterBySwap('license_requirement', form),
            dataIndex: 'license_requirement',
            key: 'license_requirement',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">LicenseCount</span>,
        column: 'LicenseCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('license_count', form),
            dataIndex: 'license_count',
            key: 'license_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">InstallationCount</span>,
        column: 'InstallationCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('installation_count', form),
            dataIndex: 'installation_count',
            key: 'installation_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">BundleInstallationCount</span>,
        column: 'BundleInstallationCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('bundle_installation_count', form),
            dataIndex: 'bundle_installation_count',
            key: 'bundle_installation_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Unused Installation Count</span>,
        column: 'UnusedInstallationCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('unused_installation_count', form),
            dataIndex: 'unused_installation_count',
            key: 'unused_installation_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Usage Factor</span>,
        column: 'UsageFactor',
        sorter: true,
        children: [
          {
            title: FilterBySwap('usage_factor', form),
            dataIndex: 'usage_factor',
            key: 'usage_factor',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">User Count</span>,
        column: 'UserCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('user_count', form),
            dataIndex: 'user_count',
            key: 'user_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Risk</span>,
        column: 'Risk',
        sorter: true,
        children: [
          {
            title: FilterBySwap('risk', form),
            dataIndex: 'risk',
            key: 'risk',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Risk Unused</span>,
        column: 'RiskUnused',
        sorter: true,
        children: [
          {
            title: FilterBySwap('risk_unused', form),
            dataIndex: 'risk_unused',
            key: 'risk_unused',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Compliance</span>,
        column: 'Compliance',
        sorter: true,
        children: [
          {
            title: FilterBySwap('compliance', form),
            dataIndex: 'compliance',
            key: 'compliance',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Allocation</span>,
        column: 'Allocation',
        sorter: true,
        children: [
          {
            title: FilterBySwap('allocation', form),
            dataIndex: 'allocation',
            key: 'allocation',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Keys</span>,
        column: 'LicenseKeys',
        sorter: true,
        children: [
          {
            title: FilterBySwap('license_keys', form),
            dataIndex: 'license_keys',
            key: 'license_keys',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Metric</span>,
        column: 'Metric',
        sorter: true,
        children: [
          {
            title: FilterBySwap('metric', form),
            dataIndex: 'metric',
            key: 'metric',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Application Cost Total</span>,
        column: 'ApplicationCostTotal',
        sorter: true,
        children: [
          {
            title: FilterBySwap('application_cost_total', form),
            dataIndex: 'application_cost_total',
            key: 'application_cost_total',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Application Cost Per License</span>,
        column: 'ApplicationCostPerLicense',
        sorter: true,
        children: [
          {
            title: FilterBySwap('application_cost_per_license', form),
            dataIndex: 'application_cost_per_license',
            key: 'application_cost_per_license',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Average Cost Per License</span>,
        column: 'AverageCostPerLicense',
        sorter: true,
        children: [
          {
            title: FilterBySwap('average_cost_per_license', form),
            dataIndex: 'average_cost_per_license',
            key: 'average_cost_per_license',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Cost Total</span>,
        column: 'LicenseCostTotal',
        sorter: true,
        children: [
          {
            title: FilterBySwap('license_cost_total', form),
            dataIndex: 'license_cost_total',
            key: 'license_cost_total',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">LicenseCostCurrency</span>,
        column: 'LicenseCostCurrency',
        sorter: true,
        children: [
          {
            title: FilterBySwap('license_cost_currency', form),
            dataIndex: 'license_cost_currency',
            key: 'license_cost_currency',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">User License Cost</span>,
        column: 'UserLicenseCost',
        sorter: true,
        children: [
          {
            title: FilterBySwap('user_license_cost', form),
            dataIndex: 'user_license_cost',
            key: 'user_license_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Alert On Overlicensing</span>,
        column: 'AlertOnOverlicensing',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'alert_on_overlicensing',
              snowApplication.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'alert_on_overlicensing',
            key: 'alert_on_overlicensing',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Alert On Underlicensing</span>,
        column: 'AlertOnUnderlicensing',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'alert_on_underlicensing',
              snowApplication.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'alert_on_underlicensing',
            key: 'alert_on_underlicensing',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Alert When Not Used</span>,
        column: 'AlertWhenNotUsed',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'alert_when_not_used',
              snowApplication.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'alert_when_not_used',
            key: 'alert_when_not_used',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Custom Values</span>,
        column: 'CustomValues',
        sorter: true,
        children: [
          {
            title: FilterBySwap('custom_values', form),
            dataIndex: 'custom_values',
            key: 'custom_values',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Secondary Use Allowed</span>,
        column: 'SecondaryUseAllowed',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'secondary_use_allowed',
              snowApplication.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'secondary_use_allowed',
            key: 'secondary_use_allowed',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Required</span>,
        column: 'LicenseRequired',
        sorter: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'license_required',
              snowApplication.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'license_required',
            key: 'license_required',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Multiple Versions Allowed</span>,
        column: 'MultipleVersionsAllowed',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'multiple_versions_allowed',
              snowApplication.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'multiple_versions_allowed',
            key: 'multiple_versions_allowed',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Multiple Editions Allowed</span>,
        column: 'MultipleEditionsAllowed',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'multiple_editions_allowed',
              snowApplication.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'multiple_editions_allowed',
            key: 'multiple_editions_allowed',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
    ];
  };

  const removeSnowApplication = (id: number) => {
    dispatch(deleteSnowApplication(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.SnowApplication}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/snow/snow-application/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.SnowApplication}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeSnowApplication(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.SnowApplication)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={snowApplicationSelector}
        searchTableData={searchSnowApplication}
        clearTableDataMessages={clearSnowApplicationMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.SnowApplication)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
