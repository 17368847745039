import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigSoftwareMetric,
  ISearchConfigSoftwareMetric,
} from '../../../services/master/configSoftwareMetric/configSoftwareMetric.model';
import configSoftwareMetric from '../../../services/master/configSoftwareMetric/configSoftwareMetric.service';
// Asynchronous thunk action

export const searchConfigSoftwareMetric = createAsyncThunk(
  'searchConfigSoftwareMetric',
  async (searchParam?: ISearchConfigSoftwareMetric) => {
    const response = await configSoftwareMetric.searchConfigSoftwareMetric(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigSoftwareMetricById = createAsyncThunk(
  'getConfigSoftwareMetricById',
  async (id: number) => {
    const response = await configSoftwareMetric.getConfigSoftwareMetricById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigSoftwareMetric = createAsyncThunk(
  'saveConfigSoftwareMetric',
  async (data: IConfigSoftwareMetric) => {
    const response = await configSoftwareMetric.saveConfigSoftwareMetric(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigSoftwareMetric = createAsyncThunk(
  'deleteConfigSoftwareMetric',
  async (id: number) => {
    const response = await configSoftwareMetric.deleteConfigSoftwareMetric(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
