import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigAzureApp,
  ISearchConfigAzureApp,
} from '../../../services/master/configAzureApp/configAzureApp.model';
import ConfigAzureApp from '../../../services/master/configAzureApp/configAzureApp.service';
// Asynchronous thunk action

export const searchConfigAzureApp = createAsyncThunk(
  'searchConfigAzureApp',
  async (searchParam?: ISearchConfigAzureApp) => {
    const response = await ConfigAzureApp.searchConfigAzureApp(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigAzureAppById = createAsyncThunk(
  'getConfigAzureAppById',
  async (id: number) => {
    const response = await ConfigAzureApp.getConfigAzureAppById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigAzureApp = createAsyncThunk(
  'saveConfigAzureApp',
  async (data: IConfigAzureApp) => {
    const response = await ConfigAzureApp.saveConfigAzureApp(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigAzureApp = createAsyncThunk(
  'deleteConfigAzureApp',
  async (id: number) => {
    const response = await ConfigAzureApp.deleteConfigAzureApp(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
