import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigSoftwareProduct,
  ISearchConfigSoftwareProduct,
} from '../../../services/master/configSoftwareProduct/configSoftwareProduct.model';
import configSoftwareProduct from '../../../services/master/configSoftwareProduct/configSoftwareProduct.service';
// Asynchronous thunk action

export const searchConfigSoftwareProduct = createAsyncThunk(
  'searchConfigSoftwareProduct',
  async (searchParam?: ISearchConfigSoftwareProduct) => {
    const response = await configSoftwareProduct.searchConfigSoftwareProduct(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigSoftwareProductById = createAsyncThunk(
  'getConfigSoftwareProductById',
  async (id: number) => {
    const response = await configSoftwareProduct.getConfigSoftwareProductById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigSoftwareProduct = createAsyncThunk(
  'saveConfigSoftwareProduct',
  async (data: IConfigSoftwareProduct) => {
    const response = await configSoftwareProduct.saveConfigSoftwareProduct(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigSoftwareProduct = createAsyncThunk(
  'deleteConfigSoftwareProduct',
  async (id: number) => {
    const response = await configSoftwareProduct.deleteConfigSoftwareProduct(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
