export enum Page {
  SqlServerInventory = 'sql-server-inventory',
  SqlServerEntitlement = 'sql-server-entitlements',
  SqlServerOverrides = 'sql-server-overrides',
  SqlServerPricing = 'sql-server-pricing',
  SqlServerExclusions = 'sql-server-exclusions',
  SqlServerLicense = 'sql-server-license',
  SqlServerLicenseDetail = 'sql-server-license-detail',

  WindowsServerInventory = 'windows-server-inventory',
  WindowsServerEntitlement = 'windows-server-entitlements',
  WindowsServerOverrides = 'windows-server-overrides',
  WindowsServerPricing = 'windows-server-pricing',
  WindowsServerExclusions = 'windows-server-exclusions',
  WindowsServerLicense = 'windows-server-license',
  WindowsServerLicenseDetail = 'windows-server-license-detail',

  ADDevices = 'ad-devices',
  ADExclusions = 'ad-devices-exclusions',
  ADUsers = 'ad-users',
  AdUsersExclusions = 'ad-users-exclusions',
  AzurePrices = "azure-prices",

  Menu = 'menu',
  Tenant = 'tenant',
  Company = 'company',
  Bu = 'bu',
  Currency = 'currency',
  RoleMenuRights = 'role-menu-access-right',
  CompanyMenuRights = 'company-menu-access-right',
  GlobalTableColumnSelection = 'global-table-column-selection',
  MenuAccessRights = 'menu-access-rights',
  User = 'user',
  Role = 'role',
  Application = 'application',

  TabVCluster = 'tab-v-cluster',
  TabVHost = 'tab-v-host',
  TabVInfo = 'tab-v-info',
  TabVLicense = 'v-license',

  AzureDailyUsage = 'azure-daily-usage',
  AzureRateCard = 'azure-rate-card',
  AzureAPIVmSizes = 'azure-api-vm-sizes',

  BulkImport = 'bulk-import',
  BulkImportV2 = 'bulk-import-v2',
  DeleteData = 'delete-data-set',
  ConfigExcelFileMapping = 'config-excel-file-mapping',
  Telemetry = "telemetry",
  ErrorLogFile = "error-log-file",

  O365SubscribedSkus = 'o365-subscribed-skus',
  O365ActivationsUserDetail = 'o365-activations-user-detail',
  O365ActiveUserDetail = 'o365-active-user-detail',
  O365M365AppsUsageUserDetail = 'o365-m365-apps-usage-user-detail',
  O365MailboxUsage = 'o365-mailbox-usage',
  O365OneDriveUsage = 'o365-one-drive-usage',
  O365ProductList = 'o365-product-list',
  O365Reservations = 'o365-reservations',
  O365Users = 'o365-users',
  O365Subscriptions = 'o365-subscriptions',

  HwCiscoSiteMatrix = 'hw-cisco-cisco-site-matrix',
  HwCiscoHost = 'hw-cisco-host',
  HwCiscoIB = 'hw-cisco-ib',
  HwCiscoPolicy = 'hw-cisco-policy',
  HwCiscoProduct = 'hw-cisco-product',
  HwCiscoProductAttributes = 'hw-cisco-product-attributes',
  HwCiscoReady = 'hw-cisco-ready',
  HwCiscoSNTC = 'hw-cisco-sntc',
  HwCiscoSpectrum = 'hw-cisco-spectrum',

  CmsCategory = 'cms-category',
  CmsContact = 'cms-contact',
  CmsContractAgreement = 'cms-contract-agreement',
  CmsContractAgreementAttachment = 'cms-contract-agreement-attachment',
  CmsPurchase = 'cms-purchase',
  CmsVendor = 'cms-vendor',
  CmsPurchaseLineItem = 'cms-purchase-line-item',
  CmsCategoryExtended = 'cms-category-extended',
  CmsTriggerType = 'cms-trigger-type',

  CmdbOperatingSystem = 'cmdb-operating-system',
  CmdbProcessor = 'cmdb-processor',
  CmdbVirtualization = 'cmdb-virtualization',
  CmdbApplication = 'cmdb-application',
  CmdbDevice = 'cmdb-device',
  CmdbLicenseModel = 'cmdb-license-model',
  CmdbSoftware = 'cmdb-software',
  CmdbUser = 'cmdb-user',
  CmdbUserMap = 'cmdb-user-map',
  CmdbExclusion = 'cmdb-exclusion',
  CmdbOsNormalization = 'cmdb-os-normalization',
  CmdbSoftwareNormalization = 'cmdb-software-normalization',

  ConfigAzureApp = 'azure-app-services',
  ConfigComponent = 'config-component',
  ConfigComponentTableColumn = 'config-component-table-column',
  ConfigExclusionComponent = 'config-exclusion-component',
  ConfigExclusionLocation = 'config-exclusion-location',
  ConfigExclusionType = 'config-exclusion-type',
  ConfigExclusionOperation = 'config-exclusion-operation',
  ConfigFileCategories = 'config-file-categories',
  ConfigFileType = 'config-file-types',
  ConfigLicenseUnits = 'config-license-units',
  ConfigOnlineProducts = 'config-online-products',
  ConfigOnlineProductServicePlans = 'config-online-product-service-plans',
  ConfigOnlineServicePlans = 'config-online-service-plans',
  ConfigProcessors = 'config-processors',
  ConfigSqlServerEditions = 'config-sql-server-editions',
  ConfigSqlServerServices = 'config-sql-server-services',
  ConfigSqlServerVersions = 'config-sql-server-versions',
  ConfigSupportTypes = 'config-support-types',
  ConfigWindowsServerEditions = 'config-windows-server-editions',
  ConfigSqlServerLicense = 'config-sql-server-license',
  ConfigWindowsServerVersions = 'config-windows-server-versions',
  ConfigWindowsServerLicense = 'config-windows-server-license',
  ConfigModelPopUpSelection = 'config-model-pop-up-data-selection',
  ConfigSoftware = "config-software",
  ConfigSoftwareDeviceType = "config-software-device-type",
  ConfigSoftwareEdition = "config-software-edition",
  ConfigSoftwareFamily = "config-software-family",
  ConfigSoftwarePublisher = "config-software-publisher",
  ConfigSoftwareMetric = "config-software-metric",
  ConfigSoftwareProduct = "config-software-product",
  ConfigSoftwareVersion = "config-software-version",
  DeleteDataset = 'config-delete-data-set',

  //Slim360
  Slim360O365Licenses = 'slim360-o365-licenses',
  Slim360O365UserLicenses = 'slim360-o365-user-licenses',
  Slim360O365UserPlans = 'slim360-o365-user-plans',

  PowerBIConfig = 'power-bi-report-config',

  // Global search dropdown
  TenantDropdown = 'tenant-drop-down',
  CompanyDropdown = 'company-drop-down',
  BUDropdown = 'bu-drop-down',
  AgreementTypes = 'agreement-types',

  SPSApiCall = 'sps-api',
  SPSApi = 'sps-config-api',
  SpsApiJobs = 'sps-api-jobs',
  SpsApiJobsData = 'sps-api-jobs-data',
  SpsApiGroup = 'sps-api-group',
  SpsApiType = 'sps-api-type',
  SpsApiOauth = 'sps-api-oauth',
  SpsApiTokenConfigOptions = 'sps-api-token-config-options',
  SpsApiBaseUrl = 'sps-api-base-url',
  SpsApiOauthUrlInjectionSite = 'sps-api-oauth-url-injection-site',
  SpsApiOauthIdUrlInjectionSite = 'sps-api-oauth-id-url-injection-site',
  SpsApiInjectionParamV2 = 'sps-api-injection-param-v2',
  SpsApiOauthV2 = 'sps-api-oauth-v2',
  SpsApiTokenConfigOptionsV2 = 'sps-api-token-config-options-v2',
  SpsApiInjectionValueParamV2 = 'sps-api-injection-value-v2',

  Cron = 'sps-scheduler',
  CronViewLogData = 'sps-scheduler-log',

  ConfigSPSColMapping = 'config-sps-api-column-mapping',
  ConfigO365Products = 'config-o365-products',

  Inventory = 'inventory',
  Software = 'inventory-software',
  Device = 'device',
  DeviceState = 'device-states',
  Hardware = 'inventory-hardware',
  ExcelFileMapping = 'config-excel-file-mapping',
  ExcelColumnMapping = 'config-excel-column-mapping',
  Version = 'version-history',
  BackgroundProcesses = 'back-ground-processes',

  CmdbCiComputer = "service-now-cmdb-ci-computer",
  CmdbCiDbMsSqlInstance = "service-now-cmdb-ci-db-mssql-instance",
  CmdbCiVcenterCluster = "service-now-cmdb-ci-vcenter-cluster",
  CmdbCiVMInstance = "service-now-cmdb-ci-vm-instance",
  CmdbCiWinClusterNode = "service-now-cmdb-ci-win-cluster-node",
  CmdbRelCi = "service-now-cmdb-rel-ci",
  CmdbSamSWInstall = "service-now-cmdb-sam-sw-install",
  CmdbCiServer = "service-now-cmdb-ci-server",

  BulkUpdateFromExcel = "bulk-update-from-excel",

  //Pega
  PegaDetails = "pega-detail-user-data",
  PegaSecurityReports = "pega-security-report",

  //Snow
  SnowApplication = "snow-application",
  SnowApplications = "snow-applications",
  SnowComputerApplications = "snow-computer-applications",
  SnowComputer = "snow-computer",
  SnowComputers = "snow-computers",
  SnowDCCS = "snow-dccs",
  SnowHost = "snow-host",
  SnowVM = "snow-vm",
  SnowUser = "snow-user",
  SnowUsers = "snow-users",
  HyperV = "virtualization-hyper-v",

}
export enum Action {
  View = 'view',
  Add = 'add',
  Update = 'update',
  Delete = 'delete',
  ImportToExcel = 'import_to_excel',
  UpdateFromExcel = 'bulk_update_from_excel',
  ProcessData = 'process_data',
  DeleteData = 'delete_data',
  RunAllLicenseScenario = 'run_all_license_scenario',
  RunCronJob = 'run_cron_job',
  Select = 'select',
  Call = 'call',
  Error = 'error_view',
  ModelDataSeletion = 'model_popup_data_config',
}
