import { createAsyncThunk } from '@reduxjs/toolkit';
import CmdbSamSWInstallService from '../../../services/serviceNow/cmdbSamSWInstall/cmdbSamSWInstall.service';
import {
  ISearchCmdbSamSWInstall,
  ICmdbSamSWInstall,
  IProcessData,
} from '../../../services/serviceNow/cmdbSamSWInstall/cmdbSamSWInstall.model';

// Asynchronous thunk action

export const searchCmdbSamSWInstall = createAsyncThunk(
  'searchCmdbSamSWInstall',
  async (searchParam?: ISearchCmdbSamSWInstall) => {
    const response = await CmdbSamSWInstallService.searchCmdbSamSWInstall(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getCmdbSamSWInstallById = createAsyncThunk('getCmdbSamSWInstallById', async (id: number) => {
  const response = await CmdbSamSWInstallService.getCmdbSamSWInstallById(id).then((res) => {
    return res.body;
  });
  return response.data;
});

export const saveCmdbSamSWInstall = createAsyncThunk('saveCmdbSamSWInstall', async (data: ICmdbSamSWInstall) => {
  const response = await CmdbSamSWInstallService.saveCmdbSamSWInstall(data).then((res) => {
    return res.body;
  });
  return response;
});

export const deleteCmdbSamSWInstall = createAsyncThunk('deleteCmdbSamSWInstall', async (id: number) => {
  const response = await CmdbSamSWInstallService.deleteCmdbSamSWInstall(id).then((res) => {
    return res.body;
  });
  return response;
});

export const processDataCmdbSamSWInstall = createAsyncThunk(
  'processDataCmdbSamSWInstall',
  async (data: IProcessData) => {
    const response = await CmdbSamSWInstallService.processData(data).then((res) => {
      return res.body;
    });
    return response;
  }
);
