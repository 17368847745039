import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import {
  clearSnowComputerMessages,
  snowComputerSelector,
  setTableColumnSelection,
} from '../../../../store/snow/snowComputer/snowComputer.reducer';
import {
  deleteSnowComputer,
  searchSnowComputer,
} from '../../../../store/snow/snowComputer/snowComputer.action';
import snowComputerService from '../../../../services/snow/snowComputer/snowComputer.service';
import { showDateFromApi } from '../../../../common/helperFunction';
import { AlignType } from 'rc-table/lib/interface';
import _ from 'lodash';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
    setFilterKeys
  } = props;
  const snowComputer = useAppSelector(snowComputerSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return snowComputerService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean) => {
    setFilterKeys(ObjectForColumnFilter);
    return FilterWithSwapOption(
      dataIndex,
      snowComputer.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('date_added', snowComputer.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Computer Id</span>,
        column: 'ComputerId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'computer_id',
              form
            ),
            dataIndex: 'computer_id',
            key: 'computer_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Name</span>,
        column: 'Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'name',
              form
            ),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Organization</span>,
        column: 'Organization',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'organization',
              form
            ),
            dataIndex: 'organization',
            key: 'organization',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Org Checksum</span>,
        column: 'OrgChecksum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap(
              'org_checksum',
              form
            ),
            dataIndex: 'org_checksum',
            key: 'org_checksum',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer</span>,
        column: 'Manufacturer',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer',
              form
            ),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Manufacturer Website</span>,
        column: 'Manufacturer Website',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'manufacturer_website',
              form
            ),
            dataIndex: 'manufacturer_website',
            key: 'manufacturer_website',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Model</span>,
        column: 'Model',
        sorter: true,
        children: [
          {
            title: FilterBySwap('model', form),
            dataIndex: 'model',
            key: 'model',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System</span>,
        column: 'OperatingSystem',
        sorter: true,
        children: [
          {
            title: FilterBySwap('operating_system', form),
            dataIndex: 'operating_system',
            key: 'operating_system',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System Service Pack</span>,
        column: 'OperatingSystemServicePack',
        sorter: true,
        children: [
          {
            title: FilterBySwap('operating_system_service_pack', form),
            dataIndex: 'operating_system_service_pack',
            key: 'operating_system_service_pack',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Status</span>,
        column: 'Status',
        sorter: true,
        children: [
          {
            title: FilterBySwap('status', form),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Last Scan Date</span>,
        column: 'LastScanDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('last_scan_date', snowComputer.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'last_scan_date',
            key: 'last_scan_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Updated Date</span>,
        column: 'UpdatedDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('updated_date', snowComputer.search.tableName, form),
            dataIndex: 'updated_date',
            key: 'updated_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Ip Addresses</span>,
        column: 'IpAddresses',
        sorter: true,
        children: [
          {
            title: FilterBySwap('ip_addresses', form),
            dataIndex: 'ip_addresses',
            key: 'ip_addresses',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Updated By</span>,
        column: 'UpdatedBy',
        sorter: true,
        children: [
          {
            title: FilterBySwap('updated_by', form),
            dataIndex: 'updated_by',
            key: 'updated_by',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Domain</span>,
        column: 'Domain',
        sorter: true,
        children: [
          {
            title: FilterBySwap('domain', form),
            dataIndex: 'domain',
            key: 'domain',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Datacenter Name</span>,
        column: 'DatacenterName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('datacenter_name', form),
            dataIndex: 'datacenter_name',
            key: 'datacenter_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Client Configuration Name</span>,
        column: 'ClientConfigurationName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('client_configuration_name', form),
            dataIndex: 'client_configuration_name',
            key: 'client_configuration_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Client Installed Date</span>,
        column: 'ClientInstalledDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap('client_install_date', snowComputer.search.tableName, form,
            null,
            ObjectForColumnFilter,),
            dataIndex: 'client_install_date',
            key: 'client_install_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Client Site Name</span>,
        column: 'ClientSiteName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('client_site_name', form),
            dataIndex: 'client_site_name',
            key: 'client_site_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Client Version</span>,
        column: 'ClientVersion',
        sorter: true,
        children: [
          {
            title: FilterBySwap('client_version', form),
            dataIndex: 'client_version',
            key: 'client_version',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Custom Fields</span>,
        column: 'CustomFields',
        sorter: true,
        children: [
          {
            title: FilterBySwap('custom_fields', form),
            dataIndex: 'custom_fields',
            key: 'custom_fields',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is 64 Bit</span>,
        column: 'Is 64 Bit',
        sorter: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_64_bit',
              snowComputer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_64_bit',
            key: 'is_64_bit',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Part of VDI</span>,
        column: 'Is PartofVDI',
        sorter: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_part_of_vdi',
              snowComputer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_part_of_vdi',
            key: 'is_part_of_vdi',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Notes</span>,
        column: 'Notes',
        sorter: true,
        children: [
          {
            title: FilterBySwap('notes', form),
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Purchase Currency</span>,
        column: 'PurchaseCurrency',
        sorter: true,
        children: [
          {
            title: FilterBySwap('purchase_currency', form),
            dataIndex: 'purchase_currency',
            key: 'purchase_currency',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Purchase Invoice Reference</span>,
        column: 'PurchaseInvoiceReference',
        sorter: true,
        children: [
          {
            title: FilterBySwap('purchase_invoice_reference', form),
            dataIndex: 'purchase_invoice_reference',
            key: 'purchase_invoice_reference',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Purchase Price</span>,
        column: 'PurchasePrice',
        sorter: true,
        children: [
          {
            title: FilterBySwap('purchase_price', form),
            dataIndex: 'purchase_price',
            key: 'purchase_price',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Purchase Vendor</span>,
        column: 'PurchaseVendor',
        sorter: true,
        children: [
          {
            title: FilterBySwap('purchase_vendor', form),
            dataIndex: 'purchase_vendor',
            key: 'purchase_vendor',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Scan Identifier</span>,
        column: 'ScanIdentifier',
        sorter: true,
        children: [
          {
            title: FilterBySwap('scan_identifier', form),
            dataIndex: 'scan_identifier',
            key: 'scan_identifier',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Scanner Version</span>,
        column: 'ScannerVersion',
        sorter: true,
        children: [
          {
            title: FilterBySwap('scanner_version', form),
            dataIndex: 'scanner_version',
            key: 'scanner_version',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Security Code</span>,
        column: 'SecurityCode',
        sorter: true,
        children: [
          {
            title: FilterBySwap('security_code', form),
            dataIndex: 'security_code',
            key: 'security_code',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Quarantine Management</span>,
        column: 'QuarantineManagement',
        sorter: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'quarantine_management',
              snowComputer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'quarantine_management',
            key: 'quarantine_management',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Quarantine Date</span>,
        column: 'QuarantineDate',
        sorter: true,
        children: [
          {
            title: FilterBySwap('quarantine_date', form),
            dataIndex: 'quarantine_date',
            key: 'quarantine_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Quarantine Delete Date</span>,
        column: 'QuarantineDeleteDate',
        sorter: true,
        children: [
          {
            title: FilterBySwap('quarantine_delete_date', form),
            dataIndex: 'quarantine_delete_date',
            key: 'quarantine_delete_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Processor Count</span>,
        column: 'ProcessorCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('processor_count', form),
            dataIndex: 'processor_count',
            key: 'processor_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Core Count</span>,
        column: 'CoreCount',
        sorter: true,
        children: [
          {
            title: FilterBySwap('core_count', form),
            dataIndex: 'core_count',
            key: 'core_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bios Serial Number</span>,
        column: 'BiosSerialNumber',
        sorter: true,
        children: [
          {
            title: FilterBySwap('bios_serial_number', form),
            dataIndex: 'bios_serial_number',
            key: 'bios_serial_number',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Hardware</span>,
        column: 'Hardware',
        sorter: true,
        children: [
          {
            title: FilterBySwap('hardware', form),
            dataIndex: 'hardware',
            key: 'hardware',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Hypervisor Name</span>,
        column: 'HypervisorName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('hypervisor_name', form),
            dataIndex: 'hypervisor_name',
            key: 'hypervisor_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Most Frequent User Id</span>,
        column: 'MostFrequentUserId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('most_frequent_user_id', form),
            dataIndex: 'most_frequent_user_id',
            key: 'most_frequent_user_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Most Recent User Id</span>,
        column: 'MostRecentUserId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('most_recent_user_id', form),
            dataIndex: 'most_recent_user_id',
            key: 'most_recent_user_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Auto Editing</span>,
        column: 'AutoEditing',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'auto_editing',
              snowComputer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'auto_editing',
            key: 'auto_editing',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Virtual</span>,
        column: 'IsVirtual',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_virtual',
              snowComputer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_virtual',
            key: 'is_virtual',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Portable</span>,
        column: 'IsPortable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_portable',
              snowComputer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_portable',
            key: 'is_portable',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Server</span>,
        column: 'IsServer',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_server',
              snowComputer.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_server',
            key: 'is_server',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
    ];
  };

  const removeSnowComputer = (id: number) => {
    dispatch(deleteSnowComputer(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.SnowComputer}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/snow/snow-computer/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.SnowComputer}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeSnowComputer(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.SnowComputer)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={snowComputerSelector}
        searchTableData={searchSnowComputer}
        clearTableDataMessages={clearSnowComputerMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        showBulkUpdate={ability.can(Action.Update, Page.SnowComputer)}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
