import { Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import {
  setTableColumnSelection,
  clearBackgroundProcessesMessages,
  backgroundProcessesSelector,
} from '../../../../store/backgroundProcesses/backgroundProcesses.reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  deleteBackgroundProcesses,
  // pauseBackgroundProcesses,
  searchBackgroundProcesses,
} from '../../../../store/backgroundProcesses/backgroundProcesses.action';
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import {
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { Common } from '../../../../common/constants/common';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ModalForInfo from './modal';
import { toast } from 'react-toastify';
import backgroundProcessesService from '../../../../services/backgroundProcesses/backgroundProcesses.service';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
  } = props;
  const backgroundProcesses = useAppSelector(backgroundProcessesSelector);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const dataTableRef = useRef(null);
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [data, setData] = useState('');

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (backgroundProcesses.pause.messages.length > 0) {
      if (backgroundProcesses.pause.hasErrors) {
        toast.error(backgroundProcesses.pause.messages.join(' '));
      } else {
        toast.success(backgroundProcesses.pause.messages.join(' '));
        dataTableRef?.current.refreshData();
      }
      dispatch(clearBackgroundProcessesMessages());
    }
  }, [backgroundProcesses.pause.messages]);

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const FilterBySwap = (dataIndex: string, form, columnWidthOpt?: boolean, isRelativeDropDownFlag?: boolean) => {
    return FilterWithSwapOption(
      dataIndex,
      backgroundProcesses.search.tableName,
      form,
      null,
      ObjectForColumnFilter,
      null,
      columnWidthOpt === undefined ? false : columnWidthOpt,
      isRelativeDropDownFlag
    );
  };

  const exportExcelFile = (searchData: ISearch) => {
    return backgroundProcessesService.exportExcelFile(searchData);
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form, true),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'TenantId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'tenant_id',
              form,
              false,
              true
            ),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'CompanyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'company_id',
              form,
              false,
              true
            ),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Bu Name</span>,
        column: 'BU_Id',
        sorter: true,
        children: [
          {
            title: FilterBySwap(
              'bu_id',
              form,
              false,
              true
            ),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap(
              'date_added',
              backgroundProcesses.search.tableName,
              form,
              null,
              ObjectForColumnFilter,
              true,
              true
            ),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? moment(date).local().format(Common.DATETIMEFORMAT) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Table Name</span>,
        column: 'Table Name',
        sorter: true,
        children: [
          {
            title: FilterBySwap('table_name', form),
            dataIndex: 'table_name',
            key: 'table_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Type</span>,
        column: 'Type',
        sorter: true,
        children: [
          {
            title: FilterBySwap('type', form),
            dataIndex: 'type',
            key: 'type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Status</span>,
        column: 'Status',
        sorter: true,
        children: [
          {
            title: FilterBySwap('status', form, true),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Comment</span>,
        column: 'Comment',
        sorter: true,
        children: [
          {
            title: FilterBySwap('comment', form),
            dataIndex: 'comment',
            key: 'comment',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">User Name</span>,
        column: 'UserName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('user_id', form, false, true),
            dataIndex: 'user_name',
            key: 'user_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Start</span>,
        column: 'StartDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap(
              'start_date',
              backgroundProcesses.search.tableName,
              form,
              null,
              ObjectForColumnFilter,
              true,
              true
            ),
            dataIndex: 'start_date',
            key: 'start_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? moment(date).local().format(Common.DATETIMEFORMAT) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Complete</span>,
        column: 'CompleteDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwap(
              'complete_date',
              backgroundProcesses.search.tableName,
              form,
              null,
              ObjectForColumnFilter,
              true,
              true
            ),
            dataIndex: 'complete_date',
            key: 'complete_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? moment(date).local().format(Common.DATETIMEFORMAT) : ''),
          },
        ],
      },
    ];
  };

  const removeBackgroundProcessess = (id: number) => {
    dispatch(deleteBackgroundProcesses(id));
  };

  // const pauseBackgroundProcess = (id: number) => {
  //   const data = {
  //     status: 'Cancelled'
  //   };
  //   dispatch(pauseBackgroundProcesses({ id, data }));
  // };

  const tableAction = (_, data: any) => (
    <div className="btns-block">
      {/* <Can I={Action.Update} a={Page.BackgroundProcesses}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/ad/ad-devices-exclusions/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can> */}
      <a
        title=""
        className="action-btn"
        onClick={() => {
          history.push(`/administration/telemetry/${data.id}`);
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/assets/images/ic-eye.svg`} alt="" />
      </a>
      <div style={{ marginTop: '5px' }}>
        <InfoCircleOutlined onClick={() => { setShowInfoModal(true); setData(data.json); }} />
      </div>
      <div>
        {/* {data.status == 'Running' ? (
          <div style={{ marginTop: '5px' }}><PauseOutlined onClick={() => pauseBackgroundProcess(data.id)} /></div>
        ) : ( */}
          <Can I={Action.Delete} a={Page.BackgroundProcesses}>
            <Popconfirm title="Delete Record?" onConfirm={() => removeBackgroundProcessess(data.id)}>
              <a href="#" title="" className="action-btn">
                <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
              </a>
            </Popconfirm>
          </Can>
        {/* )} */}
      </div>
    </div >
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={false}
        showDelete={ability.can(Action.Delete, Page.BackgroundProcesses)}
        hideExportButton={false}
        showBulkUpdate={false}
        //hideShowHideButton={true}
        // disableRowSelection={true}
        exportExcelFile={exportExcelFile}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        getTableColumns={getTableColumns}
        reduxSelector={backgroundProcessesSelector}
        searchTableData={searchBackgroundProcesses}
        clearTableDataMessages={clearBackgroundProcessesMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
      {showInfoModal && (
        <ModalForInfo
          showModal={showInfoModal}
          setShowModal={setShowInfoModal}
          data={data}
        />
      )}
    </>
  );
};

export default forwardRef(MainTable);
