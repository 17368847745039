import { Checkbox, Popconfirm } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import {
  setTableColumnSelection,
  clearAzurePricesMessages,
  azurePricesSelector,
} from '../../../../store/azure/azurePrices/azurePrices.reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import {
  deleteAzurePrices,
  searchAzurePrices,
} from '../../../../store/azure/azurePrices/azurePrices.action';
import _ from 'lodash';
import azurePricesService from '../../../../services/azure/azurePrices/azurePrices.service';
import {
  FilterByBooleanDropDown,
  FilterByDateSwap,
  FilterWithSwapOption,
} from '../../../../common/components/DataTable/DataTableFilters';
import { IMainTable, ISearch } from '../../../../common/models/common';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../../common/components/DataTable';
import ability, { Can } from '../../../../common/ability';
import { Action, Page } from '../../../../common/constants/pageAction';
import { showDateFromApi } from '../../../../common/helperFunction';
import { AlignType } from 'rc-table/lib/interface';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
  } = props;
  const azurePrices = useAppSelector(azurePricesSelector);
  const dispatch = useAppDispatch();
  const dataTableRef = useRef(null);
  const history = useHistory();
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const exportExcelFile = (searchData: ISearch) => {
    return azurePricesService.exportExcelFile(searchData);
  };

  const FilterBySwap = (dataIndex: string, form) => {
    return FilterWithSwapOption(
      dataIndex,
      azurePrices.search.tableName,
      form,
      null,
      ObjectForColumnFilter
    );
  };

  const FilterByDateSwapTable = (dataIndex: string, tableName: string, form: any) => {
    return FilterByDateSwap(dataIndex, tableName, form, null, ObjectForColumnFilter);
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'Date Added',
        sorter: true,
        children: [
          {
            title: FilterByDateSwapTable('date_added', azurePrices.search.tableName, form),
            dataIndex: 'date_added',
            key: 'date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Currency Code</span>,
        column: 'currencyCode',
        sorter: true,
        children: [
          {
            title: FilterBySwap('currency_code', form),
            dataIndex: 'currency_code',
            key: 'currency_code',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tier Minimum Units</span>,
        column: 'tierMinimumUnits',
        sorter: true,
        children: [
          {
            title: FilterBySwap('tier_minimum_units', form),
            dataIndex: 'tier_minimum_units',
            key: 'tier_minimum_units',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Reservation Term</span>,
        column: 'reservationTerm',
        sorter: true,
        children: [
          {
            title: FilterBySwap('reservation_term', form),
            dataIndex: 'reservation_term',
            key: 'reservation_term',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Retail Price</span>,
        column: 'retailPrice',
        sorter: true,
        children: [
          {
            title: FilterBySwap('retail_price', form),
            dataIndex: 'retail_price',
            key: 'retail_price',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Unit Price</span>,
        column: 'unitPrice',
        sorter: true,
        children: [
          {
            title: FilterBySwap('unit_price', form),
            dataIndex: 'unit_price',
            key: 'unit_price',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Arm Region Name</span>,
        column: 'armRegionName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('arm_region_name', form),
            dataIndex: 'arm_region_name',
            key: 'arm_region_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Location</span>,
        column: 'Location',
        sorter: true,
        children: [
          {
            title: FilterBySwap('location', form),
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Effective Start Date</span>,
        column: 'EffectiveStartDate',
        sorter: true,
        children: [
          {
            title: FilterByDateSwapTable('effective_start_date', azurePrices.search.tableName, form),
            dataIndex: 'effective_start_date',
            key: 'effective_start_date',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Meter Id</span>,
        column: 'MeterId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('meter_id', form),
            dataIndex: 'meter_id',
            key: 'meter_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Meter Name</span>,
        column: 'MeterName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('meter_name', form),
            dataIndex: 'meter_name',
            key: 'meter_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Product Id</span>,
        column: 'productid',
        sorter: true,
        children: [
          {
            title: FilterBySwap('productid', form),
            dataIndex: 'productid',
            key: 'productid',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Product Name</span>,
        column: 'productName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('product_name', form),
            dataIndex: 'product_name',
            key: 'product_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Sku Id</span>,
        column: 'skuId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sku_id', form),
            dataIndex: 'sku_id',
            key: 'sku_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Sku Name</span>,
        column: 'skuName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('sku_name', form),
            dataIndex: 'sku_name',
            key: 'sku_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Service Name</span>,
        column: 'serviceName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('service_name', form),
            dataIndex: 'service_name',
            key: 'service_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Service Id</span>,
        column: 'serviceId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('service_id', form),
            dataIndex: 'service_id',
            key: 'service_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Service Family</span>,
        column: 'serviceFamily',
        sorter: true,
        children: [
          {
            title: FilterBySwap('service_family', form),
            dataIndex: 'service_family',
            key: 'service_family',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Unit Of Measure</span>,
        column: 'unitOfMeasure',
        sorter: true,
        children: [
          {
            title: FilterBySwap('unit_of_measure', form),
            dataIndex: 'unit_of_measure',
            key: 'unit_of_measure',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Type</span>,
        column: 'Type',
        sorter: true,
        children: [
          {
            title: FilterBySwap('type', form),
            dataIndex: 'type',
            key: 'type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Is Primary Meter Region</span>,
        column: 'isPrimaryMeterRegion',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByBooleanDropDown(
              'is_primary_meter_region',
              azurePrices.search.tableName,
              ObjectForColumnFilter
            ),
            dataIndex: 'is_primary_meter_region',
            key: 'is_primary_meter_region',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Arm Sku Name</span>,
        column: 'armSkuName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('arm_sku_name', form),
            dataIndex: 'arm_sku_name',
            key: 'arm_sku_name',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const removeAzurePrices = (id: number) => {
    dispatch(deleteAzurePrices(id));
  };
  const tableAction = (_, data: any) => (
    <div className="btns-block">
      <Can I={Action.Update} a={Page.AzurePrices}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/azure/azure-prices/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can>
      <Can I={Action.Delete} a={Page.AzurePrices}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeAzurePrices(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={ability.can(Action.Add, Page.AzurePrices)}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={azurePricesSelector}
        searchTableData={searchAzurePrices}
        clearTableDataMessages={clearAzurePricesMessages}
        globalSearchExist={false}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        showBulkUpdate={ability.can(Action.Update, Page.AzurePrices)}
        setValuesForSelection={setValuesForSelection}
        setObjectForColumnFilter={setObjectForColumnFilter}
        tableButtons={tableButtons}
      />
    </>
  );
};

export default forwardRef(MainTable);
