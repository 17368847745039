import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { IMainTable } from '../../../../common/models/common';
import DataTable from '../../../../common/components/DataTable';
import { showDateFromApi } from '../../../../common/helperFunction';
import { clearErrorLogFileMessages, errorLogFileSelector, setTableColumnSelection } from '../../../../store/master/errorLogFile/errorLogFile.reducer';
import { searchErrorLogFile } from '../../../../store/master/errorLogFile/errorLogFile.action';
import { InfoCircleOutlined } from '@ant-design/icons';
import ModalForInfo from './modal';

const MainTable: React.ForwardRefRenderFunction<unknown, IMainTable> = (props, ref) => {
  const {
    setSelectedId,
    setShowSelectedListModal,
    setValuesForSelection,
    isMultiple,
    tableButtons,
  } = props;
  const dataTableRef = useRef(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [data, setData] = useState('');

  useImperativeHandle(ref, () => ({
    refreshData() {
      dataTableRef?.current.refreshData();
    },
  }));

  // const FilterByDateSwapTable = (dataIndex: string, tableName: string, form: any) => {
  //   return FilterByDateSwap(dataIndex, tableName, form, null, ObjectForColumnFilter);
  // };

  useEffect(() => {
    if (isMultiple) {
      dataTableRef?.current.getValuesForSelection();
    }
  }, [isMultiple]);

  const getTableColumns = () => {
    return [
      {
        title: <span className="dragHandler">Level</span>,
        column: 'Level',
        children: [
          {
            dataIndex: 'level',
            key: 'level',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Time Stamp</span>,
        column: 'Time Stamp',
        children: [
          {
            dataIndex: 'timestamp',
            key: 'timestamp',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date, true) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Error Log Message</span>,
        column: 'Error Log Message',
        children: [
          {
            dataIndex: 'message',
            key: 'message',
            ellipsis: true,
          },
        ],
      },
    ];
  };

  const tableAction = (_, data: any) => (
    <div className="btns-block">
      {/* <Can I={Action.Update} a={Page.ErrorLogFile}>
        <a
          className="action-btn"
          onClick={() => {
            setSelectedId(data.id);
            history.push(`/ad/ad-devices-exclusions/${data.id}`);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/ic-edit.svg`} alt="" />
        </a>
      </Can> 
      <a
        title=""
        className="action-btn"
        onClick={() => {
          history.push(`/administration/telemetry/${data.id}`);
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/assets/images/ic-eye.svg`} alt="" />
      </a>
      <Can I={Action.Delete} a={Page.ErrorLogFile}>
        <Popconfirm title="Delete Record?" onConfirm={() => removeErrorLogFiles(data.id)}>
          <a href="#" title="" className="action-btn">
            <img src={`${process.env.PUBLIC_URL}/assets/images/ic-delete.svg`} alt="" />
          </a>
        </Popconfirm>
      </Can>*/}
      <div style={{ marginTop: '5px' }}>
        <InfoCircleOutlined onClick={() => { setShowInfoModal(true); setData(data.message); }} />
      </div>
    </div>
  );

  return (
    <>
      <DataTable
        ref={dataTableRef}
        removeSearch={true}
        showAddButton={false}
        showDelete={false}
        hideExportButton={true}
        showBulkUpdate={false}
        hideShowHideButton={true}
        disableRowSelection={true}
        setSelectedId={setSelectedId}
        tableAction={tableAction}
        getTableColumns={getTableColumns}
        reduxSelector={errorLogFileSelector}
        searchTableData={searchErrorLogFile}
        clearTableDataMessages={clearErrorLogFileMessages}
        setTableColumnSelection={setTableColumnSelection}
        setShowSelectedListModal={setShowSelectedListModal}
        setValuesForSelection={setValuesForSelection}
        tableButtons={tableButtons}
      />
      {showInfoModal && (
        <ModalForInfo
          showModal={showInfoModal}
          setShowModal={setShowInfoModal}
          data={data}
        />
      )}
    </>
  );
};

export default forwardRef(MainTable);
