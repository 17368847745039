import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Spin,
  Switch,
} from 'antd';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { IAzurePrices } from '../../../../services/azure/azurePrices/azurePrices.model';
import { useAppSelector, useAppDispatch } from '../../../../store/app.hooks';
import {
  getAzurePricesById,
  saveAzurePrices,
} from '../../../../store/azure/azurePrices/azurePrices.action';
import {
  clearAzurePricesGetById,
  clearAzurePricesMessages,
  azurePricesSelector,
} from '../../../../store/azure/azurePrices/azurePrices.reducer';
import { IAddAzurePricesProps } from './addAzurePrices.model';
import { validateMessages } from '../../../../common/constants/common';
import BreadCrumbs from '../../../../common/components/Breadcrumbs';
import { Page } from '../../../../common/constants/pageAction';
import {
  forEditModal,
  getObjectForUpdateMultiple,
  getSimpleDate,
  passDateToApi,
} from '../../../../common/helperFunction';
import { updateMultiple } from '../../../../store/common/common.action';
import {
  clearMultipleUpdateMessages,
  commonSelector,
} from '../../../../store/common/common.reducer';

const AddAzurePricesModal: React.FC<IAddAzurePricesProps> = (props) => {
  const azurePrices = useAppSelector(azurePricesSelector);
  const common = useAppSelector(commonSelector);
  const dispatch = useAppDispatch();

  const { id, showModal, handleModalClose, refreshDataTable, isMultiple, valuesForSelection } =
    props;

  const isNew: boolean = id || isMultiple ? false : true;
  const title = useMemo(() => {
    return (
      <>
        {isNew ? 'Add ' : 'Edit '} <BreadCrumbs pageName={Page.AzurePrices} level={1} />
      </>
    );
  }, [isNew]);
  const submitButtonText = useMemo(() => {
    return isNew ? 'Save' : 'Update';
  }, [isNew]);

  const [form] = Form.useForm();

  let initialValues: IAzurePrices = {
    currency_code: '',
    tier_minimum_units: null,
    reservation_term: '',
    retail_price: null,
    unit_price: null,
    arm_region_name: '',
    location: '',
    effective_start_date: getSimpleDate(),
    meter_id: '',
    meter_name: '',
    productid: '',
    sku_id: '',
    product_name: '',
    sku_name: '',
    service_name: '',
    service_id: '',
    service_family: '',
    unit_of_measure: '',
    type: '',
    is_primary_meter_region: false,
    arm_sku_name: '',
    date_added: getSimpleDate(),
  };

  const onFinish = (values: IAzurePrices) => {
    const inputValues: IAzurePrices = {
      ...values,
      id: id ? +id : null,
    };
    inputValues.effective_start_date = passDateToApi(inputValues.effective_start_date, true);
    inputValues.date_added = passDateToApi(inputValues.date_added, true);
    if (!isMultiple) {
      dispatch(saveAzurePrices(inputValues));
    } else {
      const result = getObjectForUpdateMultiple(
        valuesForSelection,
        inputValues,
        azurePrices.search.tableName
      );
      if (result) {
        dispatch(updateMultiple(result));
      }
    }
  };

  const fillValuesOnEdit = async (data: IAzurePrices) => {
    if (data) {
      initialValues = {
        currency_code: data.currency_code,
        tier_minimum_units: _.isNull(data.tier_minimum_units) ? null : (data.tier_minimum_units),
        reservation_term: data.reservation_term,
        retail_price: _.isNull(data.retail_price) ? null : (data.retail_price),
        unit_price: _.isNull(data.unit_price) ? null : (data.unit_price),
        arm_region_name: data.arm_region_name,
        location: data.location,
        effective_start_date: _.isNull(data.effective_start_date) ? null : forEditModal(data.effective_start_date),
        meter_id: data.meter_id,
        meter_name: data.meter_name,
        productid: data.productid,
        sku_id: data.sku_id,
        product_name: data.product_name,
        sku_name: data.sku_name,
        service_name: data.service_name,
        service_id: data.service_id,
        service_family: data.service_family,
        unit_of_measure: data.unit_of_measure,
        type: data.type,
        is_primary_meter_region: data.is_primary_meter_region,
        arm_sku_name: data.arm_sku_name,
        date_added: _.isNull(data.date_added) ? null : forEditModal(data.date_added),
      };
      form.setFieldsValue(initialValues);
    }
  };

  useEffect(() => {
    if (azurePrices.save.messages.length > 0) {
      if (azurePrices.save.hasErrors) {
        toast.error(azurePrices.save.messages.join(' '));
      } else {
        toast.success(azurePrices.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearAzurePricesMessages());
    }
  }, [azurePrices.save.messages]);

  useEffect(() => {
    if (common.save.messages.length > 0) {
      if (common.save.hasErrors) {
        toast.error(common.save.messages.join(' '));
      } else {
        toast.warn(common.save.messages.join(' '));
        handleModalClose();
        refreshDataTable();
      }
      dispatch(clearMultipleUpdateMessages());
    }
  }, [common.save.messages]);

  useEffect(() => {
    if (+id > 0 && azurePrices.getById.data) {
      const data = azurePrices.getById.data;
      fillValuesOnEdit(data);
    }
  }, [azurePrices.getById.data]);

  useEffect(() => {
    if (+id > 0) {
      dispatch(getAzurePricesById(+id));
    }
    return () => {
      dispatch(clearAzurePricesGetById());
    };
  }, [dispatch]);

  return (
    <>
      <Modal
        wrapClassName="custom-modal"
        title={title}
        centered
        visible={showModal}
        onCancel={handleModalClose}
        footer={false}
      >
        {azurePrices.getById.loading ? (
          <div className="spin-loader">
            <Spin spinning={azurePrices.getById.loading} />
          </div>
        ) : (
          <Form
            form={form}
            name="addAzurePrices"
            initialValues={initialValues}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={[30, 15]} className="form-label-hide">
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'currency_code']} valuePropName="checked" noStyle>
                      <Checkbox>Currency Code</Checkbox>
                    </Form.Item>
                  ) : (
                    'Currency Code'
                  )}
                  <Form.Item
                    name="currency_code"
                    label="Currency Code"
                    className="m-0"
                    rules={[{ max: 3 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'tier_minimum_units']} valuePropName="checked" noStyle>
                      <Checkbox>Tier Minimum Units</Checkbox>
                    </Form.Item>
                  ) : (
                    'Tier Minimum Units'
                  )}
                  <Form.Item
                    name="tier_minimum_units"
                    label="Tier Minimum Units"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="w-100 form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'reservation_term']} valuePropName="checked" noStyle>
                      <Checkbox>Reservation Term</Checkbox>
                    </Form.Item>
                  ) : (
                    'Reservation Term'
                  )}
                  <Form.Item
                    name="reservation_term"
                    label="Reservation Term"
                    className="m-0"
                    rules={[{ max: 10 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'retail_price']} valuePropName="checked" noStyle>
                      <Checkbox>Retail Price</Checkbox>
                    </Form.Item>
                  ) : (
                    'Retail Price'
                  )}
                  <Form.Item
                    name="retail_price"
                    label="Retail Price"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="w-100 form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'unit_price']} valuePropName="checked" noStyle>
                      <Checkbox>Unit Price</Checkbox>
                    </Form.Item>
                  ) : (
                    'Unit Price'
                  )}
                  <Form.Item
                    name="unit_price"
                    label="Unit Price"
                    className="m-0"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber className="w-100 form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'arm_region_name']} valuePropName="checked" noStyle>
                      <Checkbox>Arm Region Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Arm Region Name'
                  )}
                  <Form.Item
                    name="arm_region_name"
                    label="Arm Region Name"
                    className="m-0"
                    rules={[{ max: 50 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'location']} valuePropName="checked" noStyle>
                      <Checkbox>Location</Checkbox>
                    </Form.Item>
                  ) : (
                    'Location'
                  )}
                  <Form.Item
                    name="location"
                    label="Location"
                    className="m-0"
                    rules={[{ max: 50 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'date_added']} valuePropName="checked" noStyle>
                      <Checkbox>Date Added</Checkbox>
                    </Form.Item>
                  ) : (
                    'Date Added'
                  )}
                  <Form.Item name="date_added" label="Date Added" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'effective_start_date']} valuePropName="checked" noStyle>
                      <Checkbox>Effective Start date</Checkbox>
                    </Form.Item>
                  ) : (
                    'Effective Start date'
                  )}
                  <Form.Item name="effective_start_date" label="Effective Start date" className="m-0">
                    <DatePicker className="form-control w-100" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'meter_id']} valuePropName="checked" noStyle>
                      <Checkbox>Meter Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'Meter Id'
                  )}
                  <Form.Item
                    name="meter_id"
                    label="Meter Id"
                    className="m-0"
                    rules={[{ max: 36 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'meter_name']} valuePropName="checked" noStyle>
                      <Checkbox>Meter Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Meter Name'
                  )}
                  <Form.Item
                    name="meter_name"
                    label="Meter Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'productid']} valuePropName="checked" noStyle>
                      <Checkbox>Product ID</Checkbox>
                    </Form.Item>
                  ) : (
                    'Product ID'
                  )}
                  <Form.Item
                    name="productid"
                    label="Product ID"
                    className="m-0"
                    rules={[{ max: 50 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'sku_id']} valuePropName="checked" noStyle>
                      <Checkbox>SKU Id</Checkbox>
                    </Form.Item>
                  ) : (
                    'SKU Id'
                  )}
                  <Form.Item
                    name="sku_id"
                    label="SKU Id"
                    className="m-0"
                    rules={[{ max: 50 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'product_name']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>Product Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Product Name'
                  )}
                  <Form.Item
                    name="product_name"
                    label="Product Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'sku_name']} valuePropName="checked" noStyle>
                      <Checkbox>SKU Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'SKU Name'
                  )}
                  <Form.Item
                    name="sku_name"
                    label="SKU Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'service_id']} valuePropName="checked" noStyle>
                      <Checkbox>Service ID</Checkbox>
                    </Form.Item>
                  ) : (
                    'Service ID'
                  )}
                  <Form.Item
                    name="service_id"
                    label="Service ID"
                    className="m-0"
                    rules={[{ max: 50 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'service_name']} valuePropName="checked" noStyle>
                      <Checkbox>Service Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Service Name'
                  )}
                  <Form.Item
                    name="service_name"
                    label="Service Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'service_family']} valuePropName="checked" noStyle>
                      <Checkbox>Service Family</Checkbox>
                    </Form.Item>
                  ) : (
                    'Service Family'
                  )}
                  <Form.Item
                    name="service_family"
                    label="Service Family"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item name={['checked', 'unit_of_measure']} valuePropName="checked" noStyle>
                      <Checkbox>Unit of Measure</Checkbox>
                    </Form.Item>
                  ) : (
                    'Unit of Measure'
                  )}
                  <Form.Item name="unit_of_measure" label="Unit of Measure" className="m-0" rules={[{ max: 50 }]}>
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'type']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>Type</Checkbox>
                    </Form.Item>
                  ) : (
                    'Type'
                  )}
                  <Form.Item
                    name="type"
                    label="Type"
                    className="m-0"
                    rules={[{ max: 100 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group m-0">
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'arm_sku_name']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>Arm Sku Name</Checkbox>
                    </Form.Item>
                  ) : (
                    'Arm Sku Name'
                  )}
                  <Form.Item
                    name="arm_sku_name"
                    label="Arm Sku Name"
                    className="m-0"
                    rules={[{ max: 255 }]}
                  >
                    <Input className="form-control" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="form-group form-inline-pt m-0">
                  <Form.Item name="is_primary_meter_region" className="m-0 mr-1" valuePropName="checked">
                    <Switch className="form-control" />
                  </Form.Item>
                  {isMultiple ? (
                    <Form.Item
                      name={['checked', 'is_primary_meter_region']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox>Is Primary Meter Region</Checkbox>
                    </Form.Item>
                  ) : (
                    'Is Primary Meter Region'
                  )}
                </div>
              </Col>
            </Row>
            <div className="btns-block modal-footer">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={azurePrices.save.loading || common.save.loading}
              >
                {submitButtonText}
              </Button>
              <Button key="back" onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default AddAzurePricesModal;
