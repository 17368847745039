import React, { useRef, useState } from 'react';
import { IDetailDataTableProps } from './detailDataTable.model';
import _ from 'lodash';
import DataTable from '../../../../../common/components/DataTable';
import {
  FilterWithSwapOption,
  FilterByDropdown,
} from '../../../../../common/components/DataTable/DataTableFilters';
import { AlignType } from 'rc-table/lib/interface';
import windowsServerLicenseDetailService from '../../../../../services/windowsServer/windowsServerLicenseDetail/windowsServerLicenseDetail.service';
import { useAppSelector } from '../../../../../store/app.hooks';
import { searchWindowsServerLicenseDetail } from '../../../../../store/windowsServer/windowsServerLicenseDetail/windowsServerLicenseDetail.action';
import {
  setTableColumnSelection,
  windowsServerLicenseDetailSelector,
} from '../../../../../store/windowsServer/windowsServerLicenseDetail/windowsServerLicenseDetail.reducer';
import { ISearchWindowsServerLicenseDetail } from '../../../../../services/windowsServer/windowsServerLicenseDetail/windowsServerLicenseDetail.model';
import { Checkbox } from 'antd';
import { showDateFromApi } from '../../../../../common/helperFunction';

const DetailDataTable: React.FC<IDetailDataTableProps> = (props) => {
  const { licenseId } = props;
  const windowsServerLicenseDetail = useAppSelector(windowsServerLicenseDetailSelector);
  const dataTableRef = useRef(null);
  const [ObjectForColumnFilter, setObjectForColumnFilter] = useState({});

  const extraSearchData = {
    windows_server_license_id: licenseId,
  };

  const exportExcelFile = (searchData: ISearchWindowsServerLicenseDetail) => {
    return windowsServerLicenseDetailService.exportExcelFile(searchData);
  };

  const getColumnLookup = (data: {}) => {
    return windowsServerLicenseDetailService
      .getLicenseDetailColumnLookup(licenseId, data)
      .then((res) => {
        return res.body.data;
      });
  };

  const FilterBySwap = (dataIndex: string, form, isRelativeDropDownFlag?: boolean, isInventory?: boolean, additional_column?: string, switchDropdown?: boolean) => {
    return FilterWithSwapOption(
      dataIndex,
      windowsServerLicenseDetail.search.tableName,
      form,
      getColumnLookup,
      ObjectForColumnFilter,
      null,
      null,
      isRelativeDropDownFlag,
      false,
      isInventory,
      additional_column,
      switchDropdown
    );
  };

  const getTableColumns = (form) => {
    return [
      {
        title: <span className="dragHandler">ID</span>,
        column: 'id',
        sorter: true,
        children: [
          {
            title: FilterBySwap('id', form),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Windows Server Id</span>,
        column: 'WindowsServerId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('windows_server_id', form),
            dataIndex: 'windows_server_id',
            key: 'windows_server_id',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Tenant Name</span>,
        column: 'SqlServerTenantId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'TenantId'),
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Company Name</span>,
        column: 'SqlServerCompanyId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'CompanyId'),
            dataIndex: 'company_name',
            key: 'company_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">BU Name</span>,
        column: 'SqlServerBUId',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'BU_Id'),
            dataIndex: 'bu_name',
            key: 'bu_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Date Added</span>,
        column: 'WindowsServerDateAdded',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'Date Added'),
            dataIndex: 'windows_server_license_date_added',
            key: 'windows_server_license_date_added',
            ellipsis: true,
            render: (date: Date) => (!_.isNull(date) ? showDateFromApi(date) : ''),
          },
        ],
      },
      {
        title: <span className="dragHandler">Source</span>,
        column: 'WindowsServer',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Source', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_source',
            key: 'windows_server_source',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Agreement Type</span>,
        column: 'WindowsServerLicenseAgreementType',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'Opt_AgreementType'),
            dataIndex: 'windows_server_license_agreement_type',
            key: 'windows_server_license_agreement_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Exclude Non Prod</span>,
        column: 'WindowsServerLicenseExcludeNonProd',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'Opt_ExcludeNonProd'),
            dataIndex: 'windows_server_license_exclude_non_prod',
            key: 'windows_server_license_exclude_non_prod',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Default to Datacenter on Host</span>,
        column: 'WindowsServerLicenseDefaulttoEnterprise',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'Opt_DefaultToDatacenterOnHosts'),
            dataIndex: 'windows_server_license_default_to_enterprise',
            key: 'windows_server_license_default_to_enterprise',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assign Entitlements</span>,
        column: 'WindowsServerLicenseAssignEntitlements',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'Opt_Entitlements'),
            dataIndex: 'windows_server_license_assign_entitlements',
            key: 'windows_server_license_assign_entitlements',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Notes( Name )</span>,
        column: 'WindowsServerLicenseNotes',
        sorter: true,
        children: [
          {
            title: FilterBySwap('windows_server_license_id', form, true, true, 'Notes'),
            dataIndex: 'windows_server_license_notes',
            key: 'windows_server_license_notes',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Data Center</span>,
        column: 'WindowsServerDataCenter',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Datacenter', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_data_center',
            key: 'windows_server_data_center',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster</span>,
        column: 'WindowsServerCluster',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Cluster', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_cluster',
            key: 'windows_server_cluster',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">HA Enabled</span>,
        column: 'HA enabled',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('HA enabled', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_ha_enabled',
            key: 'windows_server_ha_enabled',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Drs Enabled</span>,
        column: 'DRS enabled',
        sorter: true,
        children: [
          {
            title: FilterBySwap('DRS Enabled', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_drs_enabled',
            key: 'windows_server_drs_enabled',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host</span>,
        column: 'WindowsServerHost',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Host', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_host',
            key: 'windows_server_host',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Procs</span>,
        column: 'WindowsServerProcs',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Procs', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_procs',
            key: 'windows_server_procs',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cores</span>,
        column: 'WindowsServerCores',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Cores', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_cores',
            key: 'windows_server_cores',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Device Name</span>,
        column: 'WindowsServerDeviceName',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Device Name', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_device_name',
            key: 'windows_server_device_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Device Type</span>,
        column: 'WindowsServerDevice Type',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Device Type', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_device_type',
            key: 'windows_server_device_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Operating System</span>,
        column: 'WindowsServerOS',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Operating System', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_os',
            key: 'windows_server_os',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Product Family</span>,
        column: 'WindowsServerProductFamily',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Product Family', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_product_family',
            key: 'windows_server_product_family',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Edition</span>,
        column: 'WindowsServerEdition',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Edition', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_edition',
            key: 'windows_server_edition',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Version</span>,
        column: 'WindowsServerVersion',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Version', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_version',
            key: 'windows_server_version',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC Version</span>,
        column: 'WindowsServerSCVersion',
        sorter: true,
        children: [
          {
            title: FilterBySwap('SC Version', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_sc_version',
            key: 'windows_server_sc_version',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">V Cpu</span>,
        column: 'WindowsServerVCPU',
        sorter: true,
        children: [
          {
            title: FilterBySwap('vCPU', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_vcpu',
            key: 'windows_server_vcpu',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Device State</span>,
        column: 'WindowsServerDeviceState',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Device State', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_device_state',
            key: 'windows_server_device_state',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Software State</span>,
        column: 'WindowsServerSoftwareState',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Software State', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_software_state',
            key: 'windows_server_software_state',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">FQDN</span>,
        column: 'WindowsServerFQDN',
        sorter: true,
        children: [
          {
            title: FilterBySwap('FQDN', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_fqdn',
            key: 'windows_server_fqdn',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Serial Number</span>,
        column: 'WindowsServerSerialNumber',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Serial Number', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_serial_num',
            key: 'windows_server_serial_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cost Code</span>,
        column: 'WindowsServerCostCode',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Cost Code', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_cost_code',
            key: 'windows_server_cost_code',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Line Of Business</span>,
        column: 'WindowsServerLineOfBusiness',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Line of Business', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_line_of_business',
            key: 'windows_server_line_of_business',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Market</span>,
        column: 'WindowsServerMarket',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Market', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_market',
            key: 'windows_server_market',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Application</span>,
        column: 'WindowsServerApplication',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Application', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_application',
            key: 'windows_server_application',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Exempt</span>,
        column: 'WindowsServerExempt',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Exempt', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_exempt',
            key: 'windows_server_exempt',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC Exempt</span>,
        column: 'WindowsServerSCExempt',
        sorter: true,
        children: [
          {
            title: FilterBySwap('SC Exempt', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_sc_exempt',
            key: 'windows_server_sc_exempt',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC Server</span>,
        column: 'WindowsServerSCServer',
        sorter: true,
        children: [
          {
            title: FilterBySwap('SC Server', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_sc_server',
            key: 'windows_server_sc_server',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC Agent</span>,
        column: 'WindowsServerSCAgent',
        sorter: true,
        children: [
          {
            title: FilterBySwap('SC Agent', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_sc_agent',
            key: 'windows_server_sc_agent',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Azure Hosted</span>,
        column: 'WindowsServerAzure Hosted',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Azure Hosted', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_azure_hosted',
            key: 'windows_server_azure_hosted',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">AWS Hosted</span>,
        column: 'WindowsServerAWSHosted',
        sorter: true,
        children: [
          {
            title: FilterBySwap('AWS Hosted', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_aws_hosted',
            key: 'windows_server_aws_hosted',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cloud BYOL</span>,
        column: 'WindowsServerCloudBYOL',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Cloud BYOL', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_cloud_byol',
            key: 'windows_server_cloud_byol',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Orphaned Vm</span>,
        column: 'OrphanedVM',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByDropdown(
              'orphaned_vm',
              windowsServerLicenseDetail.search.lookups?.booleanLookup
            ),
            dataIndex: 'orphaned_vm',
            key: 'orphaned_vm',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Licensable</span>,
        column: 'Licensable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByDropdown(
              'licensable',
              windowsServerLicenseDetail.search.lookups?.booleanLookup
            ),
            dataIndex: 'licensable',
            key: 'licensable',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">Notes</span>,
        column: 'Notes',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('notes', form),
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC Licensable</span>,
        column: 'SC Licensable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterByDropdown(
              'sc_licensable',
              windowsServerLicenseDetail.search.lookups?.booleanLookup
            ),
            dataIndex: 'sc_licensable',
            key: 'sc_licensable',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC Notes</span>,
        column: 'SC Notes',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sc_notes', form),
            dataIndex: 'sc_notes',
            key: 'sc_notes',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Normalized Version</span>,
        column: 'OS Version Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('os_version_id', form, true),
            dataIndex: 'os_version_name',
            key: 'os_version_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Normalized Edition</span>,
        column: 'OS Edition Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('os_edition_id', form, true),
            dataIndex: 'os_edition_name',
            key: 'os_edition_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Normalized SC Version</span>,
        column: 'SC Version ID',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sc_version_id', form, true),
            dataIndex: 'sc_version_name',
            key: 'sc_version_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Max Version</span>,
        column: 'Max OS Version Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('max_os_version_id', form, true),
            dataIndex: 'max_os_version_name',
            key: 'max_os_version_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Max Edition</span>,
        column: 'Max OS Edition Name',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('max_os_edition_id', form, true),
            dataIndex: 'max_os_edition_name',
            key: 'max_os_edition_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Max SC Version</span>,
        column: 'MaxSCVersionId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('max_sc_version_id', form, true),
            dataIndex: 'max_sc_version_name',
            key: 'max_sc_version_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster Seq Num</span>,
        column: 'ClusterSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('cluster_seq_num', form),
            dataIndex: 'cluster_seq_num',
            key: 'cluster_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host Seq Num</span>,
        column: 'HostSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('host_seq_num', form),
            dataIndex: 'host_seq_num',
            key: 'host_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Device Seq Num</span>,
        column: 'DeviceSeqNum',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('device_seq_num', form),
            dataIndex: 'device_seq_num',
            key: 'device_seq_num',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host - Num of VMs</span>,
        column: 'Host - Num of VMs',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('host_num_of_v_ms', form),
            dataIndex: 'host_num_of_v_ms',
            key: 'host_num_of_v_ms',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host - Num of VMs Licensable</span>,
        column: 'Host - Num of VMs Licensable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('host_num_of_v_ms_licensable', form),
            dataIndex: 'host_num_of_v_ms_licensable',
            key: 'host_num_of_v_ms_licensable',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host - Num VMs with WS DC</span>,
        column: 'Host - Num VMs with WS DC',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('host_num_v_ms_with_ws_dc', form),
            dataIndex: 'host_num_v_ms_with_ws_dc',
            key: 'host_num_v_ms_with_ws_dc',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Host - SC Num of VMs Licensable</span>,
        column: 'Host - SC Num of VMs Licensable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('host_sc_num_of_v_ms_licensable', form),
            dataIndex: 'host_sc_num_of_v_ms_licensable',
            key: 'host_sc_num_of_v_ms_licensable',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster - Num of VMs</span>,
        column: 'Cluster - Num of VMs',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('cluster_num_of_v_ms', form),
            dataIndex: 'cluster_num_of_v_ms',
            key: 'cluster_num_of_v_ms',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster - Num of VMs Licensable</span>,
        column: 'Cluster - Num of VMs Licensable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('cluster_num_of_v_ms_licensable', form),
            dataIndex: 'cluster_num_of_v_ms_licensable',
            key: 'cluster_num_of_v_ms_licensable',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster - Num VMs with WS DC</span>,
        column: 'Cluster - Num VMs with WS DC',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('cluster_num_v_ms_with_ws_dc', form),
            dataIndex: 'cluster_num_v_ms_with_ws_dc',
            key: 'cluster_num_v_ms_with_ws_dc',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Cluster - SC Num of VMs Licensable</span>,
        column: 'Cluster - SC Num of VMs Licensable',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('cluster_sc_num_of_v_ms_licensable', form),
            dataIndex: 'cluster_sc_num_of_v_ms_licensable',
            key: 'cluster_sc_num_of_v_ms_licensable',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">WS Effective Processors</span>,
        column: 'WSEffectiveProcessors',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('ws_effective_processors', form),
            dataIndex: 'ws_effective_processors',
            key: 'ws_effective_processors',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">WS Effective Cores</span>,
        column: 'WSEffectiveCores',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('ws_effective_cores', form),
            dataIndex: 'ws_effective_cores',
            key: 'ws_effective_cores',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">WS License Multiplier</span>,
        column: 'WSLicenseMultiplier',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('ws_license_multiplier', form),
            dataIndex: 'ws_license_multiplier',
            key: 'ws_license_multiplier',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">WS License Type</span>,
        column: 'WS License Type',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('ws_license_type', form),
            dataIndex: 'ws_license_type',
            key: 'ws_license_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">WS License Count</span>,
        column: 'WSLicenseCount',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('ws_license_count', form),
            dataIndex: 'ws_license_count',
            key: 'ws_license_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">WS License Cost</span>,
        column: 'WSLicenseCost',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('ws_license_cost', form),
            dataIndex: 'ws_license_cost',
            key: 'ws_license_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC License Multiplier</span>,
        column: 'SCLicenseMultiplier',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sc_license_multiplier', form),
            dataIndex: 'sc_license_multiplier',
            key: 'sc_license_multiplier',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC License Type</span>,
        column: 'SCLicenseType',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sc_license_type', form),
            dataIndex: 'sc_license_type',
            key: 'sc_license_type',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC License Count</span>,
        column: 'SCLicenseCount',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sc_license_count', form),
            dataIndex: 'sc_license_count',
            key: 'sc_license_count',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC License Cost</span>,
        column: 'SCLicenseCost',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sc_license_cost', form),
            dataIndex: 'sc_license_cost',
            key: 'sc_license_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - WS DC Core Licenses</span>,
        column: 'S - WS DC Core Licenses',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_ws_dc_core_licenses', form),
            dataIndex: 's_ws_dc_core_licenses',
            key: 's_ws_dc_core_licenses',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - WS Std Core Licenses</span>,
        column: 'S - WS Std Core Licenses',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_ws_std_core_licenses', form),
            dataIndex: 's_ws_std_core_licenses',
            key: 's_ws_std_core_licenses',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - WS DC Core Licenses Cost</span>,
        column: 'S - WS DC Core Licenses Cost',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_ws_dc_core_licenses_cost', form),
            dataIndex: 's_ws_dc_core_licenses_cost',
            key: 's_ws_dc_core_licenses_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - WS Std Core Licenses Cost</span>,
        column: 'S - WS Std Core Licenses Cost',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_ws_std_core_licenses_cost', form),
            dataIndex: 's_ws_std_core_licenses_cost',
            key: 's_ws_std_core_licenses_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - SC DC Core Licenses</span>,
        column: 'S - SC DC Core Licenses',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_sc_dc_core_licenses', form),
            dataIndex: 's_sc_dc_core_licenses',
            key: 's_sc_dc_core_licenses',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - SC Std Core Licenses</span>,
        column: 'S - SC Std Core Licenses',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_sc_std_core_licenses', form),
            dataIndex: 's_sc_std_core_licenses',
            key: 's_sc_std_core_licenses',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - SC DC Core Licenses Cost</span>,
        column: 'S - SC DC Core Licenses Cost',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_sc_dc_core_licenses_cost', form),
            dataIndex: 's_sc_dc_core_licenses_cost',
            key: 's_sc_dc_core_licenses_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">S - SC Std Core Licenses Cost</span>,
        column: 'S - SC Std Core Licenses Cost',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('s_sc_std_core_licenses_cost', form),
            dataIndex: 's_sc_std_core_licenses_cost',
            key: 's_sc_std_core_licenses_cost',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Product Name</span>,
        column: 'LicenseId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('license_id', form, true),
            dataIndex: 'license_product_name',
            key: 'license_product_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">License Qty</span>,
        column: 'LicenseQty',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('license_qty', form),
            dataIndex: 'license_qty',
            key: 'license_qty',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC License Product Name</span>,
        column: 'SCLicenseId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sc_license_id', form, true),
            dataIndex: 'sc_license_name',
            key: 'sc_license_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">SC License Qty</span>,
        column: 'SCLicenseQty',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('sc_license_qty', form),
            dataIndex: 'sc_license_qty',
            key: 'sc_license_qty',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assigned License Product Name</span>,
        column: 'AssignedLicenseId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('assigned_license_id', form, true),
            dataIndex: 'assigned_license_product_name',
            key: 'assigned_license_product_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assigned License Qty</span>,
        column: 'AssignedLicenseQty',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('assigned_license_qty', form),
            dataIndex: 'assigned_license_qty',
            key: 'assigned_license_qty',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assigned SC License Product Name</span>,
        column: 'AssignedSCLicenseId',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('assigned_sc_license_id', form, true),
            dataIndex: 'assigned_sc_license_product_name',
            key: 'assigned_sc_license_product_name',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Assigned SC License Qty</span>,
        column: 'AssignedSCLicenseQty',
        sorter: true,
        ellipsis: true,
        children: [
          {
            title: FilterBySwap('assigned_sc_license_qty', form),
            dataIndex: 'assigned_sc_license_qty',
            key: 'assigned_sc_license_qty',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Install Date</span>,
        column: 'WindowsServerInstallDate',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Install Date', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_install_date',
            key: 'windows_server_install_date',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Department</span>,
        column: 'WindowsServerDepartment',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Department', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_department',
            key: 'windows_server_department',
            ellipsis: true,
          },
        ],
      },
      {
        title: <span className="dragHandler">Other Cloud Hosted</span>,
        column: 'WindowsServerOtherCloudHosted',
        sorter: true,
        children: [
          {
            title: FilterBySwap('Other Cloud Hosted', form, true, true, 'windows_server_id', true),
            dataIndex: 'windows_server_other_cloud_hosted',
            key: 'windows_server_other_cloud_hosted',
            ellipsis: true,
            render: (value: boolean) =>
              !_.isNull(value) ? (
                value ? (
                  <Checkbox checked disabled />
                ) : (
                  <Checkbox checked={false} disabled />
                )
              ) : (
                ''
              ),
            align: 'center' as AlignType,
          },
        ],
      },
    ];
  };

  return (
    <>
      <DataTable
        ref={dataTableRef}
        showAddButton={false}
        globalSearchExist={false}
        showDelete={false}
        disableRowSelection={true}
        exportExcelFile={exportExcelFile}
        getTableColumns={getTableColumns}
        reduxSelector={windowsServerLicenseDetailSelector}
        searchTableData={searchWindowsServerLicenseDetail}
        setTableColumnSelection={setTableColumnSelection}
        defaultOrderBy="id"
        extraSearchData={extraSearchData}
        isSpsApiJobsId={true}
        setObjectForColumnFilter={setObjectForColumnFilter}
      />
    </>
  );
};

export default DetailDataTable;
