import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IConfigSoftware,
  ISearchConfigSoftware,
} from '../../../services/master/configSoftware/configSoftware.model';
import configSoftware from '../../../services/master/configSoftware/configSoftware.service';
// Asynchronous thunk action

export const searchConfigSoftware = createAsyncThunk(
  'searchConfigSoftware',
  async (searchParam?: ISearchConfigSoftware) => {
    const response = await configSoftware.searchConfigSoftware(searchParam).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const getConfigSoftwareById = createAsyncThunk(
  'getConfigSoftwareById',
  async (id: number) => {
    const response = await configSoftware.getConfigSoftwareById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveConfigSoftware = createAsyncThunk(
  'saveConfigSoftware',
  async (data: IConfigSoftware) => {
    const response = await configSoftware.saveConfigSoftware(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteConfigSoftware = createAsyncThunk(
  'deleteConfigSoftware',
  async (id: number) => {
    const response = await configSoftware.deleteConfigSoftware(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
