import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISnowComputers,
  ISearchSnowComputers,
} from '../../../services/snow/snowComputers/snowComputers.model';
import snowComputersService from '../../../services/snow/snowComputers/snowComputers.service';

// Asynchronous thunk action

export const searchSnowComputers = createAsyncThunk(
  'searchSnowComputers',
  async (searchParam?: ISearchSnowComputers) => {
    const response = await snowComputersService
      .searchSnowComputers(searchParam)
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getSnowComputersById = createAsyncThunk(
  'getSnowComputersById',
  async (id: number) => {
    const response = await snowComputersService.getSnowComputersById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const saveSnowComputers = createAsyncThunk(
  'saveSnowComputers',
  async (data: ISnowComputers) => {
    const response = await snowComputersService.saveSnowComputers(data).then((res) => {
      return res.body;
    });
    return response;
  }
);

export const deleteSnowComputers = createAsyncThunk(
  'deleteSnowComputers',
  async (id: number) => {
    const response = await snowComputersService.deleteSnowComputers(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
