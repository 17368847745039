import { createAsyncThunk } from '@reduxjs/toolkit';
import errorLogFileService from '../../../services/master/errorLogFile/errorLogFile.service';

// Asynchronous thunk action

export const searchErrorLogFile = createAsyncThunk(
  'searchErrorLogFile',
  async () => {
    const response = await errorLogFileService
      .searchErrorLogFile()
      .then((res) => {
        return res.body;
      });
    return response.data;
  }
);

export const getErrorLogFileById = createAsyncThunk(
  'getErrorLogFileById',
  async (id: number) => {
    const response = await errorLogFileService.getErrorLogFileById(id).then((res) => {
      return res.body;
    });
    return response.data;
  }
);

export const deleteErrorLogFile = createAsyncThunk(
  'deleteErrorLogFile',
  async (id: number) => {
    const response = await errorLogFileService.deleteErrorLogFile(id).then((res) => {
      return res.body;
    });
    return response;
  }
);
