import { Button, Col, DatePicker, Form, Row, Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import GlobalSearch from '../../common/components/globalSearch/GlobalSearch';
import { IInlineSearch } from '../../common/models/common';
import { ILookup } from '../../services/common/common.model';
import { useAppDispatch, useAppSelector } from '../../store/app.hooks';
import { getBackgroundProcessGraphDetails, getGraphBackgroundProcessTypesModule, getGraphDetails, getGraphSPSApiJobsModule, getGraphSPSAPITypesModule, getGraphSPSSchedulerTypesModule, getSPSApiGraphDetails, getSPSSchedulerGraphDetails } from '../../store/common/common.action';
import { commonSelector } from '../../store/common/common.reducer';
import { globalSearchSelector } from '../../store/globalSearch/globalSearch.reducer';
import { BarGraph } from './barGraphs';
import { PieChart } from './pieCharts';
import _ from 'lodash';
import { Common } from '../../common/constants/common';
import moment from 'moment';
import { AreaChartOutlined, PieChartOutlined } from '@ant-design/icons';
import { Can } from '../../common/ability';
import { Action, Page } from '../../common/constants/pageAction';

const { RangePicker } = DatePicker;
const { Option } = Select;

export const Dashboard: React.FC = () => {
  const commonLookups = useAppSelector(commonSelector);
  const dispatch = useAppDispatch();
  const globalFilters = useAppSelector(globalSearchSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getGraphSPSApiJobsModule());
    dispatch(getGraphBackgroundProcessTypesModule());
    dispatch(getGraphSPSAPITypesModule());
    dispatch(getGraphSPSSchedulerTypesModule());
  }, []);

  useEffect(() => {
    if (commonLookups?.filterGraphSPSApiJobstype?.filters?.length > 0) {
      form.setFieldsValue({
        range_api_jobs: [
          moment().subtract(1, 'month'),
          moment(),
        ],
        sps_api_jobs: 'Status',
      });
    }
  }, [commonLookups?.filterGraphSPSApiJobstype?.start_date]);

  useEffect(() => {
    if (commonLookups?.filterGraphBackgroundProcesstype?.filters?.length > 0) {
      form.setFieldsValue({
        range_background_process: [
          moment().subtract(1, 'month'),
          moment(),
        ],
        background_process: 'Status',
      });
    }
  }, [commonLookups?.filterGraphBackgroundProcesstype?.start_date]);

  useEffect(() => {
    if (commonLookups?.filterGraphSPSSchedulertype?.filters?.length > 0) {
      form.setFieldsValue({
        sps_scheduler_types: 'Status'
      });
    }
  }, [commonLookups?.filterGraphSPSSchedulertype?.start_date]);

  useEffect(() => {
    if (commonLookups?.filterGraphSPSApitype?.filters?.length > 0) {
      form.setFieldsValue({
        sps_api_types: 'API Type',
      });
    }
  }, [commonLookups?.filterGraphSPSApitype?.start_date]);

  React.useEffect(() => {
    getGraphforSPSSchedule();
    getGraphsForSPSApiType();
    getGraphforBackgroundProcess();
    getGraphforSPSJobs();
  }, [globalFilters?.globalCompanyLookup?.data, globalFilters?.globalBULookup?.data, globalFilters?.search.bu_id]);


  const getGraphforSPSJobs = () => {
    const globalSearch: IInlineSearch = {};
    for (const key in globalFilters.search) {
      const element = globalFilters.search[key];
      globalSearch[key] = element ? [element] : null;
    }
    const fieldValues = form.getFieldValue('range_api_jobs');
    const data = {
      graph_by_types: form.getFieldValue('sps_api_jobs') ? form.getFieldValue('sps_api_jobs') : 'Status',
      start_date: fieldValues ? moment(fieldValues[0]).local() : moment().subtract(1, 'month').format(Common.DATETIMEFORMAT),
      end_date: fieldValues ? moment(fieldValues[1]).local() : moment().format(Common.DATETIMEFORMAT),
      company_id: _.isNull(globalSearch.company_id) || globalSearch.company_id == undefined ? null : globalSearch.company_id[0],
      bu_id: _.isNull(globalSearch.bu_id) || globalSearch.bu_id == undefined ? null : globalSearch.bu_id[0],
      tenant_id: _.isNull(globalSearch.tenant_id) || globalSearch.tenant_id == undefined ? null : globalSearch.tenant_id[0],
    };
    dispatch(getGraphDetails(data));
  };

  const getGraphsForSPSApiType = () => {
    const globalSearch: IInlineSearch = {};
    for (const key in globalFilters.search) {
      const element = globalFilters.search[key];
      globalSearch[key] = element ? [element] : null;
    }
    const data = {
      graph_by_types: form.getFieldValue('sps_api_types') ? form.getFieldValue('sps_api_types') : 'API Type',
      company_id: _.isNull(globalSearch.company_id) || globalSearch.company_id == undefined ? null : globalSearch.company_id[0],
      bu_id: _.isNull(globalSearch.bu_id) || globalSearch.bu_id == undefined ? null : globalSearch.bu_id[0],
      tenant_id: _.isNull(globalSearch.tenant_id) || globalSearch.tenant_id == undefined ? null : globalSearch.tenant_id[0],
    };
    dispatch(getSPSApiGraphDetails(data));
  };

  const getGraphforSPSSchedule = () => {
    const globalSearch: IInlineSearch = {};
    for (const key in globalFilters.search) {
      const element = globalFilters.search[key];
      globalSearch[key] = element ? [element] : null;
    }
    const data = {
      graph_by_types: form.getFieldValue('sps_scheduler_types') ? form.getFieldValue('sps_scheduler_types') : 'Status',
      company_id: _.isNull(globalSearch.company_id) || globalSearch.company_id == undefined ? null : globalSearch.company_id[0],
      bu_id: _.isNull(globalSearch.bu_id) || globalSearch.bu_id == undefined ? null : globalSearch.bu_id[0],
      tenant_id: _.isNull(globalSearch.tenant_id) || globalSearch.tenant_id == undefined ? null : globalSearch.tenant_id[0],
    };
    dispatch(getSPSSchedulerGraphDetails(data));
  };

  const getGraphforBackgroundProcess = () => {
    const globalSearch: IInlineSearch = {};
    for (const key in globalFilters.search) {
      const element = globalFilters.search[key];
      globalSearch[key] = element ? [element] : null;
    }
    const fieldValues = form.getFieldValue('range_background_process');
    const data = {
      graph_by_types: form.getFieldValue('background_process') ? form.getFieldValue('background_process') : 'Status',
      start_date: fieldValues ? moment(fieldValues[0]).local() : moment().subtract(1, 'month').format(Common.DATETIMEFORMAT),
      end_date: fieldValues ? moment(fieldValues[1]).local() : moment().format(Common.DATETIMEFORMAT),
      company_id: _.isNull(globalSearch.company_id) || globalSearch.company_id == undefined ? null : globalSearch.company_id[0],
      bu_id: _.isNull(globalSearch.bu_id) || globalSearch.bu_id == undefined ? null : globalSearch.bu_id[0],
      tenant_id: _.isNull(globalSearch.tenant_id) || globalSearch.tenant_id == undefined ? null : globalSearch.tenant_id[0],
    };
    dispatch(getBackgroundProcessGraphDetails(data));
  };

  return (
    <>
      <div className="sqlServer">
        <div className="title-block">
          <h4 className="p-0">
            <h1>&gt; Dashboard</h1>
          </h4>
          <div className="right-title">
            <GlobalSearch />
          </div>
        </div>
        <Form
          form={form}
          name="dashboardGraph"
        >
          <Row gutter={[30, 15]} className="align-item-start">
            <Can I={Action.View} a={Page.SpsApiJobs}>
              <Col xs={24} md={12}>
                <div className='bar-graph'>
                  <div className="main-card">
                    <h1 className='graph-heading'>SPS API Jobs</h1>
                    <Row gutter={[30, 30]} className="align-item-start">
                      <Col xs={24} md={8}>
                        <div className="form-group m-0">
                          <label className="label">Type of Graph</label>
                          <Form.Item
                            name="sps_api_jobs"
                            className="m-0"
                          >
                            <Select
                              allowClear={false}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA: any, optionB: any) =>
                                optionA.children
                                  ?.toLowerCase()
                                  ?.localeCompare(optionB.children?.toLowerCase())
                              }
                              loading={commonLookups.tenantLookup.loading}
                            >
                              {commonLookups?.filterGraphSPSApiJobstype?.filters.map((option: ILookup) => (
                                <Option key={option.id} value={option.id}>
                                  {option.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={12}>
                        <div className="form-group m-0">
                          <label className="label">Range of Date</label>
                          <Form.Item name="range_api_jobs" className="m-0">
                            <RangePicker allowClear={false} className="form-control w-100" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={4}>
                        <div className="form-group m-0">
                          <label className="label"></label>
                          <Form.Item name="range_api_jobs" className="m-0">
                            <Button onClick={() => { getGraphforSPSJobs() }} >
                              <AreaChartOutlined className="form-control w-100" />
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    {/* <h1>Bar Graphs</h1> */}
                    {commonLookups.getGraphDetails.data.length > 0 && (commonLookups.getGraphDetails.loading ? (
                      <Spin spinning={commonLookups.getGraphDetails.loading} />
                    ) : (<BarGraph isSpsJobApi={true} />))}
                  </div>
                </div>
              </Col >
            </Can>
            <Can I={Action.View} a={Page.BackgroundProcesses}>
              <Col xs={24} md={12}>
                <div className='pie-chart'>
                  <div className="main-card">
                    <h1 className='graph-heading'>Background Processes</h1>
                    <Row gutter={[30, 30]} className="align-item-start">
                      <Col xs={24} md={8}>
                        <div className="form-group m-0">
                          <label className="label">Type of Graph</label>
                          <Form.Item
                            name="background_process"
                            className="m-0"
                          >
                            <Select
                              allowClear={false}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA: any, optionB: any) =>
                                optionA.children
                                  ?.toLowerCase()
                                  ?.localeCompare(optionB.children?.toLowerCase())
                              }
                              loading={commonLookups.tenantLookup.loading}
                            >
                              {commonLookups?.filterGraphBackgroundProcesstype?.filters.map((option: ILookup) => (
                                <Option key={option.id} value={option.id}>
                                  {option.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={12}>
                        <div className="form-group m-0">
                          <label className="label">Range of Date</label>
                          <Form.Item name="range_background_process" className="m-0">
                            <RangePicker allowClear={false} className="form-control w-100" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={4}>
                        <div className="form-group m-0">
                          <label className="label"></label>
                          <Form.Item name="button2" className="m-0">
                            <Button onClick={() => { getGraphforBackgroundProcess() }} >
                              <PieChartOutlined className="form-control w-100" />
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    {/* <h1>Pie Charts</h1> */}
                    {commonLookups.getBackgroundProcessGraphDetails.data?.length > 0 && (commonLookups.getBackgroundProcessGraphDetails.loading ? (<Spin spinning={commonLookups.getBackgroundProcessGraphDetails.loading} />) : (<PieChart isBackgroundProcess={true} />))}
                  </div>
                </div>
              </Col>
            </Can>
          </Row >
          {/* This is for 2nd row */}
          <br />
          <hr />
          <br />
          <Row gutter={[30, 30]} className="align-item-start">
            <Can I={Action.View} a={Page.Cron}>
              <Col xs={24} md={12}>
                <div className='bar-graph'>
                  <div className="main-card">
                    <h3 className='graph-heading'>SPS Scheduler Types</h3>
                    <Row gutter={[30, 30]} className="align-item-start">
                      <Col xs={24} md={8}>
                        <div className="form-group m-0">
                          <label className="label">Type of Graph</label>
                          <Form.Item
                            name="sps_scheduler_types"
                            className="m-0"
                          >
                            <Select
                              allowClear={false}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA: any, optionB: any) =>
                                optionA.children
                                  ?.toLowerCase()
                                  ?.localeCompare(optionB.children?.toLowerCase())
                              }
                              loading={commonLookups.tenantLookup.loading}
                            >
                              {commonLookups?.filterGraphSPSSchedulertype?.filters.map((option: ILookup) => (
                                <Option key={option.id} value={option.id}>
                                  {option.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={4}>
                        <div className="form-group m-0">
                          <label className="label"></label>
                          <Form.Item name="button3" className="m-0">
                            <Button onClick={() => { getGraphforSPSSchedule() }} >
                              <AreaChartOutlined className="form-control w-100" />
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    {/* <h1>Bar Graphs</h1> */}
                    {commonLookups.getSPSSchedulerGraphDetails.data.length > 0 && (commonLookups.getSPSSchedulerGraphDetails.loading ? (<Spin spinning={commonLookups.getSPSSchedulerGraphDetails.loading} />) : (<BarGraph isSpsJobApi={false} />))}
                  </div>
                </div>
              </Col >
            </Can>
            <Can I={Action.View} a={Page.SPSApiCall}>
              <Col xs={24} md={12}>
                <div className='pie-chart'>
                  <div className="main-card">
                    <h1 className='graph-heading'>SPS API Types</h1>
                    <Row gutter={[30, 30]} className="align-item-start">
                      <Col xs={24} md={8}>
                        <div className="form-group m-0">
                          <label className="label">Type of Graph</label>
                          <Form.Item
                            name="sps_api_types"
                            className="m-0"
                          >
                            <Select
                              allowClear={false}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA: any, optionB: any) =>
                                optionA.children
                                  ?.toLowerCase()
                                  ?.localeCompare(optionB.children?.toLowerCase())
                              }
                              loading={commonLookups.tenantLookup.loading}
                            >
                              {commonLookups?.filterGraphSPSApitype?.filters.map((option: ILookup) => (
                                <Option key={option.id} value={option.id}>
                                  {option.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={4}>
                        <div className="form-group m-0">
                          <label className="label"></label>
                          <Form.Item name="button4" className="m-0">
                            <Button onClick={() => { getGraphsForSPSApiType() }} >
                              <PieChartOutlined className="form-control w-100" />
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    {commonLookups.getSPSApiGraphDetails.data?.length > 0 && (commonLookups.getSPSApiGraphDetails.loading ? (<Spin spinning={commonLookups.getSPSApiGraphDetails.loading} />) : (<PieChart isBackgroundProcess={false} />))}
                  </div>
                </div>
              </Col>
            </Can>
          </Row >
        </Form>
      </div >
    </>
  )
};
